import { fork, put, delay, takeLatest, select, all } from "redux-saga/effects";
import { API } from "./api";
import { getIn } from "../../../../utils/app-utils";
import callApi from "../../../../utils/callApi";

import {
    getRole,
    getRolesFailure,
    getRolesSuccess,
    getRoleFailure,
    getRoleSuccess,
    RoleActionTypes,
    addRoleSuccess,
    addRoleFailure,
    deleteRoleSuccess,
    deleteRoleFailure,
    updateRoleSuccess,
    updateRoleFailure,
    getRoleCapabilitiesSuccess,
    getRoleCapabilitiesFailure,
    updateRoleCapabilitySuccess,
    updateRoleCapabilityFailure,
} from "../../../actions/roleActions";
import { isLoading, openSnackbar, closeSnackbar, toggleDrawer } from "../../../../actions/feature/app/appActions";

function* getRolesSaga(reqObject) {
    const { payload } = reqObject;
    try {
        yield put(isLoading(true));
        const [error, res] = yield* callApi(API.getRoles, { disableLoading: true }, payload, true);
        if (error || res.status === "ERROR") {
            yield put(getRolesFailure({ status: "ERROR" }));
        } else {
            yield put(getRolesSuccess(res.data));
        }
        yield put(isLoading(false));
    } catch (error) {
        yield put(isLoading(false));
        yield put(getRolesFailure({ status: "ERROR" }));
    }
}

function* getRoleSaga(reqObject) {
    const { payload } = reqObject;
    try {
        yield put(isLoading(true));
        const [error, res] = yield* callApi(API.getRole, { disableLoading: true }, payload, true);
        if (error || res.status === "ERROR") {
            yield put(getRoleFailure({ status: "ERROR" }));
        } else {
            yield put(getRoleSuccess(res.data));
        }
        yield put(isLoading(false));
    } catch (error) {
        yield put(isLoading(false));
        yield put(getRoleFailure({ status: "ERROR" }));
    }
}

function* addRoleSaga(reqObject) {
    const { payload } = reqObject;
    try {
        yield put(isLoading(true));
        const [error, res] = yield* callApi(API.addRole, { disableLoading: true }, payload, true);
        if (error || res.status === "ERROR") {
            yield put(openSnackbar("Invalid error, please try again"));
            yield put(addRoleFailure({ status: "ERROR" }));
        } else {
            yield put(toggleDrawer(false));
            yield put(openSnackbar("Role added successfully"));
            yield put(addRoleSuccess(res.data));
        }

        yield put(isLoading(false));
        yield delay(3000);
        yield put(closeSnackbar());
    } catch (error) {
        yield put(isLoading(false));
        yield put(openSnackbar("Invalid error, please try again"));
        yield put(addRoleFailure({ status: "ERROR" }));
        yield delay(3000);
        yield put(closeSnackbar());
    }
}

function* updateRoleSaga(reqObject) {
    const { payload } = reqObject;
    try {
        yield put(isLoading(true));
        const [error, res] = yield* callApi(API.updateRole, { disableLoading: true }, payload, true);
        if (error || res.status === "ERROR") {
            yield put(openSnackbar("Invalid error, please try again"));
            yield put(updateRoleFailure({ status: "ERROR" }));
        } else {
            yield put(toggleDrawer(false));
            yield put(openSnackbar("Role updated successfully"));
            yield put(updateRoleSuccess(res.data));
        }

        yield put(isLoading(false));
        yield delay(3000);
        yield put(closeSnackbar());
    } catch (error) {
        yield put(isLoading(false));
        yield put(openSnackbar("Invalid error, please try again"));
        yield put(updateRoleFailure({ status: "ERROR" }));
        yield delay(3000);
        yield put(closeSnackbar());
    }
}

function* deleteRoleSaga(reqObject) {
    const { payload } = reqObject;
    try {
        yield put(isLoading(true));
        const [error, res] = yield* callApi(API.deleteRole, { disableLoading: true }, payload, true);
        if (error || res.status === "ERROR") {
            yield put(openSnackbar("Invalid error, please try again"));
            yield put(deleteRoleFailure({ status: "ERROR" }));
        } else {
            yield put(openSnackbar("Role deleted successfully"));
            yield put(deleteRoleSuccess({ id: payload }));
        }

        yield put(isLoading(false));
        yield delay(3000);
        yield put(closeSnackbar());
    } catch (error) {
        yield put(isLoading(false));
        yield put(openSnackbar("Invalid error, please try again"));
        yield put(deleteRoleFailure({ status: "ERROR" }));
        yield delay(3000);
        yield put(closeSnackbar());
    }
}

function* getRoleCapabiliites(reqObject) {
    const { payload } = reqObject;
    try {
        yield put(isLoading(true));
        const [error, res] = yield* callApi(API.getRoleCapabiliites, { disableLoading: true }, payload, true);
        if (error || res.status === "ERROR") {
            yield put(getRoleCapabilitiesFailure({ status: "ERROR" }));
        } else {
            yield put(getRoleCapabilitiesSuccess(res.data));
        }
        yield put(isLoading(false));
    } catch (error) {
        yield put(isLoading(false));
        yield put(getRoleCapabilitiesFailure({ status: "ERROR" }));
    }
}

function* updateRoleCapabilitySaga(reqObject) {
    const { payload } = reqObject;
    try {
        yield put(isLoading(true));
        const [error, res] = yield* callApi(API.updateRoleCapability, { disableLoading: true }, payload, true);
        if (error || res.status === "ERROR") {
            yield put(openSnackbar("Invalid error, please try again"));
            yield put(updateRoleCapabilityFailure({ status: "ERROR" }));
        } else {
            yield put(toggleDrawer(false));
            yield put(openSnackbar("Permissions updated successfully"));
            yield put(updateRoleCapabilitySuccess(res.data));
        }
        // console.log("error", error);
        yield put(isLoading(false));
        yield delay(3000);
        yield put(closeSnackbar());
    } catch (error) {
        console.log("error", error);
        yield put(isLoading(false));
        yield put(openSnackbar("Invalid error, please try again"));
        yield put(updateRoleCapabilityFailure({ status: "ERROR" }));
        yield delay(3000);
        yield put(closeSnackbar());
    }
}

function* watchRole() {
    yield takeLatest(RoleActionTypes.GET_ROLES, getRolesSaga);
    yield takeLatest(RoleActionTypes.GET_ROLE, getRoleSaga);
    yield takeLatest(RoleActionTypes.ADD_ROLE, addRoleSaga);
    yield takeLatest(RoleActionTypes.UPDATE_ROLE, updateRoleSaga);
    yield takeLatest(RoleActionTypes.DELETE_ROLE, deleteRoleSaga);
    yield takeLatest(RoleActionTypes.GET_ROLES_CAPABILITIES, getRoleCapabiliites);
    yield takeLatest(RoleActionTypes.UPDATE_ROLE_CAPABILITY, updateRoleCapabilitySaga);
}

const roleSagas = [fork(watchRole)];

export default roleSagas;
