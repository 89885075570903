import React, { FC, useEffect, useState, useMemo } from "react";
import { connect } from "react-redux";
import { Droppable } from "react-beautiful-dnd";
import Card from "../Card/Card";

interface PageProps {
    list: any;
}

const Cards: FC<PageProps> = ({ list }) => {
    let listEnd: any = "";
    const { tasks } = list;
    const [sortedTaskLists, setSortedTaskLists] = useState<any>([]);

    useEffect(() => {
        if (tasks[tasks.length - 2]) {
            scrollToBottom();
        }
    }, []);

    const scrollToBottom = () => {
        listEnd.scrollIntoView();
    };

    return (
        <Droppable droppableId={list?.id}>
            {(provided, { isDraggingOver }) => (
                <>
                    <div className="cards" ref={provided.innerRef}>
                        {tasks?.length > 0 &&
                            tasks
                                .sort((a: any, b: any) => a.taskOrder - b.taskOrder)
                                .map((task, index) => (
                                    <Card isDraggingOver={isDraggingOver} key={task.id} index={index} task={task} />
                                ))}
                        {provided.placeholder}
                        <div
                            style={{ float: "left", clear: "both" }}
                            ref={(el) => {
                                listEnd = el;
                            }}
                        />
                    </div>
                </>
            )}
        </Droppable>
    );
};

export default connect(null, null)(Cards);
