import { fork, put, delay, takeLatest, select, all } from "redux-saga/effects";
import { API } from "./api";
import { getIn } from "../../../../utils/app-utils";
import callApi from "../../../../utils/callApi";

import {
    getOffice,
    getOfficesFailure,
    getOfficesSuccess,
    getOfficeFailure,
    getOfficeSuccess,
    OfficeActionTypes,
    addOfficeSuccess,
    addOfficeFailure,
    deleteOfficeSuccess,
    deleteOfficeFailure,
    updateOfficeSuccess,
    updateOfficeFailure,
} from "../../../actions/officeActions";
import {
    isLoading,
    openSnackbar,
    closeSnackbar,
    toggleDrawer,
    openAlert,
} from "../../../../actions/feature/app/appActions";

function* getOfficesSaga() {
    try {
        yield put(isLoading(true));
        const [error, res] = yield* callApi(API.getOffices, { disableLoading: true }, true);
        if (error || res.status === "ERROR") {
            yield put(getOfficesFailure({ status: "ERROR" }));
        } else {
            yield put(getOfficesSuccess(res));
        }
        yield put(isLoading(false));
    } catch (error) {
        yield put(isLoading(false));
        yield put(getOfficesFailure({ status: "ERROR" }));
    }
}

function* getOfficeSaga(reqObject) {
    const { payload } = reqObject;
    try {
        yield put(isLoading(true));
        const [error, res] = yield* callApi(API.getOffice, { disableLoading: true }, payload, true);
        if (error || res.status === "ERROR") {
            yield put(getOfficeFailure({ status: "ERROR" }));
        } else {
            yield put(getOfficeSuccess(res));
        }
        yield put(isLoading(false));
    } catch (error) {
        yield put(isLoading(false));
        yield put(getOfficeFailure({ status: "ERROR" }));
    }
}

function* addOfficeSaga(reqObject) {
    const { payload } = reqObject;
    try {
        yield put(isLoading(true));
        const [error, res] = yield* callApi(API.addOffice, { disableLoading: true }, payload, true);
        if (error || res.status === "ERROR") {
            yield put(openSnackbar("Invalid error, please try again"));
            yield put(addOfficeFailure({ status: "ERROR" }));
        } else {
            yield put(toggleDrawer(false));
            yield put(openSnackbar("Office added successfully"));
            yield put(addOfficeSuccess(res));
        }

        yield put(isLoading(false));
        yield delay(3000);
        yield put(closeSnackbar());
    } catch (error) {
        yield put(isLoading(false));
        yield put(openSnackbar("Invalid error, please try again"));
        yield put(addOfficeFailure({ status: "ERROR" }));
        yield delay(3000);
        yield put(closeSnackbar());
    }
}

function* updateOfficeSaga(reqObject) {
    const { payload } = reqObject;
    try {
        yield put(isLoading(true));
        const [error, res] = yield* callApi(API.updateOffice, { disableLoading: true }, payload, true);
        if (error || res.status === "ERROR") {
            yield put(openSnackbar("Invalid error, please try again"));
            yield put(updateOfficeFailure({ status: "ERROR" }));
        } else {
            yield put(toggleDrawer(false));
            yield put(openSnackbar("Office updated successfully"));
            yield put(updateOfficeSuccess(res.data));
        }

        yield put(isLoading(false));
        yield delay(3000);
        yield put(closeSnackbar());
    } catch (error) {
        yield put(isLoading(false));
        yield put(openSnackbar("Invalid error, please try again"));
        yield put(updateOfficeFailure({ status: "ERROR" }));
        yield delay(3000);
        yield put(closeSnackbar());
    }
}

function* deleteOfficeSaga(reqObject) {
    const { payload } = reqObject;
    try {
        yield put(isLoading(true));
        const [error, res] = yield* callApi(API.deleteOffice, { disableLoading: true }, payload, true);
        if (error || res.status === "ERROR") {
            if (res.description === "OFFICE_HAS_EMPLOYEES") {
                yield put(
                    openAlert({
                        message: "Office Cannot be deleted as some employees belong to this office",
                        type: "error",
                    })
                );
            } else {
                yield put(openAlert({ message: "Invalid error, please try again", type: "error" }));
            }
            yield put(deleteOfficeFailure({ status: "ERROR" }));
        } else {
            yield put(openSnackbar("Office deleted successfully"));
            yield put(openAlert({ message: "", type: "", open: false }));
            yield put(deleteOfficeSuccess({ id: payload }));
        }

        yield put(isLoading(false));
        yield delay(3000);
        yield put(closeSnackbar());
    } catch (error) {
        yield put(isLoading(false));
        yield put(openSnackbar("Invalid error, please try again"));
        yield put(deleteOfficeFailure({ status: "ERROR" }));
        yield delay(3000);
        yield put(closeSnackbar());
    }
}

function* watchOffice() {
    yield takeLatest(OfficeActionTypes.GET_OFFICES, getOfficesSaga);
    yield takeLatest(OfficeActionTypes.GET_OFFICE, getOfficeSaga);
    yield takeLatest(OfficeActionTypes.ADD_OFFICE, addOfficeSaga);
    yield takeLatest(OfficeActionTypes.UPDATE_OFFICE, updateOfficeSaga);
    yield takeLatest(OfficeActionTypes.DELETE_OFFICE, deleteOfficeSaga);
}

const officeSagas = [fork(watchOffice)];

export default officeSagas;
