import { action } from "typesafe-actions";

export const CompanyActionTypes = {
    GET_COMPANY: "COMPANY/GET_COMPANY",
    GET_COMPANY_SUCCESS: "COMPANY/GET_COMPANY_SUCCESS",
    GET_COMPANY_FAILURE: "COMPANY/GET_COMPANY_FAILURE",

    UPDATE_COMPANY: "COMPANY/UPDATE_COMPANY",
    UPDATE_COMPANY_SUCCESS: "COMPANY/UPDATE_COMPANY_SUCCESS",
    UPDATE_COMPANY_FAILURE: "COMPANY/UPDATE_COMPANY_FAILURE",

    DELETE_COMPANY: "COMPANY/DELETE_COMPANY",
    DELETE_COMPANY_SUCCESS: "COMPANY/DELETE_COMPANY_SUCCESS",
    DELETE_COMPANY_FAILURE: "COMPANY/DELETE_COMPANY_FAILURE",

    CLOSE_COMPANY: "COMPANY/CLOSE_COMPANY",
    CLOSE_COMPANY_SUCCESS: "COMPANY/CLOSE_COMPANY_SUCCESS",
    CLOSE_COMPANY_FAILURE: "COMPANY/CLOSE_COMPANY_FAILURE",

    CLEAR_COMPANY: "COMPANY/CLEAR_COMPANY",

    RESET: "RESET",
};

export const getCompany = () => action(CompanyActionTypes.GET_COMPANY);
export const getCompanySuccess = (payload) => action(CompanyActionTypes.GET_COMPANY_SUCCESS, payload);
export const getCompanyFailure = (payload) => action(CompanyActionTypes.GET_COMPANY_FAILURE, payload);

export const updateCompany = (payload) => action(CompanyActionTypes.UPDATE_COMPANY, payload);
export const updateCompanySuccess = (payload) => action(CompanyActionTypes.UPDATE_COMPANY_SUCCESS, payload);
export const updateCompanyFailure = (payload) => action(CompanyActionTypes.UPDATE_COMPANY_FAILURE, payload);

export const clearCompany = () => action(CompanyActionTypes.CLEAR_COMPANY);

export const reset = () => action(CompanyActionTypes.RESET);
