import React, { FC } from "react";
import { Link } from "react-router-dom";
import { List, ListItem, ListItemText, Typography } from "@material-ui/core";
import classnames from "classnames/bind";
import styles from "./payment.scss";
const cx = classnames.bind(styles);

interface PageProps {
    selected: string;
}

const PaymentSubHeader: FC<PageProps> = ({ selected }) => {
    return (
        <div className={cx("subHeader")}>
            <div className={cx("topNav")}>
                <Typography variant="h3">Settings</Typography>
            </div>
            <div className={cx("content")}>
                <List component="nav" aria-label="main mailbox folders">
                    <Link to="/hrm/company">
                        <ListItem button selected={selected === "company"}>
                            <ListItemText primary="Company" />
                        </ListItem>
                    </Link>
                    <Link to="/hrm/office">
                        <ListItem button selected={selected === "office"}>
                            <ListItemText primary="Office" />
                        </ListItem>
                    </Link>
                    <Link to="/hrm/department">
                        <ListItem button selected={selected === "department"}>
                            <ListItemText primary="Department" />
                        </ListItem>
                    </Link>
                    <Link to="/hrm/designation">
                        <ListItem button selected={selected === "designation"}>
                            <ListItemText primary="Designation" />
                        </ListItem>
                    </Link>
                    <Link to="/hrm/billing">
                        <ListItem button selected={selected === "billing"}>
                            <ListItemText primary="Billing" />
                        </ListItem>
                    </Link>
                </List>
            </div>
        </div>
    );
};

export default PaymentSubHeader;
