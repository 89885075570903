import React, { useEffect, FC } from "react";
import { Link, useHistory } from "react-router-dom";
import { getAuthToken } from "../../../utils/app-utils";
import PeopleOutlineOutlinedIcon from "@material-ui/icons/PeopleOutlineOutlined";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import ChatOutlinedIcon from "@material-ui/icons/ChatOutlined";
import { useAllowActionsHook } from "../../../utils/userAccess";

import { ListItem } from "@material-ui/core";

import classnames from "classnames/bind";
import styles from "./header.scss";
import { UserProfile } from "utils/types";
const cx = classnames.bind(styles);

interface HeaderProps {
    userProfile: UserProfile;
}

const Header: FC<HeaderProps> = ({ userProfile }) => {
    const isAdminAccess = useAllowActionsHook();
    const history = useHistory();
    const { pathName = "" } = history.location || {};
    useEffect(() => {
        if (!getAuthToken()) {
            history.push("/login");
        }
    }, []);

    return (
        <div className={cx("header")}>
            <ul>
                {/* <li
                    className={cx({
                        active: pathName.indexOf("dashboard") >= 0,
                    })}
                >
                    <Link to="/hrm/chat">
                        <HomeOutlinedIcon fontSize="large" />
                    </Link>
                </li> */}
                <li>
                    <Link to="/hrm/chat">
                        <ChatOutlinedIcon fontSize="large" />
                    </Link>
                </li>
                {isAdminAccess && (
                    <li>
                        <Link to="/hrm/users">
                            <PeopleOutlineOutlinedIcon fontSize="large" />
                        </Link>
                    </li>
                )}
            </ul>
            <div className={cx("median")}></div>
            <ul>
                {isAdminAccess && (
                    <li>
                        <Link to="/hrm/company">
                            <SettingsOutlinedIcon fontSize="large" />
                        </Link>
                    </li>
                )}
                <li>
                    <Link to="/hrm/profile">
                        <AccountCircleOutlinedIcon fontSize="large" />
                    </Link>
                </li>
            </ul>
        </div>
    );
};

export default Header;
