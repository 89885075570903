import React, { useEffect, FC } from "react";
import { connect } from "react-redux";

import { Drawer } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import { getOffice, addOffice, updateOffice, clearOffice } from "./../../../../actions/officeActions";
import OfficeForm from "./OfficeForm";

import classnames from "classnames/bind";
import styles from "./Styles.scss";
const cx = classnames.bind(styles);

interface IPageProps {
    id?: string;
    office: any;
    isLoading: boolean;
    onAddOffice: (payload: any) => null;
    apiResponse: any;
    isDrawerOpen: boolean;
    toggleDrawer: (isOpen: boolean) => null;
    onGetOffice: (id: string) => null;
    onUpdateOffice: (payload: any) => null;
    clearOffice: () => null;
    config: {
        country: [];
        dateFormat: [];
        timeFormat: [];
        timezone: [];
        currency: [];
        fiscalYearMonth: [];
        weekStarts: [];
    };
}

const AddOffice: FC<IPageProps> = ({
    isLoading,
    isDrawerOpen,
    toggleDrawer,
    id,
    office,
    onGetOffice,
    onAddOffice,
    onUpdateOffice,
    clearOffice,
    config,
}) => {
    // Get API Data
    useEffect(() => {
        if (id) {
            onGetOffice(id);
        } else {
            clearOffice();
        }
    }, [id]);

    const closeDrawer = () => {
        toggleDrawer(false);
    };

    return (
        <Drawer anchor={"right"} open={isDrawerOpen} onClose={closeDrawer}>
            <div role="presentation" className={cx("drawerContainer")} style={{ boxShadow: "none" }}>
                <div className={cx("drawerTitle")}>
                    <h3>{id ? <>Edit Office</> : <>Add Office</>}</h3>
                    <div className={cx("icon")}>
                        <CloseIcon color="primary" onClick={closeDrawer} onKeyDown={closeDrawer} />
                    </div>
                </div>
                <div className={cx("drawerSection")} style={{ background: "transparent" }}>
                    <OfficeForm
                        id={id}
                        office={office}
                        config={config}
                        addOffice={onAddOffice}
                        updateOffice={onUpdateOffice}
                        toggleDrawer={toggleDrawer}
                    />
                </div>
            </div>
        </Drawer>
    );
};

/* istanbul ignore next */
const mapStateToProps = (state: any) => {
    return {
        isLoading: state.app.isLoading,
        config: state.app.config,
        office: state.office.office,
        apiResponse: state.office.apiResponse.addOffice,
    };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: any) => ({
    onGetOffice: (id: string) => dispatch(getOffice(id)),
    onAddOffice: (payload: any) => dispatch(addOffice(payload)),
    onUpdateOffice: (payload: any) => dispatch(updateOffice(payload)),
    clearOffice: () => dispatch(clearOffice()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddOffice);
