import { fork, put, delay, takeLatest, select, all } from "redux-saga/effects";
import { API } from "./api";
import { getIn } from "./../../../../utils/app-utils";
import callApi from "./../../../../utils/callApi";

import {
    getUser,
    getUsersFailure,
    getUsersSuccess,
    getUserFailure,
    getUserSuccess,
    UserActionTypes,
    addUserSuccess,
    deleteUserSuccess,
    deleteUserFailure,
    updateUserSuccess,
    updateUserFailure,
    updateUserSettingSuccess,
    updateUserSettingFailure,
    getUserProfileSuccess,
} from "./../../../actions/userActions";
import {
    isLoading,
    openSnackbar,
    closeSnackbar,
    toggleDrawer,
    openAlert,
    closeAlert,
} from "../../../../actions/feature/app/appActions";

function* getUsersSaga() {
    try {
        yield put(isLoading(true));
        const [error, res] = yield* callApi(API.getUsers, { disableLoading: true }, true);
        if (error || res.status === "ERROR") {
            yield put(getUsersFailure({ status: "ERROR" }));
        } else {
            yield put(getUsersSuccess(res));
            // Fetch user details for first user
            if ((res.data || []).length) {
                yield put(getUser(res.data[0].id));
            }
        }
        yield put(isLoading(false));
    } catch (error) {
        yield put(isLoading(false));
        yield put(getUsersFailure({ status: "ERROR" }));
    }
}

function* getUserSaga(reqObject) {
    const { payload } = reqObject;
    try {
        yield put(isLoading(true));
        const [error, res] = yield* callApi(API.getUser, { disableLoading: true }, payload, true);
        if (error || res.status === "ERROR") {
            yield put(getUserFailure({ status: "ERROR" }));
        } else {
            yield put(getUserSuccess(res));
        }
        yield put(isLoading(false));
    } catch (error) {
        yield put(isLoading(false));
        yield put(getUserFailure({ status: "ERROR" }));
    }
}

function* addUserSaga(reqObject) {
    const { payload } = reqObject;
    try {
        yield put(isLoading(true));
        const [error, res] = yield* callApi(API.addUser, { disableLoading: true }, payload, true);
        if (error || res.status === "ERROR") {
            // yield put(openSnackbar("Invalid error, please try again"));
            yield put(openAlert({ message: "Invalid error, please try again", type: "error" }));
            // yield put(addUserFailure({ status: "ERROR" }));
        } else {
            yield put(toggleDrawer(false));
            yield put(openSnackbar("User added successfully"));
            yield put(addUserSuccess(res));
        }

        yield put(isLoading(false));
        yield delay(3000);
        yield put(closeSnackbar());
        yield put(closeAlert());
    } catch (error) {
        yield put(isLoading(false));
        yield put(openAlert({ message: "Invalid error, please try again", type: "error" }));
        // yield put(addUserFailure({ status: "ERROR" }));
        yield delay(3000);
        yield put(closeSnackbar());
        yield put(closeAlert());
    }
}

function* deleteUserSaga(reqObject) {
    const { payload } = reqObject;
    try {
        yield put(isLoading(true));
        const [error, res] = yield* callApi(API.deleteUser, { disableLoading: true }, payload, true);
        if (error || res.status === "ERROR") {
            yield put(openSnackbar("Invalid error, please try again"));
            yield put(deleteUserFailure({ status: "ERROR" }));
        } else {
            yield put(openSnackbar("User deleted successfully"));
            yield put(deleteUserSuccess({ id: payload }));
            // Fetch next record in the list

            const [user] = yield all([select(getIn("user", "users"))]);
            if (user[0].id) {
                yield put(getUser(user[0].id));
            }
        }

        yield put(isLoading(false));
        yield delay(3000);
        yield put(closeSnackbar());
    } catch (error) {
        yield put(isLoading(false));
        yield put(openSnackbar("Invalid error, please try again"));
        yield put(deleteUserFailure({ status: "ERROR" }));
        yield delay(3000);
        yield put(closeSnackbar());
    }
}

function* updateUserSaga(reqObject) {
    const { payload } = reqObject;
    try {
        yield put(isLoading(true));
        const [error, res] = yield* callApi(API.updateUser, { disableLoading: true }, payload, true);
        if (error || res.status === "ERROR") {
            yield put(openSnackbar("Invalid error, please try again"));
            yield put(updateUserFailure({ status: "ERROR" }));
        } else {
            // yield put(toggleDrawer(false));
            yield put(openSnackbar("User updated successfully"));
            yield put(updateUserSuccess(res.data));
        }

        yield put(isLoading(false));
        yield delay(3000);
        yield put(closeSnackbar());
    } catch (error) {
        yield put(isLoading(false));
        yield put(openSnackbar("Invalid error, please try again"));
        yield put(updateUserFailure({ status: "ERROR" }));
        yield delay(3000);
        yield put(closeSnackbar());
    }
}

function* updateUserSettingSaga(reqObject) {
    const { payload } = reqObject;
    try {
        yield put(isLoading(true));
        const [error, res] = yield* callApi(API.updateUserSetting, { disableLoading: true }, payload, true);
        if (error || res.status === "ERROR") {
            yield put(openSnackbar("Invalid error, please try again"));
            yield put(updateUserSettingFailure({ status: "ERROR" }));
        } else {
            yield put(openSnackbar("Settings updated successfully"));
            yield put(updateUserSettingSuccess(res.data));
        }

        yield put(isLoading(false));
        yield delay(3000);
        yield put(closeSnackbar());
    } catch (error) {
        yield put(isLoading(false));
        yield put(openSnackbar("Invalid error, please try again"));
        yield put(updateUserSettingFailure({ status: "ERROR" }));
        yield delay(3000);
        yield put(closeSnackbar());
    }
}

function* getUserProfileSaga() {
    try {
        yield put(isLoading(true));
        const [error, res] = yield* callApi(API.getUserProfile, { disableLoading: true }, {}, true);
        if (error || res.status === "ERROR") {
        } else {
            yield put(getUserProfileSuccess(res));
        }
        yield put(isLoading(false));
    } catch (error) {
        yield put(isLoading(false));
        yield put(getUserProfileSuccess({ status: "ERROR" }));
    }
}

function* watchUser() {
    yield takeLatest(UserActionTypes.GET_USERS, getUsersSaga);
    yield takeLatest(UserActionTypes.GET_USER, getUserSaga);
    yield takeLatest(UserActionTypes.ADD_USER, addUserSaga);
    yield takeLatest(UserActionTypes.DELETE_USER, deleteUserSaga);
    yield takeLatest(UserActionTypes.UPDATE_USER, updateUserSaga);
    yield takeLatest(UserActionTypes.UPDATE_USER_SETTING, updateUserSettingSaga);
    yield takeLatest(UserActionTypes.GET_USER_PROFILE, getUserProfileSaga);
}

const userSagas = [fork(watchUser)];

export default userSagas;
