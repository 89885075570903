import { buildGET, buildAuthPOST, buildDELETE, buildAuthPUT } from "./../../../../utils/api";

// APIs
const getDepartments = () => {
    return buildGET(`department`);
};

const getDepartment = (id) => buildGET(`department/${id}`);

const addDepartment = (payload) => buildAuthPOST("department", payload);

const updateDepartment = (payload) => {
    const { id, ...rest } = payload;
    return buildAuthPUT(`department/${id}`, rest);
};

const deleteDepartment = (id) => buildDELETE(`department/${id}`);

// API export
export const API = {
    getDepartments,
    getDepartment,
    addDepartment,
    deleteDepartment,
    updateDepartment,
};
