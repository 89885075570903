import { UserActionTypes } from "./../../actions/userActions";

interface Users {
    id: String;
}

const initialState = {
    multiAccounts: [],
    profile: {},
    users: [],
    user: {
        basicInfo: {},
        personalInfo: {},
        contacts: [],
    },
    usersApiResponse: {},
    userApiResponse: {},
    deleteUserApiResponse: {},
};

const reducer = (state = initialState, action: any) => {
    switch (action.type) {
        //USERS
        case UserActionTypes.GET_USERS_SUCCESS:
            return { ...state, users: action.payload.data };
        case UserActionTypes.GET_USERS_FAILURE:
            return { ...state, usersApiResponse: action.payload };
        //USER
        case UserActionTypes.GET_USER_SUCCESS:
            return { ...state, user: action.payload.data };
        case UserActionTypes.GET_USER_FAILURE:
            return { ...state, userApiResponse: action.payload };
        //PROFILE
        case UserActionTypes.GET_USER_PROFILE_SUCCESS:
            return { ...state, profile: action.payload.data };
        //ADD USER
        case UserActionTypes.ADD_USER_SUCCESS:
            return { ...state, user: action.payload.data[0], users: [...state.users, action.payload.data[0]] };
        //ADD USER ONBOARDING
        case UserActionTypes.ADD_USER_ONBOARDING_SUCCESS:
            return { ...state };
        //UPDATE USER
        case UserActionTypes.UPDATE_USER_SUCCESS:
            return { ...state, user: action.payload.data[0] };
        case UserActionTypes.ADD_USER_FAILURE:
            return { ...state, userApiResponse: action.payload };
        //UPDATE USER SETTING
        case UserActionTypes.UPDATE_USER_SETTING_SUCCESS:
            return { ...state, user: action.payload };
        case UserActionTypes.ADD_USER_SETTING_FAILURE:
            return { ...state, userApiResponse: action.payload };
        //DELETE USER
        case UserActionTypes.DELETE_USER_SUCCESS:
            return {
                ...state,
                users: state.users.filter((user: any) => {
                    return user.id !== action.payload.id;
                }),
            };
        case UserActionTypes.DELETE_USER_FAILURE:
            return { ...state, deleteUserApiResponse: action.payload };

        // UPDATE_USER_CONTACTS
        case UserActionTypes.UPDATE_USER_CONTACTS:
            return {
                ...state,
                user: { ...state.user, contacts: { ...state.user.contacts, ...action.payload } },
            };

        // UPDATE_USER_INFORMATION
        case UserActionTypes.UPDATE_USER_INFORMATION:
            return {
                ...state,
                user: { ...state.user, ...action.payload },
            };

        case UserActionTypes.UPDATE_BASIC_INFO_SUCCCESS:
            return { ...state, user: { ...state.user, basicInfo: { ...action.payload } } };

        case UserActionTypes.UPDATE_PERSONAL_INFO_SUCCESS:
            return { ...state, user: { ...state.user, personalInfo: { ...action.payload } } };

        case UserActionTypes.UPDATE_CONTACTS_SUCCESS:
            return { ...state, user: { ...state.user, contacts: [...action.payload] } };

        case UserActionTypes.UPDATE_MULTI_ACCOUNTS:
            return { ...state, multiAccounts: [...action.payload] };

        case UserActionTypes.RESET:
            return { ...state, ...initialState };

        //DEFAULT
        default:
            return state;
    }
};

export { reducer as userReducer };
