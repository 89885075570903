import React, { FC, useState, useEffect, useRef } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { getTopics } from "./../../../actions/feature/feeds/feedsActions";
import Topics from "./Topics";
import SubHeader from "./../../common/header/subHeader";
import { Link, useHistory, useParams } from "react-router-dom";
import { Typography, Button, TextField } from "@material-ui/core";
import Avatar from "./../../common/avatar/Avatar";

import classnames from "classnames/bind";
import styles from "./Feeds.scss";
const cx = classnames.bind(styles);

interface IPageProps {
    socket: any;
    topicId: string;
    user: any;
}

const WriteComment: FC<IPageProps> = ({ socket, topicId, user }) => {
    const { groupId } = useParams();
    const [writeComment, setWriteComment] = useState<boolean>(false);
    const [formValues, setFormValues] = useState<any>({
        comment: "",
    });
    const inputWriteCommentEl = useRef(null);

    useEffect(() => {
        //if (writeComment) inputWriteCommentEl?.current?.focus();
    }, [writeComment]);

    const inputChangeHandler = (evt: any) => {
        setFormValues({ ...formValues, [evt.target.name]: evt.target.value });
    };

    const handleCreateComment = () => {
        const payload = {
            comment: formValues.comment,
            topicId: topicId,
            feedGroupId: groupId,
        };
        if (groupId && payload.comment) {
            socket.emit("createComment", payload);
            setWriteComment(false);
            setFormValues({ ...formValues, comment: "" });
        }
    };

    return (
        <>
            {!writeComment ? (
                <div className={cx("writeComment")} onClick={() => setWriteComment(true)}>
                    <Avatar name={user?.UserFullName?.charAt(0)} />
                    <div className={cx("box")}>
                        <Typography>Write a comment</Typography>
                    </div>
                </div>
            ) : (
                <div className={cx("writeComment edit")}>
                    <TextField
                        id="outlined-multiline-static"
                        label="Write a comment"
                        autoFocus={true}
                        multiline
                        rows="4"
                        ref={inputWriteCommentEl}
                        className={"textarea"}
                        margin="normal"
                        variant="outlined"
                        name="comment"
                        onChange={inputChangeHandler}
                    />
                    <div className={cx("actions")}>
                        <Button
                            type="submit"
                            style={{ margin: "24px 24px 0 0", width: "auto" }}
                            variant="outlined"
                            color="primary"
                            size="medium"
                            onClick={() => setWriteComment(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            style={{ margin: "24px 0 0 0", width: "auto" }}
                            variant="contained"
                            color="primary"
                            size="medium"
                            onClick={handleCreateComment}
                        >
                            Post
                        </Button>
                    </div>
                </div>
            )}
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.user.profile.user,
    };
};

/* istanbul ignore next */
// const mapDispatchToProps = (dispatch: Dispatch) => ({
//     getTopics: (groupId: any) => dispatch(getTopics(groupId)),
// });

export default connect(mapStateToProps, null)(WriteComment);
