import React, { FC, useState, useEffect } from "react";
import { Card, Box, Typography, Button } from "@material-ui/core";
import { loadStripe } from "@stripe/stripe-js";

// import { API_BASE_URL } from "./../../../../../utils/api";
import ConfirmModal from "components/common/confirm-modal/confirmModal";
import PaymentPreview from "./PaymentPreview";
import { API } from "./../../../../sagas/feature/user/api";
import { getCurrencySymbol } from "../../../../../utils/app-utils";
import Alert from "./../../../../../components/common/alert/Alert";
import { UserProfile } from "./../../../../../utils/types";
import AlertDialog from "./../../../../../components/common/alert-dialog/AlertDialog";
import { useIsAdministratorHook } from "../../../../../utils/userAccess";

import classnames from "classnames/bind";
import styles from "./../Styles.scss";
import colors from "colors";
const cx = classnames.bind(styles);

interface PlanFeaturesProps {
    plans: any;
    userPlans: any;
    userProfile: UserProfile;
}

interface PriceMap {
    id: any;
    planPrice: number;
}

const PlanFeatures: FC<PlanFeaturesProps> = ({ plans, userPlans, userProfile }) => {
    const isAdministratorAccess = useIsAdministratorHook();
    const [showPaymentDialog, setShowPaymentDialog] = useState<boolean>(false);
    const [selectedPlan, setSelectedPlan] = useState<any>({});
    const [durationActive, setDurationActive] = useState<string>("yearly");
    const [planPriceMap, setPlanPriceMap] = useState<PriceMap[]>([]);
    const [usage, setUsage] = useState<any>({});
    const [isUserLimitExceed, setIsUserLimitExceed] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [totalUsers, setTotalUsers] = useState<string>("");

    useEffect(() => {
        plans.map((plan, index) => {
            setPlanPriceMap((planPriceMap) => [...planPriceMap, { id: plan.id, planPrice: plan.planPrice }]);
        });
    }, []);

    useEffect(() => {
        if (durationActive === "monthly") {
            setPlanPriceMap([]);
            plans.map((plan, index) => {
                setPlanPriceMap((planPriceMap) => [...planPriceMap, { id: plan.id, planPrice: plan.planPrice }]);
            });
        } else {
            setPlanPriceMap([]);
            plans.map((plan, index) => {
                const price = Math.floor(
                    plan.planPrice - (plan.planPrice * parseInt(process.env.REACT_APP_PLAN_YEARLY_DISCOUNT!)) / 100
                );
                setPlanPriceMap((planPriceMap) => [...planPriceMap, { id: plan.id, planPrice: price }]);
            });
        }
    }, [durationActive]);

    const getPlanPrice = (id: any) => {
        const plan = planPriceMap.find((plan) => plan.id === id);
        return plan?.planPrice;
    };

    const handleSelectPlan = (id: any) => {
        if (!isAdministratorAccess) return false;

        const plan = plans.find((plan) => plan.id === id);

        // validate users count
        // if (plan && userPlans && userPlans?.[0] && userPlans[0]["IsTrial"] === "Yes") {
        //     // get users count added in /users api
        //     API.getUserProfile().then(function(response: any) {
        //         const { data, status } = response;
        //         if (status === "SUCCESS") {
        //             const { company } = data;
        //             setTotalUsers(company.TotalUsers);
        //             if (company.TotalUsers > plan.usersCount) {
        //                 setIsUserLimitExceed(true);
        //             } else {
        //                 setSelectedPlan(plan);
        //                 setShowPaymentDialog(true);
        //             }
        //         } else {
        //             setIsError(true);
        //         }
        //     });
        // } else if (plan) {
        //     setSelectedPlan(plan);
        //     setShowPaymentDialog(true);
        // }
        setSelectedPlan(plan);
        setShowPaymentDialog(true);
    };

    const changeDuration = (type: any) => {
        if (type === "monthly") {
            setDurationActive("monthly");
        } else {
            setDurationActive("yearly");
        }
    };

    const handleContactUs = () => {
        window.open(`${process.env.REACT_APP_STATIC_APP_URL}/contact-us`, "_blank");
    };

    if (!isAdministratorAccess) {
        return <div></div>;
    }

    return (
        <Box>
            <div className={cx("paymentInfo")}>
                <i className="fas fa-lock icon"></i>
                <Typography> 100% secure payment, powered by</Typography>
                <Typography className={cx("primaryText")}>Stripe</Typography>
            </div>
            <Box display={"flex"} flexDirection={"row"} justifyContent={"space-between"}>
                <Typography color="textSecondary" gutterBottom>
                    Choose plan
                </Typography>
                <div className="billing">
                    <span
                        className={cx({ link: true, active: durationActive === "yearly" })}
                        onClick={() => changeDuration("yearly")}
                    >
                        Yearly
                    </span>{" "}
                    <span className="sep">|</span>{" "}
                    <span
                        className={cx({ link: true, active: durationActive === "monthly" })}
                        onClick={() => changeDuration("monthly")}
                    >
                        Monthly
                    </span>
                </div>
            </Box>
            <div className={cx("planheaderContainer")}>
                <div className={cx("planheader")}>&nbsp;</div>
                {plans?.length > 0 &&
                    plans
                        .filter((plan) => plan.id > 1)
                        .map((plan, index) => (
                            <div className={cx("planheader")} style={{ borderLeft: "none" }} key={index}>
                                <div>
                                    {plan.popular === "1" && <div className={cx("popular")}>MOST POPULAR</div>}
                                    <Typography variant={"h4"} style={{ marginBottom: 12 }}>
                                        {plan.PlanName}
                                    </Typography>
                                    {plan.planPriceLabel === "Custom" ? (
                                        <>
                                            <Typography variant={"h3"} style={{ marginBottom: 4 }}>
                                                Custom pricing
                                            </Typography>
                                            <Typography variant={"body2"} style={{ marginBottom: 25 }}>
                                                Ready to learn more?
                                            </Typography>
                                            <div className="mb4">&nbsp;</div>
                                            <Button
                                                color={"primary"}
                                                variant={"outlined"}
                                                onClick={() => handleContactUs()}
                                                style={{ marginBottom: 16 }}
                                            >
                                                Contact Us
                                            </Button>
                                        </>
                                    ) : (
                                        <>
                                            <Typography variant={"h3"} className={cx("priceLabel")}>
                                                <span className={cx("priceLabelCurrency")}>
                                                    {getCurrencySymbol(plan.planCurrency)}
                                                </span>
                                                {getPlanPrice(plan.id)}
                                            </Typography>
                                            {/* <Typography variant={"body2"} style={{ marginBottom: 4 }}>
                                            /month billed {durationActive === "monthly" ? "monthly" : "annualy"}
                                        </Typography> */}
                                            {/* {plan.usersCountType === "Fixed" ? (
                                            <div
                                                className="mb4 bold"
                                                style={{ marginBottom: 16 }}
                                            >{`Fixed price for first ${plan.usersCount} users`}</div>
                                        ) : (
                                            <>{plan.planPrice > 0 && <div className="mb4">per user</div>}</>
                                        )} */}

                                            <div className="mb4" style={{ marginBottom: "24px" }}>
                                                {plan.planDescription}
                                            </div>

                                            <Button
                                                color={"primary"}
                                                variant={"outlined"}
                                                onClick={() => handleSelectPlan(plan.id)}
                                                style={{ marginBottom: 16 }}
                                            >
                                                Select
                                            </Button>
                                            {/* <Typography variant={"body2"} style={{ marginBottom: 12 }}>
                                            {`$${plan.additionalUserPrice} per month for each additional user`}
                                        </Typography> */}
                                        </>
                                    )}
                                </div>
                            </div>
                        ))}
            </div>
            <Card className={cx("planTable")} variant="outlined">
                {plans[0].category.map((item, i) => (
                    <div className={cx("planTableRow")} key={i}>
                        <div className="featureHead">{plans[0].category[i].categoryName}</div>
                        <div className="featureContainer">
                            {plans[0].category[i].features.map((feature, m) => (
                                <div className={cx("innerRow")} key={m}>
                                    {plans
                                        .filter((plan) => plan.id > 1)
                                        .map((planData, j) => (
                                            <>
                                                {j === 0 && (
                                                    <div className={cx("col")}>
                                                        {planData.category[i].features[m].planFeatureName}
                                                    </div>
                                                )}

                                                <div className={cx("col")}>
                                                    {planData.category[i].features[m].planFeatureRecordValue ===
                                                    "Yes" ? (
                                                        <i className="fas fa-check-circle icon primary"></i>
                                                    ) : (
                                                        <>
                                                            {planData.category[i].features[m].planFeatureRecordValue ===
                                                            "No" ? (
                                                                <i className="fas fa-times-circle icon"></i>
                                                            ) : (
                                                                <Typography>
                                                                    {
                                                                        planData.category[i].features[m]
                                                                            .planFeatureRecordValue
                                                                    }{" "}
                                                                    {planData.category[i].features[m].unit}{" "}
                                                                </Typography>
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                            </>
                                        ))}
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </Card>
            <PaymentPreview
                handleClose={() => setShowPaymentDialog(false)}
                title="Purchase plan"
                open={showPaymentDialog}
                selectedPlan={selectedPlan}
                durationActive={durationActive}
                usage={usage}
                totalUsers={totalUsers}
            />
            <AlertDialog
                isOpen={isUserLimitExceed}
                onClickYes={() => setIsUserLimitExceed(false)}
                isNoDisplay={false}
                yesTitle={"Ok"}
                title={"Employees limit exceed"}
                description={
                    "Your account emloyees exceed the maximum selected plan employees limit. Please remove some emloyees or choose another plan"
                }
            />
            <AlertDialog
                isOpen={isError}
                onClickYes={() => setIsError(false)}
                isNoDisplay={false}
                yesTitle={"Ok"}
                title={"Unknown error"}
                description={"Please try again later"}
            />
            {/* <ConfirmModal
                open={false}
                title={"Delete audience"}
                content={"You will no longer be able to use this audience in your future campaigns"}
                textNo={"Cancel"}
                textYes={"Continue"}
                onClose={() => {
                    setShowPaymentDialog(false);
                }}
                confirm={() => {
                    //
                }}
            /> */}
        </Box>
    );
};

interface PageProps {
    plans: [];
    userPlans: any;
    alert: {
        open: boolean;
        message: string;
        type: string;
    };
    openAlert: (payload: any) => null;
    closeAlert: () => null;
    userProfile: UserProfile;
}

const UpgradePlan: FC<PageProps> = ({ plans, userPlans, alert, openAlert, closeAlert, userProfile }) => {
    return (
        <div className={cx("pageOuter")}>
            {alert.open && alert.message && (
                <Alert onClose={() => {}} message={alert.message} type={alert.type}></Alert>
            )}
            <Box display={"flex"} flexDirection={"column"} width={"100%"}>
                {plans?.length > 0 && <PlanFeatures plans={plans} userPlans={userPlans} userProfile={userProfile} />}
            </Box>
        </div>
    );
};

export default UpgradePlan;
