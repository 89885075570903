import { buildGET, buildAuthPOST, buildDELETE, buildAuthPUT } from "./../../../../utils/api";

// APIs
const getRoles = (payload) => {
    return buildGET(`role`);
};

const getRole = (id) => buildGET(`role/${id}`);

const addRole = (payload) => buildAuthPOST("role", payload);

const updateRole = (payload) => {
    const { id, ...rest } = payload;
    return buildAuthPUT(`role/${id}`, rest);
};

const deleteRole = (id) => buildDELETE(`role/${id}`);

const getRoleCapabiliites = (id) => buildGET(`role/${id}/capability`);

const updateRoleCapability = (payload) => {
    return buildAuthPUT(`role/${payload.id}/capability`, payload);
};

// API export
export const API = {
    getRoles,
    getRole,
    addRole,
    deleteRole,
    updateRole,
    getRoleCapabiliites,
    updateRoleCapability,
};
