import React, { FC, useState, useEffect } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Typography, Tabs, Tab, Box } from "@material-ui/core";

import { toggleDrawer } from "./../../../../../actions/feature/app/appActions";
import { openAlert, closeAlert } from "../../../../../actions/feature/app/appActions";
import SettingsSubHeader from "./../SettingsSubHeader";
import BodyContent from "./../../../common/body/bodyContent";
import PlanDetails from "./PlanDetails";
import PlanHistory from "./PlanHistory";
import UpgradePlan from "./UpgradePlan";
import { getPlans, getCompanyPlans } from "./../../../../actions/planActions";
import { getCompany } from "./../../../../actions/companyActions";
import { UserProfile } from "./../../../../../utils/types";

import { useAllowActionsHook, useIsAdministratorHook } from "../../../../../utils/userAccess";
import NotFound from "../../notFound/NotFound";

import { API } from "./../../../../sagas/feature/company/api";

import classnames from "classnames/bind";
import styles from "./../Styles.scss";
const cx = classnames.bind(styles);

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </div>
    );
}

interface HeaderContentProps {
    value: any;
    setValue: (val: any) => void;
}

const HeaderContent: FC<HeaderContentProps> = ({ value, setValue }) => {
    const history = useHistory();
    const handleChange = (event, newValue) => {
        setValue(newValue);
        if (newValue === 0) {
            history.push("/hrm/billing#overview");
        } else if (newValue === 1) {
            history.push("/hrm/billing#upgrade");
        } else if (newValue === 2) {
            history.push("/hrm/billing#history");
        }
    };
    return (
        <div className={cx("headerSection")} style={{ paddingBottom: 0 }}>
            <div className={cx("top")}>
                <div>
                    <Typography variant="h4">Billing information</Typography>
                </div>
            </div>
            <div>
                <Tabs value={value} onChange={handleChange} aria-label="billing">
                    <Tab label="Overview" />
                    <Tab label="Upgrade" />
                    <Tab label="History" />
                </Tabs>
            </div>
        </div>
    );
};

interface PageProps {
    company: any;
    plans: [];
    userPlans: {};
    isDrawerOpen: boolean;
    config: any;
    alert: {
        open: boolean;
        message: string;
        type: string;
    };
    getPlans: () => null;
    getCompanyPlans: (id: any) => null;
    toggleDrawer: (isOpen: boolean) => null;
    openAlert: (payload: any) => null;
    closeAlert: () => null;
    getCompany: () => null;
    userProfile: UserProfile;
}

const Billing: FC<PageProps> = ({
    company,
    plans,
    userPlans,
    isDrawerOpen,
    getPlans,
    getCompanyPlans,
    toggleDrawer,
    config,
    alert,
    openAlert,
    closeAlert,
    getCompany,
    userProfile,
}) => {
    let location = useLocation();
    const isAdministratorAccess = useIsAdministratorHook();
    const [isAlertDialogOpen, setIsAlertDialogOpen] = React.useState(false);
    const [selectedId, setSelectedId] = React.useState("");
    const [action, setAction] = React.useState("");
    const [value, setValue] = React.useState(0);

    useEffect(() => {
        if (isAdministratorAccess) {
            // Get all plans
            getPlans();

            if (!company.id) {
                getCompany();
            }
        }
    }, [isAdministratorAccess]);

    useEffect(() => {
        if (isAdministratorAccess) {
            API.getCompany().then(
                (res) => {
                    if (res.status === "SUCCESS") {
                        getCompanyPlans(res.data[0].id);
                    }
                },
                (res) => {
                    //
                }
            );
        }
    }, [isAdministratorAccess]);

    useEffect(() => {
        if (location.hash === "#overview") {
            setValue(0);
        } else if (location.hash === "#upgrade") {
            setValue(1);
        } else if (location.hash === "#history") {
            setValue(2);
        }
    }, [location.hash]);

    if (!isAdministratorAccess) {
        return <NotFound />;
    }

    return (
        <div className={cx("pageOuter")}>
            <SettingsSubHeader selected={"billing"} />
            <BodyContent>
                <div className={"headerContainer"}>
                    <HeaderContent value={value} setValue={setValue} />
                </div>
                <div className="contentBody">
                    <TabPanel value={value} index={0} style={{ padding: 0 }}>
                        <PlanDetails
                            company={userProfile?.company}
                            companyId={company.id}
                            setValue={setValue}
                            userPlans={userPlans}
                            plans={plans}
                            alert={alert}
                            openAlert={openAlert}
                            closeAlert={closeAlert}
                        />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <UpgradePlan
                            userProfile={userProfile}
                            userPlans={userPlans}
                            plans={plans}
                            alert={alert}
                            openAlert={openAlert}
                            closeAlert={closeAlert}
                        />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <PlanHistory
                            config={config}
                            user={userProfile?.user}
                            userPlans={userPlans}
                            plans={plans}
                            alert={alert}
                            openAlert={openAlert}
                            closeAlert={closeAlert}
                        />
                    </TabPanel>
                </div>
            </BodyContent>
        </div>
    );
};

/* istanbul ignore next */
const mapStateToProps = (state) => {
    return {
        userProfile: state.user.profile,
        company: state.company.company || {},
        isDrawerOpen: state.app.isDrawerOpen,
        config: state.app.config,
        userPlans: state.plan.userPlans,
        plans: state.plan.plans,
        alert: state.app.alert,
    };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => ({
    getCompany: () => dispatch(getCompany()),
    getPlans: () => dispatch(getPlans()),
    getCompanyPlans: (id: any) => dispatch(getCompanyPlans(id)),
    toggleDrawer: (isOpen: boolean) => dispatch(toggleDrawer(isOpen)),
    openAlert: (payload: any) => dispatch(openAlert(payload)),
    closeAlert: () => dispatch(closeAlert()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Billing);
