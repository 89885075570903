import React, { FC, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { Typography } from "@material-ui/core";
import Textarea from "react-textarea-autosize";

import DoneOutlinedIcon from "@material-ui/icons/DoneOutlined";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";

import { getAuthToken } from "./../../../../../utils/app-utils";
import { UserContext } from "../../../../../components/app/userContext";

import classnames from "classnames/bind";
import styles from "./ListAdder.scss";
const cx = classnames.bind(styles);
interface PageProps {
    projectId: string;
}

const ListAdder: FC<{}> = () => {
    const { workspaceId = "", projectId = "" } = useParams();
    const contextObj: any = useContext(UserContext);
    const [listTitle, setListTitle] = useState("");
    const [isOpen, setIsOpen] = useState(false);

    const handleBlur = () => {
        setIsOpen(false);
    };

    const handleChange = (event) => {
        setListTitle(event.target.value);
    };

    const handleKeyDown = (event) => {
        if (event.keyCode === 13) {
            event.preventDefault();
            createList();
        } else if (event.keyCode === 27) {
            setIsOpen(false);
            setListTitle("");
        }
    };

    const createList = () => {
        if (contextObj?.socketProjects.current?.readyState !== WebSocket.OPEN || !listTitle) return false;

        contextObj?.socketProjects.current?.send(
            JSON.stringify({
                action: "createList",
                body: {
                    workspaceId,
                    projectId,
                    listTitle,
                    listOrder: new Date().getTime(),
                },
                token: getAuthToken(),
            })
        );
        setIsOpen(true);
        setListTitle("");
    };

    if (!isOpen) {
        return (
            <button onClick={() => setIsOpen(true)} className={cx("add-list-button")}>
                <Typography>Enter list title...</Typography>
            </button>
        );
    }
    return (
        <div className={cx("list")}>
            <Textarea
                autoFocus
                useCacheForDOMMeasurements
                value={listTitle}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                className="list-adder-textarea"
                onBlur={handleBlur}
                spellCheck={false}
            />
            <div className={cx("bottomButtons")}>
                <CloseOutlinedIcon
                    fontSize={"large"}
                    onClick={() => {
                        console.log("close");
                        setIsOpen(false);
                        setListTitle("");
                    }}
                />
                <DoneOutlinedIcon fontSize={"large"} onClick={createList} />
            </div>
        </div>
    );
};

export default ListAdder;
