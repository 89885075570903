import { ProfileActionTypes } from "./../../actions/profileActions";

const initialState = {
    user: {
        basicInfo: {},
        personalInfo: {},
        contacts: [],
        setting: {},
    },
    getProfileApiResponse: {},
    updateProfileApiResponse: {},
    changePasswordApiResponse: {},
};

const reducer = (state = initialState, action: any) => {
    switch (action.type) {
        //USER
        case ProfileActionTypes.GET_PROFILE_SUCCESS:
            return { ...state, user: { ...state.user, ...action.payload.data } };
        case ProfileActionTypes.GET_PROFILE_FAILURE:
            return { ...state, getProfileApiResponse: { ...state.getProfileApiResponse, ...action.payload } };

        case ProfileActionTypes.UPDATE_PROFILE_SUCCESS:
            return { ...state, user: { ...state.user, ...action.payload.data } };
        case ProfileActionTypes.UPDATE_PROFILE_FAILURE:
            return { ...state, updateProfileApiResponse: { ...state.updateProfileApiResponse, ...action.payload } };

        case ProfileActionTypes.UPDATE_BASIC_INFO_SUCCCESS:
            return { ...state, user: { ...state.user, basicInfo: { ...action.payload } } };

        case ProfileActionTypes.UPDATE_PERSONAL_INFO_SUCCESS:
            return { ...state, user: { ...state.user, personalInfo: { ...action.payload } } };

        case ProfileActionTypes.UPDATE_CONTACTS_SUCCESS:
            return { ...state, user: { ...state.user, contacts: [...action.payload] } };

        // UPDATE_PROFILE_CONTACTS
        case ProfileActionTypes.UPDATE_PROFILE_CONTACTS:
            return {
                ...state,
                user: { ...state.user, contacts: { ...state.user.contacts, ...action.payload } },
            };

        // UPDATE_PROFILE_INFORMATION
        case ProfileActionTypes.UPDATE_PROFILE_INFORMATION:
            return {
                ...state,
                user: { ...state.user, ...action.payload },
            };

        // CHANGE_PASSWORD_SUCCESS
        case ProfileActionTypes.CHANGE_PASSWORD_SUCCESS:
            return { ...state, changePasswordApiResponse: {} };

        // CHANGE_PASSWORD_FAILURE
        case ProfileActionTypes.CHANGE_PASSWORD_FAILURE:
            return { ...state, changePasswordApiResponse: { ...state.changePasswordApiResponse, ...action.payload } };

        case ProfileActionTypes.RESET:
            return { ...state, ...initialState };

        default:
            return state;
    }
};

export { reducer as profileReducer };
