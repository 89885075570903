import React, { FC, useEffect, useState, useContext } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { useParams, useHistory } from "react-router-dom";

import ChatSubHeader from "./ChatSubHeader";
import ChatSidebar from "./ChatSidebar";
import classnames from "classnames/bind";
import styles from "./Chat.scss";

import { getRoom, getRooms } from "./../../../../actions/feature/rooms/roomsActions";
import ChatMessages from "./ChatMessages";
import { Typography, List, ListItem, Box, Collapse, ListItemText } from "@material-ui/core";
//import { UserContext } from "./../../../../../components/app/userContext";
import { UserContext } from "./../../../../components/app/userContext";
import { getAuthToken } from "./../../../../utils/app-utils";
import {
    RoomMessagesInterface,
    MessageInterface,
    RoomInterface,
    UserContextInterface,
} from "./../../../../utils/interfaces";
import { updateMessage } from "./../../../../actions/feature/rooms/roomsActions";

const cx = classnames.bind(styles);

export const isUserChatRoom = (isCreateNewRoom: boolean, room: any, user: any, isUserAccess: boolean) => {
    if (!isUserAccess) {
        return false;
    }
    if (isCreateNewRoom) {
        return true;
    }
    const userFound = room?.users?.find((item) => item.id == user?.id && item.chatUserStatus === "Active");
    return userFound || false;
};

interface PageProps {
    rooms: any;
    user: any;
    selectedRoom: RoomMessagesInterface;
    getRooms: () => void;
    getRoom: (payload: any) => void;
    updateMessage: (payload: any) => void;

    sendMessageNewRoom: (message: string, newRoomUsers: any) => void;
    sendMessage: (message: string, id: string) => void;
    isLoading?: boolean;
}

const Chat: FC<PageProps> = ({ rooms, user, selectedRoom, getRooms, getRoom, updateMessage, isLoading = false }) => {
    const { id } = useParams();
    const history = useHistory();
    const [isCreateNewRoom, setIsCreateNewRoom] = useState(false);
    const [roomsLoaded, setRoomsLoaded] = useState(false);
    const [currentRoom, setCurrentRoom] = useState<RoomInterface>();
    const [isChatSideBar, setIsChatSideBar] = useState(false);
    const [selectedRoomUsers, setSelectedRoomUsers] = useState([]);

    const contextObj: any = useContext(UserContext);

    useEffect(() => {
        getRooms();
    }, []);

    useEffect(() => {
        if (id) {
            setRoomsLoaded(false);
            getRoom({ id, page: 1 });
        }
    }, [id]);

    useEffect(() => {
        if (rooms?.length && !selectedRoom?.chatRoomId && !id) {
            getRoom({ id: rooms[0]?.chatRoomId, page: 1 });
        }
    }, [rooms, selectedRoom, id]);

    useEffect(() => {
        if (rooms?.length && selectedRoom?.chatRoomId && selectedRoom?.messages?.length && !roomsLoaded) {
            setIsCreateNewRoom(false);
            setRoomsLoaded(true);
            //updateMessage({ ...selectedRoom });
        }
    }, [rooms, selectedRoom]);

    useEffect(() => {
        if (rooms?.length && selectedRoom?.chatRoomId) {
            const room = rooms.find((room) => room.chatRoomId === selectedRoom.chatRoomId);
            setSelectedRoomUsers(room?.users);
        }
    }, [rooms, selectedRoom]);

    useEffect(() => {
        if (id && rooms?.length) {
            const selectedRoom = rooms.find((room) => room.chatRoomId == id);
            setCurrentRoom(selectedRoom);
        }
    }, [id, rooms, selectedRoom]);

    useEffect(() => {
        if (!id && selectedRoom?.chatRoomId) {
            history.push(`/hrm/chat/${selectedRoom.chatRoomId}`);
        }
    }, [id, selectedRoom]);

    const createRoom = () => {
        setIsCreateNewRoom(true);
    };

    const getRoomName = (users: any) => {
        var userNames = users.map((user) => {
            return user["name"];
        });
        return userNames.join(",");
    };

    const updateChatRoom = (room: any) => {
        if (!id || contextObj?.socket.current?.readyState !== WebSocket.OPEN) return false;
        const { chatRoomName, chatRoomId } = room;
        contextObj?.socket.current?.send(
            JSON.stringify({
                action: "updateRoom",
                body: {
                    chatRoomName,
                    chatRoomId,
                },
                token: getAuthToken(),
            })
        );
    };

    const sendMessage = (message: string, id: string) => {
        if (!id || contextObj?.socket.current?.readyState !== WebSocket.OPEN) return false;

        contextObj?.socket.current?.send(
            JSON.stringify({
                action: "sendMessage",
                body: {
                    message,
                    chatRoomId: id,
                },
                token: getAuthToken(),
            })
        );
    };

    const sendMessageNewRoom = (message: string, newRoomUsers: any) => {
        const users = newRoomUsers.reduce((prevVal: any, currVal: { userID: any }, idx) => {
            return idx == 0 ? [currVal.userID] : [...prevVal, currVal.userID];
        }, []);

        // const users = newRoomUsers.map((user) => {
        //     return {
        //         id: user.userID,
        //         name: user.userFullName,
        //     };
        // });
        // const loginUser = {
        //     id: user?.id,
        //     name: user.UserFullName,
        // };
        // users.push(loginUser);

        contextObj?.socket.current?.send(
            JSON.stringify({
                action: "createRoom",
                body: {
                    message,
                    users,
                    roomName: "",
                    roomDescription: "",
                    roomType: users.length > 1 ? "GROUP" : "ONE_TO_ONE",
                },
                token: getAuthToken(),
            })
        );
        setIsCreateNewRoom(false);
    };

    const handleRemoveUser = (id: string) => {
        contextObj?.socket.current?.send(
            JSON.stringify({
                action: "leaveRoom",
                body: {
                    userId: id,
                    chatRoomId: currentRoom?.chatRoomId,
                },
                token: getAuthToken(),
            })
        );
    };

    const handleJoinRoom = (userIds: string[]) => {
        contextObj?.socket.current?.send(
            JSON.stringify({
                action: "joinRoom",
                body: {
                    users: userIds,
                    chatRoomId: currentRoom?.chatRoomId,
                },
                token: getAuthToken(),
            })
        );
    };

    const handleGetRoom = (page: number) => {
        getRoom({ id, page });
    };

    return (
        <>
            <ChatSubHeader
                isCreateNewRoom={isCreateNewRoom}
                rooms={rooms}
                createRoom={createRoom}
                selectedRoom={selectedRoom}
            />
            <ChatMessages
                room={currentRoom}
                isCreateNewRoom={isCreateNewRoom}
                sendMessageNewRoom={sendMessageNewRoom}
                sendMessage={sendMessage}
                isChatSideBar={isChatSideBar}
                setIsChatSideBar={setIsChatSideBar}
                getRoom={handleGetRoom}
                updateChatRoom={updateChatRoom}
                isLoading={isLoading}
            />
            {isChatSideBar && !isCreateNewRoom && rooms?.length > 0 && (
                <ChatSidebar
                    selectedRoomUsers={selectedRoomUsers}
                    isChatSideBar={isChatSideBar}
                    setIsChatSideBar={setIsChatSideBar}
                    removeUser={handleRemoveUser}
                    joinRoom={handleJoinRoom}
                    room={currentRoom}
                    isCreateNewRoom={isCreateNewRoom}
                    user={user}
                />
            )}
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        rooms: state.rooms.list || [],
        selectedRoom: state.rooms.selectedRoom || {},
        user: state.user.profile.user,
        isLoading: state.app.isLoading,
    };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => ({
    getRooms: () => dispatch(getRooms()),
    getRoom: (payload: any) => dispatch(getRoom(payload)),
    updateMessage: (payload: any) => dispatch(updateMessage(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Chat);
