import React from "react";
import ClearIcon from "@material-ui/icons/ClearRounded";
import { Dialog, AppBar, Toolbar, Typography, IconButton } from "@material-ui/core";

import { makeStyles, Theme } from "@material-ui/core/styles";
import colors from "colors";

type BaseModalProps = {
    open: boolean;
    title: string | React.ReactNode;
    maxWidth?: false | "xs" | "sm" | "md" | "lg" | "xl" | undefined;
    children: React.ReactNode;
    handleClose: () => void;
    scroll?: "body" | "paper";
    subTitle?: string | React.ReactNode;
    showClose?: boolean;
    fullScreen?: boolean;
    fullWidth?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
    appBar: {
        position: "relative",
        height: 80,
        display: "flex",
        justifyContent: "center",
    },
    title: {
        flex: 1,
    },
    header: {
        boxShadow: "none",
    },
}));

const BaseModal = ({
    title,
    children,
    open,
    handleClose,
    maxWidth = "sm",
    scroll = "paper",
    subTitle,
    showClose = true,
    fullScreen = false,
    fullWidth = false,
}: BaseModalProps) => {
    const classes = useStyles();
    return (
        <Dialog open={open} fullScreen={fullScreen} fullWidth={fullWidth} maxWidth={maxWidth} scroll={scroll}>
            <AppBar className={classes.appBar}>
                <Toolbar style={{ padding: "0 32px" }}>
                    {showClose && (
                        <IconButton onClick={handleClose} style={{ padding: 0, marginRight: 16 }}>
                            <ClearIcon style={{ color: "#FFF", fontSize: 27.5 }} />
                        </IconButton>
                    )}
                    <Typography variant="h6" className={classes.title}>
                        {title}
                    </Typography>
                    <>{subTitle}</>
                </Toolbar>
            </AppBar>
            {children}
        </Dialog>
    );
};

export default BaseModal;
