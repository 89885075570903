import { buildPOST } from "./../../../utils/api";

// APIs
const login = ({ email, password }) =>
    buildPOST("login", {
        email: email,
        password: password,
    });

const signup = ({ fullName, email, password, domain, token, planID, duration, timeZone }) =>
    buildPOST("signup", {
        fullName: fullName,
        email: email,
        password: password,
        domain: domain,
        token,
        planID,
        duration,
        timeZone,
    });

const forgotPassword = ({ email }) =>
    buildPOST("sendresetpassword", {
        email: email,
    });

const resetPassword = ({ password, confirmPassword, token }) =>
    buildPOST("resetpassword", {
        password,
        confirmPassword,
        token,
    });

// API export
export const API = {
    login,
    signup,
    forgotPassword,
    resetPassword,
};
