import * as React from "react";
import { Route, Switch } from "react-router-dom";

import Login from "../feature/login/Login";
import Signup from "../feature/signup/Signup";
import ForgotPassword from "../feature/forgotPassword/ForgotPassword";
import ResetPassword from "../feature/forgotPassword/ResetPassword";
import ActivateAccount from "../feature/forgotPassword/ActivateAccount";
import MultiAccounts from "../feature/forgotPassword/MultiAccounts";
import Feeds from "../feature/feeds/Feeds";
import Design from "../feature/design/Design";
import NotFound from "./../../hrm/components/feature/notFound/NotFound";

const Routes = ({ location }) => {
    return (
        <Switch location={location}>
            <Route path="/" exact={true} component={Login} />
            <Route path="/login" exact={true} component={Login} />
            <Route path="/signup/:id?/:duration?" exact={true} component={Signup} />
            <Route path="/forgot-password" exact={true} component={ForgotPassword} />
            <Route path="/reset-password" exact={true} component={ResetPassword} />
            <Route path="/multi-accounts" exact={true} component={MultiAccounts} />
            <Route path="/activate-account" exact={true} component={ActivateAccount} />
            {/* <Route path="/dashboard/group/:groupId" exact={true} component={Feeds} />
            <Route path="/dashboard" exact={true} component={Feeds} />
            <Route path="/dashboard/group" exact={true} component={Feeds} /> */}
            <Route path="/annoucements" exact={true} component={Feeds} />

            {/* <Route path="/design" exact={true} component={Design} /> */}
            <Route path="*" component={NotFound} />
        </Switch>
    );
};

export default Routes;
