import { RoomsActionTypes } from "../../../actions/feature/rooms/roomsActions";

const initialState = {
    list: [],
    selectedRoom: {},
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        // GET ROOMS
        case RoomsActionTypes.GET_ROOMS_SUCCESS:
            return { ...state, list: action.payload };
        case RoomsActionTypes.GET_ROOMS_FAILURE:
            return { ...state };

        case RoomsActionTypes.GET_ROOM_SUCCESS:
            const { currentPage } = action.payload.pagination;
            return {
                ...state,
                selectedRoom: {
                    //...state.selectedRoom,
                    chatRoomId: action.payload.chatRoomId,
                    pagination: action.payload.pagination,
                    chatRoomName: action.payload.chatRoomName,
                    messages:
                        currentPage === 1
                            ? action.payload.messages
                            : [...state.selectedRoom.messages, ...action.payload.messages],
                },
            };
        case RoomsActionTypes.GET_ROOM_FAILURE:
            return { ...state };

        case RoomsActionTypes.UPDATE_MESSAGE:
            // update in respective chatRoomId
            return {
                ...state,
                list: [
                    ...state.list.map((room) => {
                        if (room?.chatRoomId == action.payload.chatRoomId) {
                            return {
                                ...room,
                                message: {
                                    message: action.payload.message,
                                    messageAt: action.payload.messageAt,
                                    messageRead: state.selectedRoom.chatRoomId == action.payload.chatRoomId,
                                },
                            };
                        }
                        return {
                            ...room,
                            message: {
                                ...room.message,
                                messageRead: true,
                            },
                        };
                    }),
                ],
                selectedRoom:
                    action.payload.chatRoomId == state.selectedRoom.chatRoomId
                        ? {
                              ...state.selectedRoom,
                              pagination: action.payload.pagination,
                              chatRoomName: action.payload.chatRoomName,
                              messages: [...state.selectedRoom.messages, action.payload],
                          }
                        : { ...state.selectedRoom },
            };

        case RoomsActionTypes.UPDATE_CHAT_USERS:
            return {
                ...state,
                list: [
                    ...state.list.map((item) => {
                        if (item.chatRoomId == action.payload.chatRoomId) {
                            return {
                                ...item,
                                users: action.payload.users,
                            };
                        } else {
                            return item;
                        }
                    }),
                ],
            };
        case RoomsActionTypes.UPDATE_ROOM:
            return {
                ...state,
                list: [
                    ...state.list.map((room) => {
                        if (room?.chatRoomId == action.payload.room.chatRoomId) {
                            return {
                                ...room,
                                chatRoomName: action.payload.room.chatRoomName,
                                chatRoomDescription: action.payload.room.chatRoomDescription,
                            };
                        }
                        return { ...room };
                    }),
                ],
                selectedRoom:
                    action.payload.chatRoomId == state.selectedRoom.chatRoomId
                        ? {
                              ...state.selectedRoom,
                              chatRoomName: action.payload.chatRoomName,
                              messages: [
                                  ...state.selectedRoom.messages,
                                  {
                                      messageId: action.payload.messageId,
                                      message: action.payload.message,
                                      messageAt: action.payload.messageAt,
                                      messageBy: action.payload.messageBy,
                                      messageType: action.payload.messageType,
                                      messageByUser: {
                                          ...action.payload.messageByUser,
                                      },
                                  },
                              ],
                          }
                        : { ...state.selectedRoom },
            };

        case RoomsActionTypes.CREATE_ROOM:
            return {
                ...state,
                list: [
                    {
                        chatRoomId: action.payload.chatRoomId,
                        chatRoomName: action.payload.chatRoomName,
                        chatRoomDescription: action.payload.chatRoomDescription,
                        message: {
                            message: action.payload.message,
                            messageAt: action.payload.messageAt,
                        },
                        users: action.payload.room.users,
                    },
                    ...state.list,
                ],
                selectedRoom:
                    action.payload.chatRoomId == state.selectedRoom.chatRoomId
                        ? {
                              chatRoomId: action.payload.chatRoomId,
                              chatRoomName: action.payload.chatRoomName,
                              pagination: { totalPages: 1, currentPage: 1 },
                              messages: [
                                  {
                                      messageId: action.payload.messageId,
                                      message: action.payload.message,
                                      messageAt: action.payload.messageAt,
                                      messageBy: action.payload.messageBy,
                                      messageType: action.payload.messageType,
                                      messageByUser: {
                                          ...action.payload.messageByUser,
                                      },
                                  },
                              ],
                          }
                        : { ...state.selectedRoom },
            };

        case RoomsActionTypes.RESET_MESSAGES:
            return {
                ...state,
                selectedRoom: { ...state.selectedRoom, messages: [], pagination: { totalPages: 1, currentPage: 1 } },
            };

        case RoomsActionTypes.RESET:
            return { ...state, ...initialState };

        //DEFAULT
        default:
            return state;
    }
};

export { reducer as roomsReducer };
