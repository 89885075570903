export interface MessageInterface {
    messageId: string;
    message: string;
    messageBy: string;
    messageAt: string;
}

export interface RoomMessagesInterface {
    chatRoomId: string;
    chatRoomName?: string;
    messages?: MessageInterface[]
}


export interface RoomInterface {
    chatRoomDescription: string;
    chatRoomId: string;
    chatRoomName: string;
    chatUserRole: ChatUserRole;
    users: ChatUser[];
    message?: MessageInterface[];
}

export interface UpdateRoomInterface {
    chatRoomDescription?: string;
    chatRoomId: string;
    chatRoomName: string;
}

export enum ChatUserRole {
    "ONE_TO_ONE" = "ONE_TO_ONE",
    "GROUP" = "GROUP"
}

export interface Message {
    messageType: MessageType;
    chatRoomId: string;
    message: string;
    messageId: string;
    messageBy: string;
    messageAt: string,
    messageFile: string;
    file?: {
        id: string;
        name: string;
    }
    messageByUser: {
        id: string,
        userFullName: string;
    }
}

export enum ChatUserStatus {
    ACTIVE = "Active",
    INACTIVE = "Inactive",
    DELETED = "Deleted"
}

export enum ChatUserRole {
    ADMIN = "Admin",
    MEMBER = "Member",
}

export interface ChatUser {
    id: string;
    userFullName: string;
    chatUserRole: ChatUserRole,
    chatUserStatus: ChatUserStatus,
    userProfileColor: string,
}

export enum MessageType {
    "MESSAGE" = "MESSAGE",
    "MESSAGE_DELETED" = "MESSAGE_DELETED",
    "USER_ADDED" = "USER_ADDED",
    "USER_REMOVED" = "USER_REMOVED",
    "ROOM_CREATED" = "ROOM_CREATED",
    "ROOM_UPDATED" = "ROOM_UPDATED",
    "MESSAGE_FILE" = "MESSAGE_FILE",
    "CHAT_FILE_CREATED" = "CHAT_FILE_CREATED",
    "TASK_FILE_CREATED" = "TASK_FILE_CREATED",
    "TASK_COMMENT_FILE_CREATED" = "TASK_COMMENT_FILE_CREATED",
}

export interface UserContextInterface {
    userPlanStatus: string;
    socket: WebSocket | null;
    timeZoneName: string;
    dateFormatLabel: string;
    timeFormatLabel: string;
}