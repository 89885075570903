import { action } from "typesafe-actions";

export const DepartmentActionTypes = {
    GET_DEPARTMENTS: "DEPARTMENT/GET_DEPARTMENTS",
    GET_DEPARTMENTS_SUCCESS: "DEPARTMENT/GET_DEPARTMENTS_SUCCESS",
    GET_DEPARTMENTS_FAILURE: "DEPARTMENT/GET_DEPARTMENTS_FAILURE",

    GET_DEPARTMENT: "DEPARTMENT/GET_DEPARTMENT",
    GET_DEPARTMENT_SUCCESS: "DEPARTMENT/GET_DEPARTMENT_SUCCESS",
    GET_DEPARTMENT_FAILURE: "DEPARTMENT/GET_DEPARTMENT_FAILURE",

    ADD_DEPARTMENT: "DEPARTMENT/ADD_DEPARTMENT",
    ADD_DEPARTMENT_SUCCESS: "DEPARTMENT/ADD_DEPARTMENT_SUCCESS",
    ADD_DEPARTMENT_FAILURE: "DEPARTMENT/ADD_DEPARTMENT_FAILURE",

    UPDATE_DEPARTMENT: "DEPARTMENT/UPDATE_DEPARTMENT",
    UPDATE_DEPARTMENT_SUCCESS: "DEPARTMENT/UPDATE_DEPARTMENT_SUCCESS",
    UPDATE_DEPARTMENT_FAILURE: "DEPARTMENT/UPDATE_DEPARTMENT_FAILURE",

    DELETE_DEPARTMENT: "DEPARTMENT/DELETE_DEPARTMENT",
    DELETE_DEPARTMENT_SUCCESS: "DEPARTMENT/DELETE_DEPARTMENT_SUCCESS",
    DELETE_DEPARTMENT_FAILURE: "DEPARTMENT/DELETE_DEPARTMENT_FAILURE",

    CLEAR_DEPARTMENT: "DEPARTMENT/CLEAR_DEPARTMENT",

    RESET: "RESET",
};

export const getDepartments = () => action(DepartmentActionTypes.GET_DEPARTMENTS);
export const getDepartmentsSuccess = (payload) => action(DepartmentActionTypes.GET_DEPARTMENTS_SUCCESS, payload);
export const getDepartmentsFailure = (payload) => action(DepartmentActionTypes.GET_DEPARTMENTS_FAILURE, payload);

export const getDepartment = (id) => action(DepartmentActionTypes.GET_DEPARTMENT, id);
export const getDepartmentSuccess = (payload) => action(DepartmentActionTypes.GET_DEPARTMENT_SUCCESS, payload);
export const getDepartmentFailure = (payload) => action(DepartmentActionTypes.GET_DEPARTMENT_FAILURE, payload);

export const addDepartment = (payload) => action(DepartmentActionTypes.ADD_DEPARTMENT, payload);
export const addDepartmentSuccess = (payload) => action(DepartmentActionTypes.ADD_DEPARTMENT_SUCCESS, payload);
export const addDepartmentFailure = (payload) => action(DepartmentActionTypes.ADD_DEPARTMENT_FAILURE, payload);

export const updateDepartment = (payload) => action(DepartmentActionTypes.UPDATE_DEPARTMENT, payload);
export const updateDepartmentSuccess = (payload) => action(DepartmentActionTypes.UPDATE_DEPARTMENT_SUCCESS, payload);
export const updateDepartmentFailure = (payload) => action(DepartmentActionTypes.UPDATE_DEPARTMENT_FAILURE, payload);

export const deleteDepartment = (payload) => action(DepartmentActionTypes.DELETE_DEPARTMENT, payload);
export const deleteDepartmentSuccess = (payload) => action(DepartmentActionTypes.DELETE_DEPARTMENT_SUCCESS, payload);
export const deleteDepartmentFailure = (payload) => action(DepartmentActionTypes.DELETE_DEPARTMENT_FAILURE, payload);

export const clearDepartment = () => action(DepartmentActionTypes.CLEAR_DEPARTMENT);

export const reset = () => action(DepartmentActionTypes.RESET);
