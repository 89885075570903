export default {
    black: "#000000",
    white: "#ffffff",
    light: "#f9f9fb",
    dark: "#48494a",
    red: "#d00",
    lightRed: "#fff5f5",
    primary: "#00609b",
    lightPrimary: "#67daff",
    lightestPrimary: "#e6f8ff",
    verylightestPrimary: "#F3F9FE",
    darkPrimary: "#00609b",
    secondary: "#03a9f4",
    lightSecondary: "#5dbdff",
    darkSecondary: "#00609b",
    success: "#21cc53",
    info: "#17a2b8",
    warning: "#ffc107",
    danger: "#c00",
    ink: "#39474e",
    link: "#39474e",
    linkHover: "#39474e",
    grey: "#a5a6a7",
    darkestGrey: "#4a5c65",
    darkerGrey: "#5a707b",
    darkGrey: "#6b8592",
    lightGrey: "#d7dadc",
    lighterGrey: "#dce2e5",
    lightestGrey: "#f2f5f6",
    background: "#f8fbfb",
    body: "#48494a",
    bodyDark: "#2b2c2d",
};
