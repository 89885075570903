import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";

//common
import { authReducer } from "./feature/auth/authReducer";
import { appReducer } from "./feature/app/appReducer";
import { feedsReducer } from "./feature/feeds/feedsReducer";
import { roomsReducer } from "./feature/rooms/roomsReducer";
import { workspacesReducer } from "./feature/workspaces/workspacesReducer";

// HRM Reducers
import { userReducer } from "./../hrm/reducers/feature/userReducer";
import { profileReducer } from "./../hrm/reducers/feature/profileReducer";
import { departmentReducer } from "./../hrm/reducers/feature/departmentReducer";
import { companyReducer } from "./../hrm/reducers/feature/companyReducer";
import { officeReducer } from "./../hrm/reducers/feature/officeReducer";
import { planReducer } from "./../hrm/reducers/feature/planReducer";
import { roleReducer } from "./../hrm/reducers/feature/roleReducer";
import { designationReducer } from "./../hrm/reducers/feature/designationReducer";

export default (history) =>
    combineReducers({
        router: connectRouter(history),
        app: appReducer,
        auth: authReducer,
        feeds: feedsReducer,
        rooms: roomsReducer,
        user: userReducer,
        profile: profileReducer,
        role: roleReducer,
        department: departmentReducer,
        company: companyReducer,
        office: officeReducer,
        plan: planReducer,
        designation: designationReducer,
        workspaces: workspacesReducer,
    });
