import * as React from "react";
import { connect } from "react-redux";

import classnames from "classnames/bind";
import styles from "./Dashboard.scss";
const cx = classnames.bind(styles);

export class Dashboard extends React.Component {
    render() {
        return (
            <>
                <div className={cx("dashboard")}>
                    <div className={cx("details")}>
                        <div className={cx("head")}>About</div>
                        <div className={cx("body")}>
                            <div className={cx("left")}>
                                <ul>
                                    <li className={cx("active")}>
                                        <a>Personal Information</a>
                                    </li>
                                    <li>
                                        <a>About you</a>
                                    </li>
                                </ul>
                            </div>
                            <div className={cx("right")}>this is right content of Dasbboards</div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

/* istanbul ignore next */
// const mapStateToProps = (state) => {
//     return {
//         memos: state,
//     };
// };

// /* istanbul ignore next */
// const mapDispatchToProps = (dispatch) => ({
//     handleOnSortChange: (value) => dispatch(sortMemos(value)),
// });

export default connect(null, null)(Dashboard);
