import { action } from "typesafe-actions";

export const AppActionTypes = {
    IS_LOADING: "app/IS_LOADING",

    OPEN_SNACKBAR: "app/OPEN_SNACKBAR",
    CLOSE_SNACKBAR: "app/CLOSE_SNACKBAR",

    TOGGLE_DRAWER: "app/TOGGLE_DRAWER",

    OPEN_ALERT: "app/OPEN_ALERT",
    CLOSE_ALERT: "app/CLOSE_ALERT",

    API_RESPONSE: "app/API_RESONSE",

    RESET: "app/RESET",
};

export const isLoading = (payload) => action(AppActionTypes.IS_LOADING, payload);

export const openSnackbar = (payload) => action(AppActionTypes.OPEN_SNACKBAR, payload);
export const closeSnackbar = () => action(AppActionTypes.CLOSE_SNACKBAR);

export const toggleDrawer = (payload) => action(AppActionTypes.TOGGLE_DRAWER, payload);

export const openAlert = (payload) => action(AppActionTypes.OPEN_ALERT, payload);
export const closeAlert = () => action(AppActionTypes.CLOSE_ALERT);

export const updateApiResponse = (payload) => action(AppActionTypes.API_RESPONSE, payload);

export const resetApp = () => action(AppActionTypes.RESET);
