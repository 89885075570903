import { fork, put, delay, takeLatest, select, all } from "redux-saga/effects";
import { API } from "./api";
import callApi from "./../../../utils/callApi";

import {
    FeedActionTypes,
    getFeedsFailure,
    getFeedsSuccess,
    getTopicsSuccess,
    getTopicsFailure,
} from "./../../../actions/feature/feeds/feedsActions";

import {
    isLoading,
    openSnackbar,
    closeSnackbar,
    toggleDrawer,
    openAlert,
    closeAlert,
} from "../../../actions/feature/app/appActions";

function* getFeedsSaga() {
    try {
        yield put(isLoading(true));
        const [error, res] = yield* callApi(API.getFeeds, { disableLoading: true }, true);
        if (error || res.status === "ERROR") {
            yield put(getFeedsFailure({ status: "ERROR" }));
        } else {
            yield put(getFeedsSuccess(res));
        }
        yield put(isLoading(false));
    } catch (error) {
        yield put(isLoading(false));
        yield put(getFeedsFailure({ status: "ERROR" }));
    }
}

function* getTopicsSaga(payload) {
    const { payload: groupId } = payload;
    try {
        yield put(isLoading(true));
        const [error, res] = yield* callApi(API.getTopics, { disableLoading: true }, groupId);
        if (error || res.status === "ERROR") {
            yield put(getTopicsFailure({ status: "ERROR" }));
        } else {
            yield put(getTopicsSuccess(res));
        }
        yield put(isLoading(false));
    } catch (error) {
        yield put(isLoading(false));
        yield put(getTopicsFailure({ status: "ERROR" }));
    }
}

function* watchUser() {
    yield takeLatest(FeedActionTypes.GET_FEEDS, getFeedsSaga);
    yield takeLatest(FeedActionTypes.GET_TOPICS, getTopicsSaga);
}

const feedsSagas = [fork(watchUser)];

export default feedsSagas;
