import React, { useEffect, FC } from "react";
import { connect } from "react-redux";

import { Drawer, Button, CircularProgress } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Alert from "./../../../../../components/common/alert/Alert";

import InputTextField from "./../../../../../components/common/text-field/TextField";
import { addDepartment, getDepartment, updateDepartment, clearDepartment } from "../../../../actions/departmentActions";
import { closeAlert } from "../../../../../actions/feature/app/appActions";

import classnames from "classnames/bind";
import styles from "./Styles.scss";
const cx = classnames.bind(styles);

interface IPageProps {
    id?: string;
    department: any;
    isLoading: boolean;
    onSave: (payload: any) => null;
    apiResponse: any;
    isDrawerOpen: boolean;
    toggleDrawer: (isOpen: boolean) => null;
    onGetDepartment: (id: string) => null;
    onUpdateDepartment: (payload: any) => null;
    clearDepartment: () => null;
    alert: {
        open: boolean;
        message: string;
        type: string;
    };
    closeAlert: () => void;
}

const AddDepartment: FC<IPageProps> = ({
    isLoading,
    isDrawerOpen,
    toggleDrawer,
    id,
    department,
    onGetDepartment,
    onSave,
    onUpdateDepartment,
    clearDepartment,
    alert,
    closeAlert,
}) => {
    const [departmentError, setDepartmentError] = React.useState(false);
    const [departmentName, setDepartmentName] = React.useState("");

    // Get API Data
    useEffect(() => {
        if (id) {
            onGetDepartment(id);
        } else {
            clearDepartment();
            setDepartmentName("");
        }
    }, [id]);

    useEffect(() => {
        if (isDrawerOpen && !id) {
            setDepartmentName("");
        }
    }, [isDrawerOpen, id]);

    // Set form values
    useEffect(() => {
        if (department && department.DepartmentName) {
            setDepartmentName(department.DepartmentName);
        }
    }, [department]);

    useEffect(() => {
        if (isDrawerOpen) closeAlert();
    }, [isDrawerOpen]);

    // Reset errors
    useEffect(() => {
        if (isDrawerOpen) {
            setDepartmentError(false);
        }
    }, [isDrawerOpen]);

    const inputChangeHandler = (event: any) => {
        setDepartmentName(event.target.value);
    };

    const submitForm = (evt: any) => {
        evt.preventDefault();
        if (!departmentName) {
            setDepartmentError(true);
        }
        if (departmentName) {
            if (id) {
                onUpdateDepartment({
                    id: id,
                    departmentName: departmentName,
                });
            } else {
                onSave({
                    departmentName: departmentName,
                });
            }
        }
    };

    const closeDrawer = () => {
        toggleDrawer(false);
        closeAlert();
    };

    return (
        <Drawer anchor={"right"} open={isDrawerOpen} onClose={closeDrawer}>
            <div role="presentation" className={cx("drawerContainer")} style={{ boxShadow: "none" }}>
                <div className={cx("drawerTitle")}>
                    <h3>{id ? <>Edit Department</> : <>Add Department</>}</h3>
                    <div className={cx("icon")}>
                        <CloseIcon color="primary" onClick={closeDrawer} onKeyDown={closeDrawer} />
                    </div>
                </div>

                <div className={cx("drawerSection")} style={{ background: "transparent" }}>
                    {alert.open && alert.message && (
                        <Alert onClose={() => {}} message={alert.message} type={alert.type}></Alert>
                    )}
                    <div className={cx("drawerRow")}>
                        <div className={cx("drawerCol")}>
                            <InputTextField
                                id={"departmentName"}
                                name={"departmentName"}
                                label={"Department Name"}
                                value={departmentName}
                                onChange={inputChangeHandler}
                                required={true}
                                error={departmentError}
                                errorText={"Enter department name"}
                            />
                        </div>
                    </div>
                </div>

                <div style={{ paddingLeft: 24 }}>
                    <Button
                        variant="contained"
                        size="medium"
                        color="primary"
                        style={{ marginRight: 16 }}
                        onClick={submitForm}
                        disabled={isLoading}
                        // endIcon={isLoading ? <CircularProgress size="20px" /> : null}
                    >
                        {id ? "Update" : "Save"}
                    </Button>
                    <Button
                        variant="outlined"
                        size="medium"
                        color="primary"
                        onClick={closeDrawer}
                        onKeyDown={closeDrawer}
                    >
                        Cancel
                    </Button>
                </div>
            </div>
        </Drawer>
    );
};

/* istanbul ignore next */
const mapStateToProps = (state: any) => {
    return {
        isLoading: state.app.isLoading,
        department: state.department.department,
        apiResponse: state.department.apiResponse.addDepartment,
        alert: state.app.alert,
    };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: any) => ({
    onGetDepartment: (id: string) => dispatch(getDepartment(id)),
    onSave: (payload: any) => dispatch(addDepartment(payload)),
    onUpdateDepartment: (payload: any) => dispatch(updateDepartment(payload)),
    clearDepartment: () => dispatch(clearDepartment()),
    closeAlert: () => dispatch(closeAlert()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddDepartment);
