import { fork, put, takeLatest } from "redux-saga/effects";
import { API } from "./api";
import callApi from "./../../../utils/callApi";

import {
    workspacesActionTypes,
    getWorkspacesSuccess,
    getWorkspacesFailure,
    getWorkspaceSuccess,
    getWorkspaceFailure,
    getWorkspaceProjectListsSuccess,
    getWorkspaceProjectListsFailure,
    getWorkspaceTaskSuccess,
    getWorkspaceTaskFailure,
    deleteWorkspaceMemberSuccess,
    deleteWorkspaceMemberFailure,
} from "./../../../actions/feature/workspaces/workspacesActions";

import { isLoading } from "../../../actions/feature/app/appActions";

function* getWorkspacesSaga() {
    try {
        yield put(isLoading(true));
        const [error, res] = yield* callApi(API.getWorkspaces, { disableLoading: true }, true);
        if (error || res.status === "ERROR") {
            yield put(getWorkspacesFailure({ status: "ERROR" }));
        } else {
            yield put(getWorkspacesSuccess(res.data));
        }
        yield put(isLoading(false));
    } catch (error) {
        yield put(isLoading(false));
        //yield put(getWorkspacesFailure({ status: "ERROR" }));
    }
}

function* getWorkspacesaga(reqObject) {
    const { payload } = reqObject;
    try {
        yield put(isLoading(true));
        const [error, res] = yield* callApi(API.getWorkspace, { disableLoading: true }, payload, true);
        if (error || res.status === "ERROR") {
            yield put(getWorkspaceFailure({ status: "ERROR" }));
        } else {
            yield put(getWorkspaceSuccess(res.data));
        }
        yield put(isLoading(false));
    } catch (error) {
        yield put(isLoading(false));
        //yield put(getWorkspaceFailure({ status: "ERROR" }));
    }
}

function* getWorkspaceProjectListsaga(reqObject) {
    const { payload } = reqObject;
    try {
        yield put(isLoading(true));
        const [error, res] = yield* callApi(API.getWorkspaceProjectLists, { disableLoading: true }, payload, true);
        if (error || res.status === "ERROR") {
            yield put(getWorkspaceProjectListsFailure({ status: "ERROR" }));
        } else {
            yield put(getWorkspaceProjectListsSuccess(res.data));
        }
        yield put(isLoading(false));
    } catch (error) {
        yield put(isLoading(false));
        //yield put(getWorkspaceProjectListsFailure({ status: "ERROR" }));
    }
}

function* getWorkspaceTasksaga(reqObject) {
    const { payload } = reqObject;
    try {
        yield put(isLoading(true));
        const [error, res] = yield* callApi(API.getTask, { disableLoading: true }, payload, true);
        if (error || res.status === "ERROR") {
            yield put(getWorkspaceTaskFailure({ status: "ERROR" }));
        } else {
            yield put(getWorkspaceTaskSuccess(res.data));
        }
        yield put(isLoading(false));
    } catch (error) {
        yield put(isLoading(false));
        //yield put(getWorkspaceTaskFailure({ status: "ERROR" }));
    }
}

function* deleteWorkspaceMemberSaga(reqObject) {
    const { payload } = reqObject;
    try {
        yield put(isLoading(true));
        const [error, res] = yield* callApi(API.deleteMember, { disableLoading: true }, payload, true);
        if (error || res.status === "ERROR") {
            yield put(deleteWorkspaceMemberFailure({ status: "ERROR" }));
        } else {
            yield put(deleteWorkspaceMemberSuccess(res.data));
        }
        yield put(isLoading(false));
    } catch (error) {
        yield put(isLoading(false));
        //yield put(deleteWorkspaceMemberFailure({ status: "ERROR" }));
    }
}

function* watchUser() {
    yield takeLatest(workspacesActionTypes.GET_WORKSPACES, getWorkspacesSaga);
    yield takeLatest(workspacesActionTypes.GET_WORKSPACE, getWorkspacesaga);
    yield takeLatest(workspacesActionTypes.GET_WORKSPACE_PROJECT_LISTS, getWorkspaceProjectListsaga);
    yield takeLatest(workspacesActionTypes.GET_WORKSPACE_TASK, getWorkspaceTasksaga);
    yield takeLatest(workspacesActionTypes.DELETE_WORKSPACE_MEMBER, deleteWorkspaceMemberSaga);
}

const workspacesSagas = [fork(watchUser)];

export default workspacesSagas;
