import React, { FC, useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import CalendarIcon from "@material-ui/icons/CalendarToday";
import { format } from "date-fns";
import { Drawer, Button, Typography, Menu, InputAdornment, FormHelperText, Box, IconButton } from "@material-ui/core";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import AttachmentOutlinedIcon from "@material-ui/icons/AttachmentOutlined";
import ChatBubbleOutlineOutlinedIcon from "@material-ui/icons/ChatBubbleOutlineOutlined";
import Avatar from "./../../../../../components/common/avatar/Avatar";
import InputTextField from "./../../../../../components/common/text-field/TextField";
import TextareaSkeleton from "./../../../../../components/common/skeleton/Textarea";
import MoreHorizOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import withProjectSocket from "./../../../../hoc/ProjectsSocket";
import FileUpload from "./../../../../../components/common/file-upload/FileUpload";

import PreviewPDF from "./../../../../../components/common/preview/PreviewPDF";
import RenderImage from "./../../../../../components/common/render-image/RenderImage";

import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DoneOutlinedIcon from "@material-ui/icons/DoneOutlined";

import { UserContext } from "../../../../../components/app/userContext";
import { getAuthToken, toFormatDate } from "./../../../../../utils/app-utils";
import { toTimeZoneDateOnly } from "./../../../../../utils/utils";

import CreateLabel from "./createLabel";
import TaskComments from "./Comments";
import AddAssignee from "./AddAssignee";

import classnames from "classnames/bind";
import styles from "./CardDetails.scss";
const cx = classnames.bind(styles);

const TaskLabels = ({ taskLabels, projectLabels, taskId }: { taskLabels: any; projectLabels: any; taskId: string }) => {
    const [isCreateLabel, setIsCreateLabel] = useState(false);
    return (
        <div className={cx("row", "labels")}>
            <div className={cx("col")}>Labels</div>
            <div className={cx("col", "flexRow")}>
                {taskLabels?.length > 0 && (
                    <div className={cx("flexRow")}>
                        {taskLabels?.map((label) => (
                            <div className={cx("label")} style={{ background: label.background }}>
                                <Typography>{label.name}</Typography>
                            </div>
                        ))}
                    </div>
                )}

                <div className={cx("flex")}>
                    {!taskLabels?.length && (
                        <div className={cx("noData")} style={{ marginRight: "5px" }}>
                            No label
                        </div>
                    )}
                    <AddOutlinedIcon onClick={() => setIsCreateLabel(true)} />
                    <div style={{ position: "relative" }}>
                        {isCreateLabel && (
                            <CreateLabel
                                projectLabels={projectLabels}
                                setIsCreateLabel={setIsCreateLabel}
                                isCreateLabel={isCreateLabel}
                                taskId={taskId}
                                taskLabels={taskLabels}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export const RenderAttachment = ({ attachment }) => {
    const fileUrl = `${process.env.REACT_APP_FILE_URL}/${attachment.filePath}`;
    switch (attachment?.fileType) {
        case "image/png":
        case "image/jpg":
        case "image/jpeg":
        case "image/gif":
            return <RenderImage src={fileUrl} />;
        case "application/pdf":
            return <PreviewPDF url={fileUrl} />;
        default:
            return <span></span>;
    }
};

export const TaskAttachments = ({ attachments, taskId }: { attachments: any; taskId: string }) => {
    return (
        <div className={cx("row", "attachments")}>
            {attachments?.map((attachment) => (
                <div className={cx("attachment")}>
                    <RenderAttachment attachment={attachment} />
                    <div className={cx("attachmentRight")}>
                        <div className={cx("name")}>{attachment.name}</div>
                        <div className={cx("by")}>By {attachment?.attachedBy.fullName}</div>
                    </div>
                </div>
            ))}
        </div>
    );
};

const SubTasks = ({ subTasks }: { subTasks: any }) => {
    return (
        <>
            {subTasks?.length > 0 && (
                <div className={cx("tableRow")}>
                    <div className={cx("head")}>Sub Tasks</div>
                    {subTasks?.length > 0 &&
                        subTasks.map((subTask, index) => (
                            <div className={cx("listItem", "subTaskItem")} key={index}>
                                <div className={cx("title")}>{subTask.taskTitle}</div>
                                <div className={cx("subTasksRightSection")}>
                                    <div className={cx("group")}>
                                        {subTask.attachmentsCount}
                                        <AttachmentOutlinedIcon />
                                    </div>
                                    <div className={cx("group")}>
                                        {subTask.commentsCount}
                                        <ChatBubbleOutlineOutlinedIcon />
                                    </div>
                                    {subTask.taskAssignee?.map((assignee) => (
                                        <Avatar
                                            name={assignee?.assignee?.fullName}
                                            bgColor={assignee?.assignee?.userProfileColor}
                                        />
                                    ))}
                                </div>
                            </div>
                        ))}
                </div>
            )}
        </>
    );
};

const TaskAssignee = ({
    taskAssignee,
    workspaceId,
    taskId,
}: {
    taskAssignee: any;
    workspaceId: any;
    taskId: string;
}) => {
    const [isAssignee, setIsAssignee] = useState(false);
    return (
        <div className={cx("row")}>
            <div className={cx("col")}>Assignee</div>
            <div className={cx("col")}>
                <div className={cx("assignee")} style={{ position: "relative" }}>
                    {taskAssignee?.length ? (
                        <>
                            {taskAssignee?.map((assignee) => (
                                <Avatar
                                    name={assignee?.assignee?.fullName}
                                    bgColor={assignee?.assignee?.userProfileColor}
                                />
                            ))}
                            <AddOutlinedIcon onClick={() => setIsAssignee(true)} />
                        </>
                    ) : (
                        <div className={cx("flex")}>
                            <div className={cx("noData")}>No assignee</div>
                            <AddOutlinedIcon onClick={() => setIsAssignee(true)} />
                        </div>
                    )}
                    {isAssignee && (
                        <AddAssignee setIsAssignee={setIsAssignee} workspaceId={workspaceId} taskId={taskId} />
                    )}
                </div>
            </div>
        </div>
    );
};

interface PageProps {
    isDrawerOpen: boolean;
    selectedWorkspaceProjectTaskId: string;
    selectedWorkspaceProjectTask: any;
    resetWorkspaceTask: () => void;
    getWorkspaceTask: (taskId: string) => void;
    toggleDrawer: (isOpen: boolean) => null;
    getWorkspaceProjectLists: (payload: any) => void;
    socketProject: WebSocket | null;
    authToken: string;
    projectLabels: [];
}

const CardDetails: FC<PageProps> = ({
    isDrawerOpen,
    selectedWorkspaceProjectTaskId,
    selectedWorkspaceProjectTask,
    resetWorkspaceTask,
    getWorkspaceTask,
    toggleDrawer,
    getWorkspaceProjectLists,
    socketProject,
    authToken,
    projectLabels,
}) => {
    const {
        id: taskId,
        taskTitle,
        attachments,
        comments,
        taskAssignee,
        taskLabels,
        createdBy,
        dueDate,
        startDate,
        taskDescription,
        subTasks,
    } = selectedWorkspaceProjectTask;

    const userContextObj: any = useContext(UserContext);
    const { dateFormatLabel, timeFormatLabel, timeZoneName } = userContextObj || {};

    // const cardDetailsRef = React.createRef<HTMLDivElement>();
    const contextObj: any = useContext(UserContext);
    const { workspaceId = "", projectId = "" } = useParams();
    const [isEditTitle, setIsEditTitle] = useState(false);
    const [isStartDate, setIsStartDate] = useState(false);
    const [isDueDate, setIsDueDate] = useState(false);
    const [isMouseEnter, setIsMouseEnter] = useState(false);
    const [isEditDescription, setIsEditDescription] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    // use in comments page
    const [isEditComment, setIsEditComment] = useState(false);

    const [formValues, setFormValues] = useState({
        taskTitle: "",
        taskDescription: "",
        startDate: "",
        dueDate: "",
    });
    const [formValidations, setFormValidations] = useState({
        taskTitle: false,
        taskDescription: false,
    });

    // const handlerEvent = (event) => {
    //     event.preventDefault();
    //     console.log("formValues.description", formValues.description);

    //     if (event.target.type === "textarea") {
    //     } else {
    //         if (formValues.description !== "") {
    //             setIsEditDescription(false);
    //             setIsEditTitle(false);
    //         }
    //     }
    // };

    // useEffect(() => {
    //     cardDetailsRef.current?.addEventListener("click", handlerEvent);

    //     return () => {
    //         cardDetailsRef.current?.removeEventListener("click", handlerEvent);
    //     };
    // }, [cardDetailsRef]);

    useEffect(() => {
        setFormValues({
            ...formValues,
            taskTitle: taskTitle,
            taskDescription: taskDescription,
            startDate: startDate,
            dueDate: dueDate,
        });
    }, [taskTitle, taskDescription, startDate, dueDate]);

    useEffect(() => {
        if (isDrawerOpen && selectedWorkspaceProjectTaskId) {
            if (selectedWorkspaceProjectTaskId) {
                getWorkspaceTask(selectedWorkspaceProjectTaskId);
            }
        }
    }, [isDrawerOpen, selectedWorkspaceProjectTaskId]);

    useEffect(() => {
        if (!isDrawerOpen) {
            resetWorkspaceTask();
            // get project details
            getWorkspaceProjectLists({ workspaceId, projectId });
        }
    }, [isDrawerOpen]);

    const closeDrawer = () => {
        toggleDrawer(false);
    };

    const inputChangeHandler = (evt: any) => {
        setFormValues({ ...formValues, [evt.target.name]: evt.target.value });
    };

    const handleEditTitle = () => {
        if (!formValues.taskTitle) return false;
        const payload = {
            taskId,
            taskTitle: formValues.taskTitle,
        };
        updateTask(payload);
    };

    const handleEditkDescription = () => {
        if (!formValues.taskDescription) return false;

        const payload = {
            taskId,
            taskDescription: formValues.taskDescription,
        };
        updateTask(payload);
    };

    const updateTask = (payload: any) => {
        if (contextObj?.socketProjects.current?.readyState !== WebSocket.OPEN) return false;

        contextObj?.socketProjects.current?.send(
            JSON.stringify({
                action: "updateTask",
                body: { ...payload },
                token: getAuthToken(),
            })
        );
        setIsEditComment(false);
        setIsEditDescription(false);
        setIsEditTitle(false);
    };

    const handleMouseEnter = () => {
        setIsMouseEnter(true);
    };

    const handleMouseLeave = () => {
        setIsMouseEnter(false);
    };

    const handleDeleteTask = () => {
        if (socketProject) {
            socketProject?.send(
                JSON.stringify({
                    action: "deleteTask",
                    body: {
                        taskId,
                    },
                    token: authToken,
                })
            );
            setAnchorEl(null);
            closeDrawer();
        }
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onDateChange = (val: MaterialUiPickersDate, key: string) => {
        //if (val) setFormValues({ ...formValues, [key]: toFormatDate(new Date(val).toISOString()) });
        if (val) setFormValues({ ...formValues, [key]: val });

        const payload = {
            taskId,
            [key]: val,
        };
        updateTask(payload);
        setIsStartDate(false);
        setIsDueDate(false);
    };

    return (
        <Drawer anchor={"right"} open={isDrawerOpen} onClose={closeDrawer}>
            {/* ref={cardDetailsRef} */}
            <div role="presentation" className={cx("cardDetails")}>
                <div className={cx("header")}>
                    {isEditTitle ? (
                        <div className={cx("headerFields")}>
                            <InputTextField
                                name="taskTitle"
                                required={false}
                                id="taskTitle"
                                label=""
                                hover={true}
                                value={formValues.taskTitle}
                                onChange={inputChangeHandler}
                            />
                            <DoneOutlinedIcon fontSize={"large"} onClick={() => handleEditTitle()} />
                            <CloseOutlinedIcon fontSize={"large"} onClick={() => setIsEditTitle(false)} />
                        </div>
                    ) : (
                        <div className={cx("headerFields")}>
                            <div className={cx("title")}>
                                <Typography>{taskTitle}</Typography>
                            </div>
                            <EditOutlinedIcon onClick={() => setIsEditTitle(true)} />
                        </div>
                    )}

                    <div>
                        <div className={cx("boxFlex")}>
                            <IconButton
                                aria-label="more"
                                aria-controls="long-menu"
                                aria-haspopup="true"
                                onClick={(e) => handleClick(e)}
                            >
                                <MoreHorizOutlinedIcon />
                            </IconButton>
                            <Menu id="long-menu" anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
                                <ul>
                                    {/* <li onClick={() => console.log("")}>Edit list title</li> */}
                                    <li onClick={handleDeleteTask}>Delete task</li>
                                </ul>
                            </Menu>
                            <IconButton
                                aria-label="more"
                                aria-controls="long-menu"
                                aria-haspopup="true"
                                onClick={closeDrawer}
                            >
                                <CloseOutlinedIcon />
                            </IconButton>
                        </div>
                    </div>
                </div>

                <TaskLabels taskLabels={taskLabels} projectLabels={projectLabels} taskId={taskId} />
                <TaskAssignee taskAssignee={taskAssignee} workspaceId={workspaceId} taskId={taskId} />

                {/* {dueDate && dueDate != "0000-00-00 00:00:00" && ( */}
                <div className={cx("row")}>
                    <div className={cx("col")}>Start Date</div>
                    <div className={cx("col")}>
                        <Box display="flex">
                            <CalendarIcon
                                color="primary"
                                style={{ fontSize: 20, marginRight: "4px" }}
                                onClick={() => setIsStartDate(true)}
                            />
                            {/* {formValues.startDate && formValues.startDate != "0000-00-00 00:00:00"
                                ? toFormatDate(formValues.startDate, "dd-MM-yyyy")
                                : "No start date"} */}
                            {formValues.startDate && formValues.startDate != "0000-00-00 00:00:00"
                                ? toTimeZoneDateOnly(formValues.startDate, "", dateFormatLabel)
                                : "No start date"}
                        </Box>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePicker
                                open={isStartDate}
                                value={formValues.startDate}
                                format="dd-MM-yyyy"
                                style={{ width: "200px" }}
                                placeholder={"Start date"}
                                onChange={(date) => {
                                    //const dt = toFormatDate(new Date(date).toISOString());
                                    onDateChange(date, "startDate");
                                }}
                                onClose={() => {
                                    setIsStartDate(false);
                                }}
                                TextFieldComponent={() => null}
                                InputProps={{
                                    endAdornment: <CalendarIcon color="primary" style={{ fontSize: 20 }} />,
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                </div>
                <div className={cx("row")}>
                    <div className={cx("col")}>Due Date</div>
                    <div className={cx("col")}>
                        <Box display="flex">
                            <CalendarIcon
                                color="primary"
                                style={{ fontSize: 20, marginRight: "4px" }}
                                onClick={() => setIsDueDate(true)}
                            />
                            {formValues.dueDate && formValues.dueDate != "0000-00-00 00:00:00"
                                ? toTimeZoneDateOnly(formValues.dueDate, "", dateFormatLabel)
                                : "No due date"}
                        </Box>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePicker
                                open={isDueDate}
                                value={formValues.dueDate}
                                disablePast={true}
                                format="dd-MM-yyyy"
                                style={{ width: "200px" }}
                                placeholder={"Start date"}
                                onChange={(date) => {
                                    //const dt = toFormatDate(new Date(date).toISOString());
                                    onDateChange(date, "dueDate");
                                }}
                                onClose={() => {
                                    setIsDueDate(false);
                                }}
                                TextFieldComponent={() => null}
                                InputProps={{
                                    endAdornment: <CalendarIcon color="primary" style={{ fontSize: 20 }} />,
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                </div>
                {/* )} */}
                <div className={cx("row", "start")}>
                    <div className={cx("col")}>Description</div>
                    <div className={cx("col")}>
                        {isEditDescription ? (
                            <div className={cx("alignCenterCol")}>
                                <InputTextField
                                    name="taskDescription"
                                    label="Description"
                                    value={formValues.taskDescription}
                                    multiline={true}
                                    rows={4}
                                    onChange={inputChangeHandler}
                                />
                                <div style={{ marginTop: "16px" }}>
                                    <Button
                                        style={{ marginRight: "16px" }}
                                        size={"small"}
                                        onClick={handleEditkDescription}
                                        variant={"outlined"}
                                        color={"primary"}
                                        disabled={!formValues.taskDescription}
                                    >
                                        Save
                                    </Button>
                                    <Button
                                        size={"small"}
                                        onClick={() => {
                                            setIsMouseEnter(false);
                                            setIsEditDescription(false);
                                        }}
                                        color={"primary"}
                                    >
                                        Cancel
                                    </Button>
                                </div>
                            </div>
                        ) : (
                            <>
                                {taskDescription && !isEditDescription && (
                                    <>
                                        {isMouseEnter ? (
                                            <div
                                                className={cx("hover")}
                                                onMouseLeave={handleMouseLeave}
                                                onMouseEnter={handleMouseEnter}
                                                onClick={() => setIsEditDescription(true)}
                                            >
                                                {taskDescription}
                                            </div>
                                        ) : (
                                            <div
                                                className={cx("noHover")}
                                                onMouseLeave={handleMouseLeave}
                                                onMouseEnter={handleMouseEnter}
                                                onClick={() => setIsEditDescription(true)}
                                            >
                                                {taskDescription}
                                            </div>
                                        )}
                                    </>
                                )}
                                {!taskDescription && (
                                    <TextareaSkeleton
                                        description="Add description..."
                                        onClick={() => setIsEditDescription(true)}
                                    />
                                )}
                            </>
                        )}
                    </div>
                </div>
                <SubTasks subTasks={subTasks} />
                <div className={cx("row")}>
                    <div className={cx("col")}>Attach Files</div>
                    <div className={cx("col")}>
                        <div
                            style={{
                                display: "flex",
                            }}
                        >
                            <FileUpload type={"task"} id={selectedWorkspaceProjectTaskId} />
                        </div>
                    </div>
                </div>

                <TaskAttachments attachments={attachments} taskId={selectedWorkspaceProjectTaskId} />
                <TaskComments
                    comments={comments}
                    taskId={selectedWorkspaceProjectTaskId}
                    setIsEditComment={setIsEditComment}
                    isEditComment={isEditComment}
                />
            </div>
        </Drawer>
    );
};

export default withProjectSocket(CardDetails);
