import React, { FC, useState, useEffect } from "react";
import {
    Card,
    CardContent,
    Typography,
    Button,
    Box,
    ListItemText,
    FormControl,
    FormLabel,
    FormControlLabel,
    RadioGroup,
    Radio,
    InputAdornment,
} from "@material-ui/core";
import { toFormatDate } from "../../../../utils/app-utils";
import { API } from "./../../../sagas/feature/profile/api";
import DateFnsUtils from "@date-io/date-fns";
import { format } from "date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import CalendarIcon from "@material-ui/icons/CalendarToday";
import Alert from "./../../../../components/common/alert/Alert";
import { usePlanActiveHook } from "../../../../utils/userAccess";

import SelectField from "./../../../../components/common/select-field/SelectField";
import InputTextField from "./../../../../components/common/text-field/TextField";

import classnames from "classnames/bind";
import styles from "./Profile.scss";
const cx = classnames.bind(styles);

interface PageProps {
    profile: any;
    config: any;
    alert: {
        open: boolean;
        message: string;
        type: string;
    };
    closeAlert: () => void;
    updatePersonalInfoSuccess: (payload: any) => null;
    openSnackbar: (payload: any) => null;
    closeSnackbar: () => null;
    openAlert: (payload: any) => null;
}

const PersonalInformation: FC<PageProps> = ({
    profile = {},
    config = {},
    closeAlert,
    alert,
    updatePersonalInfoSuccess,
    openSnackbar,
    closeSnackbar,
    openAlert,
}) => {
    const isUserAccess = usePlanActiveHook();
    const { country } = config;
    const [edit, setEdit] = useState<boolean>(false);
    const [formValidations, setFormValidations] = useState<any>({
        IDType: false,
        IDNumber: false,
    });

    const [formValues, setFormValues] = useState<any>({
        id: "",
        IDType: "",
        IDNumber: "",
        passportNumber: "",
        dateOfBirth: "",
        address: "",
        telephone: "",
        nationalityID: "",
        maritalStatus: "",
    });

    useEffect(() => {
        setFormValues({
            id: profile.id,
            IDType: profile.IDType,
            IDNumber: profile.IDNumber,
            passportNumber: profile.passportNumber,
            dateOfBirth: profile.dateOfBirth,
            address: profile.address,
            telephone: profile.telephone,
            nationalityID: profile.nationalityID,
            maritalStatus: profile.maritalStatus,
        });
    }, [profile]);

    const getNationalityName = (id) => {
        return ((country && country.length && country.find((item: any) => item.id === id)) || {}).CountryName || "N/A";
    };

    const getNationalityOptions = () => {
        if (country && country.length) {
            return country.map((item: any) => {
                return {
                    label: item.CountryName,
                    value: item.id,
                };
            });
        }
    };

    const inputChangeHandler = (evt: any) => {
        setFormValues({ ...formValues, [evt.target.name]: evt.target.value });
    };

    const onDateChange = (val: MaterialUiPickersDate, key: string) => {
        if (val) setFormValues({ ...formValues, ["dateOfBirth"]: val });
    };

    const validateFormValues = () => {
        let isValid = true;
        let tempFormValidations = {};
        for (const [key, value] of Object.entries(formValues)) {
            if (key === "IDType" || key === "IDNumber") {
                if (!value) {
                    isValid = false;
                    tempFormValidations[`${key}`] = true;
                } else {
                    tempFormValidations[`${key}`] = false;
                }
            }
        }
        setFormValidations({ ...formValidations, ...tempFormValidations });
        return isValid;
    };

    const handleSave = () => {
        const formValuesUpdated = {
            ...formValues,
            dateOfBirth: formValues.dateOfBirth ? format(new Date(formValues.dateOfBirth) || null, "yyyy-MM-dd") : null,
        };
        if (validateFormValues()) {
            API.updatePersonalInfo(formValuesUpdated)
                .then(
                    (res) => {
                        if (res.status === "ERROR") {
                            openAlert({ message: "Invalid error, please try again", type: "error" });
                        } else {
                            openSnackbar("Details updated successfully");
                            updatePersonalInfoSuccess(res.data.personalInfo);
                            setEdit(false);
                        }
                    },
                    (res) => {
                        openAlert({ message: "Invalid error, please try again", type: "error" });
                    }
                )
                .finally(() => {
                    setTimeout(() => {
                        closeSnackbar();
                    }, 3000);
                });
        }
    };

    return (
        <div style={{ margin: "24px 0" }} className={cx("profilePage")}>
            <div className={cx("title")}>
                <Typography color="textSecondary" gutterBottom>
                    Personal Information
                </Typography>
                {!edit && (
                    <Button color="primary" onClick={() => setEdit(true)} disabled={!isUserAccess}>
                        Edit
                    </Button>
                )}
            </div>
            <Card variant="outlined">
                <CardContent style={{ padding: 24 }}>
                    {alert.open && alert.message && edit && (
                        <Alert onClose={() => {}} message={alert.message} type={alert.type}></Alert>
                    )}
                    <div className={cx("rowItem")}>
                        <div className={cx("colItem")}>
                            {!edit && <ListItemText primary="ID Type" secondary={profile.IDType} />}
                            {edit && (
                                <InputTextField
                                    name="IDType"
                                    id="IDType"
                                    required={true}
                                    label="ID Type"
                                    value={formValues.IDType}
                                    onChange={inputChangeHandler}
                                    error={formValidations.IDType}
                                    errorText={"Please enter ID type"}
                                />
                            )}
                        </div>
                        <div className={cx("colItem")}>
                            {!edit && <ListItemText primary="ID Number" secondary={profile.IDNumber} />}
                            {edit && (
                                <InputTextField
                                    name="IDNumber"
                                    id="IDNumber"
                                    required={true}
                                    label="ID Number"
                                    value={formValues.IDNumber}
                                    onChange={inputChangeHandler}
                                    error={formValidations.IDNumber}
                                    errorText={"Please enter ID number"}
                                />
                            )}
                        </div>
                    </div>

                    <div className={cx("rowItem")}>
                        <div className={cx("colItem")}>
                            {!edit && <ListItemText primary="Passport" secondary={profile.passportNumber} />}
                            {edit && (
                                <InputTextField
                                    name="passportNumber"
                                    required={false}
                                    id="passportNumber"
                                    label="Passport"
                                    value={formValues.passportNumber}
                                    onChange={inputChangeHandler}
                                />
                            )}
                        </div>
                        <div className={cx("colItem")}>
                            {!edit && (
                                <ListItemText
                                    primary="Date of Birth"
                                    secondary={
                                        profile.dateOfBirth ? toFormatDate(formValues.dateOfBirth, "dd-MM-yyyy") : ""
                                    }
                                />
                            )}
                            {edit && (
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker
                                        inputVariant="outlined"
                                        value={formValues.dateOfBirth ? formValues.dateOfBirth : null}
                                        format="dd-MM-yyyy"
                                        placeholder={"Date of Birth"}
                                        disableFuture={true}
                                        onChange={(date) => {
                                            onDateChange(date, "dateOfBirth");
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <CalendarIcon color="primary" style={{ fontSize: 20 }} />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            )}

                            {/* {!edit && <ListItemText primary="Date of Birth" secondary={profile.dateOfBirth} />}
                            {edit && (
                                <InputTextField
                                    name="dateOfBirth"
                                    required={false}
                                    id="dateOfBirth"
                                    label="Date of Birth"
                                    value={formValues.dateOfBirth}
                                    onChange={inputChangeHandler}
                                />
                            )} */}
                        </div>
                    </div>
                    <div className={cx("rowItem")}>
                        <div className={cx("colItem")}>
                            {!edit && <ListItemText primary="Address" secondary={profile.address} />}
                            {edit && (
                                <InputTextField
                                    name="address"
                                    required={false}
                                    id="address"
                                    label="Address"
                                    value={formValues.address}
                                    onChange={inputChangeHandler}
                                />
                            )}
                        </div>
                        <div className={cx("colItem")}>
                            {!edit && <ListItemText primary="Telephone" secondary={profile.telephone} />}
                            {edit && (
                                <InputTextField
                                    name="telephone"
                                    required={false}
                                    id="telephone"
                                    label="Telephone"
                                    value={formValues.telephone}
                                    onChange={inputChangeHandler}
                                />
                            )}
                        </div>
                    </div>
                    <div className={cx("rowItem")}>
                        <div className={cx("colItem")}>
                            {!edit && (
                                <ListItemText
                                    primary="Nationality"
                                    secondary={getNationalityName(profile.nationalityID)}
                                />
                            )}
                            {edit && (
                                <SelectField
                                    name={"nationalityID"}
                                    required={false}
                                    label={"Select Nationality"}
                                    value={formValues.nationalityID}
                                    onChange={inputChangeHandler}
                                    options={getNationalityOptions()}
                                />
                            )}
                        </div>
                        <div className={cx("colItem")}>
                            {!edit && <ListItemText primary="Maritial Status" secondary={profile.maritalStatus} />}
                            {edit && (
                                <FormControl error={false} component="fieldset">
                                    <FormLabel component="legend">Marital Status</FormLabel>
                                    <RadioGroup
                                        aria-label="Marital Status"
                                        name="maritalStatus"
                                        id="maritalStatus"
                                        value={formValues.maritalStatus}
                                        onChange={inputChangeHandler}
                                    >
                                        <FormControlLabel
                                            value="Married"
                                            checked={formValues.maritalStatus === "Married" ? true : false}
                                            control={<Radio />}
                                            label="Married"
                                        />
                                        <FormControlLabel
                                            value="Unmarried"
                                            checked={formValues.maritalStatus === "Unmarried" ? true : false}
                                            control={<Radio />}
                                            label="Unmarried"
                                        />
                                        <FormControlLabel
                                            value="Divorced"
                                            checked={formValues.maritalStatus === "Divorced" ? true : false}
                                            control={<Radio />}
                                            label="Divorced"
                                        />
                                        <FormControlLabel
                                            value="Other"
                                            checked={formValues.maritalStatus === "Other" ? true : false}
                                            control={<Radio />}
                                            label="Other"
                                        />
                                    </RadioGroup>
                                </FormControl>
                            )}
                        </div>
                    </div>
                    {edit && (
                        <Box className={cx("rowItem")} style={{ justifyContent: "flex-end", marginTop: 24 }}>
                            <Button
                                variant="outlined"
                                style={{ marginRight: 16 }}
                                size="medium"
                                color="primary"
                                onClick={() => setEdit(false)}
                            >
                                Cancel
                            </Button>
                            <Button variant="contained" size="medium" color="primary" onClick={handleSave}>
                                Save
                            </Button>
                        </Box>
                    )}
                </CardContent>
            </Card>
        </div>
    );
};

export default PersonalInformation;
