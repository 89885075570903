import { buildGET, buildAuthPOST, buildDELETE, buildAuthPUT } from "./../../../../utils/api";

// APIs
const getPlans = () => {
    return buildGET(`plans`);
};

const getCompanyPlans = (id) => {
    return buildGET(`company/${id}/plans`);
};

// const getCompanyPlansUnused = (id) => {
//     return buildGET(`company/${id}/plans/unused`);
// };

const getPlan = (id) => buildGET(`plans/${id}`);

const makePayment = (payload) => buildAuthPOST("payment/stripe", payload);

const getCheckoutSession = (id) => buildGET(`payment/stripe/checkout/sessions/${id}`);

// API export
export const API = {
    getPlans,
    getPlan,
    getCompanyPlans,
    makePayment,
    getCheckoutSession,
    //getCompanyPlansUnused,
};
