import React, { FC, useContext, useState } from "react";
import { Typography, Button } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import AlertDialog from "./../../../../../components/common/alert-dialog/AlertDialog";

import Avatar from "./../../../../../components/common/avatar/Avatar";
import { WorkspaceResponse, WorkspaceMember } from "./../../../../../utils/projectInterfaces";
import SelectField from "./../../../../../components/common/select-field/SelectField";
import InputTextField from "./../../../../../components/common/text-field/TextField";
import GroupAddOutlinedIcon from "@material-ui/icons/GroupAddOutlined";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import SaveIcon from "@material-ui/icons/Save";
import MembersDropdown from "../../../common/membersDropdown/MembersDropdown";
import { getAuthToken } from "./../../../../../utils/app-utils";

import { UserContext } from "../../../../../components/app/userContext";

import { API } from "./../../../../../sagas/feature/workspaces/api";

import { WorkspaceVisibility } from "../../../../../utils/projectInterfaces";

import classnames from "classnames/bind";
import styles from "./Workspaces.scss";
//import { isAdmin } from "utils/roles";
const cx = classnames.bind(styles);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: "11px 32px",
        },
    })
);

interface PageProps {
    // selectedWorkspace: WorkspaceResponse;
    // getWorkspace: (id: string) => void;
    // user: any;
    // deleteWorkspaceMember: (payload: any) => void;
    setIsCreateWorkspace: () => void;
    customClass?: string;
}

const CreateWorkspace: FC<PageProps> = (props) => {
    const { setIsCreateWorkspace, customClass = "" } = props;
    const contextObj: any = useContext(UserContext);
    const [formValues, setFormValues] = useState({
        name: "",
        description: "",
        users: [],
    });
    const [formValidations, setFormValidations] = useState({
        name: false,
    });

    const inputChangeHandler = (evt: any) => {
        setFormValues({ ...formValues, [evt.target.name]: evt.target.value });
        if (evt.target.value) setFormValidations({ ...formValidations, [evt.target.name]: false });
    };

    const handleCreateWorkspace = () => {
        if (contextObj?.socketProjects.current?.readyState !== WebSocket.OPEN) return false;

        if (!formValues.name) {
            setFormValidations({ ...formValidations, name: true });
            return false;
        }

        contextObj?.socketProjects.current?.send(
            JSON.stringify({
                action: "createWorkspace",
                body: {
                    name: formValues.name,
                    description: formValues.description,
                    users: formValues.users,
                },
                token: getAuthToken(),
            })
        );
    };

    const handleWorkspaceUsers = (users) => {
        const updatedUsersList =
            users?.length &&
            users.reduce((accum, curr) => {
                return [...accum, curr["userID"]];
            }, []);
        setFormValues({ ...formValues, users: updatedUsersList });
    };

    return (
        <div className={cx("createModal", customClass)}>
            <div className={cx("header")}>
                <Typography>Create workspace</Typography>
                <div
                    onClick={() => {
                        setIsCreateWorkspace();
                    }}
                >
                    <CloseOutlinedIcon />
                </div>
            </div>
            <div className={cx("content")}>
                <div className={cx("row")}>
                    <InputTextField
                        error={formValidations.name}
                        errorText={"This field is required"}
                        name="name"
                        required={true}
                        label="Workspace Name"
                        autoFocus={true}
                        value={formValues.name}
                        onChange={inputChangeHandler}
                    />
                </div>
                <div className={cx("row")}>
                    <InputTextField
                        name="description"
                        label="Description"
                        value={formValues.description}
                        multiline={true}
                        rows={4}
                        onChange={inputChangeHandler}
                    />
                </div>
                {/* <div className={cx("row")}>
                    <Typography className={cx("col1")}>Visibility</Typography>
                    <SelectField
                        name={"initialMemberRole"}
                        label={"Role"}
                        required={true}
                        //disabled={member?.user?.id != user?.id && isAdmin() ? false : true}
                        options={WorkspaceVisibility?.map((item) => ({
                            value: item.type,
                            label: item.type,
                            description: item.description,
                        }))}
                        value={""}
                        onChange={inputChangeHandler}
                    />
                </div> */}
                <div className={cx("row")} style={{ marginBottom: "24px" }}>
                    <MembersDropdown
                        users={[]}
                        isError={false}
                        existingUsers={[]}
                        setUsers={handleWorkspaceUsers}
                        toText={""}
                        entryPoint={"workspace"}
                    />
                </div>
                <div className={cx("row")}>
                    <Button variant="outlined" size="small" onClick={handleCreateWorkspace}>
                        Create
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default CreateWorkspace;
