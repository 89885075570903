import { DesignationActionTypes } from "./../../actions/designationActions";

const initialState = {
    designations: [],
    designation: {},
    pagination: {
        records: 0,
        per_page_records: 0,
        page: 0,
        pages: 0,
    },
    apiResponse: {
        getDesignations: {},
        getDesignation: {},
        addDesignation: {},
        deleteDesignation: {},
        updateDesignation: {},
    },
};

const reducer = (state = initialState, action: any) => {
    switch (action.type) {
        //DESIGNATIONS
        case DesignationActionTypes.GET_DESIGNATIONS_SUCCESS:
            return { ...state, designations: action.payload.data };
        //return { ...state, designations: action.payload.data.data, pagination: action.payload.data.pagination };
        case DesignationActionTypes.GET_DESIGNATIONS_FAILURE:
            return { ...state, apiResponse: { getDesignations: action.payload } };

        //DESIGNATION
        case DesignationActionTypes.GET_DESIGNATION_SUCCESS:
            return { ...state, designation: action.payload.data[0] };
        case DesignationActionTypes.GET_DESIGNATION_FAILURE:
            return { ...state, apiResponse: { getDesignation: action.payload } };

        //ADD DESIGNATION
        case DesignationActionTypes.ADD_DESIGNATION_SUCCESS:
            return {
                ...state,
                apiResponse: { addDesignation: { status: "success" } },
                designations: [...state.designations, action.payload.data[0]],
            };
        case DesignationActionTypes.ADD_DESIGNATION_FAILURE:
            return { ...state, apiResponse: { addDesignation: action.payload } };

        //UPDATE DESIGNATION
        case DesignationActionTypes.UPDATE_DESIGNATION_SUCCESS:
            return {
                ...state,
                designations: state.designations.map((designation: any) => {
                    if (designation.id === action.payload[0].id) {
                        return action.payload[0];
                    } else {
                        return designation;
                    }
                }),
            };
        case DesignationActionTypes.ADD_DESIGNATION_FAILURE:
            return { ...state, apiResponse: { updateDesignation: action.payload } };

        //DELETE DESIGNATION
        case DesignationActionTypes.DELETE_DESIGNATION_SUCCESS:
            return {
                ...state,
                designations: state.designations.filter((designation: any) => {
                    return designation.id !== action.payload.id;
                }),
            };
        case DesignationActionTypes.DELETE_DESIGNATION_FAILURE:
            return { ...state, apiResponse: { deleteDesignation: action.payload } };

        // CLEAR DESIGNATION
        case DesignationActionTypes.CLEAR_DESIGNATION:
            return {
                ...state,
                designation: {},
            };

        case DesignationActionTypes.RESET:
            return { ...state, ...initialState };

        //DEFAULT
        default:
            return state;
    }
};

export { reducer as designationReducer };
