import React, { FC, useEffect, useState, useContext, useRef, useMemo } from "react";
import { connect } from "react-redux";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import { IconButton, TextField } from "@material-ui/core";

import Avatar from "./../../../../components/common/avatar/Avatar";
import classnames from "classnames/bind";
import styles from "./Chat.scss";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import SendOutlinedIcon from "@material-ui/icons/SendOutlined";
import { Message, UpdateRoomInterface } from "./../../../../utils/interfaces";
import MoreHorizOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";
import NoMessages from "./../../../../assets/images/noMessages.svg";
import { toTimeZone } from "./../../../../utils/utils";
import { UserContext } from "./../../../../components/app/userContext";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import GroupsOutlinedIcon from "@material-ui/icons/GroupOutlined";
import GroupAddOutlinedIcon from "@material-ui/icons/GroupAddOutlined";
import DoneOutlinedIcon from "@material-ui/icons/DoneOutlined";
import { getRoomName } from "./ChatSubHeader";
import InputTextField from "./../../../../components/common/text-field/TextField";
import MembersDropdown from "./../../common/membersDropdown/MembersDropdown";
import { isUserChatRoom } from "./Chat";
import { usePlanActiveHook } from "../../../../utils/userAccess";
import FileUpload from "./../../../../components/common/file-upload/FileUpload";
import PreviewPDF from "./../../../../components/common/preview/PreviewPDF";
import RenderImage from "./../../../../components/common/render-image/RenderImage";

const cx = classnames.bind(styles);

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            "& .MuiInputBase-root": {
                height: "auto",
            },
            "& .MuiInputBase-multiline": {
                padding: "12px 0",
            },
        },
    })
);

interface PageProps {
    room: any;
    rooms: [];
    user: any;
    sendMessageNewRoom: (message: string, newRoomUsers: any) => void;
    sendMessage: (message: string, id: string) => void;
    updateChatRoom: (room: UpdateRoomInterface) => void;
    isCreateNewRoom: boolean;
    setIsChatSideBar: (val: boolean) => void;
    isChatSideBar: boolean;
    getRoom: (page: number) => void;
    selectedRoom: any;
    isLoading?: boolean;
}

const ChatMessages: FC<PageProps> = ({
    room,
    rooms,
    user,
    sendMessageNewRoom,
    sendMessage,
    isCreateNewRoom,
    setIsChatSideBar,
    isChatSideBar,
    getRoom,
    selectedRoom,
    updateChatRoom,
    isLoading,
}) => {
    const isUserAccess = usePlanActiveHook();
    const classes = useStyles();
    const [messages, setMessages] = useState([]);
    const messagesRefs = useRef<any>(null);
    const [newRoomUsers, setNewRoomUsers] = useState([]);
    const [isEditName, setIsEditName] = useState(false);
    const [formValues, setFormValues] = useState<any>({
        message: "",
        chatRoomName: "",
    });
    const [formValidations, setFormValidations] = useState({
        chatRoomName: false,
    });
    const [createRoomError, setCreateRoomError] = useState(false);

    const userContextObj: any = useContext(UserContext);

    const { dateFormatLabel, timeFormatLabel, timeZoneName } = userContextObj || {};

    const isUserChatRoomAllowed = isUserChatRoom(isCreateNewRoom, room, user, isUserAccess);

    const resetForm = () => {
        setFormValues({ message: "", chatRoomName: "" });
        setFormValidations({ ...formValidations, chatRoomName: false });
    };

    const handleKeyDown = (e: any) => {
        if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            if (isCreateNewRoom) {
                sendMessageNewRoom(formValues.message, newRoomUsers);
            } else if (isUserChatRoomAllowed) {
                sendMessage(formValues.message, room.chatRoomId);
            }
            resetForm();
        }
    };

    useEffect(() => {
        if (room?.chatRoomId) {
            //setMessages(room.messages || []);
            setMessages(selectedRoom?.messages || []);
            setFormValues({ ...formValues, chatRoomName: room?.chatRoomName });
        }
    }, [room, selectedRoom]);

    useEffect(() => {
        if (newRoomUsers?.length) {
            setCreateRoomError(false);
        }
    }, [newRoomUsers]);

    useEffect(() => {
        if (formValues?.chatRoomName) {
            setFormValidations({ ...formValidations, chatRoomName: false });
        }
    }, [formValues]);

    const inputChangeHandler = (evt: any) => {
        setFormValues({ ...formValues, [evt.target.name]: evt.target.value });
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages?.length]);

    const scrollToBottom = () => {
        messagesRefs.current?.scrollIntoView({ behavior: "smooth" });
    };

    const handleScroll = (e) => {
        if (e.target.scrollTop <= 0) {
            const { pagination } = selectedRoom;
            if (pagination?.currentPage < pagination?.totalPages) {
                getRoom(pagination.currentPage + 1);
            }
        }
    };

    const validateFormValues = () => {
        return formValues.chatRoomName !== "";
    };

    const handleOnSubmit = () => {
        if (validateFormValues() && isUserChatRoomAllowed) {
            updateChatRoom({ chatRoomId: room.chatRoomId, chatRoomName: formValues.chatRoomName });
        } else {
            setFormValidations({ ...formValidations, chatRoomName: true });
        }
    };

    const RenderAttachment = useMemo(
        () => ({ message }) => {
            const fileUrl = `${process.env.REACT_APP_FILE_URL}/${message.file?.filePath}`;
            switch (message.file.fileType) {
                case "image/png":
                case "image/jpg":
                case "image/jpeg":
                case "image/gif":
                    return <RenderImage src={fileUrl} />;
                case "application/pdf":
                    return <PreviewPDF url={fileUrl} />;
                // return <div>hi</div>;
                default:
                    return <span></span>;
            }
        },
        []
    );

    return (
        <div className={cx("chatContainer")}>
            <div className={cx("chatHistory")}>
                {rooms?.length > 0 && (
                    <div className={cx("chatHistoryHeader")}>
                        {/* selectedRoom?.messages?.length > 0 && */}
                        {!isCreateNewRoom && rooms?.length > 0 && (
                            <div className={cx("chatHeader")}>
                                {room?.users?.length <= 2 ? (
                                    <Avatar name={getRoomName(room, user)} bgColor={user.UserProfileColor} />
                                ) : (
                                    <Avatar>
                                        <GroupsOutlinedIcon />
                                    </Avatar>
                                )}
                                <span className={cx("name")}>
                                    {!isEditName || room?.users?.length <= 2 ? (
                                        <div>{getRoomName(room, user)}</div>
                                    ) : (
                                        <InputTextField
                                            error={formValidations.chatRoomName}
                                            errorText={"This field is required"}
                                            name="chatRoomName"
                                            required={true}
                                            id="chatRoomName"
                                            label="Name your conversation"
                                            value={formValues.chatRoomName}
                                            onChange={inputChangeHandler}
                                        />
                                    )}
                                    {isEditName && isUserChatRoomAllowed && (
                                        <div className={"editActions"}>
                                            <DoneOutlinedIcon
                                                fontSize={"large"}
                                                onClick={() => {
                                                    handleOnSubmit();
                                                    setIsEditName(false);
                                                }}
                                            />
                                            <CloseOutlinedIcon
                                                fontSize={"large"}
                                                onClick={() => setIsEditName(false)}
                                            />
                                        </div>
                                    )}

                                    {room?.users?.length > 2 && !isEditName && isUserChatRoomAllowed && (
                                        <EditOutlinedIcon
                                            onClick={() => {
                                                const name = getRoomName(room, user);
                                                setFormValues({ ...formValues, chatRoomName: name });
                                                setIsEditName(true);
                                            }}
                                        />
                                    )}
                                </span>
                            </div>
                        )}
                        {!isChatSideBar && !isCreateNewRoom && rooms?.length > 0 && (
                            <IconButton
                                aria-label="more"
                                aria-controls="long-menu"
                                aria-haspopup="true"
                                onClick={(e) => setIsChatSideBar(true)}
                            >
                                <MoreHorizOutlinedIcon />
                            </IconButton>

                            // <ArrowBackIosOutlinedIcon onClick={() => setIsChatSideBar(true)} />
                        )}
                    </div>
                )}
                <div className={cx("chatMessagesList", { newRoom: isCreateNewRoom })} onScroll={handleScroll}>
                    {isCreateNewRoom ? (
                        // <CreateNewRoom isError={createRoomError} setNewRoomUsers={setNewRoomUsers} toText={"To"} />
                        <MembersDropdown
                            users={[]}
                            existingUsers={[]}
                            isError={createRoomError}
                            setUsers={setNewRoomUsers}
                            toText={""}
                        />
                    ) : (
                        <>
                            {/* {isLoading && <MessagesSkeleton />} */}
                            {messages?.length > 0 && (
                                <>
                                    {messages
                                        //.sort((a: any, b: any) => a.messageId - b.messageId)
                                        .sort((a: any, b: any) => (a.messageAt > b.messageAt ? 1 : -1))
                                        .map((message: Message, index: number) => {
                                            return (
                                                <div
                                                    ref={messagesRefs}
                                                    className={cx("messageContainer", {
                                                        [message?.messageType]: message?.messageType,
                                                    })}
                                                    key={index}
                                                >
                                                    {(message.messageType === "MESSAGE" ||
                                                        message.messageType === "MESSAGE_FILE" ||
                                                        message.messageType === "ROOM_CREATED") && (
                                                        <div className={cx("avatar")}>
                                                            {message?.messageByUser?.userFullName?.charAt(0)}
                                                        </div>
                                                    )}
                                                    <div className={cx("content")}>
                                                        {(message.messageType === "MESSAGE" ||
                                                            message.messageType === "MESSAGE_FILE" ||
                                                            message.messageType === "ROOM_CREATED") && (
                                                            <>
                                                                <span
                                                                    style={{
                                                                        marginRight: 4,
                                                                        fontWeight: "bold",
                                                                        fontSize: 16,
                                                                    }}
                                                                >
                                                                    {message?.messageByUser?.userFullName}
                                                                </span>{" "}
                                                                <span className={cx("time")}>
                                                                    {toTimeZone(
                                                                        message.messageAt,
                                                                        timeZoneName,
                                                                        dateFormatLabel,
                                                                        timeFormatLabel
                                                                    )}
                                                                </span>
                                                            </>
                                                        )}
                                                        <div className={cx("messageBox")}>
                                                            {message.messageType === "USER_REMOVED" && (
                                                                <DeleteOutlineOutlinedIcon />
                                                            )}
                                                            {message.messageType === "USER_ADDED" && (
                                                                <GroupAddOutlinedIcon />
                                                            )}
                                                            <div className={cx("message")}>{message.message}</div>
                                                        </div>
                                                        {message.messageType === "MESSAGE_FILE" && (
                                                            <div className={cx("messageBox")}>
                                                                <RenderAttachment message={message} />
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                </>
                            )}
                            {!isLoading && messages?.length <= 0 && (
                                <div className={cx("noChatFound")}>
                                    <img src={NoMessages} />
                                    <div className={cx("text")}>No messages found</div>
                                </div>
                            )}
                        </>
                    )}
                </div>

                {/* {(room?.chatRoomId || isCreateNewRoom) && ( */}
                <div className={cx("messageOuter")}>
                    <div className={cx("messageInputContainer")}>
                        <div className={cx("message", { invalidUser: !isUserChatRoomAllowed })}>
                            <div className={cx("inputBox")}>
                                {isUserChatRoomAllowed ? (
                                    <TextField
                                        classes={{ root: classes.root }}
                                        name="message"
                                        required={true}
                                        id="message"
                                        value={formValues.message}
                                        multiline
                                        onKeyDown={handleKeyDown}
                                        onChange={inputChangeHandler}
                                    />
                                ) : (
                                    <TextField disabled classes={{ root: classes.root }} />
                                )}
                            </div>
                            <div className={cx("actions")}>
                                <div
                                    style={{
                                        display: "flex",
                                    }}
                                >
                                    {isUserChatRoomAllowed ? (
                                        <FileUpload type={"chat"} id={room?.chatRoomId} />
                                    ) : (
                                        <FileUpload type={"chat"} id={room?.chatRoomId} disabled={true} />
                                    )}
                                </div>

                                <div>
                                    {isCreateNewRoom ? (
                                        <SendOutlinedIcon
                                            onClick={() => {
                                                if (newRoomUsers?.length) {
                                                    setCreateRoomError(false);
                                                    sendMessageNewRoom(formValues.message, newRoomUsers);
                                                    resetForm();
                                                } else {
                                                    setCreateRoomError(true);
                                                }
                                            }}
                                        />
                                    ) : (
                                        <SendOutlinedIcon
                                            onClick={() => {
                                                if (room?.chatRoomId && isUserChatRoomAllowed) {
                                                    sendMessage(formValues.message, room?.chatRoomId);
                                                    resetForm();
                                                }
                                            }}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* )} */}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        selectedRoom: state.rooms.selectedRoom || {},
        user: state.user.profile.user || {},
        rooms: state.rooms.list || [],
    };
};

/* istanbul ignore next */
// const mapDispatchToProps = (dispatch: Dispatch) => ({
//     getRoom: (id: string) => dispatch(getRoom(id)),
//     updateMessage: (payload: any) => dispatch(updateMessage(payload)),
// });

export default connect(mapStateToProps, null)(ChatMessages);
