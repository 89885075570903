import { fork, put, takeLatest } from "redux-saga/effects";
import { API } from "./api";
import callApi from "../../../../utils/callApi";

import {
    getPlansFailure,
    getPlansSuccess,
    PlanActionTypes,
    getCompanyPlansSuccess,
    getCompanyPlansFailure,
} from "../../../actions/planActions";
import {
    isLoading,
} from "../../../../actions/feature/app/appActions";

function* getPlansSaga() {
    try {
        yield put(isLoading(true));
        const [error, res] = yield* callApi(API.getPlans, { disableLoading: true }, true);
        if (error || res.status === "ERROR") {
            yield put(getPlansFailure({ status: "ERROR" }));
        } else {
            yield put(getPlansSuccess(res));
        }
        yield put(isLoading(false));
    } catch (error) {
        yield put(isLoading(false));
        yield put(getPlansFailure({ status: "ERROR" }));
    }
}

function* getCompanyPlansSaga(reqObject) {
    const { payload } = reqObject;
    try {
        yield put(isLoading(true));
        const [error, res] = yield* callApi(API.getCompanyPlans, { disableLoading: true }, payload, true);
        if (error || res.status === "ERROR") {
            yield put(getCompanyPlansFailure({ status: "ERROR" }));
        } else {
            yield put(getCompanyPlansSuccess(res));
        }
        yield put(isLoading(false));
    } catch (error) {
        yield put(isLoading(false));
        yield put(getCompanyPlansFailure({ status: "ERROR" }));
    }
}

function* getPlanSaga(reqObject) {
    const { payload } = reqObject;
    try {
        yield put(isLoading(true));
        const [error, res] = yield* callApi(API.getPlan, { disableLoading: true }, payload, true);
        if (error || res.status === "ERROR") {
            yield put(getPlansFailure({ status: "ERROR" }));
        } else {
            yield put(getPlansSuccess(res));
        }
        yield put(isLoading(false));
    } catch (error) {
        yield put(isLoading(false));
        yield put(getPlansFailure({ status: "ERROR" }));
    }
}

function* watchPlan() {
    yield takeLatest(PlanActionTypes.GET_PLANS, getPlansSaga);
    yield takeLatest(PlanActionTypes.GET_PLAN, getPlanSaga);
    yield takeLatest(PlanActionTypes.GET_COMPANY_PLANS, getCompanyPlansSaga);
}

const planSagas = [fork(watchPlan)];

export default planSagas;
