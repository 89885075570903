import React, { FC, useState, useEffect, useRef, useCallback } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { io } from "socket.io-client";
import { useHistory } from "react-router-dom";
import FeedsSubHeader from "./FeedsSubHeader";
import Feed from "./Feed";
import { getFeeds } from "./../../../actions/feature/feeds/feedsActions";
import { toggleDrawer } from "../../../actions/feature/app/appActions";
import BodyContent from "./../../common/body/bodyContent";
import { Typography } from "@material-ui/core";
import { useParams } from "react-router-dom";
import Members from "./Members";
import CreateCommunity from "./CreateCommunity";
import { getAuthToken } from "./../../../utils/app-utils";
import { updateTopicsSuccess, updateComment } from "./../../../actions/feature/feeds/feedsActions";

import InputTextField from "./../../../components/common/text-field/TextField";

import classnames from "classnames/bind";
import styles from "./Feeds.scss";
const cx = classnames.bind(styles);
interface IPageProps {
    feeds: [];
    user: any;
    toggleDrawer: (isOpen: boolean) => null;
    getFeeds: () => void;
    updateTopicsSuccess: (result: any) => void;
    updateComment: (result: any) => void;
    //getRooms: () => void;
}

const Feeds: FC<IPageProps> = ({
    feeds = [{ id: "" }],
    toggleDrawer,
    getFeeds,
    updateTopicsSuccess,
    updateComment,
    user,
    //getRooms,
}) => {
    const { groupId } = useParams();
    const [createCommunityOpen, setCreateCommunityOpen] = useState<boolean>(false);
    const [type, setType] = useState<string>("");
    const [feedGroupId, setFeedGroupId] = useState<string>("");
    const [socketConn, setSocketConn] = useState<any>();
    const history = useHistory();

    const [formValues, setFormValues] = useState<any>({
        message: "",
    });
    const inputChangeHandler = (evt: any) => {
        setFormValues({ ...formValues, [evt.target.name]: evt.target.value });
    };

    let socket = useRef<WebSocket | null>(null);

    // useEffect(() => {
    //     if (!socket?.connected && user) {
    //         socket = io(process.env.REACT_APP_FEEDS_SOCKETS_URL!, {
    //             // WARNING: in that case, there is no fallback to long-polling
    //             transports: ["websocket"], // or [ "websocket", "polling" ] (the order matters)
    //             forceNew: true,
    //             secure: true,
    //             upgrade: true,
    //             auth: {
    //                 token: getAuthToken(),
    //             },
    //         });

    //         console.log("INIT=======");
    //         socket.on("connect", () => {
    //             console.log("Socket ID: ", socket.id);
    //         });

    //         socket.on("disconnect", (reason) => {
    //             console.log("disconnected", reason);
    //         });
    //         socket.on("message", (data) => {
    //             console.log("message received", data);
    //         });

    //         // socket.on("commentCreated", (data) => {
    //         //     console.log("new comment received", data);
    //         // });

    //         socket.on("feedGroupCreated", (data) => {
    //             if (data !== "ERROR") {
    //                 getFeeds();
    //                 socket.emit("subscribe");
    //                 if (data?.uid == user?.id) {
    //                     history.push(`/dashboard/group/${data.groupdId}`);
    //                 }
    //             }
    //         });

    //         socket.on("feedGroupUpdated", (data) => {
    //             if (data === "SUCCESS") {
    //                 getFeeds();
    //                 socket.emit("subscribe");
    //             }
    //         });

    //         socket.on("topicCreated", (data) => {
    //             console.log("topicCreated", data);
    //             updateTopicsSuccess(data);
    //         });

    //         socket.on("commentCreated", (data) => {
    //             console.log("commentCreated", data);
    //             updateComment(data);
    //         });

    //         socket.on("comments", (data) => {
    //             console.log("comments received", data);
    //         });

    //         return () => {
    //             socket.off("connect");
    //             socket.off("disconnect");
    //             socket.off("message");
    //         };
    //     }
    // }, [user]);

    // const onSocketOpen = useCallback(() => {
    //     console.log("open");
    // }, []);
    // const onSocketClose = useCallback(() => {
    //     console.log("close");
    // }, []);
    // const onSocketMessage = useCallback((dataStr) => {
    //     const data = JSON.parse(dataStr);
    //     console.log("message", data);
    // }, []);
    // const onDisconnect = useCallback((data) => {
    //     socket.current?.close();
    //     console.log("disconnect");
    // }, []);

    // const joinRoom = useCallback((data) => {
    //     console.log("joinroom", data);
    //     socket.current?.send(
    //         JSON.stringify({
    //             action: "joinRoom",
    //         })
    //     );
    // }, []);

    useEffect(() => {
        return () => {
            socket.current?.close();
        };
    }, []);

    useEffect(() => {
        if (user) {
            // if (socket.current?.readyState !== WebSocket.OPEN) {
            //     socket.current = new WebSocket(
            //         `wss://qdod80jph0.execute-api.ap-southeast-1.amazonaws.com/staging?token=${getAuthToken()}`
            //     );
            // socket.current.addEventListener("open", onSocketOpen);
            // socket.current.addEventListener("close", onSocketClose);
            // socket.current.addEventListener("message", (event) => {
            //     onSocketMessage(event.data);
            // });
            // socket.onopen = (event) => {
            //     console.log("WebSocket is now open.");
            //     //ws.send(`{ "action": "send_message", type="join_room" "data": 1}`);
            //     ws.send({
            //         action: "sendMessage",
            //         body: "hello",
            //         author: "vv",
            //     });
            // };
            // socket.onclose = (event) => {
            //     console.log("WebSocket is now closed.");
            // };
            // socket.onerror = (event) => {
            //     console.error("WebSocket error observed:", event);
            // };
            // socket.onmessage = (e) => {
            //     console.log("onmessage", e.data);
            // };
            // }
        }
    }, [user]);

    useEffect(() => {
        setSocketConn(socket);
    }, [user, socket]);

    useEffect(() => {
        getFeeds();
        ///getRooms();
    }, []);

    useEffect(() => {
        if (!groupId) {
            if (feeds && typeof feeds !== "undefined" && feeds.length) {
                const gid = feeds[0]["id"];
                history.push(`/dashboard/group/${gid}`);
            }
        }
    }, [groupId, feeds]);

    const handleCreateCommunityOpen = (type: string, id?: string) => {
        setFeedGroupId(id!);
        setType(type);
        setCreateCommunityOpen(true);
    };

    const handleCreateCommunityClose = () => {
        setCreateCommunityOpen(false);
    };

    const handleCreateCommunity = (payload: any) => {
        socketConn.emit("createFeedGroup", payload);
    };

    const handleUpdateCommunity = (payload: any) => {
        socketConn.emit("updateFeedGroup", payload);
    };

    return (
        <div className={cx("feeds")}>
            <FeedsSubHeader
                feeds={feeds}
                selected={groupId}
                onCreateCommunity={handleCreateCommunityOpen}
                user={user}
            />
            <BodyContent style={{ overflowY: "scroll" }}>
                <Feed socket={socketConn} />
            </BodyContent>
            <div className={cx("feedsRightSide")}>
                <Members />
            </div>
            <CreateCommunity
                createCommunityOpen={createCommunityOpen}
                onCreateCommunityClose={handleCreateCommunityClose}
                onCreateCommunity={handleCreateCommunity}
                onUpdateCommunity={handleUpdateCommunity}
                type={type}
                id={feedGroupId}
                feeds={feeds}
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        feeds: state.feeds.feedGroups || [],
        user: state.user.profile.user,
    };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => ({
    getFeeds: () => dispatch(getFeeds()),
    toggleDrawer: (isOpen: boolean) => dispatch(toggleDrawer(isOpen)),
    updateTopicsSuccess: (result: any) => dispatch(updateTopicsSuccess(result)),
    updateComment: (result: any) => dispatch(updateComment(result)),
    //getRooms: () => dispatch(getRooms()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Feeds);
