import React, { FC, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
    Button,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Paper,
    Box,
    Menu,
    MenuItem,
    IconButton,
} from "@material-ui/core";
import { API } from "./../../../../sagas/feature/user/api";
import Alert from "./../../../../../components/common/alert/Alert";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import AlertDialog from "./../../../../../components/common/alert-dialog/AlertDialog";
import Avatar from "./../../../../../components/common/avatar/Avatar";
import AddIcon from "@material-ui/icons/Add";
import { getComparator, descendingComparator, stableSort } from "../../../../../utils/app-utils";
import { UserProfile } from "./../../../../../utils/types";
import { useAllowActionsHook, useIsAllowAddUsersHook, usePlanActiveHook } from "../../../../../utils/userAccess";
import MoreHorizOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";
import { UserContext } from "./../../../../../components/app/userContext";

import classnames from "classnames/bind";
import styles from "./../Styles.scss";
const cx = classnames.bind(styles);

const HeaderContent = ({ onClickAdd, userProfile, users }) => {
    const isActionsAccess = useAllowActionsHook();
    const isAddUsersAccesss = useIsAllowAddUsersHook();
    const isPlanActiveAccess = usePlanActiveHook();
    return (
        <div className={cx("headerSection")} style={{ padding: "12px 24px" }}>
            <div className={cx("top")}>
                <Typography variant="h4">Users</Typography>
                <Button
                    color="primary"
                    variant="outlined"
                    size="small"
                    onClick={onClickAdd}
                    startIcon={<AddIcon />}
                    disabled={!isActionsAccess || !isAddUsersAccesss || !isPlanActiveAccess}
                >
                    Add User
                </Button>
            </div>
        </div>
    );
};

const rows = (list: any) => {
    return list.map((item: any) => {
        return {
            id: item.id,
            userFullName: item.UserFullName,
            userEmail: item.UserEmail,
            employeeID: item.EmployeeID,
            roleName: item.RoleName,
            userStatus: item.UserStatus,
            userInviteSend: item.UserInviteSend,
            userProfileColor: item.UserProfileColor,
        };
    });
};

const headCells = [
    { id: "name", numeric: false, padding: "0 0 0 10px", label: "Name", sort: true },
    { id: "role", numeric: false, padding: "0 0 0 10px", label: "Role", sort: true },
    { id: "status", numeric: false, padding: "0 0 0 10px", label: "Status", sort: true },
    { id: "actions", numeric: true, padding: "0 0 0 10px", label: "Action", sort: false },
];

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? "right" : "left"}
                        // padding={headCell.padding}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        {headCell.sort ? (
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : "asc"}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <span className={cx("visuallyHidden")}>{order === "desc" ? "" : ""}</span>
                                ) : null}
                            </TableSortLabel>
                        ) : (
                            <TableSortLabel hideSortIcon={true}>{headCell.label}</TableSortLabel>
                        )}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

interface IPageProps {
    users: [];
    onClickEdit: (id: string) => void;
    onClickAdd: () => void;
    pagination: any;
    onDeleteUser: (id: any) => void;
    userProfile: UserProfile;
    getUsersList: () => void;
    getUserProfile: () => void;
}

const UserPage: FC<IPageProps> = ({
    users,
    onClickEdit,
    onClickAdd,
    pagination = {},
    onDeleteUser,
    userProfile,
    getUsersList,
    getUserProfile,
}) => {
    const isAdminAccess = useAllowActionsHook();
    const isPlanActiveAccess = usePlanActiveHook();
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("name");
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [selectedId, setSelectedId] = React.useState("");
    const [action, setAction] = React.useState("");
    const [isAlertDialogOpen, setIsAlertDialogOpen] = React.useState(false);
    const [isTerminateDialogOpen, setIsTerminateDialogOpen] = React.useState(false);
    const [isTerminate, setIsTerminate] = React.useState("");
    const [activeUsers, setActiveUsers] = React.useState(0);
    const [isUserInviteSend, setIsUserInviteSend] = React.useState(true);
    const [isUserInviteSendOpen, setIsUserInviteSendOpen] = React.useState(false);
    const [isUserInviteSuccess, setIsUserInviteSuccess] = React.useState(false);
    const history = useHistory();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    useEffect(() => {
        const activeUsers = users.filter((user) => user["UserStatus"] === "Active");
        setActiveUsers(activeUsers?.length);
    }, [users]);

    const handleClick = (event, id) => {
        setAnchorEl(event.currentTarget);
        setSelectedId(id);
    };

    useEffect(() => {
        const currUser = users.find((user) => user["id"] === selectedId && user["UserInviteSend"] !== "Yes");
        if (currUser) {
            setIsUserInviteSend(false);
        } else {
            setIsUserInviteSend(true);
        }
    }, [users, selectedId]);

    const handleMenuAction = (action) => {
        if (!isAdminAccess) return false;

        if (action === "edit") {
            onClickEdit(selectedId);
        } else if (action === "terminate") {
            setIsTerminateDialogOpen(true);
        } else if (action === "resend_invite") {
            setIsUserInviteSendOpen(true);
        }

        setAnchorEl(null);
    };

    const handleClose = () => {
        setSelectedId("");
        setAnchorEl(null);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const onClickYes = (isYes: boolean) => {
        if (!isAdminAccess) return false;

        setIsAlertDialogOpen(false);
        // Delete records
        if (isYes) {
            onDeleteUser(selectedId);
        } else {
            setSelectedId("");
        }
    };

    const onClickTerminateYes = (isYes: boolean) => {
        if (!isAdminAccess) return false;
        setIsTerminateDialogOpen(false);
        if (isYes) {
            API.updateUserOffboarding({ id: selectedId }).then((res) => {
                if (res.status === "ERROR") {
                    setIsTerminate("error");
                } else {
                    setIsTerminate("success");
                    getUsersList();
                    getUserProfile();
                }
            });
        } else {
            setSelectedId("");
        }
    };

    const onClickInviteSendYes = (isYes: boolean) => {
        if (!isAdminAccess) return false;
        setIsUserInviteSendOpen(false);
        if (isYes) {
            API.sendInviteEmail({ id: selectedId }).then((res) => {
                if (res.status === "ERROR") {
                    setIsUserInviteSuccess(false);
                } else {
                    setIsUserInviteSuccess(true);
                    getUsersList();
                }
            });
        } else {
            setSelectedId("");
        }
    };

    const handleDelete = (event: any, id: string) => {
        setAction("");
        setSelectedId(id);
        setIsAlertDialogOpen(true);
    };

    if (!isAdminAccess) {
        //history.push("/dashboard");
    }

    return (
        <>
            <div className={"headerContainer"}>
                <HeaderContent onClickAdd={onClickAdd} userProfile={userProfile} users={users} />
            </div>

            <div className="contentBody">
                <AlertDialog
                    isOpen={isAlertDialogOpen}
                    maxWidth={"md"}
                    onClickYes={onClickYes}
                    title={"Delete User?"}
                    description={"Are you sure you want to delete record?"}
                />
                <AlertDialog
                    isOpen={isTerminateDialogOpen}
                    maxWidth={"md"}
                    onClickYes={onClickTerminateYes}
                    title={"Terminate User?"}
                    description={"Are you sure you want to terminate user?"}
                />
                <AlertDialog
                    isOpen={isUserInviteSendOpen}
                    maxWidth={"md"}
                    onClickYes={onClickInviteSendYes}
                    title={"Send Invite email?"}
                    description={"Are you sure you want to send invite email to user?"}
                />
                {isTerminate && (
                    <Alert
                        onClose={() => {}}
                        message={isTerminate ? "User terminated successfully" : "Invalid error, please try again later"}
                        type={isTerminate}
                    ></Alert>
                )}
                {isUserInviteSuccess && (
                    <Alert
                        onClose={() => {}}
                        message={
                            isUserInviteSuccess
                                ? "Invite email sent successfully"
                                : "Invalid error, please try again later"
                        }
                        type={isUserInviteSuccess}
                    ></Alert>
                )}

                <Box style={{ padding: "0 0 8px 0" }}>
                    <Typography>Total users (Active): {activeUsers}</Typography>
                </Box>
                <Paper className={cx("contentWrapper")}>
                    <div className={cx("userListing")}>
                        <TableContainer>
                            <Table
                                className={cx("table")}
                                aria-labelledby="tableTitle"
                                size={"medium"}
                                aria-label="enhanced table"
                            >
                                <EnhancedTableHead
                                    numSelected={0}
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                    rowCount={rows.length}
                                />
                                <TableBody>
                                    {stableSort(rows(users), getComparator(order, orderBy)).map((row, index) => {
                                        const labelId = `enhanced-table-checkbox-${index}`;
                                        return (
                                            <TableRow hover={true} role="checkbox" tabIndex={-1} key={index}>
                                                <TableCell>
                                                    <div className={cx("avatarSection")}>
                                                        <Avatar
                                                            name={row.userFullName}
                                                            bgColor={row.userProfileColor}
                                                        />
                                                        <div className={cx("left")}>
                                                            <div>
                                                                {row.userFullName} {`(Emp ID: ${row.employeeID})`}
                                                            </div>
                                                            <Typography variant="body2">{row.userEmail}</Typography>
                                                        </div>
                                                    </div>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant="body2">{row.roleName}</Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <div
                                                        className={`${row.userStatus === "Terminate" ? "warning" : ""}`}
                                                    >
                                                        <Typography variant="body2">{row.userStatus}</Typography>
                                                    </div>
                                                </TableCell>
                                                <TableCell align="right" className={cx("iconBtn")}>
                                                    {row.userStatus !== "Terminate" && (
                                                        <IconButton
                                                            aria-label="more"
                                                            aria-controls="long-menu"
                                                            aria-haspopup="true"
                                                            onClick={(e) => handleClick(e, row.id)}
                                                        >
                                                            <MoreHorizOutlinedIcon />
                                                        </IconButton>
                                                    )}

                                                    {/* {userProfile &&
                                                        userProfile.user &&
                                                        userProfile.user.RoleName === "ADMINISTRATOR" && (
                                                            <>
                                                                <a
                                                                    onClick={(event) => onClickEdit(row.id)}
                                                                    className={"mr-16"}
                                                                    style={{ cursor: "pointer" }}
                                                                >
                                                                    <i className="far fa-edit fa-2x"></i>
                                                                </a>
                                                                {userProfile &&
                                                                userProfile.user &&
                                                                userProfile.user.id === row.id ? (
                                                                    <a style={{ cursor: "default" }}>
                                                                        <i className="fas fa-trash-alt fa-2x fa-disabled"></i>
                                                                    </a>
                                                                ) : (
                                                                    <a
                                                                        onClick={(event) => handleDelete(event, row.id)}
                                                                        style={{ cursor: "pointer" }}
                                                                    >
                                                                        <i className="fas fa-trash-alt fa-2x"></i>
                                                                    </a>
                                                                )}
                                                            </>
                                                        )} */}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                            <Menu id="long-menu" anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
                                {isAdminAccess ? (
                                    <MenuItem onClick={() => handleMenuAction("edit")}>View</MenuItem>
                                ) : (
                                    <MenuItem disabled>View</MenuItem>
                                )}
                                {(userProfile && userProfile.user && userProfile.user.id === selectedId) ||
                                !isAdminAccess ||
                                !isPlanActiveAccess ? (
                                    <MenuItem disabled>Terminate</MenuItem>
                                ) : (
                                    <MenuItem onClick={() => handleMenuAction("terminate")}>Terminate</MenuItem>
                                )}
                                {isAdminAccess && !isUserInviteSend && (
                                    <MenuItem onClick={() => handleMenuAction("resend_invite")}>Resend invite</MenuItem>
                                )}
                            </Menu>
                        </TableContainer>
                        {pagination.records > 10 && (
                            <TablePagination
                                rowsPerPageOptions={[10]}
                                component="div"
                                count={parseInt(pagination.records)}
                                rowsPerPage={parseInt(pagination.per_page_records)}
                                page={parseInt(pagination.page)}
                                onChangePage={handleChangePage}
                            />
                        )}
                    </div>
                </Paper>
            </div>
        </>
    );
};

export default UserPage;
