import { fork, put, delay, takeLatest, select, all } from "redux-saga/effects";
import { API } from "./api";
import callApi from "./../../../utils/callApi";

import {
    RoomsActionTypes,
    getRoomsFailure,
    getRoomsSuccess,
    getRoomSuccess,
    getRoomFailure,
} from "./../../../actions/feature/rooms/roomsActions";

import {
    isLoading,
    openSnackbar,
    closeSnackbar,
    toggleDrawer,
    openAlert,
    closeAlert,
} from "../../../actions/feature/app/appActions";

function* getRoomsSaga() {
    try {
        yield put(isLoading(true));
        const [error, res] = yield* callApi(API.getRooms, { disableLoading: true }, true);
        if (error || res.status === "ERROR") {
            yield put(getRoomsFailure({ status: "ERROR" }));
        } else {
            yield put(getRoomsSuccess(JSON.parse(res.data)));
        }
        yield put(isLoading(false));
    } catch (error) {
        yield put(isLoading(false));
        //yield put(getRoomsFailure({ status: "ERROR" }));
    }
}

function* getRoomSaga(reqObject) {
    const { payload } = reqObject;
    try {
        yield put(isLoading(true));
        const [error, res] = yield* callApi(API.getRoom, { disableLoading: true }, payload, true);
        if (error || res.status === "ERROR") {
            yield put(getRoomFailure({ status: "ERROR" }));
        } else {
            yield put(getRoomSuccess(JSON.parse(res.data)));
        }
        yield put(isLoading(false));
    } catch (error) {
        yield put(isLoading(false));
        //yield put(getRoomsFailure({ status: "ERROR" }));
    }
}

function* watchUser() {
    yield takeLatest(RoomsActionTypes.GET_ROOMS, getRoomsSaga);
    yield takeLatest(RoomsActionTypes.GET_ROOM, getRoomSaga);
}

const roomsSagas = [fork(watchUser)];

export default roomsSagas;
