import React, { FC, useState, useEffect } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { Button, Link, Checkbox, Typography, FormHelperText, CircularProgress, Box, Paper } from "@material-ui/core";
import { Link as RouterLink, useParams, useHistory, Redirect } from "react-router-dom";
import InputTextField from "./../../../components/common/text-field/TextField";
import { signup } from "../../../actions/feature/auth/authActions";
import { setAuthToken, removeCookie } from "./../../../utils/app-utils";
import { getUserProfile } from "./../../../hrm/actions/userActions";
import Logo from "./../../../assets/images/logo2.png";
import Alert from "./../../common/alert/Alert";

import classnames from "classnames/bind";
import styles from "./Signup.scss";
import colors from "colors";
const cx = classnames.bind(styles);

const useStyles = makeStyles((theme: Theme) => ({
    title: {
        textAlign: "center",
        marginBottom: "40px",
    },
}));

declare global {
    interface Window {
        grecaptcha: any;
    }
}

interface PageProps {
    apiResponse: any;
    isLoading: boolean;
    onSignup: (payload: any) => void;
    getUserProfile: () => void;
}

const Signup: FC<PageProps> = ({ apiResponse, isLoading, onSignup, getUserProfile }) => {
    const history = useHistory();
    const classes = useStyles();
    const { id = 0, duration = "yearly" } = useParams();

    useEffect(() => {
        setTimeout(() => {
            try {
                window.grecaptcha.render("recaptcha", {
                    sitekey: process.env.REACT_APP_GOOGLE_SITE_KEY,
                    size: "invisible",
                });
            } catch (err) {
                console.log(err);
            }
        }, 1000);
    }, []);

    const REACT_APP_STATIC_APP_URL = process.env.REACT_APP_STATIC_APP_URL || "http://localhost:7004";

    const [timeZone, setTimeZone] = useState<string>("");
    const [showError, setShowError] = useState<boolean>(false);
    const [formValues, setFormValues] = useState<any>({
        fullName: "",
        email: "",
        password: "",
        acceptTerms: "",
    });
    const [formValidations, setFormValidations] = useState<any>({
        fullName: false,
        email: false,
        password: false,
        acceptTerms: false,
    });

    useEffect(() => {
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        setTimeZone(timezone);
    }, []);

    useEffect(() => {
        removeCookie();
    }, []);

    // useEffect(() => {
    //     if (apiResponse?.status === "ERROR" && apiResponse?.description === "ACCESS_DENIED") {
    //         setFormValues({ fullName: "", email: "", password: "", acceptTerms: false });
    //     }
    // }, [apiResponse]);

    const inputChangeHandler = (evt: any) => {
        setFormValues({ ...formValues, [evt.target.name]: evt.target.value });
    };

    const checkboxChangeHandler = (evt: any) => {
        setFormValues({ ...formValues, [evt.target.name]: evt.target.checked ? true : false });
    };

    const validateFormValues = () => {
        let isValid = true;
        let tempFormValidations = {};
        for (const [key, value] of Object.entries(formValues)) {
            if (!value) {
                isValid = false;
                tempFormValidations[`${key}`] = true;
            } else {
                tempFormValidations[`${key}`] = false;
            }
        }
        setFormValidations({ ...formValidations, ...tempFormValidations });
        return isValid;
    };

    const submitForm = (evt: any) => {
        evt.preventDefault();
        if (isLoading) {
            return false;
        }
        setShowError(true);
        if (validateFormValues()) {
            window.grecaptcha.execute();

            const interval = setInterval(function() {
                const token = window.grecaptcha.getResponse();
                if (token) {
                    window.grecaptcha.reset();
                    clearInterval(interval);
                    onSignup({ ...formValues, token: token, planID: id, duration, timeZone });
                }
            }, 500);
        }
    };

    if (apiResponse && apiResponse.status === "SUCCESS") {
        setAuthToken(apiResponse.data.jwt);
        getUserProfile();
        return <Redirect to="/hrm/chat" />;
    }

    return (
        <div className={cx("signupWrapper")}>
            <a href={process.env.REACT_APP_STATIC_APP_URL}>
                <img src={Logo} className={cx("logopage")} />
            </a>
            <Paper className={cx("formWrapper")} elevation={1}>
                <Typography variant="h1" className={classes.title}>
                    Signup
                </Typography>
                {apiResponse?.status === "ERROR" && apiResponse?.description === "ACCESS_DENIED" && (
                    <Alert onClose={() => {}} message={"Invalid error, please try again later"} type={"error"}></Alert>
                )}
                {apiResponse?.status === "ERROR" && apiResponse?.description === "RECORD_ALREADY_EXIST" && (
                    <Alert onClose={() => {}} message={"Email already exist"} type={"error"}></Alert>
                )}
                <Box className={cx("boxMargin")}>
                    <InputTextField
                        error={formValidations.fullName}
                        errorText={"This field is required"}
                        name="fullName"
                        required={true}
                        id="fullName"
                        label="Full Name"
                        value={formValues.fullName}
                        onChange={inputChangeHandler}
                    />
                </Box>
                <Box className={cx("boxMargin")}>
                    <InputTextField
                        error={formValidations.email}
                        errorText={"This field is required"}
                        name="email"
                        required={true}
                        id="email"
                        label="Email"
                        value={formValues.email}
                        onChange={inputChangeHandler}
                    />
                </Box>
                <Box className={cx("boxMargin")}>
                    <InputTextField
                        error={formValidations.password}
                        errorText={"This field is required"}
                        name="password"
                        required={true}
                        id="password"
                        label="Password"
                        type="password"
                        value={formValues.password}
                        onChange={inputChangeHandler}
                    />
                </Box>
                <Box className={cx("termsBox")}>
                    <Box className={cx("termsBoxContainer")}>
                        <Checkbox
                            color="primary"
                            value={formValues.acceptTerms}
                            name={"acceptTerms"}
                            onChange={checkboxChangeHandler}
                        />
                        <div>
                            <span className={cx("terms")}>
                                I Agree to the{" "}
                                <a
                                    className={cx("anchor")}
                                    href={`${REACT_APP_STATIC_APP_URL}/privacy-policy`}
                                    target="_blank"
                                >
                                    terms of service{" "}
                                </a>
                                and{" "}
                                <a
                                    className={cx("anchor")}
                                    href={`${REACT_APP_STATIC_APP_URL}/privacy-policy`}
                                    target="_blank"
                                >
                                    privacy policy
                                </a>
                            </span>
                        </div>
                    </Box>
                    <div style={{ display: "flex" }}>
                        {formValidations.acceptTerms && showError && (
                            <FormHelperText error>This field is required</FormHelperText>
                        )}
                    </div>
                </Box>
                <div id="recaptcha"></div>
                <Button
                    type="submit"
                    style={{ margin: "24px 0 16px" }}
                    onClick={submitForm}
                    endIcon={
                        isLoading ? (
                            <CircularProgress color={colors.darkPrimary} size="20px" />
                        ) : (
                            <div style={{ width: "20px" }} />
                        )
                    }
                    fullWidth
                    variant="contained"
                    color="primary"
                    size="large"
                >
                    Sign Up
                </Button>
                <Box className={cx("link")}>
                    <Typography variant="body2" style={{ paddingRight: 5 }}>
                        Already have an account?
                    </Typography>
                    <Link component={RouterLink} to="/login" variant="body2">
                        Sign in
                    </Link>
                </Box>
            </Paper>
        </div>
    );
};

/* istanbul ignore next */
const mapStateToProps = (state: any) => {
    return {
        isLoading: state.app.isLoading,
        apiResponse: state.auth.signup.apiResponse,
    };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: any) => ({
    onSignup: (payload: any) => dispatch(signup(payload)),
    getUserProfile: () => dispatch(getUserProfile()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Signup);
