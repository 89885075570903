import React, { FC, useEffect } from "react";
import classnames from "classnames/bind";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import styles from "./app.scss";
import Header from "./../common/header/header";
import Layout from "./../../../components/common/layout/Layout";
import Routes from "./routes";

const cx = classnames.bind(styles);

interface AppHrmPage {
    userProfile: {
        RoleName: string;
    };
}

const AppHrm: FC<AppHrmPage> = ({ userProfile }) => {
    const { location } = window;

    return (
        <>
            <Layout>
                <Header userProfile={userProfile} />
                <Routes location={location} />
            </Layout>
        </>
    );
};

/* istanbul ignore next */
const mapStateToProps = (state) => {
    return {
        userProfile: state.user.profile,
    };
};

export default withRouter(connect(mapStateToProps, null)(AppHrm));
