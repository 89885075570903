import { buildAuthPOSTFile } from "./../../../utils/filesApi";

// APIs
const postFile = (formData) => {
    return buildAuthPOSTFile(`files`, formData);
};

// API export
export const API = {
    postFile,
};
