/* Eslint_disable */
import React, { FC, useEffect, useMemo, useState } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { Typography } from "@material-ui/core";
import { useParams, useHistory } from "react-router-dom";

import { DragDropContext, Droppable } from "react-beautiful-dnd";

import List from "../List/List";
import ListAdder from "../ListAdder/ListAdder";
import BodyContent from "./../../../common/body/bodyContent";

import { toggleDrawer } from "../../../../../actions/feature/app/appActions";
import withProjectSocket from "./../../../../hoc/ProjectsSocket";
import {
    getWorkspace,
    getWorkspaceProjectLists,
    updateWorkspaceProjectSelected,
    getWorkspaceTask,
    resetWorkspaceTask,
    updateTask,
    updateTaskOrder,
    updateTaskPositionInList,
    updateListOrder,
} from "./../../../../../actions/feature/workspaces/workspacesActions";
import CardDetails from "./../Card/CardDetails";
import WorkspacesSubHeader from "./../workspaces/WorkspacesSubHeader";
import { moveItem } from "./../../../../../utils/utils";

import classnames from "classnames/bind";
import styles from "./Project.scss";
const cx = classnames.bind(styles);

interface HeaderContentProps {
    selectedWorkspace: any;
    selectedWorkspaceProject: any;
}

const HeaderContent: FC<HeaderContentProps> = ({ selectedWorkspace, selectedWorkspaceProject = {} }) => {
    const history = useHistory();
    const { workspaceId = "", projectId = "" } = useParams();

    // const handleChange = (event, newValue) => {
    //     setValue(newValue);
    //     if (newValue === 0) {
    //         history.push(`/hrm/workspaces/${workspaceId}#projects`);
    //     } else if (newValue === 1) {
    //         history.push(`/hrm/workspaces/${workspaceId}#members`);
    //     } else if (newValue === 2) {
    //         history.push(`/hrm/workspaces/${workspaceId}#settings`);
    //     }
    // };

    return (
        <div className={cx("headerSection")}>
            <div className={cx("top")}>
                <div>
                    <div className={cx("avatar")}>{selectedWorkspaceProject?.projectName?.charAt(0)}</div>
                    <Typography variant="h4">{selectedWorkspaceProject?.projectName}</Typography>
                </div>
            </div>
            {/* {!projectId && workspaceId && (
                <Tabs value={value} onChange={handleChange} aria-label="workspaces">
                    <Tab label="Projects" />
                    <Tab label="Members" />
                    <Tab label="Settings" />
                </Tabs>
            )} */}
        </div>
    );
};

interface PageProps {
    projectLists: [];
    getWorkspace: (id) => void;
    selectedWorkspace: any;
    selectedWorkspaceProject: any;
    selectedWorkspaceProjectTaskId: string;
    selectedWorkspaceProjectTask: any;
    getWorkspaceTask: (taskId: string) => void;
    getWorkspaceProjectLists: (payload) => void;
    toggleDrawer: (isOpen: boolean) => null;
    updateWorkspaceProjectSelected: (payload: any) => void;
    isDrawerOpen: boolean;
    updateTask: (payload: any) => void;
    updateTaskOrder: (payload: any) => void;
    updateListOrder: (payload: any) => void;
    updateTaskPositionInList: (payload: any) => void;
    socketProject: WebSocket | null;
    authToken: string;
    projectLabels: [];
}

const Project: FC<PageProps> = ({
    projectLists,
    getWorkspace,
    selectedWorkspace,
    selectedWorkspaceProject,
    selectedWorkspaceProjectTaskId,
    selectedWorkspaceProjectTask,
    getWorkspaceTask,
    getWorkspaceProjectLists,
    toggleDrawer,
    updateWorkspaceProjectSelected,
    isDrawerOpen,
    updateTask,
    updateTaskOrder,
    updateListOrder,
    updateTaskPositionInList,
    socketProject,
    authToken,
    projectLabels,
}) => {
    const { workspaceId = "", projectId = "" } = useParams();
    const { projects } = selectedWorkspace;
    const [isTaksOpen, setIsTaksOpen] = useState(false);
    const [startX, setStartX] = useState<number>(0);
    const [startScrollX, setStartScrollX] = useState<number>(0);

    useEffect(() => {
        getWorkspaceProjectLists({ workspaceId, projectId });
    }, []);

    useEffect(() => {
        if (workspaceId) {
            getWorkspace(workspaceId);
        }
    }, [workspaceId]);

    React.useEffect(() => {
        const project = projects?.find((project) => project.id == projectId);
        updateWorkspaceProjectSelected(project);
    }, [projects, projectId]);

    //const handleDragEnd = (props: any) => {
    const handleDragEnd = (props: any) => {
        try {
            console.log("handling drag", props);
            const { source, destination, type, reason, draggableId } = props || {};

            if (reason === "DROP" && destination) {
                if (type === "COLUMN" && source?.index >= 0 && destination?.index >= 0) {
                    console.log("col move");

                    const arrDraggableListId = draggableId?.split("list-");
                    const listId = arrDraggableListId[1];

                    const listData: any = projectLists?.find((list: any) => list.id == listId);

                    if (listData) {
                        let ind = destination?.index > source?.index ? destination?.index : destination?.index - 1;
                        const prevList: any = projectLists[ind];
                        let nextList: any = projectLists[ind + 1];

                        let listOrder = 0;

                        if (prevList) {
                            console.log("prev list");
                            listOrder = parseInt(prevList.listOrder) + 100 + parseInt(source?.index || 1);
                            if (nextList) {
                                console.log("next list");
                                const diff = Math.trunc(Math.floor(nextList.listOrder - prevList.listOrder));
                                listOrder = parseInt(prevList.listOrder) + Math.floor(diff / 2);
                            }
                        } else {
                            console.log("next list else");
                            nextList = projectLists[destination?.index];
                            listOrder = nextList ? parseInt(nextList.listOrder) - 200 : new Date().getTime();
                        }
                        console.log("listOrder", listOrder);

                        // let listDataFinal: any = projectLists[destination?.index];
                        // const listOrder =
                        //     source.index < destination.index
                        //         ? parseInt(listDataFinal.listOrder) - 1
                        //         : parseInt(listDataFinal.listOrder) + 1;

                        updateListOrder({
                            listId: listId,
                            listOrder,
                        });

                        if (socketProject) {
                            socketProject?.send(
                                JSON.stringify({
                                    action: "updateList",
                                    body: {
                                        listId,
                                        listOrder,
                                    },
                                    token: authToken,
                                })
                            );
                        }
                    }
                } else if (type === "DEFAULT") {
                    const arrDraggableId = draggableId?.split("card-");
                    const taskId = arrDraggableId[1];

                    let taskOrder = 0;
                    const list: any = projectLists?.find((list: any) => list.id == destination?.droppableId);
                    if (list) {
                        let ind = destination?.index > source?.index ? destination?.index : destination?.index - 1;
                        const prevTask = list.tasks[ind];
                        const nextTask = list.tasks[ind + 1];

                        if (prevTask) {
                            taskOrder = parseInt(prevTask.taskOrder) + 100 + parseInt(source?.index || 1);
                            if (nextTask) {
                                const diff = Math.trunc(Math.floor(nextTask.taskOrder - prevTask.taskOrder));
                                taskOrder = parseInt(prevTask.taskOrder) + Math.floor(diff / 2);
                            }
                        } else {
                            const nextTask = list.tasks[destination?.index];
                            taskOrder = nextTask ? parseInt(nextTask.taskOrder) - 200 : new Date().getTime();
                        }
                    }

                    if (source?.droppableId == destination?.droppableId && taskId && taskOrder) {
                        console.log("same list drop", taskOrder);
                        updateTaskOrder({
                            taskOrder,
                            taskId,
                            listId: source.droppableId,
                        });

                        socketProject?.send(
                            JSON.stringify({
                                action: "updateTask",
                                body: {
                                    taskId,
                                    taskOrder,
                                },
                                token: authToken,
                            })
                        );
                    } else if (taskId) {
                        console.log("other list drop", taskOrder);

                        // update delete and add task
                        if (taskId) {
                            updateTaskPositionInList({
                                taskId,
                                removedList: source.droppableId,
                                addedList: destination.droppableId,
                                taskOrder,
                            });

                            updateTaskOrder({
                                taskOrder,
                                taskId,
                                listId: destination.droppableId,
                            });
                        }

                        if (socketProject && taskId && destination?.droppableId) {
                            socketProject?.send(
                                JSON.stringify({
                                    action: "updateTask",
                                    body: {
                                        taskId,
                                        fkListID: destination.droppableId,
                                        taskOrder,
                                    },
                                    token: authToken,
                                })
                            );
                        }
                    }
                }
            }
        } catch (err) {
            console.log(err);
            return;
        }
    };

    // The following three methods implement dragging of the board by holding down the mouse
    const handleMouseDown = ({ target, clientX }) => {
        if (target.className !== "list-wrapper" && target.className !== "lists") {
            return;
        }
        window.addEventListener("mousemove", handleMouseMove);
        window.addEventListener("mouseup", handleMouseUp);
        setStartX(clientX);
        setStartScrollX(window.scrollX);
    };

    // Go to new scroll position every time the mouse moves while dragging is activated
    const handleMouseMove = ({ clientX }) => {
        const scrollX = (startScrollX || 0) - clientX + (startX || 0);
        window.scrollTo(scrollX, 0);
        const windowScrollX = window.scrollX;
        if (scrollX !== windowScrollX) {
            setStartX(clientX + windowScrollX - startScrollX);
        }
    };

    // Remove drag event listeners
    const handleMouseUp = () => {
        if (startX) {
            window.removeEventListener("mousemove", handleMouseMove);
            window.removeEventListener("mouseup", handleMouseUp);
            setStartX(0);
            setStartScrollX(0);
        }
    };

    const handleWheel = ({ target, deltaY }) => {
        // Scroll page right or left as long as the mouse is not hovering a card-list (which could have vertical scroll)
        if (
            target.className !== "list-wrapper" &&
            target.className !== "lists" &&
            target.className !== "open-composer-button" &&
            target.className !== "list-title-button"
        ) {
            return;
        }
        // Move the board 80 pixes on every wheel event
        if (Math.sign(deltaY) === 1) {
            window.scrollTo(window.scrollX + 80, 0);
        } else if (Math.sign(deltaY) === -1) {
            window.scrollTo(window.scrollX - 80, 0);
        }
    };

    return (
        <div className={cx("projectPage")}>
            <WorkspacesSubHeader selectedWorkspace={selectedWorkspace} />
            <BodyContent>
                <div className={"headerContainer"}>
                    <HeaderContent
                        selectedWorkspace={selectedWorkspace}
                        selectedWorkspaceProject={selectedWorkspaceProject}
                    />
                </div>
                <div className={cx("boardContent")}>
                    <div className={classnames("board", "blue")}>
                        <CardDetails
                            isDrawerOpen={isDrawerOpen}
                            toggleDrawer={toggleDrawer}
                            getWorkspaceTask={getWorkspaceTask}
                            resetWorkspaceTask={resetWorkspaceTask}
                            selectedWorkspaceProjectTask={selectedWorkspaceProjectTask}
                            selectedWorkspaceProjectTaskId={selectedWorkspaceProjectTaskId}
                            getWorkspaceProjectLists={getWorkspaceProjectLists}
                            projectLabels={projectLabels}
                        />
                        <div className="lists-wrapper" onMouseDown={handleMouseDown} onWheel={handleWheel}>
                            <DragDropContext onDragEnd={handleDragEnd}>
                                <Droppable droppableId={projectId} type="COLUMN" direction="horizontal">
                                    {(provided) => (
                                        <div className="lists" ref={provided.innerRef}>
                                            {projectLists?.length > 0 &&
                                                projectLists
                                                    .sort((a: any, b: any) => a.listOrder - b.listOrder)
                                                    .map((list: any, index) => (
                                                        <List
                                                            key={list.id}
                                                            list={list}
                                                            projectId={projectId}
                                                            index={index}
                                                            updateTask={updateTask}
                                                        />
                                                    ))}
                                            {provided.placeholder}
                                            <ListAdder />
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </div>
                        <div className="board-underlay" />
                    </div>
                </div>
            </BodyContent>
        </div>
    );
};

/* istanbul ignore next */
const mapStateToProps = (state) => {
    return {
        selectedWorkspace: state.workspaces.selectedWorkspace || [],
        selectedWorkspaceProject: state.workspaces.selectedWorkspaceProject,
        projectLists: state.workspaces.lists || [],
        projectLabels: state.workspaces.projectLabels || [],
        selectedWorkspaceProjectTaskId: state.workspaces.selectedWorkspaceProjectTaskId,
        selectedWorkspaceProjectTask: state.workspaces.selectedWorkspaceProjectTask,
        isDrawerOpen: state.app.isDrawerOpen,
    };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => ({
    getWorkspace: (id: string) => dispatch(getWorkspace(id)),

    getWorkspaceProjectLists: (payload) => dispatch(getWorkspaceProjectLists(payload)),
    toggleDrawer: (isOpen: boolean) => dispatch(toggleDrawer(isOpen)),
    updateWorkspaceProjectSelected: (payload: any) => dispatch(updateWorkspaceProjectSelected(payload)),
    getWorkspaceTask: (taskId: string) => dispatch(getWorkspaceTask(taskId)),
    resetWorkspaceTask: () => dispatch(resetWorkspaceTask()),
    updateTask: (payload: any) => dispatch(updateTask(payload)),
    updateTaskOrder: (payload: any) => dispatch(updateTaskOrder(payload)),
    updateListOrder: (payload: any) => dispatch(updateListOrder(payload)),
    updateTaskPositionInList: (payload: any) => dispatch(updateTaskPositionInList(payload)),

    // closeAlert: () => dispatch(closeAlert()),
    // openSnackbar: (message: string) => dispatch(openSnackbar(message)),
    // closeSnackbar: () => dispatch(closeSnackbar()),
    // openAlert: (payload: any) => dispatch(openAlert(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withProjectSocket(Project));
