import React, { FC } from "react";

import TextField from "@material-ui/core/TextField";
import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            "& .MuiOutlinedInput-root": {
                "&:hover fieldset": {
                    borderWidth: "1px",
                },
            },
            "& .MuiOutlinedInput-multiline": {
                height: "auto",
            },
            // "& .MuiOutlinedInput-notchedOutline": {
            //     borderWidth: "0px",
            // },
        },
        row: {
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-end",
        },
    })
);

interface InputTextFieldProps {
    error?: boolean;
    required?: boolean;
    errorText?: string;
    name: string;
    label: string;
    value?: any;
    id?: string;
    multiline?: boolean;
    rows?: number;
    type?: string;
    helperText?: string;
    hover?: boolean;
    onChange: (ev: any) => void;
    placeholder?: string;
    autoFocus?: boolean;
}

const InputTextField: FC<InputTextFieldProps> = ({
    name,
    label,
    value = "",
    id,
    onChange,
    errorText = "Invalid input",
    error = false,
    required = false,
    multiline = false,
    rows = "",
    helperText = "",
    type = "input",
    hover = false,
    placeholder = "",
    autoFocus = false,
}) => {
    const classes = useStyles();
    return (
        <div className={classes.row}>
            <TextField
                error={error}
                helperText={(error && errorText) || helperText}
                classes={{ root: classes.root }}
                variant="outlined"
                required={required}
                fullWidth
                value={value}
                name={name}
                multiline={multiline}
                autoFocus={autoFocus}
                rows={rows}
                placeholder={placeholder}
                label={label}
                type={type}
                id={id}
                autoComplete="off"
                onChange={onChange}
            />
            {/* <i className="fas fa-check-square fa-2x icon"></i>
            <i className="fas fa-window-close fa-2x icon"></i> */}
        </div>
    );
};

export default InputTextField;
