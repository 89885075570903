import { fork, put, takeEvery } from "redux-saga/effects";
import { API } from "./api";
import callApi from "./../../../utils/callApi";

import {
    loginSuccess,
    loginFailure,
    AuthActionTypes,
    signupSuccess,
    signupFailure,
    forgotPasswordSuccess,
    forgotPasswordFailure,
    resetPasswordSuccess,
    resetPasswordFailure,
} from "../../../actions/feature/auth/authActions";
import { isLoading } from "../../../actions/feature/app/appActions";

function* loginSaga(reqObject) {
    const { payload } = reqObject;
    try {
        yield put(isLoading(true));
        const [error, res] = yield* callApi(API.login, { disableLoading: true }, payload, true);
        if (error || res.status === "ERROR") {
            yield put(loginFailure(res));
        } else {
            yield put(loginSuccess(res));
        }
        yield put(isLoading(false));
    } catch (error) {
        yield put(isLoading(false));
        yield put(loginFailure({ status: "ERROR" }));
    }
}

function* signupSaga(reqObject) {
    const { payload } = reqObject;
    try {
        yield put(isLoading(true));
        const [error, res] = yield* callApi(API.signup, { disableLoading: true }, payload, true);
        if (error || res.status === "ERROR") {
            const err = res.status ? res : { status: "ERROR" };
            yield put(signupFailure(err));
        } else {
            // Set cookie
            yield put(signupSuccess(res));
        }
        yield put(isLoading(false));
    } catch (error) {
        yield put(isLoading(false));
        yield put(signupFailure({ status: "ERROR" }));
    }
}

function* forgotPasswordSaga(reqObject) {
    const { payload } = reqObject;
    try {
        yield put(isLoading(true));
        const [error, res] = yield* callApi(API.forgotPassword, { disableLoading: true }, payload, true);
        if (error || res.status === "ERROR") {
            yield put(forgotPasswordFailure({ status: "ERROR" }));
        } else {
            yield put(forgotPasswordSuccess(res));
        }
        yield put(isLoading(false));
    } catch (error) {
        yield put(isLoading(false));
        yield put(forgotPasswordFailure({ status: "ERROR" }));
    }
}

function* resetPasswordSaga(reqObject) {
    const { payload } = reqObject;
    try {
        yield put(isLoading(true));
        const [error, res] = yield* callApi(API.resetPassword, { disableLoading: true }, payload, true);
        if (error || res.status === "ERROR") {
            yield put(resetPasswordFailure(res));
        } else {
            yield put(resetPasswordSuccess(res));
        }
        yield put(isLoading(false));
    } catch (error) {
        yield put(isLoading(false));
        yield put(resetPasswordFailure({ status: "ERROR" }));
    }
}

function* watchAuth() {
    yield takeEvery(AuthActionTypes.LOGIN, loginSaga);
    yield takeEvery(AuthActionTypes.SIGNUP, signupSaga);
    yield takeEvery(AuthActionTypes.FORGOT_PASSWORD, forgotPasswordSaga);
    yield takeEvery(AuthActionTypes.RESET_PASSWORD, resetPasswordSaga);
}

const authSagas = [fork(watchAuth)];

export default authSagas;
