import React, { FC } from "react";
import { connect } from "react-redux";
import { Draggable } from "react-beautiful-dnd";
import classnames from "classnames";
import ListHeader from "./ListHeader";
import Cards from "./Cards";
import CardAdder from "../CardAdder/CardAdder";
import "./List.scss";

interface PageProps {
    projectId: string;
    index: number;
    list: any;
    updateTask: (payload: any) => void;
}

const List: FC<PageProps> = ({ projectId, index, list, updateTask }) => {
    return (
        <Draggable draggableId={`list-${list.id}`} index={index} disableInteractiveElementBlocking>
            {(provided: any, snapshot) => (
                <>
                    <div ref={provided.innerRef} {...provided.draggableProps} className="list-wrapper">
                        <div
                            className={classnames("list", {
                                "list--drag": snapshot.isDragging,
                            })}
                        >
                            <ListHeader
                                dragHandleProps={provided.dragHandleProps}
                                listTitle={list.listTitle}
                                listId={list.id}
                                listOrder={list.listOrder}
                                cards={list.cards}
                                projectId={projectId}
                            />
                            {/* {list.tasks?.length > 0 && ( */}
                            <div className="cards-wrapper">
                                <Cards list={list} />
                            </div>
                            {/* )} */}
                            <CardAdder listId={list.id} updateTask={updateTask} />
                        </div>
                    </div>
                    {provided?.placeholder}
                </>
            )}
        </Draggable>
    );
};

export default List;
