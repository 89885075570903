import React, { FC } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { List, ListItem, ListItemText, Typography } from "@material-ui/core";
import { UserProfile } from "./../../../../utils/types";
import { useAllowActionsHook, useIsAdministratorHook } from "../../../../utils/userAccess";

import classnames from "classnames/bind";
import styles from "./Styles.scss";
const cx = classnames.bind(styles);

interface PageProps {
    selected: string;
    userProfile: UserProfile;
}

const SettingsSubHeader: FC<PageProps> = ({ selected, userProfile }) => {
    const isAdminAccess = useAllowActionsHook();
    const isAdministratorAccess = useIsAdministratorHook();

    return (
        <div className={cx("subHeader")}>
            <div
                className={cx("topNav")}
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <Typography variant="h4" style={{ paddingLeft: "16px" }}>
                    Admin Settings
                </Typography>
            </div>
            <div className={cx("content")}>
                <List component="nav" aria-label="main mailbox folders">
                    {isAdminAccess && (
                        <>
                            <Link to="/hrm/company">
                                <ListItem button selected={selected === "company"}>
                                    <ListItemText primary="Company" />
                                </ListItem>
                            </Link>
                            <Link to="/hrm/office">
                                <ListItem button selected={selected === "office"}>
                                    <ListItemText primary="Office" />
                                </ListItem>
                            </Link>
                            <Link to="/hrm/department">
                                <ListItem button selected={selected === "department"}>
                                    <ListItemText primary="Department" />
                                </ListItem>
                            </Link>
                            <Link to="/hrm/designation">
                                <ListItem button selected={selected === "designation"}>
                                    <ListItemText primary="Designation" />
                                </ListItem>
                            </Link>
                        </>
                    )}
                    {isAdministratorAccess && (
                        <Link to="/hrm/billing#overview">
                            <ListItem button selected={selected === "billing"}>
                                <ListItemText primary="Billing" />
                            </ListItem>
                        </Link>
                    )}
                </List>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        userProfile: state.user.profile,
    };
};

export default connect(mapStateToProps, null)(SettingsSubHeader);
