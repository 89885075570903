import React, { FC, useEffect, useState } from "react";
import { Typography, Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import AlertDialog from "./../../../../../components/common/alert-dialog/AlertDialog";

import Avatar from "./../../../../../components/common/avatar/Avatar";
import { WorkspaceResponse, WorkspaceMember } from "./../../../../../utils/projectInterfaces";
import SelectField from "./../../../../../components/common/select-field/SelectField";
import GroupAddOutlinedIcon from "@material-ui/icons/GroupAddOutlined";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import SaveIcon from "@material-ui/icons/Save";
import MembersDropdown from "../../../common/membersDropdown/MembersDropdown";

import { API } from "./../../../../../sagas/feature/workspaces/api";

import { MemberRoles } from "../../../../../utils/projectInterfaces";

import AddMember from "./addMember";

import classnames from "classnames/bind";
import styles from "./Workspaces.scss";
//import { isAdmin } from "utils/roles";
const cx = classnames.bind(styles);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: "11px 32px",
        },
    })
);

const MemberActions = ({
    getWorkspace,
    user,
    members,
    member,
    memberRole,
    workspaceId,
    memberId,
    deleteWorkspaceMember,
}) => {
    const classes = useStyles();
    const [selectedMemberRole, setSelectedMemberRole] = useState("");
    const [isAlertDialogOpen, setIsAlertDialogOpen] = useState(false);
    const [isTwoAdmins, setIsTwoAdmins] = useState(false);
    const [isAlertTwoAdmins, setIsAlertTwoAdmins] = useState(false);
    const [isAlertDialogRoleOpen, setIsAlertDialogRoleOpen] = useState(false);
    const [removeType, setRemoveType] = useState("Leave");

    const [formValues, setFormValues] = useState<any>({
        initialMemberRole: "",
    });

    useEffect(() => {
        const member = members?.filter((item) => item?.role === "ADMIN");
        setIsTwoAdmins(member?.length >= 2);
    }, [members]);

    const inputChangeHandler = (evt: any) => {
        if (evt.target.value) {
            setSelectedMemberRole(evt.target.value);
            setIsAlertDialogRoleOpen(true);
        }
    };

    useEffect(() => {
        setFormValues({ ...formValues, initialMemberRole: memberRole });
        setSelectedMemberRole(memberRole);
    }, [memberRole]);

    useEffect(() => {
        if (member?.user?.id == user?.id && isAdmin()) {
            setRemoveType("Leave");
        } else if (member?.user?.id !== user?.id && isAdmin()) {
            setRemoveType("Remove");
        }
    }, [member, user]);

    const leaveWorkspace = (type) => {
        if (!isTwoAdmins) {
            setIsAlertTwoAdmins(true);
            return false;
        } else {
            setIsAlertDialogOpen(true);
            setRemoveType(type);
        }
    };

    const onClickChangeRole = (isYes: boolean) => {
        if (isYes) {
            setIsAlertDialogRoleOpen(false);
            API.updateMember({ workspaceId, memberId, role: selectedMemberRole })
                .then(() => {
                    getWorkspace(workspaceId);
                })
                .finally(() => {
                    //
                });
        } else {
            setSelectedMemberRole(formValues?.initialMemberRole);
            setIsAlertDialogRoleOpen(false);
        }
    };

    const onClickLeave = (isYes: boolean) => {
        setIsAlertTwoAdmins(false);
        if (isYes) {
            setIsAlertDialogOpen(false);
            // deleteWorkspaceMember({ workspaceId, memberId });
            API.deleteMember({ workspaceId, memberId })
                .then(() => {
                    getWorkspace(workspaceId);
                })
                .finally(() => {
                    //
                });
        } else {
            setIsAlertDialogOpen(false);
        }
    };

    const isAdmin = () => {
        const member = members?.find((item) => item?.user?.id == user?.id);
        return member?.role == "ADMIN";
    };

    return (
        <div className={cx("actions")}>
            <AlertDialog
                isOpen={isAlertTwoAdmins}
                maxWidth={"md"}
                onClickYes={() => setIsAlertTwoAdmins(false)}
                title={`Action not permitted`}
                isNoDisplay={false}
                yesTitle="Ok"
                description={"You need atleast one more member having ADMIN rights in the workspace"}
            />
            <AlertDialog
                isOpen={isAlertDialogOpen}
                maxWidth={"md"}
                onClickYes={onClickLeave}
                title={`${removeType} Workspace?`}
                description={
                    removeType === "Leave"
                        ? `Are you sure you want to leave this workspace?`
                        : `Are you sure you want to remove user from this workspace?`
                }
            />
            <AlertDialog
                isOpen={isAlertDialogRoleOpen}
                maxWidth={"md"}
                onClickYes={onClickChangeRole}
                title={"Change Role?"}
                description={"Are you sure you want to change the role?"}
            />
            {/* {isAdmin() ? ( */}
            <SelectField
                name={"initialMemberRole"}
                label={"Role"}
                required={true}
                disabled={(member?.user?.id == user?.id && isAdmin()) || !isAdmin() ? true : false}
                options={MemberRoles?.map((item) => ({
                    value: item,
                    label: item,
                }))}
                //defaultValue={selectedMemberRole}
                value={selectedMemberRole}
                onChange={inputChangeHandler}
            />
            {/* ) : (
                <Typography>{selectedMemberRole}</Typography>
            )} */}
            {(member?.user?.id == user?.id || isAdmin()) && (
                <Button
                    className={classes.root}
                    variant={"outlined"}
                    color="primary"
                    onClick={() => leaveWorkspace(removeType)}
                >
                    {removeType}
                </Button>
            )}
        </div>
    );
};

interface PageProps {
    selectedWorkspace: WorkspaceResponse;
    getWorkspace: (id: string) => void;
    user: any;
    deleteWorkspaceMember: (payload: any) => void;
    companyTotalUsers: number;
}

const WorkspaceMembers: FC<PageProps> = ({
    selectedWorkspace,
    getWorkspace,
    user,
    deleteWorkspaceMember,
    companyTotalUsers,
}) => {
    const history = useHistory();
    const { members, id: workspaceId } = selectedWorkspace || { members: [] };
    const [isAddMembers, setIsAddMembers] = useState(false);
    const [isAddMembersAllowed, setIsAddMembersAllowed] = useState(false);

    const handleAddMembers = () => {
        if (companyTotalUsers > 1) {
            setIsAddMembers(true);
        } else {
            setIsAddMembersAllowed(true);
        }
    };

    const redirectToUsers = () => {
        history.push("/hrm/users");
    };

    const Message = () => {
        return (
            <div>
                You haven't created any user yet, please create a new user{" "}
                <a className={"hyperlink"} onClick={redirectToUsers}>
                    here
                </a>
            </div>
        );
    };

    return (
        <div className={cx("memberRowContainer")}>
            <AlertDialog
                isOpen={isAddMembersAllowed}
                maxWidth={"md"}
                onClickYes={() => setIsAddMembersAllowed(false)}
                title={`Action not permitted`}
                isNoDisplay={false}
                yesTitle="Ok"
                description={<Message />}
            />
            <div className={cx("memberHeader")}>
                <div>Workspace members({members?.length})</div>
                <div className={"membersRow"} onClick={handleAddMembers}>
                    <GroupAddOutlinedIcon style={{ marginRight: 6 }} />
                    <Typography variant={"body1"} className={cx("cursor")}>
                        {isAddMembers ? "Select Members" : "Add Members"}
                    </Typography>

                    {isAddMembers && members?.length > 0 && companyTotalUsers > 1 && (
                        <div className={cx("membersModalCont")}>
                            <AddMember setIsAddMembers={setIsAddMembers} members={members} />
                        </div>
                    )}
                </div>
            </div>
            {members?.length > 0 &&
                members.map((member: WorkspaceMember, index: number) => (
                    <div key={index} className={cx("memberRow")}>
                        <div className={cx("avatarSection")}>
                            <Avatar name={member.user.userFullName} bgColor={member.user.userAvatarUrl} />
                            <div className={cx("left")}>
                                <div>{member.user.userFullName}</div>
                                <Typography variant="body2">{member.user.userFullName}</Typography>
                            </div>
                        </div>
                        <div className={cx("actionsSection")}>
                            <MemberActions
                                getWorkspace={getWorkspace}
                                deleteWorkspaceMember={deleteWorkspaceMember}
                                user={user}
                                member={member}
                                members={members}
                                memberRole={member.role}
                                workspaceId={workspaceId}
                                memberId={member.id}
                            />
                        </div>
                    </div>
                ))}
        </div>
    );
};

export default WorkspaceMembers;
