import React, { FC, useState, useEffect } from "react";
import { Button, Divider, Box, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import SelectField from "./../../../../../components/common/select-field/SelectField";
import InputTextField from "./../../../../../components/common/text-field/TextField";

import classnames from "classnames/bind";
import styles from "./Styles.scss";
import colors from "colors";
import { makeStyles, Theme } from "@material-ui/core/styles";

const cx = classnames.bind(styles);

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        marginTop: 6,
        "& button": {
            padding: "6px 12px!important",
        },
    },
}));

interface PageProps {
    office: any;
    config: {
        country: [];
        dateFormat: [];
        timeFormat: [];
        timezone: [];
        currency: [];
        fiscalYearMonth: [];
        weekStarts: [];
    };
    updateOffice: (payload: any) => null;
    addOffice: (payload: any) => null;
    toggleDrawer: (isOpen: boolean) => null;
    id?: string;
    //deleteOffice: (id: string) => null;
}

const OfficeForm: FC<PageProps> = ({ office = {}, config = {}, updateOffice, toggleDrawer, id, addOffice }) => {
    const classes = useStyles();
    const [edit, setEdit] = useState<boolean>(false);
    const [formSubmit, setFormSubmit] = useState<boolean>(false);
    const [selectedCountry, setSelectedCountry] = useState<any>({});
    const [selectedTimeZone, setSelectedTimeZone] = useState<any>({});
    const [selectedCurrency, setSelectedCurrency] = useState<any>({});
    const [formValues, setFormValues] = useState<any>({
        officeName: "",
        officeAddress: "",
        officePostalCode: "",
        country: "",
        dateFormat: "",
        timeFormat: "",
        timeZone: "",
        currency: "",
        // fiscalYearMonthStart: "",
        // weekdayStart: "",
        // workDay: [],
    });
    const [formValidations, setFormValidations] = useState<any>({
        officeName: false,
        officeAddress: false,
        officePostalCode: false,
        country: false,
        dateFormat: false,
        timeFormat: false,
        timeZone: false,
        currency: false,
        // fiscalYearMonthStart: false,
        // weekdayStart: false,
        // workDay: false,
    });

    useEffect(() => {
        setFormValues({
            officeName: office.officeName,
            officeAddress: office.officeAddress,
            officePostalCode: office.officePostalCode,
            country: office.country,
            currency: office.currency,
            dateFormat: office.dateFormat,
            timeFormat: office.timeFormat,
            timeZone: office.timeZone,
            // weekdayStart: office.weekdayStart,
            // fiscalYearMonthStart: office.fiscalYearMonthStart,
            // workDay: office.workDay ? office.workDay.split(",") : [],
        });
    }, [office]);

    useEffect(() => {
        if (formValues.country) {
            const { country } = config;
            const countryObj: any = country?.find((item: any) => item.id == formValues.country);

            if (countryObj) {
                setSelectedCountry({
                    label: countryObj.CountryName,
                    value: countryObj.id,
                });
            }
        }
    }, [formValues.country]);

    useEffect(() => {
        if (formValues.timeZone) {
            const { timezone } = config;
            const timeZoneObj: any = timezone?.find((item: any) => item.id == formValues.timeZone);

            if (timeZoneObj) {
                setSelectedTimeZone({
                    label: timeZoneObj.TimezoneTitle,
                    value: timeZoneObj.id,
                });
            }
        }
    }, [formValues.timeZone]);

    useEffect(() => {
        if (formValues.currency) {
            const { currency } = config;
            const currencyObj: any = currency?.find((item: any) => item.id == formValues.currency);

            if (currencyObj) {
                setSelectedCurrency({
                    label: `${currencyObj.CurrencyCode} - ${currencyObj.CurrencyTitle}`,
                    value: currencyObj.id,
                });
            }
        }
    }, [formValues.currency]);

    const getCountryOptions = () => {
        const { country } = config;
        if (country && country.length) {
            return country.map((item: any) => {
                return {
                    label: item.CountryName,
                    value: item.id,
                };
            });
        }
        return [
            {
                value: "",
                label: "Select",
            },
        ];
    };

    const getDateFormatOptions = () => {
        const { dateFormat } = config;
        if (dateFormat && dateFormat.length) {
            return dateFormat.map((item: any) => {
                return {
                    label: item.DateFormatTitle,
                    value: item.id,
                };
            });
        }
        return [
            {
                value: "",
                label: "Select",
            },
        ];
    };

    const getTimeFormatOptions = () => {
        const { timeFormat } = config;
        if (timeFormat && timeFormat.length) {
            return timeFormat.map((item: any) => {
                return {
                    label: item.TimeFormatTitle,
                    value: item.id,
                };
            });
        }
        return [
            {
                value: "",
                label: "Select",
            },
        ];
    };

    const getTimezoneOptions = () => {
        const { timezone } = config;
        if (timezone && timezone.length) {
            return timezone.map((item: any) => {
                return {
                    label: item.TimezoneTitle,
                    value: item.id,
                };
            });
        }
        return [
            {
                value: "",
                label: "Select",
            },
        ];
    };

    const getCurrencyOptions = () => {
        const { currency } = config;
        if (currency && currency.length) {
            return currency.map((item: any) => {
                return {
                    label: `${item.CurrencyCode} - ${item.CurrencyTitle}`,
                    value: item.id,
                };
            });
        }
        return [
            {
                value: "",
                label: "Select",
            },
        ];
    };

    // const getFiscalYearMonthOptions = () => {
    //     const { fiscalYearMonth } = config;
    //     if (fiscalYearMonth && fiscalYearMonth.length) {
    //         return fiscalYearMonth.map((item: any) => {
    //             return {
    //                 label: item.label,
    //                 value: item.id,
    //             };
    //         });
    //     }
    //     return [
    //         {
    //             value: "",
    //             label: "Select",
    //         },
    //     ];
    // };

    // const getWeekStartsOptions = () => {
    //     const { weekStarts } = config;
    //     if (weekStarts && weekStarts.length) {
    //         return weekStarts.map((item: any) => {
    //             return {
    //                 label: item.label,
    //                 value: item.id,
    //             };
    //         });
    //     }
    //     return [
    //         {
    //             value: "",
    //             label: "Select",
    //         },
    //     ];
    // };

    // const getCountryName = (id) => {
    //     return (config && config.country && config.country.find((item: any) => item.id === id).CountryName) || "N/A";
    // };

    // const getDateFormatName = (id) => {
    //     return (
    //         (config && config.dateFormat && config.dateFormat.find((item: any) => item.id === id).DateFormatTitle) ||
    //         "N/A"
    //     );
    // };

    // const getTimeFormatName = (id) => {
    //     return (
    //         (config && config.timeFormat && config.timeFormat.find((item: any) => item.id === id).TimeFormatTitle) ||
    //         "N/A"
    //     );
    // };

    // const getTimeZoneName = (id) => {
    //     return (
    //         (config && config.timezone && config.timezone.find((item: any) => item.id === id).TimezoneTitle) || "N/A"
    //     );
    // };

    // const handleDaysChange = (day: string) => {
    //     if (formValues.workDay.indexOf(day) < 0) {
    //         if (formValues.workDay.length) {
    //             setFormValues({ ...formValues, workDay: [...formValues.workDay, day] });
    //         } else {
    //             setFormValues({ ...formValues, workDay: [day] });
    //         }
    //     } else {
    //         setFormValues({ ...formValues, workDay: [...formValues.workDay.filter((item) => item !== day)] });
    //     }
    // };

    const inputChangeHandler = (evt: any) => {
        setFormValues({ ...formValues, [evt.target.name]: evt.target.value });
    };

    const validateFormValues = () => {
        let isValid = true;
        let tempFormValidations = {};
        for (const [key, value] of Object.entries(formValues)) {
            if (!value) {
                isValid = false;
                tempFormValidations[`${key}`] = true;
            } else {
                tempFormValidations[`${key}`] = false;
            }
        }
        setFormValidations({ ...formValidations, ...tempFormValidations });
        return isValid;
    };

    // const validateWorkDay = () => {
    //     const found = formValues.workDay.find((item) => item === formValues.weekdayStart);
    //     return found;
    // };

    const handleSave = () => {
        setFormSubmit(true);
        if (validateFormValues()) {
            if (id) {
                updateOffice({ ...formValues, id });
            } else {
                addOffice(formValues);
            }
        }
    };

    const isValidWorkDay = () => {
        if (!formSubmit) {
            return false;
        }
        if (formValues.workDay.length < 1) {
            return true;
        }
        if (formValues.workDay) {
            const workDays = formValues.workDay.filter((item) => item === "" || item === undefined);
            if (workDays.length > 0) {
                return true;
            }
        }

        return false;
    };

    const closeDrawer = () => {
        toggleDrawer(false);
    };

    return (
        <div>
            <div className={cx("rowItem")}>
                <div className={cx("colItem")}>
                    <InputTextField
                        error={formValidations.officeName}
                        errorText={"This field is required"}
                        name="officeName"
                        required={true}
                        id="officeName"
                        label="Office Name"
                        value={formValues.officeName}
                        onChange={inputChangeHandler}
                    />
                </div>
                <div className={cx("colItem")}>
                    <InputTextField
                        error={formValidations.officeAddress}
                        errorText={"This field is required"}
                        name="officeAddress"
                        required={true}
                        id="officeAddress"
                        label="Address"
                        multiline={true}
                        rows={5}
                        value={formValues.officeAddress}
                        onChange={inputChangeHandler}
                    />
                </div>
            </div>
            <div className={cx("rowItem")}>
                <div className={cx("colItem")}>
                    <InputTextField
                        error={formValidations.officePostalCode}
                        errorText={"This field is required"}
                        name="officePostalCode"
                        required={true}
                        id="officePostalCode"
                        label="Postal Code"
                        value={formValues.officePostalCode}
                        onChange={inputChangeHandler}
                    />
                </div>

                <div className={cx("colItem")}>
                    <Autocomplete
                        freeSolo
                        options={getCountryOptions()}
                        getOptionLabel={(option: any) => option?.label}
                        value={selectedCountry}
                        renderOption={(option) => <React.Fragment>{option.label}</React.Fragment>}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                name={formValues.country}
                                label={"Country"}
                                error={formValidations.country}
                                helperText={formValidations.country ? "This field is required" : ""}
                                required
                                variant="outlined"
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: "new-password", // disable autocomplete and autofill
                                }}
                            />
                        )}
                        onChange={(event, newValue: any) => {
                            setFormValues({ ...formValues, country: newValue?.value });
                        }}
                    />
                </div>
            </div>

            <div className={cx("rowItem")}>
                <div className={cx("colItem")}>
                    <SelectField
                        error={formValidations.dateFormat}
                        errorText={"This field is required"}
                        name="dateFormat"
                        required={true}
                        id="dateFormat"
                        label="Date Format"
                        value={formValues.dateFormat}
                        onChange={inputChangeHandler}
                        options={getDateFormatOptions()}
                    />
                </div>
                <div className={cx("colItem")}>
                    <SelectField
                        error={formValidations.timeFormat}
                        errorText={"This field is required"}
                        name="timeFormat"
                        required={true}
                        id="timeFormat"
                        label="Time Format"
                        value={formValues.timeFormat}
                        onChange={inputChangeHandler}
                        options={getTimeFormatOptions()}
                    />
                </div>
            </div>

            <div className={cx("rowItem")}>
                <div className={cx("colItem")}>
                    <Autocomplete
                        freeSolo
                        options={getTimezoneOptions()}
                        getOptionLabel={(option: any) => option?.label}
                        value={selectedTimeZone}
                        renderOption={(option) => <React.Fragment>{option.label}</React.Fragment>}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                name={formValues.timeZone}
                                label={"TimeZone"}
                                error={formValidations.timeZone}
                                helperText={formValidations.timeZone ? "This field is required" : ""}
                                required
                                variant="outlined"
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: "new-password", // disable autocomplete and autofill
                                }}
                            />
                        )}
                        onChange={(event, newValue: any) => {
                            setFormValues({ ...formValues, timeZone: newValue?.value });
                        }}
                    />
                </div>
                <div className={cx("colItem")}>
                    <Autocomplete
                        freeSolo
                        options={getCurrencyOptions()}
                        getOptionLabel={(option: any) => option?.label}
                        value={selectedCurrency}
                        renderOption={(option) => <React.Fragment>{option.label}</React.Fragment>}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                name={formValues.currency}
                                label={"Currency"}
                                error={formValidations.currency}
                                helperText={formValidations.currency ? "This field is required" : ""}
                                required
                                variant="outlined"
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: "new-password", // disable autocomplete and autofill
                                }}
                            />
                        )}
                        onChange={(event, newValue: any) => {
                            setFormValues({ ...formValues, currency: newValue?.value });
                        }}
                    />
                </div>
            </div>

            {/* <div className={cx("rowItem")}>
                <div className={cx("colItem")}>
                    <SelectField
                        error={formValidations.fiscalYearMonthStart}
                        errorText={"This field is required"}
                        name="fiscalYearMonthStart"
                        required={true}
                        id="fiscalYearMonthStart"
                        label="Fiscal Year Start Month"
                        value={formValues.fiscalYearMonthStart}
                        onChange={inputChangeHandler}
                        options={getFiscalYearMonthOptions()}
                    />
                </div>
                <div className={cx("colItem")}>
                    <SelectField
                        error={formValidations.weekdayStart}
                        errorText={"This field is required"}
                        name="weekdayStart"
                        required={true}
                        id="weekdayStart"
                        label="Week Starts On"
                        value={formValues.weekdayStart}
                        onChange={inputChangeHandler}
                        options={getWeekStartsOptions()}
                    />
                </div>
            </div> */}

            {/* <div className={cx("rowItem")}>
                <div className={cx("colItem")} style={{ width: "100%" }}>
                    <Typography variant={"body2"}>Work Day</Typography>
                    <ButtonGroup size="small" color="primary" className={classes.root}>
                        <Button
                            variant={formValues.workDay.indexOf("1") >= 0 ? "contained" : "outlined"}
                            onClick={() => handleDaysChange("1")}
                        >
                            Sun
                        </Button>
                        <Button
                            variant={formValues.workDay.indexOf("2") >= 0 ? "contained" : "outlined"}
                            onClick={() => handleDaysChange("2")}
                        >
                            Mon
                        </Button>
                        <Button
                            variant={formValues.workDay.indexOf("3") >= 0 ? "contained" : "outlined"}
                            onClick={() => handleDaysChange("3")}
                        >
                            Tue
                        </Button>
                        <Button
                            variant={formValues.workDay.indexOf("4") >= 0 ? "contained" : "outlined"}
                            onClick={() => handleDaysChange("4")}
                        >
                            Wed
                        </Button>
                        <Button
                            variant={formValues.workDay.indexOf("5") >= 0 ? "contained" : "outlined"}
                            onClick={() => handleDaysChange("5")}
                        >
                            Thu
                        </Button>
                        <Button
                            variant={formValues.workDay.indexOf("6") >= 0 ? "contained" : "outlined"}
                            onClick={() => handleDaysChange("6")}
                        >
                            Fri
                        </Button>
                        <Button
                            variant={formValues.workDay.indexOf("7") >= 0 ? "contained" : "outlined"}
                            onClick={() => handleDaysChange("7")}
                        >
                            Sat
                        </Button>
                    </ButtonGroup>
                    {isValidWorkDay() && <FormHelperText className="error">Select atleast one day</FormHelperText>}
                </div>
            </div> */}
            <Divider style={{ backgroundColor: colors.darkGrey, margin: "30px 0" }} />
            <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                    variant="outlined"
                    style={{ marginRight: 16 }}
                    size="medium"
                    color="primary"
                    onClick={closeDrawer}
                    onKeyDown={closeDrawer}
                >
                    Cancel
                </Button>
                <Button variant="contained" size="medium" color="primary" onClick={handleSave}>
                    {id ? "Update" : "Save"}
                </Button>
            </Box>
        </div>
    );
};

export default OfficeForm;
