import React, { FC, useContext, useEffect } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import UsersSubHeader from "./../UsersSubHeader";
import BodyContent from "./../../../common/body/bodyContent";
import EditUser from "./EditUser";
import AddUser from "./AddUser";
import UserPage from "./UserPage";
import { getUsers, getUser, deleteUser } from "./../../../../actions/userActions";
import { toggleDrawer } from "../../../../../actions/feature/app/appActions";
import { UserProfile } from "utils/types";
import { useAllowActionsHook } from "../../../../../utils/userAccess";
import { getUserProfile } from "./../../../../actions/userActions";

import classnames from "classnames/bind";
import styles from "./../Styles.scss";
const cx = classnames.bind(styles);

interface IPageProps {
    users: [];
    user: any;
    getUsersList: () => void;
    getUser: (id: string) => void;
    onDeleteUser: (id: string) => void;
    toggleDrawer: (isOpen: boolean) => null;
    isDrawerOpen: boolean;
    pagination: {};
    userProfile: UserProfile;
    getUserProfile: () => void;
}

const Users: FC<IPageProps> = ({
    users,
    user = {},
    getUsersList,
    getUser,
    onDeleteUser,
    toggleDrawer,
    isDrawerOpen,
    pagination,
    userProfile,
    getUserProfile,
}) => {
    const [selectedId, setSelectedId] = React.useState("");
    const [isAddOpen, setIsAddOpen] = React.useState(false);
    const [isEditOpen, setIsEditOpen] = React.useState(false);
    const isAdminAccess = useAllowActionsHook();
    useEffect(() => {
        if (isAdminAccess) {
            getUsersList();
        }
    }, [getUsersList, isAdminAccess]);

    useEffect(() => {
        !isDrawerOpen && isAdminAccess && getUsersList();
    }, [isDrawerOpen, isAdminAccess]);

    const handleEdit = (id: string) => {
        if (id && isAdminAccess) {
            getUser(id);
            setIsAddOpen(false);
            setIsEditOpen(true);
            setSelectedId(id);
            toggleDrawer(true);
        }
    };

    const handleAdd = () => {
        setIsAddOpen(true);
        setIsEditOpen(false);
        toggleDrawer(true);
    };

    return (
        <div className={cx("usersPage")}>
            {/* <UsersSubHeader selected={"users"} /> */}
            {isEditOpen && <EditUser isDrawerOpen={isDrawerOpen} toggleDrawer={toggleDrawer} id={selectedId} />}
            {isAddOpen && <AddUser isDrawerOpen={isDrawerOpen} toggleDrawer={toggleDrawer} />}
            <BodyContent>
                <UserPage
                    userProfile={userProfile}
                    getUserProfile={getUserProfile}
                    users={users}
                    onClickEdit={handleEdit}
                    onClickAdd={handleAdd}
                    pagination={pagination}
                    onDeleteUser={onDeleteUser}
                    getUsersList={getUsersList}
                />
            </BodyContent>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        users: state.user.users,
        user: state.user.user,
        isDrawerOpen: state.app.isDrawerOpen,
        pagination: state.role.pagination,
        userProfile: state.user.profile,
    };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => ({
    getUsersList: () => dispatch(getUsers()),
    getUserProfile: () => dispatch(getUserProfile()),
    getUser: (id: string) => dispatch(getUser(id)),
    onDeleteUser: (id: string) => dispatch(deleteUser(id)),
    toggleDrawer: (isOpen: boolean) => dispatch(toggleDrawer(isOpen)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Users);
