import React, { FC, useState, useEffect } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { Card, CardContent, Typography, TextField, Button, ListItemText, Box } from "@material-ui/core";
import InputTextField from "./../../../../../components/common/text-field/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { getTimezoneOptions } from "./../../../../../utils/app-utils";
import NotFound from "../../notFound/NotFound";

import AlertDialog from "./../../../../../components/common/alert-dialog/AlertDialog";
import { getCompany, updateCompany } from "./../../../../actions/companyActions";
import { toggleDrawer } from "./../../../../../actions/feature/app/appActions";
import { API } from "./../../../../sagas/feature/company/api";
import { openSnackbar, closeSnackbar } from "../../../../../actions/feature/app/appActions";

import SettingsSubHeader from "./../SettingsSubHeader";
import BodyContent from "./../../../common/body/bodyContent";

import { usePlanActiveHook } from "../../../../../utils/userAccess";
import { useAllowActionsHook } from "../../../../../utils/userAccess";

import classnames from "classnames/bind";
import styles from "./Styles.scss";
const cx = classnames.bind(styles);

const HeaderContent = () => {
    return (
        <div className={cx("headerSection")}>
            <div className={cx("top")}>
                <Box display={"flex"} alignItems={"center"}>
                    <Typography variant="h4">Company Information</Typography>
                </Box>
            </div>
        </div>
    );
};

interface PageProps {
    company: any;
    isDrawerOpen: boolean;
    getCompany: () => null;
    updateCompany: (payload: any) => null;
    toggleDrawer: (isOpen: boolean) => null;
    config: any;
    openSnackbar: (msg: string) => void;
    closeSnackbar: () => void;
}

const Company: FC<PageProps> = ({
    company,
    getCompany,
    updateCompany,
    isDrawerOpen,
    toggleDrawer,
    config,
    openSnackbar,
    closeSnackbar,
}) => {
    const isUserAccess = usePlanActiveHook();
    const isAdminAccess = useAllowActionsHook();
    const [edit, setEdit] = useState<boolean>(false);
    const [domainExist, setDomainExist] = useState<boolean>(false);
    const [isAlertDialogOpen, setIsAlertDialogOpen] = React.useState(false);
    const location = useLocation();

    useEffect(() => {
        isAdminAccess && getCompany();
        toggleDrawer(false);
    }, [isAdminAccess]);

    const [formValues, setFormValues] = useState<any>({
        CompanyName: "",
        CompanyIDNumber: "",
        CompanyWebsite: "",
        CompanyDomain: "",
    });

    const [formValidations, setFormValidations] = useState<any>({
        CompanyName: false,
        CompanyDomain: false,
    });

    useEffect(() => {
        setFormValues({
            CompanyName: company.CompanyName,
            CompanyIDNumber: company.CompanyIDNumber,
            CompanyWebsite: company.CompanyWebsite,
            CompanyDomain: company.CompanyDomain,
        });
    }, [company]);

    const inputChangeHandler = (evt: any) => {
        setFormValues({ ...formValues, [evt.target.name]: evt.target.value });
    };

    const validateFormValues = () => {
        let isValid = true;
        let tempFormValidations = {};
        for (const [key, value] of Object.entries(formValidations)) {
            if (!formValues[key]) {
                isValid = false;
                tempFormValidations[`${key}`] = true;
            } else {
                tempFormValidations[`${key}`] = false;
            }
        }
        setFormValidations({ ...formValidations, ...tempFormValidations });
        return isValid;
    };

    const updateCompanyData = () => {
        if (!isUserAccess || !isAdminAccess) return false;
        const payload = {
            CompanyName: formValues.CompanyName,
            CompanyDomain: formValues.CompanyDomain,
            CompanyIDNumber: formValues.CompanyIDNumber,
            CompanyWebsite: formValues.CompanyWebsite,
            id: company?.id,
        };
        API.updateCompany(payload)
            .then(
                (res) => {
                    const { status, data, description } = res;
                    if (status === "SUCCESS") {
                        setDomainExist(false);
                        setEdit(false);
                        getCompany();
                        openSnackbar("Company details updated successfully");
                        setTimeout(() => {
                            closeSnackbar();
                            if (process.env.REACT_APP_ENV === "production") {
                                if (data[0]?.CompanyDomain) {
                                    location.href = `https://${data[0]?.CompanyDomain}.collabey.com/hrm/company`;
                                } else {
                                    location.href = `https://app.collabey.com/hrm/company`;
                                }
                            } else {
                                location.href = `http://localhost:7006/hrm/company`;
                            }
                        }, 1000);
                    } else if (status === "ERROR" && description === "DOMAIN_ALREADY_EXIST") {
                        setDomainExist(true);
                    } else {
                        openSnackbar("Invalid error, please try again");
                        setDomainExist(false);
                    }
                },
                (res) => {
                    setDomainExist(false);
                    openSnackbar("Invalid error, please try again");
                }
            )
            .finally(() => {
                setTimeout(() => {
                    closeSnackbar();
                }, 3000);
            });
    };

    const handleSave = () => {
        if (validateFormValues()) {
            if (company && company.id) {
                if (company?.CompanyDomain && company.CompanyDomain !== formValues.CompanyDomain) {
                    setIsAlertDialogOpen(true);
                } else {
                    updateCompanyData();
                }
            }
        }
    };

    // const getTimezoneName = () => {
    //     const { timezone } = config;
    //     const timeZoneObj: any = timezone?.find((item: any) => item.id == formValues.TimeZoneId);
    //     return timeZoneObj?.TimezoneTitle;
    // };

    // useEffect(() => {
    //     if (formValues.TimeZoneId) {
    //         const { timezone } = config;
    //         const timeZoneObj: any = timezone?.find((item: any) => item.id == formValues.TimeZoneId);

    //         if (timeZoneObj) {
    //             setSelectedTimeZone({
    //                 label: timeZoneObj.TimezoneTitle,
    //                 value: timeZoneObj.id,
    //             });
    //         }
    //     }
    // }, [formValues.TimeZoneId]);

    const domainNameChangeHandler = (evt: any) => {
        const regexp = new RegExp("^[A-Za-z0-9-]+$");
        if (!regexp.test(evt.target.value) && evt.target.value !== "") return false;
        setFormValues({ ...formValues, [evt.target.name]: evt.target.value });
    };

    const onClickYes = (isYes: boolean) => {
        if (!isAdminAccess) return false;
        if (isYes === true) {
            setIsAlertDialogOpen(false);
            updateCompanyData();
        } else {
            setIsAlertDialogOpen(false);
        }
    };

    if (!isAdminAccess) {
        return <NotFound />;
    }
    return (
        <div className={cx("pageOuter")}>
            <SettingsSubHeader selected={"company"} />
            <AlertDialog
                isOpen={isAlertDialogOpen}
                maxWidth={"md"}
                onClickYes={onClickYes}
                title={"Change your account URL?"}
                description={`Are you sure you want to change the account domain URL to ${formValues.CompanyDomain}.collabey.com?`}
            />
            <BodyContent>
                <div className={"headerContainer"}>
                    <HeaderContent />
                </div>
                <div className="contentBody" style={{ padding: 20 }}>
                    <div style={{ paddingBottom: 8, textAlign: "right" }}>
                        {!edit ? (
                            <Button
                                color="primary"
                                onClick={() => setEdit(true)}
                                disabled={!isUserAccess || !isAdminAccess}
                            >
                                Edit
                            </Button>
                        ) : (
                            <div>&nbsp;</div>
                        )}
                    </div>
                    <Card variant="outlined">
                        <CardContent style={{ padding: 24 }}>
                            <div className={cx("rowItem")}>
                                <div className={cx("colItem")}>
                                    {!edit && (
                                        <ListItemText primary="Company Name" secondary={formValues.CompanyName} />
                                    )}
                                    {edit && (
                                        <InputTextField
                                            error={formValidations.CompanyName}
                                            errorText={"This field is required"}
                                            name="CompanyName"
                                            required={true}
                                            id="CompanyName"
                                            label="Company Name"
                                            value={formValues.CompanyName}
                                            onChange={inputChangeHandler}
                                        />
                                    )}
                                </div>
                                <div className={cx("colItem")}>
                                    {!edit && (
                                        <ListItemText
                                            primary="Domain"
                                            secondary={`${formValues?.CompanyDomain}.collabey.com`}
                                        />
                                    )}
                                    {edit && (
                                        <div className={cx("domainCont")}>
                                            {/* <Typography>https://</Typography> */}
                                            <InputTextField
                                                error={formValidations.CompanyDomain || domainExist}
                                                errorText={
                                                    domainExist ? "Domain already exists" : "This field is required"
                                                }
                                                name="CompanyDomain"
                                                required={true}
                                                id="CompanyDomain"
                                                label="Company domain"
                                                value={formValues.CompanyDomain}
                                                helperText="Only characters, numbers and hyphen allowed"
                                                onChange={domainNameChangeHandler}
                                            />
                                            <Typography>.collabey.com</Typography>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className={cx("rowItem")}>
                                {/* <div className={cx("colItem")}>
                                    {!edit && (
                                        <ListItemText primary="Company Domain" secondary={formValues.CompanyDomain} />
                                    )}
                                    {edit && (
                                        <InputTextField
                                            errorText={"This field is required"}
                                            name="CompanyDomain"
                                            required={false}
                                            id="CompanyDomain"
                                            label="Company Domain"
                                            value={formValues.CompanyDomain}
                                            onChange={inputChangeHandler}
                                        />
                                    )}
                                </div> */}

                                <div className={cx("colItem")}>
                                    {!edit && (
                                        <ListItemText primary="Company Website" secondary={formValues.CompanyWebsite} />
                                    )}
                                    {edit && (
                                        <InputTextField
                                            errorText={"This field is required"}
                                            name="CompanyWebsite"
                                            required={false}
                                            id="CompanyWebsite"
                                            label="Company Website"
                                            value={formValues.CompanyWebsite}
                                            onChange={inputChangeHandler}
                                        />
                                    )}
                                </div>
                                <div className={cx("colItem")}>
                                    {!edit && (
                                        <ListItemText
                                            primary="Company ID Number"
                                            secondary={formValues.CompanyIDNumber}
                                        />
                                    )}
                                    {edit && (
                                        <InputTextField
                                            errorText={"This field is required"}
                                            name="CompanyIDNumber"
                                            required={false}
                                            id="CompanyIDNumber"
                                            label="Company ID Number"
                                            value={formValues.CompanyIDNumber}
                                            onChange={inputChangeHandler}
                                        />
                                    )}
                                </div>
                                {/* <div className={cx("colItem")}>
                                    {!edit && <ListItemText primary="Time Zone" secondary={getTimezoneName()} />}
                                    {edit && (
                                        <Autocomplete
                                            freeSolo
                                            options={getTimezoneOptions(config)}
                                            getOptionLabel={(option: any) => option?.label}
                                            value={selectedTimeZone}
                                            renderOption={(option) => <React.Fragment>{option.label}</React.Fragment>}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    name={formValues.TimeZoneId}
                                                    label={"Time Zone"}
                                                    error={formValidations.TimeZoneId}
                                                    helperText={
                                                        formValidations.TimeZoneId ? "This field is required" : ""
                                                    }
                                                    required
                                                    variant="outlined"
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        autoComplete: "new-password", // disable autocomplete and autofill
                                                    }}
                                                />
                                            )}
                                            onChange={(event, newValue: any) => {
                                                setFormValues({ ...formValues, TimeZoneId: newValue?.value });
                                            }}
                                        />
                                    )}
                                </div> */}
                            </div>
                            {edit && (
                                <div style={{ marginTop: 24 }}>
                                    <div>
                                        <Button
                                            variant="contained"
                                            size="medium"
                                            color="primary"
                                            style={{ marginRight: 16 }}
                                            onClick={handleSave}
                                        >
                                            Save
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            size="medium"
                                            color="primary"
                                            onClick={() => setEdit(false)}
                                        >
                                            Cancel
                                        </Button>
                                    </div>
                                </div>
                            )}
                        </CardContent>
                    </Card>
                </div>
            </BodyContent>
        </div>
    );
};

/* istanbul ignore next */
const mapStateToProps = (state) => {
    return {
        company: state.company.company || {},
        isDrawerOpen: state.app.isDrawerOpen,
        config: state.app.config,
    };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => ({
    getCompany: () => dispatch(getCompany()),
    updateCompany: (payload: any) => dispatch(updateCompany(payload)),
    toggleDrawer: (isOpen: boolean) => dispatch(toggleDrawer(isOpen)),
    openSnackbar: (msg) => dispatch(openSnackbar(msg)),
    closeSnackbar: () => dispatch(closeSnackbar()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Company);
