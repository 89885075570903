import { action } from "typesafe-actions";

export const PlanActionTypes = {
    GET_PLANS: "PLAN/GET_PLANS",
    GET_PLANS_SUCCESS: "PLAN/GET_PLANS_SUCCESS",
    GET_PLANS_FAILURE: "PLAN/GET_PLANS_FAILURE",

    GET_PLAN: "PLAN/GET_PLAN",
    GET_PLAN_SUCCESS: "PLAN/GET_PLAN_SUCCESS",
    GET_PLAN_FAILURE: "PLAN/GET_PLAN_FAILURE",

    GET_COMPANY_PLANS: "PLAN/GET_COMPANY_PLANS",
    GET_COMPANY_PLANS_SUCCESS: "PLAN/GET_COMPANY_PLANS_SUCCESS",
    GET_COMPANY_PLANS_FAILURE: "PLAN/GET_COMPANY_PLANS_FAILURE",

    ADD_PLAN: "PLAN/ADD_PLAN",
    ADD_PLAN_SUCCESS: "PLAN/ADD_PLAN_SUCCESS",
    ADD_PLAN_FAILURE: "PLAN/ADD_PLAN_FAILURE",

    UPDATE_PLAN: "PLAN/UPDATE_PLAN",
    UPDATE_PLAN_SUCCESS: "PLAN/UPDATE_PLAN_SUCCESS",
    UPDATE_PLAN_FAILURE: "PLAN/UPDATE_PLAN_FAILURE",

    DELETE_PLAN: "PLAN/DELETE_PLAN",
    DELETE_PLAN_SUCCESS: "PLAN/DELETE_PLAN_SUCCESS",
    DELETE_PLAN_FAILURE: "PLAN/DELETE_PLAN_FAILURE",

    CLEAR_PLAN: "PLAN/CLEAR_PLAN",

    RESET: "RESET",
};

export const getPlans = () => action(PlanActionTypes.GET_PLANS);
export const getPlansSuccess = (payload) => action(PlanActionTypes.GET_PLANS_SUCCESS, payload);
export const getPlansFailure = (payload) => action(PlanActionTypes.GET_PLANS_FAILURE, payload);

export const getPlan = (id) => action(PlanActionTypes.GET_PLAN, id);
export const getPlanSuccess = (payload) => action(PlanActionTypes.GET_PLAN_SUCCESS, payload);
export const getPlanFailure = (payload) => action(PlanActionTypes.GET_PLAN_FAILURE, payload);

export const getCompanyPlans = (id) => action(PlanActionTypes.GET_COMPANY_PLANS, id);
export const getCompanyPlansSuccess = (payload) => action(PlanActionTypes.GET_COMPANY_PLANS_SUCCESS, payload);
export const getCompanyPlansFailure = (payload) => action(PlanActionTypes.GET_COMPANY_PLANS_FAILURE, payload);

export const addPlan = (payload) => action(PlanActionTypes.ADD_PLAN, payload);
export const addPlanSuccess = (payload) => action(PlanActionTypes.ADD_PLAN_SUCCESS, payload);
export const addPlanFailure = (payload) => action(PlanActionTypes.ADD_PLAN_FAILURE, payload);

export const updatePlan = (payload) => action(PlanActionTypes.UPDATE_PLAN, payload);
export const updatePlanSuccess = (payload) => action(PlanActionTypes.UPDATE_PLAN_SUCCESS, payload);
export const updatePlanFailure = (payload) => action(PlanActionTypes.UPDATE_PLAN_FAILURE, payload);

export const deletePlan = (payload) => action(PlanActionTypes.DELETE_PLAN, payload);
export const deletePlanSuccess = (payload) => action(PlanActionTypes.DELETE_PLAN_SUCCESS, payload);
export const deletePlanFailure = (payload) => action(PlanActionTypes.DELETE_PLAN_FAILURE, payload);

export const clearPlan = () => action(PlanActionTypes.CLEAR_PLAN);

export const reset = () => action(PlanActionTypes.RESET);
