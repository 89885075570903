import React, { useEffect, FC } from "react";
import { connect } from "react-redux";
import { Drawer, Button, CircularProgress } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import InputTextField from "./../../../../../components/common/text-field/TextField";
import Alert from "./../../../../../components/common/alert/Alert";
import {
    addDesignation,
    getDesignation,
    updateDesignation,
    clearDesignation,
} from "../../../../actions/designationActions";
import { closeAlert } from "../../../../../actions/feature/app/appActions";

import classnames from "classnames/bind";
import styles from "./Styles.scss";
const cx = classnames.bind(styles);

interface IPageProps {
    id?: string;
    designation: any;
    isLoading: boolean;
    onSave: (payload: any) => null;
    apiResponse: any;
    isDrawerOpen: boolean;
    toggleDrawer: (isOpen: boolean) => null;
    onGetDesignation: (id: string) => null;
    onUpdateDesignation: (payload: any) => null;
    clearDesignation: () => null;
    closeAlert: () => void;
    alert: {
        open: boolean;
        message: string;
        type: string;
    };
}

const AddDesignation: FC<IPageProps> = ({
    isLoading,
    isDrawerOpen,
    toggleDrawer,
    id,
    designation,
    onGetDesignation,
    onSave,
    onUpdateDesignation,
    clearDesignation,
    closeAlert,
    alert,
}) => {
    const [designationError, setDesignationError] = React.useState(false);
    const [designationName, setDesignationName] = React.useState("");

    // Get API Data
    useEffect(() => {
        if (id) {
            onGetDesignation(id);
        } else {
            clearDesignation();
            setDesignationName("");
        }
    }, [id]);

    // Set form values
    useEffect(() => {
        if (designation && designation.DesignationName) {
            setDesignationName(designation.DesignationName);
        }
    }, [designation]);

    // Reset errors
    useEffect(() => {
        if (isDrawerOpen) {
            setDesignationError(false);
        }
    }, [isDrawerOpen]);

    useEffect(() => {
        if (isDrawerOpen && !id) {
            setDesignationName("");
        }
    }, [isDrawerOpen, id]);

    useEffect(() => {
        if (isDrawerOpen) closeAlert();
    }, [isDrawerOpen]);

    const inputChangeHandler = (event: any) => {
        setDesignationName(event.target.value);
    };

    const submitForm = (evt: any) => {
        evt.preventDefault();
        if (!designationName) {
            setDesignationError(true);
        }
        if (designationName) {
            if (id) {
                onUpdateDesignation({
                    id: id,
                    designationName: designationName,
                });
            } else {
                onSave({
                    designationName: designationName,
                });
            }
        }
    };

    const closeDrawer = () => {
        closeAlert();
        toggleDrawer(false);
    };

    return (
        <Drawer anchor={"right"} open={isDrawerOpen} onClose={closeDrawer}>
            <div role="presentation" className={cx("drawerContainer")} style={{ boxShadow: "none" }}>
                <div className={cx("drawerTitle")}>
                    <h3>{id ? <>Edit Designation</> : <>Add Designation</>}</h3>
                    <div className={cx("icon")}>
                        <CloseIcon color="primary" onClick={closeDrawer} onKeyDown={closeDrawer} />
                    </div>
                </div>
                <div className={cx("drawerSection")} style={{ background: "transparent" }}>
                    {alert.open && alert.message && (
                        <Alert onClose={() => {}} message={alert.message} type={alert.type}></Alert>
                    )}
                    <div className={cx("drawerRow")}>
                        <div className={cx("drawerCol")}>
                            <InputTextField
                                id={"designationName"}
                                name={"designationName"}
                                label={"Designation Name"}
                                value={designationName}
                                onChange={inputChangeHandler}
                                required={true}
                                error={designationError}
                                errorText={"Invalid designation name"}
                            />
                        </div>
                    </div>
                </div>

                <div style={{ paddingLeft: 24 }}>
                    <Button
                        variant="contained"
                        size="medium"
                        color="primary"
                        style={{ marginRight: 16 }}
                        onClick={submitForm}
                        disabled={isLoading}
                        // endIcon={isLoading ? <CircularProgress size="20px" /> : null}
                    >
                        {id ? "Update" : "Save"}
                    </Button>
                    <Button
                        variant="outlined"
                        size="medium"
                        color="primary"
                        onClick={closeDrawer}
                        onKeyDown={closeDrawer}
                    >
                        Cancel
                    </Button>
                </div>
            </div>
        </Drawer>
    );
};

/* istanbul ignore next */
const mapStateToProps = (state: any) => {
    return {
        isLoading: state.app.isLoading,
        designation: state.designation.designation,
        apiResponse: state.designation.apiResponse.addDesignation,
        alert: state.app.alert,
    };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: any) => ({
    onGetDesignation: (id: string) => dispatch(getDesignation(id)),
    onSave: (payload: any) => dispatch(addDesignation(payload)),
    onUpdateDesignation: (payload: any) => dispatch(updateDesignation(payload)),
    clearDesignation: () => dispatch(clearDesignation()),
    closeAlert: () => dispatch(closeAlert()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddDesignation);
