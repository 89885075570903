import React from "react";

import SubHeader from "./../../common/header/subHeader";

import classnames from "classnames/bind";
import styles from "./bodyContent.scss";
const cx = classnames.bind(styles);

const BodyContent = (props) => {
    const { style } = props;
    return (
        <>
            <div className={cx("bodyContent")} style={{...style}}>
                <div className={cx("content")}>{props.children}</div>
            </div>
        </>
    );
};

export default BodyContent;
