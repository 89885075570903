import { action } from "typesafe-actions";

export const OfficeActionTypes = {
    GET_OFFICES: "OFFICE/GET_OFFICES",
    GET_OFFICES_SUCCESS: "OFFICE/GET_OFFICES_SUCCESS",
    GET_OFFICES_FAILURE: "OFFICE/GET_OFFICES_FAILURE",

    GET_OFFICE: "OFFICE/GET_OFFICE",
    GET_OFFICE_SUCCESS: "OFFICE/GET_OFFICE_SUCCESS",
    GET_OFFICE_FAILURE: "OFFICE/GET_OFFICE_FAILURE",

    ADD_OFFICE: "OFFICE/ADD_OFFICE",
    ADD_OFFICE_SUCCESS: "OFFICE/ADD_OFFICE_SUCCESS",
    ADD_OFFICE_FAILURE: "OFFICE/ADD_OFFICE_FAILURE",

    UPDATE_OFFICE: "OFFICE/UPDATE_OFFICE",
    UPDATE_OFFICE_SUCCESS: "OFFICE/UPDATE_OFFICE_SUCCESS",
    UPDATE_OFFICE_FAILURE: "OFFICE/UPDATE_OFFICE_FAILURE",

    DELETE_OFFICE: "OFFICE/DELETE_OFFICE",
    DELETE_OFFICE_SUCCESS: "OFFICE/DELETE_OFFICE_SUCCESS",
    DELETE_OFFICE_FAILURE: "OFFICE/DELETE_OFFICE_FAILURE",

    CLEAR_OFFICE: "OFFICE/CLEAR_OFFICE",

    RESET: "RESET",
};

export const getOffices = () => action(OfficeActionTypes.GET_OFFICES);
export const getOfficesSuccess = (payload) => action(OfficeActionTypes.GET_OFFICES_SUCCESS, payload);
export const getOfficesFailure = (payload) => action(OfficeActionTypes.GET_OFFICES_FAILURE, payload);

export const getOffice = (id) => action(OfficeActionTypes.GET_OFFICE, id);
export const getOfficeSuccess = (payload) => action(OfficeActionTypes.GET_OFFICE_SUCCESS, payload);
export const getOfficeFailure = (payload) => action(OfficeActionTypes.GET_OFFICE_FAILURE, payload);

export const addOffice = (payload) => action(OfficeActionTypes.ADD_OFFICE, payload);
export const addOfficeSuccess = (payload) => action(OfficeActionTypes.ADD_OFFICE_SUCCESS, payload);
export const addOfficeFailure = (payload) => action(OfficeActionTypes.ADD_OFFICE_FAILURE, payload);

export const updateOffice = (payload) => action(OfficeActionTypes.UPDATE_OFFICE, payload);
export const updateOfficeSuccess = (payload) => action(OfficeActionTypes.UPDATE_OFFICE_SUCCESS, payload);
export const updateOfficeFailure = (payload) => action(OfficeActionTypes.UPDATE_OFFICE_FAILURE, payload);

export const deleteOffice = (payload) => action(OfficeActionTypes.DELETE_OFFICE, payload);
export const deleteOfficeSuccess = (payload) => action(OfficeActionTypes.DELETE_OFFICE_SUCCESS, payload);
export const deleteOfficeFailure = (payload) => action(OfficeActionTypes.DELETE_OFFICE_FAILURE, payload);

export const clearOffice = () => action(OfficeActionTypes.CLEAR_OFFICE);

export const reset = () => action(OfficeActionTypes.RESET);
