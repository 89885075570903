import React, { FC, useState } from "react";
import { connect } from "react-redux";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Button, Link, Typography, CircularProgress, Box, Paper } from "@material-ui/core";
import InputTextField from "../../common/text-field/TextField";
import { Link as RouterLink } from "react-router-dom";
import Alert from "../../common/alert/Alert";
import { forgotPassword } from "../../../actions/feature/auth/authActions";
import classnames from "classnames/bind";
import styles from "./ForgotPassword.scss";
import colors from "colors";
import Logo from "./../../../assets/images/logo2.png";

const cx = classnames.bind(styles);

const useStyles = makeStyles((theme: Theme) => ({
    title: {
        textAlign: "center",
        marginBottom: "40px",
    },
}));

interface PageProps {
    apiResponse: any;
    isLoading: boolean;
    onForgotRequest: (payload: any) => null;
}

const ForgotPassword: FC<PageProps> = ({ apiResponse, isLoading, onForgotRequest }) => {
    const classes = useStyles();

    const [email, setEmail] = useState<string>("");
    const [click, setClick] = useState<boolean>(false);

    const inputChangeHandler = (evt: any) => {
        setEmail(evt.target.value);
    };

    const submitForm = (evt: any) => {
        evt.preventDefault();
        if (isLoading) {
            return false;
        }
        setClick(true);
        if (email) {
            onForgotRequest({ email });
        }
    };

    return (
        <div className={cx("passwordWrapper")}>
            <img src={Logo} className="logopage" />
            <Paper className={cx("formWrapper")} elevation={1}>
                <Typography variant="h1" className={classes.title}>
                    Forgot Password
                </Typography>
                {apiResponse && apiResponse.status === "SUCCESS" && (
                    <div className={cx("success")}>
                        <Alert
                            onClose={() => {}}
                            message={`An email with password reset link sent to your email address : ${email}. Follow the
                                    instructions in the email to reset your account password and get back into your account.`}
                            type={"success"}
                        ></Alert>
                    </div>
                )}
                {apiResponse && apiResponse.status === "ERROR" && (
                    <div className={cx("error")}>
                        <Alert
                            onClose={() => {}}
                            message={`Email does not exist, please try again`}
                            type={"error"}
                        ></Alert>
                    </div>
                )}
                <Box className={cx("boxMargin")}>
                    <InputTextField
                        error={click && !email}
                        errorText={"This field is required"}
                        name="email"
                        required={true}
                        id="email"
                        label="Email"
                        value={email}
                        onChange={inputChangeHandler}
                    />
                </Box>

                <Button
                    type="submit"
                    style={{ margin: "16px 0 8px" }}
                    onClick={submitForm}
                    endIcon={
                        isLoading ? (
                            <CircularProgress color={colors.darkPrimary} size="20px" />
                        ) : (
                            <div style={{ width: "20px" }} />
                        )
                    }
                    fullWidth
                    variant="contained"
                    color="primary"
                    size="large"
                >
                    Send Link
                </Button>
                <Box className={cx("link")}>
                    <Link component={RouterLink} to="/login" variant="body2">
                        {"Back to login"}
                    </Link>
                </Box>
            </Paper>
        </div>
    );
};

/* istanbul ignore next */
const mapStateToProps = (state: any) => {
    return {
        apiResponse: state.auth.forgotPassword.apiResponse,
        isLoading: state.app.isLoading,
    };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: any) => ({
    onForgotRequest: (payload: any) => dispatch(forgotPassword(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
