import { action } from "typesafe-actions";

export const ProfileActionTypes = {
    GET_PROFILE: "PROFILE/GET_PROFILE",
    GET_PROFILE_SUCCESS: "PROFILE/GET_PROFILE_SUCCESS",
    GET_PROFILE_FAILURE: "PROFILE/GET_PROFILE_FAILURE",

    CHANGE_PASSWORD: "PROFILE/CHANGE_PASSWORD",
    CHANGE_PASSWORD_SUCCESS: "PROFILE/CHANGE_PASSWORD_SUCCESS",
    CHANGE_PASSWORD_FAILURE: "PROFILE/CHANGE_PASSWORD_FAILURE",

    UPDATE_BASIC_INFO: "PROFILE/UPDATE_BASIC_INFO",
    UPDATE_BASIC_INFO_SUCCCESS: "PROFILE/UPDATE_BASIC_INFO_SUCCCESS",

    UPDATE_PERSONAL_INFO: "PROFILE/UPDATE_PERSONAL_INFO",
    UPDATE_PERSONAL_INFO_SUCCESS: "PROFILE/UPDATE_PERSONAL_INFO_SUCCESS",

    UPDATE_CONTACTS: "PROFILE/UPDATE_CONTACTS",
    UPDATE_CONTACTS_SUCCESS: "PROFILE/UPDATE_CONTACTS_SUCCESS",

    RESET: "RESET",
};

export const getProfile = () => action(ProfileActionTypes.GET_PROFILE);
export const getProfileSuccess = (payload) => action(ProfileActionTypes.GET_PROFILE_SUCCESS, payload);
export const getProfileFailure = (payload) => action(ProfileActionTypes.GET_PROFILE_FAILURE, payload);

export const changePassword = (payload) => action(ProfileActionTypes.CHANGE_PASSWORD, payload);
export const changePasswordSuccess = (payload) => action(ProfileActionTypes.CHANGE_PASSWORD_SUCCESS, payload);
export const changePasswordFailure = (payload) => action(ProfileActionTypes.CHANGE_PASSWORD_FAILURE, payload);

export const updateBasicInfo = (payload) => action(ProfileActionTypes.UPDATE_BASIC_INFO, payload);
export const updateBasicInfoSuccess = (payload) => action(ProfileActionTypes.UPDATE_BASIC_INFO_SUCCCESS, payload);

export const updatePersonalInfo = (payload) => action(ProfileActionTypes.UPDATE_PERSONAL_INFO, payload);
export const updatePersonalInfoSuccess = (payload) => action(ProfileActionTypes.UPDATE_PERSONAL_INFO_SUCCESS, payload);

export const updateContacts = (payload) => action(ProfileActionTypes.UPDATE_CONTACTS, payload);
export const updateContactsSuccess = (payload) => action(ProfileActionTypes.UPDATE_CONTACTS_SUCCESS, payload);

export const reset = () => action(ProfileActionTypes.RESET);
