import React, { FC, useState, useEffect } from "react";
import { connect } from "react-redux";
import { Drawer, Button, Divider, InputAdornment, FormHelperText, Box } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import { format } from "date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import CalendarIcon from "@material-ui/icons/CalendarToday";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import { API } from "./../../../../sagas/feature/user/api";
import Alert from "./../../../../../components/common/alert/Alert";
import CloseIcon from "@material-ui/icons/Close";
import { getDepartmentOptions, getDesignationOptions, getOfficeOptions } from "./../../../../../utils/app-utils";
import { randomHexColors, generateRandomNum } from "./../../../../../utils/utils";
import { getUsers, getUserProfile } from "./../../../../actions/userActions";
import { openSnackbar, closeSnackbar, openAlert, closeAlert } from "../../../../../actions/feature/app/appActions";
import { getRoles } from "./../../../../actions/roleActions";
import { getDepartments } from "./../../../../actions/departmentActions";
import { getDesignations } from "./../../../../actions/designationActions";
import { getOffices } from "./../../../../actions/officeActions";
import SelectField from "./../../../../../components/common/select-field/SelectField";
import InputTextField from "./../../../../../components/common/text-field/TextField";

import colors from "colors";
import classnames from "classnames/bind";
import styles from "./../Styles.scss";
const cx = classnames.bind(styles);

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#d00",
            },
        },
    })
);

interface IPageProps {
    isLoading: boolean;
    isDrawerOpen: boolean;
    user: any;
    users: [];
    departments: [];
    roles: [];
    designations: [];
    offices: [];
    alert: {
        open: boolean;
        message: string;
        type: string;
    };
    getUsers: () => null;
    getDepartments: () => null;
    getDesignations: () => null;
    getOffices: () => null;
    toggleDrawer: (isOpen: boolean) => null;
    closeAlert: () => void;
    openSnackbar: (payload: any) => null;
    closeSnackbar: () => null;
    openAlert: (payload: any) => null;
    getRoles: () => null;
    getUserProfile: () => null;
}

const AddUser: FC<IPageProps> = ({
    isLoading,
    isDrawerOpen,
    departments,
    roles,
    designations,
    users,
    user,
    offices,
    alert,
    getUsers,
    getDepartments,
    getDesignations,
    getOffices,
    toggleDrawer,
    closeAlert,
    openSnackbar,
    closeSnackbar,
    openAlert,
    getRoles,
    getUserProfile,
}) => {
    const classes = useStyles();
    const [formValues, setFormValues] = useState<any>({
        userFullName: "",
        userEmail: "",
        dateOfBirth: null,
        userJoinDate: null,
        userInviteDate: null,
        designationID: "",
        departmentID: "",
        officeID: "",
        role: "",
        reportingManagerID: "",
    });
    const [formValidations, setFormValidations] = useState<any>({
        userFullName: false,
        userEmail: false,
        dateOfBirth: false,
        userJoinDate: false,
        userInviteDate: false,
        designationID: false,
        departmentID: false,
        officeID: false,
        role: false,
    });

    useEffect(() => {
        getDepartments();
        getDesignations();
        getOffices();
        getRoles();
    }, []);

    const closeDrawer = () => {
        toggleDrawer(false);
    };

    const inputChangeHandler = (evt: any) => {
        setFormValues({ ...formValues, [evt.target.name]: evt.target.value });
    };

    const onDateChange = (val: MaterialUiPickersDate, key: string) => {
        if (val) setFormValues({ ...formValues, [key]: val });
    };

    const validateFormValues = () => {
        let isValid = true;
        let tempFormValidations = {};
        for (const [key, value] of Object.entries(formValues)) {
            // if (!value && formValidations.hasOwnProperty(key)) {
            if (!value && key !== "reportingManagerID") {
                isValid = false;
                tempFormValidations[`${key}`] = true;
            } else {
                tempFormValidations[`${key}`] = false;
            }
        }
        setFormValidations({ ...formValidations, ...tempFormValidations });
        return isValid;
    };

    const handleSave = () => {
        const formValuesUpdated = {
            ...formValues,
            userProfileColor: randomHexColors[generateRandomNum(30)],
            dateOfBirth: formValues.dateOfBirth ? format(new Date(formValues.dateOfBirth) || null, "yyyy-MM-dd") : null,
            userJoinDate: formValues.userJoinDate
                ? format(new Date(formValues.userJoinDate) || null, "yyyy-MM-dd")
                : null,
            userInviteDate: formValues.userInviteDate
                ? format(new Date(formValues.userInviteDate) || null, "yyyy-MM-dd")
                : null,
        };

        if (validateFormValues()) {
            API.addUserOnboarding(formValuesUpdated)
                .then(
                    (res) => {
                        if (res.status === "ERROR") {
                            if (res.description === "RECORD_ALREADY_EXIST") {
                                openAlert({ message: "Email already exists", type: "error" });
                            } else {
                                openAlert({ message: "Invalid error, please try again", type: "error" });
                            }
                        } else {
                            openSnackbar("User added successfully");
                            getUsers();
                            getUserProfile();
                            closeDrawer();
                        }
                    },
                    (res) => {
                        openAlert({ message: "Invalid error, please try again", type: "error" });
                    }
                )
                .finally(() => {
                    setTimeout(() => {
                        closeSnackbar();
                    }, 3000);
                });
        }
    };

    const getRolesOptions = () => {
        return roles?.map((role: any) => {
            return {
                label: role.roleName,
                value: role.id,
            };
        });
    };

    const getUsersOptions = () => {
        return (
            users
                // ?.filter((item: any) => {
                //     return item?.id != user?.id;
                // })
                .map((item: any) => {
                    return {
                        label: item.UserFullName,
                        value: item.id,
                    };
                })
        );
    };

    useEffect(() => {
        setFormValues({
            userFullName: "",
            userEmail: "",
            dateOfBirth: null,
            userJoinDate: null,
            userInviteDate: null,
            designationID: "",
            departmentID: "",
            officeID: "",
            role: "",
            reportingManagerID: "",
        });
        setFormValidations({
            userFullName: false,
            userEmail: false,
            dateOfBirth: false,
            userJoinDate: false,
            userInviteDate: false,
            designationID: false,
            departmentID: false,
            officeID: false,
            role: false,
        });
    }, []);

    return (
        <Drawer anchor={"right"} open={isDrawerOpen} onClose={closeDrawer}>
            <div role="presentation" className={cx("drawerContainer")} style={{ boxShadow: "none" }}>
                <div className={cx("drawerTitle")}>
                    <h3>Add User</h3>
                    <div className={cx("icon")}>
                        <CloseIcon color="primary" onClick={closeDrawer} onKeyDown={closeDrawer} />
                    </div>
                </div>
                {alert && alert.open && (
                    <div style={{ padding: "0 20px" }}>
                        <Alert onClose={() => {}} message={alert.message} type={alert.type}></Alert>
                    </div>
                )}
                <div className={cx("drawerSection")} style={{ background: "none" }}>
                    <div>
                        <div className={cx("rowItem")}>
                            <div className={cx("colItem")}>
                                <InputTextField
                                    error={formValidations.userFullName}
                                    errorText={"This field is required"}
                                    name="userFullName"
                                    required={true}
                                    id="userFullName"
                                    label="Full Name"
                                    value={formValues.userFullName}
                                    onChange={inputChangeHandler}
                                />
                            </div>
                            <div className={cx("colItem")}>
                                <InputTextField
                                    error={formValidations.userEmail}
                                    errorText={"Please enter a valid email address"}
                                    name="userEmail"
                                    required={true}
                                    id="userEmail"
                                    label="Email"
                                    value={formValues.userEmail}
                                    onChange={inputChangeHandler}
                                />
                            </div>
                        </div>

                        <div className={cx("rowItem")}>
                            <div className={cx("colItem")}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker
                                        inputVariant="outlined"
                                        value={formValues.userJoinDate}
                                        format="dd-MM-yyyy"
                                        required={true}
                                        placeholder={"Join Date"}
                                        onChange={(date) => {
                                            onDateChange(date, "userJoinDate");
                                        }}
                                        InputProps={{
                                            classes: {
                                                root: formValidations.userJoinDate ? classes.root : "",
                                            },
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <CalendarIcon color="primary" style={{ fontSize: 20 }} />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    {formValidations.userJoinDate && (
                                        <FormHelperText error>Please select join date</FormHelperText>
                                    )}
                                </MuiPickersUtilsProvider>
                            </div>
                            <div className={cx("colItem")}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker
                                        inputVariant="outlined"
                                        value={formValues.userInviteDate}
                                        format="dd-MM-yyyy"
                                        required={true}
                                        disablePast={true}
                                        placeholder={"Invite Date"}
                                        onChange={(date) => {
                                            onDateChange(date, "userInviteDate");
                                        }}
                                        InputProps={{
                                            classes: {
                                                root: formValidations.userInviteDate ? classes.root : "",
                                            },
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <CalendarIcon color="primary" style={{ fontSize: 20 }} />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <FormHelperText>
                                        When you want to send the invite for your employee to signup for collabey
                                    </FormHelperText>
                                    {formValidations.userInviteDate && (
                                        <FormHelperText error>Please select invite date</FormHelperText>
                                    )}
                                </MuiPickersUtilsProvider>
                            </div>
                        </div>

                        <div className={cx("rowItem")}>
                            <div className={cx("colItem")}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker
                                        inputVariant="outlined"
                                        value={formValues.dateOfBirth}
                                        format="dd-MM-yyyy"
                                        placeholder={"Date of Birth"}
                                        required={true}
                                        disableFuture={true}
                                        onChange={(date) => {
                                            onDateChange(date, "dateOfBirth");
                                        }}
                                        InputProps={{
                                            classes: {
                                                root: formValidations.dateOfBirth ? classes.root : "",
                                            },
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <CalendarIcon color="primary" style={{ fontSize: 20 }} />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    {formValidations.dateOfBirth && (
                                        <FormHelperText error>Please select date of birth</FormHelperText>
                                    )}
                                </MuiPickersUtilsProvider>
                            </div>
                            <div className={cx("colItem")}>
                                <SelectField
                                    name="role"
                                    required={true}
                                    id={"role"}
                                    label={"Select Role"}
                                    error={formValidations.role}
                                    errorText={"Please select role"}
                                    options={getRolesOptions()}
                                    value={formValues.role}
                                    onChange={inputChangeHandler}
                                />
                            </div>
                        </div>
                        <div className={cx("rowItem")}>
                            <div className={cx("colItem")}>
                                <SelectField
                                    name={"officeID"}
                                    label={"Select Office"}
                                    required={true}
                                    error={formValidations.officeID}
                                    errorText={"Please select office"}
                                    options={getOfficeOptions(offices)}
                                    value={formValues.officeID}
                                    onChange={inputChangeHandler}
                                />
                            </div>

                            <div className={cx("colItem")}>
                                <SelectField
                                    name={"departmentID"}
                                    required={true}
                                    label={"Select Department"}
                                    error={formValidations.departmentID}
                                    errorText={"Please select department"}
                                    value={formValues.departmentID}
                                    onChange={inputChangeHandler}
                                    options={getDepartmentOptions(departments)}
                                />
                            </div>
                        </div>

                        <div className={cx("rowItem")}>
                            <div className={cx("colItem")}>
                                <SelectField
                                    name={"designationID"}
                                    required={true}
                                    label={"Select Designation"}
                                    error={formValidations.designationID}
                                    errorText={"Please select designation"}
                                    value={formValues.designationID}
                                    onChange={inputChangeHandler}
                                    options={getDesignationOptions(designations)}
                                />
                            </div>
                            <div className={cx("colItem")}>
                                <SelectField
                                    name={"reportingManagerID"}
                                    label={"Select Reporting Manager"}
                                    value={formValues.reportingManagerID}
                                    onChange={inputChangeHandler}
                                    options={getUsersOptions()}
                                />
                            </div>
                        </div>
                    </div>
                    <Divider style={{ backgroundColor: colors.darkGrey, margin: "30px 0" }} />
                    <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button
                            variant="outlined"
                            style={{ marginRight: 16 }}
                            size="medium"
                            color="primary"
                            onClick={closeDrawer}
                        >
                            Cancel
                        </Button>
                        <Button variant="contained" size="medium" color="primary" onClick={handleSave}>
                            Save
                        </Button>
                    </Box>
                </div>
            </div>
        </Drawer>
    );
};

/* istanbul ignore next */
const mapStateToProps = (state: any) => {
    return {
        isLoading: state.app.isLoading,
        alert: state.app.alert,
        departments: state.department.departments || [],
        designations: state.designation.designations || [],
        offices: state.office.offices || [],
        roles: state.role.roles || [],
        user: state.user.profile.user || [],
        users: state.user.users || [],
    };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: any) => ({
    getUsers: () => dispatch(getUsers()),
    getUserProfile: () => dispatch(getUserProfile()),
    getRoles: () => dispatch(getRoles()),
    getDepartments: () => dispatch(getDepartments()),
    getDesignations: () => dispatch(getDesignations()),
    getOffices: () => dispatch(getOffices()),
    closeAlert: () => dispatch(closeAlert()),
    openSnackbar: (message: string) => dispatch(openSnackbar(message)),
    closeSnackbar: () => dispatch(closeSnackbar()),
    openAlert: (payload: any) => dispatch(openAlert(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUser);
