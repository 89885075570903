import React, { FC, useState, useEffect } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { getTopics } from "./../../../actions/feature/feeds/feedsActions";
import Topics from "./Topics";
import SubHeader from "./../../common/header/subHeader";
import { useParams } from "react-router-dom";
import { Typography, Button, TextField, Box, Checkbox, CircularProgress, Chip } from "@material-ui/core";
import Avatar from "./../../common/avatar/Avatar";
import BaseModal from "./../../../components/common/base-modal/baseModal";
import InputTextField from "./../../../components/common/text-field/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import SelectField from "./../../../components/common/select-field/SelectField";
import { API } from "./../../../hrm/sagas/feature/company/api";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";

import classnames from "classnames/bind";
import styles from "./Feeds.scss";
const cx = classnames.bind(styles);

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface IPageProps {
    user: any;
    type: string;
    id: string;
    feeds: any;
    createCommunityOpen: boolean;
    onCreateCommunityClose: () => void;
    onCreateCommunity: (payload: any) => void;
    onUpdateCommunity: (payload: any) => void;
}

const CreateCommunity: FC<IPageProps> = ({
    user,
    type,
    id,
    feeds,
    createCommunityOpen,
    onCreateCommunityClose,
    onCreateCommunity,
    onUpdateCommunity,
}) => {
    const { groupId } = useParams();
    const [open, setOpen] = useState(false);
    const [selectedFeed, setSelectedFeed] = useState<any>({});
    const [options, setOptions] = useState<any>([]);
    const loading = open && options.length === 0;
    const [formValues, setFormValues] = useState<any>({
        name: "",
        description: "",
        users: "",
        access: "",
    });
    const [formValidations, setFormValidations] = useState<any>({
        name: false,
        description: false,
        //users: false,
        access: false,
    });

    React.useEffect(() => {
        if (createCommunityOpen) {
            setFormValues({ ...formValues, name: "", description: "", users: "", access: "" });
            setFormValidations({ ...formValidations, name: false, description: false, access: false });
        }
    }, [createCommunityOpen]);

    React.useEffect(() => {
        if (createCommunityOpen) {
            const feed = feeds && feeds.find((feed) => feed.id === id);
            setSelectedFeed(feed);
            setFormValues({
                ...formValues,
                name: feed?.Name,
                description: feed?.Description,
                access: feed?.Access,
                users: getFormUsers(feed?.users),
            });
        }
    }, [feeds, id, createCommunityOpen]);

    React.useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    React.useEffect(() => {
        if (createCommunityOpen) {
            const users =
                options?.length &&
                options
                    .filter((dataItem) => dataItem.userID != user?.id)
                    .map((item: any) => ({
                        userID: item.userID,
                        userFullName: item.userFullName,
                    }));

            setOptions(users);
        }
    }, [createCommunityOpen, user]);

    React.useEffect(() => {
        let active = true;

        if (!createCommunityOpen) {
            //return;
        }

        (async () => {
            API.getCompanyUsers().then(
                (res) => {
                    const { status, data } = res;
                    if (status === "SUCCESS") {
                        if (true) {
                            const users = data
                                .filter((dataItem) => dataItem.id != user?.id)
                                .map((item: any) => ({
                                    userID: item.id,
                                    userFullName: item.UserFullName,
                                }));
                            setOptions(users);
                        }
                    } else {
                        setOpen(false);
                    }
                },
                (res) => {
                    setOpen(false);
                }
            );
        })();

        return () => {
            active = false;
        };
    }, []);

    const inputChangeHandler = (evt: any) => {
        setFormValues({ ...formValues, [evt.target.name]: evt.target.value });
    };

    const validateFormValues = () => {
        let isValid = true;
        let tempFormValidations = {};
        for (const [key, value] of Object.entries(formValues)) {
            //if (!value || (Array.isArray(value) && !value.length)) {
            if (!value) {
                isValid = false;
                tempFormValidations[`${key}`] = true;
            } else {
                tempFormValidations[`${key}`] = false;
            }
        }
        setFormValidations({ ...formValidations, ...tempFormValidations });
        return isValid;
    };

    const handleOnSubmit = () => {
        if (validateFormValues()) {
            // submit form
            const payload = {
                ...formValues,
            };
            const currentUser = payload.users.find((item) => item.userID == user.id);
            if (!currentUser) {
                payload.users = [...payload.users, { userID: user.id }];
            }
            if (id) {
                onUpdateCommunity({ ...payload, id });
            } else {
                onCreateCommunity(payload);
            }
            onCreateCommunityClose();
        }
    };

    const getFormUsers = (users: any) => {
        if (users?.length) {
            const updatedUsers = users.map((user) => {
                return {
                    id: user.id,
                    userID: user.UserID,
                    userFullName: user.UserFullName,
                };
            });
            return updatedUsers;
        }
        return [];
    };

    const getDefaultOptions = () => {
        if (!formValues?.users?.length) return [];
        const updatedOptions =
            options &&
            options.length &&
            options.filter((userDefault) => {
                return formValues?.users?.find((user) => user.userID == userDefault.userID);
            });
        return updatedOptions;
    };

    const TitleContent = () => {
        return (
            <div className={cx("titleContainer")}>
                <Typography>Edit community</Typography>
                <div>{/* <DeleteOutlinedIcon /> */}</div>
            </div>
        );
    };

    return (
        <BaseModal
            title={type === "edit" ? <TitleContent /> : "Create community"}
            open={createCommunityOpen}
            handleClose={onCreateCommunityClose}
            maxWidth="lg"
            scroll="body"
        >
            <Box width={704} minHeight={480} height="auto">
                <div className={cx("createCommunity")}>
                    <div className={cx("row")}>
                        <InputTextField
                            error={formValidations.name}
                            errorText={"This field is required"}
                            name="name"
                            required={true}
                            id="name"
                            label="Name"
                            value={formValues.name}
                            onChange={inputChangeHandler}
                        />
                    </div>
                    <div className={cx("row")}>
                        <TextField
                            error={formValidations.description}
                            helperText={formValidations.description && "This field is required"}
                            id="outlined-multiline-static"
                            label="Description"
                            multiline
                            required={true}
                            rows="8"
                            value={formValues.description}
                            className={"textarea"}
                            margin="normal"
                            variant="outlined"
                            name="description"
                            onChange={inputChangeHandler}
                        />
                    </div>
                    <div className={cx("row")}>
                        <Autocomplete
                            multiple
                            limitTags={2}
                            freeSolo
                            disableCloseOnSelect
                            id="multiple-limit-tags"
                            options={options}
                            getOptionLabel={(option: any) => option?.userFullName}
                            defaultValue={getDefaultOptions()}
                            getOptionDisabled={(option) => option.userID == user?.id}
                            renderTags={(tagValue, getTagProps) =>
                                tagValue.map((option, index) => (
                                    <Chip
                                        label={option.userFullName}
                                        {...getTagProps({ index })}
                                        disabled={option.userID == user?.id}
                                    />
                                ))
                            }
                            renderOption={(option: any, { selected }) => {
                                return (
                                    <React.Fragment>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option.userFullName}
                                    </React.Fragment>
                                );
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Users"
                                    variant="outlined"
                                    placeholder="Users"
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}
                                />
                            )}
                            onChange={(event, newValue, reason, detail) => {
                                setFormValues({ ...formValues, users: newValue });
                            }}
                        />

                        {/* <Autocomplete
                            multiple
                            id="checkboxes-tags-demo"
                            options={options}
                            disableCloseOnSelect
                            getOptionLabel={(option: any) => option.userFullName}
                            defaultValue={[top100Films[1], top100Films[1]]}
                            limitTags={2}
                            renderOption={(option, { selected }) => {
                                return (
                                    <React.Fragment>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option.userFullName}
                                    </React.Fragment>
                                );
                            }}
                            onOpen={() => {
                                setOpen(true);
                            }}
                            onClose={() => {
                                setOpen(false);
                            }}
                            // renderOption={(option, { selected }) => {
                            //     console.log("selected", selected, option);
                            //     return (
                            //         <React.Fragment>
                            //             <Checkbox
                            //                 icon={icon}
                            //                 checkedIcon={checkedIcon}
                            //                 style={{ marginRight: 8 }}
                            //                 checked={selected}
                            //             />
                            //             {option.userFullName}
                            //         </React.Fragment>
                            //     );
                            // }}
                            onChange={(event, newValue, reason, detail) => {
                                // console.log("reason", reason);
                                // let usersList: any = [];
                                // let newUserAdded = false;
                                // if (reason === "select-option") {
                                //     console.log("detail?.option", detail?.option);
                                //     const updatedUsers = formValues.users.map((user) => {
                                //         if (user.userID == detail?.option.userID && user.status === "delete") {
                                //             newUserAdded = true;
                                //             return {
                                //                 ...user,
                                //                 status: "active",
                                //             };
                                //         } else {
                                //             return user;
                                //         }
                                //     });
                                //     !newUserAdded && usersList.push(detail?.option);
                                //     setFormValues({ ...formValues, users: [...updatedUsers, ...usersList] });
                                // } else if (reason === "remove-option") {
                                //     console.log("detail?.option", detail?.option);
                                //     const updatedUsers = formValues.users.map((user) => {
                                //         console.log("user", user);
                                //         if (user.userID == detail?.option.userID) {
                                //             console.log("got it");
                                //             return {
                                //                 ...user,
                                //                 status: "delete",
                                //             };
                                //         } else {
                                //             console.log("not got");
                                //             return user;
                                //         }
                                //     });
                                //     console.log("updatedUsers", updatedUsers);
                                //     //option = { ...detail?.option, status: "delete" };
                                //     setFormValues({ ...formValues, users: updatedUsers });
                                // }
                            }}
                            style={{ width: "100%" }}
                            // renderInput={(params) => (
                            //     <TextField
                            //         {...params}
                            //         label="Users"
                            //         variant="outlined"
                            //         required
                            //         placeholder="Users"
                            //         error={formValidations.users}
                            //         helperText={formValidations.users && "This field is required"}
                            //         InputProps={{
                            //             ...params.InputProps,
                            //             endAdornment: (
                            //                 <React.Fragment>
                            //                     {loading ? <CircularProgress color="inherit" size={20} /> : null}
                            //                     {params.InputProps.endAdornment}
                            //                 </React.Fragment>
                            //             ),
                            //         }}
                            //     />
                            // )}
                            renderInput={(params) => (
                                <TextField {...params} variant="outlined" label="limitTags" placeholder="Favorites" />
                            )}
                        /> */}
                    </div>
                    <div className={cx("row")}>
                        <SelectField
                            error={formValidations.access}
                            name={"access"}
                            required={true}
                            label={"Access"}
                            value={formValues.access}
                            onChange={inputChangeHandler}
                            options={[
                                {
                                    label: "Public (All the members in your network have access to community)",
                                    value: "public",
                                },
                                { label: "Private (Only assigned members have access to community)", value: "private" },
                            ]}
                        />
                    </div>
                    <Box style={{ marginTop: "48px", textAlign: "right" }}>
                        <Button
                            variant={"outlined"}
                            color={"primary"}
                            onClick={onCreateCommunityClose}
                            style={{ marginRight: 16 }}
                        >
                            Cancel
                        </Button>
                        <Button variant={"contained"} color={"primary"} onClick={handleOnSubmit}>
                            Submit
                        </Button>
                    </Box>
                </div>
            </Box>
        </BaseModal>
    );
};

const mapStateToProps = (state) => {
    return {
        feeds: state.feeds.feedGroups,
        user: state.user.profile.user,
    };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => ({
    getTopics: (groupId: any) => dispatch(getTopics(groupId)),
});

export default connect(mapStateToProps, null)(CreateCommunity);
