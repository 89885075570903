import React, { FC } from "react";
import SubHeader from "./../../common/header/subHeader";
import { Link } from "react-router-dom";
import { List, ListItem, ListItemText, Typography, Fab, Box } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";

import classnames from "classnames/bind";
import styles from "./Feeds.scss";
const cx = classnames.bind(styles);

interface IPageProps {
    user: any;
    feeds: any;
    selected?: string;
    onCreateCommunity: (type: string, id?: string) => void;
}

const FeedsSubHeader: FC<IPageProps> = ({ feeds, selected, onCreateCommunity, user }) => {
    const isAdmin = (feedUsers: any) => {
        return feedUsers.find((feedUser) => feedUser.UserID == user?.id && feedUser.Role === "Admin");
    };

    return (
        <SubHeader styles={{ display: "flex", flexDirection: "column", height: "100vh" }}>
            <div className={cx("headerInnerCont")}>
                <div className={cx("mainHeader")}>
                    <Typography variant="h3" className={cx("mainTitle")}>
                        My Communities
                    </Typography>
                    <Fab aria-label="add" size="small" color="secondary" onClick={() => onCreateCommunity("add")}>
                        <AddIcon />
                    </Fab>
                </div>
                <div className={cx("middleContent")}>
                    {feeds &&
                        feeds.length > 0 &&
                        feeds.map((feed, index) => (
                            <List component="nav" key={index} className={cx("pad0")}>
                                <Link to={`/dashboard/group/${feed.id}`}>
                                    <ListItem className={cx("listItem")} button selected={selected == feed.id}>
                                        <ListItemText primary={feed.Name} />
                                        {isAdmin(feed.users) && (
                                            <EditOutlinedIcon
                                                className={cx("editIcon")}
                                                onClick={() => onCreateCommunity("edit", feed.id)}
                                            />
                                        )}
                                    </ListItem>
                                </Link>
                            </List>
                        ))}
                </div>
                {/* <div className={cx("footer")}>
                    <List component="nav">
                        <Link to={`/dashboard/group`}>
                            <ListItem className={cx("listItem")} button>
                                <ListItemText primary={"Search communities"} />
                            </ListItem>
                        </Link>
                    </List>
                </div> */}
            </div>
        </SubHeader>
    );
};

export default FeedsSubHeader;
