import React, { useState } from "react";
import AlertDialog from "./../../../components/common/alert-dialog/AlertDialog";

import { API } from "./../../../sagas/feature/files/api";

import classnames from "classnames/bind";
import styles from "./FileUpload.scss";

const cx = classnames.bind(styles);
interface AlertProps {
    type: string;
    id: string;
    disabled?: boolean;
}

function FileUpload(props: AlertProps) {
    const { type, id, disabled = false } = props;
    const [isAlertDialogOpen, setIsAlertDialogOpen] = useState(false);

    const handleFileChange = async (event) => {
        try {
            setIsAlertDialogOpen(false);
            const file = event.target.files[0];
            // Max file upload size 3mb
            // 3382090 to mb 3.4
            // 3000000 = 3mb
            if (file?.size > parseInt(`${process.env.REACT_APP_MAX_FILE_SIZE_UPLOAD}`)) {
                setIsAlertDialogOpen(true);
                return;
            }
            const formData = new FormData();
            const reader = new FileReader();
            reader.onload = async () => {
                if (!reader) {
                    return false;
                }
                const fileData = reader.result;

                const trimStrlen = `data:${file.type};base64`;
                const imageBase64 = fileData?.toString().slice(trimStrlen.length + 1 || 22);
                formData.append("image", imageBase64 || "");
                formData.append("contentType", file.type);
                formData.append("fileName", file.name); //encodeURIComponent
                formData.append("fileSize", file.size);
                formData.append("type", type);
                formData.append("id", id);

                API.postFile(formData)
                    .then(() => {
                        console.log("success");
                    })
                    .finally(() => {
                        console.log("done");
                    });
            };

            reader.readAsDataURL(file);

            // API.postFile(formData)
            //     .then(() => {
            //         console.log("success");
            //     })
            //     .finally(() => {
            //         console.log("done");
            //     });

            // for (let i = 0; i < filesLen; i++) {
            //     const formData = new FormData();
            //     console.log("sds", event.target.files[i]);
            //     formData.append("file", event.target.files[i]);
            //     console.log("formData", formData);
            //     //api call
            //     API.postFile(formData)
            //         .then(() => {
            //             console.log("success");
            //         })
            //         .finally(() => {
            //             console.log("done");
            //         });
            // }
        } catch (err) {
            console.log("error", err);
        }
    };

    return (
        <div className={cx("image-upload-container")}>
            <AlertDialog
                isOpen={isAlertDialogOpen}
                maxWidth={"md"}
                onClickYes={() => console.log("")}
                isNoDisplay={false}
                yesTitle={"Ok"}
                title={`Error`}
                description={`Error in uploading file, maximum file size allowed is 3mb`}
            />
            <input
                type="file"
                title={"Upload file"}
                className={"custom-file-input"}
                disabled={disabled}
                accept="application/pdf, image/png, image/jpg, image/jpeg, image/gif"
                onChange={handleFileChange}
                multiple
            />
        </div>
    );
}

export default FileUpload;
