import { useContext } from "react";
import { UserContext } from "../components/app/userContext";

export const usePlanActiveHook = () => {
    const contextObj: any = useContext(UserContext);
    return contextObj?.userPlanStatus === "active";
};

export const useAllowActionsHook = () => {
    const contextObj: any = useContext(UserContext);
    return contextObj?.userRole === "ADMINISTRATOR" || contextObj?.userRole === "ADMIN";
};

export const useIsAllowAddUsersHook = () => {
    //const contextObj: any = useContext(UserContext);
    //return contextObj?.totalUsers < contextObj?.totalPlanUsers;
    return true;
};

export const useIsAdminHook = () => {
    const contextObj: any = useContext(UserContext);
    return contextObj?.userRole === "ADMIN";
};

export const useIsAdministratorHook = () => {
    const contextObj: any = useContext(UserContext);
    return contextObj?.userRole === "ADMINISTRATOR";
};

//ADMINISTRATOR: ADMINISTRATOR is the site owner and has access to everything.
//ADMIN: ADMIN has access to everything except billing
//MEMBER: This is the normal role of employee.