import { buildGET, buildAuthPOST, buildDELETE, buildAuthPUT } from "./../../../utils/pmApi";

// APIs
const getFeeds = () => buildGET("feedgroups");

const getTopics = (groupId) => buildGET(`feedgroups/${groupId}/topics`);

// const getUser = (id) => buildGET(`users/${id}`);

// const getUserProfile = () => buildGET(`user`);

// const addUser = (payload) => buildAuthPOST("users", payload);

// const addUserOnboarding = (payload) => buildAuthPOST("users/onboarding", payload);

// const deleteUser = (id) => buildDELETE(`users/${id}`);

// const updateUser = (payload) => {
//     const { id, ...rest } = payload;
//     return buildAuthPUT(`users/${id}`, rest);
// };

// const updateUserSetting = (payload) => {
//     const { id, ...rest } = payload;
//     return buildAuthPUT(`users/setting/${id}`, rest);
// };

// API export
export const API = {
    getFeeds,
    getTopics,
    // getUser,
    // addUser,
    // deleteUser,
    // updateUser,
    // addUserOnboarding,
    // updateUserSetting,
    // getUserProfile,
};
