import React, { useContext } from "react";

import { UserContext } from "../../components/app/userContext";
import { getAuthToken } from "./../..//utils/app-utils";

// This function takes a component...
const withProjectSocket = (WrappedComponent) => {
    // ...and returns another component...
    return (props) => {
        const contextObj: any = useContext(UserContext);

        return (
            <WrappedComponent
                {...props}
                socketProject={contextObj?.socketProjects.current}
                authToken={getAuthToken()}
            />
        );
    };
};

export default withProjectSocket;
