import React, { FC, useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button, Link, Typography, CircularProgress, Box, Paper } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Link as RouterLink, useHistory, useLocation } from "react-router-dom";
import Alert from "./../../common/alert/Alert";
import InputTextField from "./../../../components/common/text-field/TextField";
import { setAuthToken, removeCookie } from "./../../../utils/app-utils";
import { login } from "../../../actions/feature/auth/authActions";
import { getUserProfile, updateMultiAccounts } from "./../../../hrm/actions/userActions";
import Logo from "./../../../assets/images/logo2.png";
import { resetApp } from "./../../../actions/feature/app/appActions";

import classnames from "classnames/bind";
import styles from "./Login.scss";
import colors from "colors";
const cx = classnames.bind(styles);

const useStyles = makeStyles((theme: Theme) => ({
    title: {
        textAlign: "center",
        marginBottom: "40px",
    },
}));

interface PageProps {
    apiResponse: any;
    isLoading: boolean;
    onLogin: (payload: any) => void;
    getUserProfile: () => void;
    updateMultiAccounts: (payload: any) => void;
    resetApp: () => void;
}

const Login: FC<PageProps> = ({ apiResponse, isLoading, onLogin, getUserProfile, updateMultiAccounts, resetApp }) => {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const [accessDeniedError, setAccessDeniedError] = useState(false);

    useEffect(() => {
        resetApp();
    }, []);

    useEffect(() => {
        if (apiResponse?.status === "SUCCESS" && apiResponse?.description === "MULTI_COMPANIES") {
            updateMultiAccounts(apiResponse.data);
            history.push("multi-accounts");
        } else if (apiResponse?.status === "SUCCESS") {
            setAuthToken(apiResponse.data);
            getUserProfile();
            history.push("hrm/chat");
        }
    }, [apiResponse]);

    useEffect(() => {
        if (location.hash && location.hash === "#accessdenied") {
            setAccessDeniedError(true);
        }
    }, [location.hash]);

    useEffect(() => {
        removeCookie();
    }, []);

    const [formValues, setFormValues] = useState<any>({
        email: "",
        password: "",
    });
    const [formValidations, setFormValidations] = useState<any>({
        email: false,
        password: false,
    });

    const inputChangeHandler = (evt: any) => {
        setFormValues({ ...formValues, [evt.target.name]: evt.target.value });
    };

    const validateFormValues = () => {
        let isValid = true;
        let tempFormValidations = {};
        for (const [key, value] of Object.entries(formValues)) {
            if (!value) {
                isValid = false;
                tempFormValidations[`${key}`] = true;
            } else {
                tempFormValidations[`${key}`] = false;
            }
        }
        setFormValidations({ ...formValidations, ...tempFormValidations });
        return isValid;
    };

    const submitForm = () => {
        if (isLoading) {
            return false;
        }
        if (validateFormValues()) {
            setTimeout(() => {
                onLogin(formValues);
            }, 100);
        }
    };

    const handlDomainNavigation = (action: string) => {
        window.location.href = `${process.env.REACT_APP_UI_APP_URL}/${action}`;
    };

    return (
        <div className={cx("loginWrapper")}>
            <img src={Logo} className={cx("logopage")} />
            <Paper className={cx("formWrapper")} elevation={1}>
                <Typography variant="h1" className={classes.title}>
                    Login
                </Typography>
                {((apiResponse && apiResponse.status === "ERROR") || accessDeniedError) && (
                    <div className={cx("success")}>
                        <Alert
                            onClose={() => {}}
                            message={
                                apiResponse.description === "ACCESS_DENIED" || accessDeniedError
                                    ? "Access denied"
                                    : "Invalid email or password"
                            }
                            type={"error"}
                        ></Alert>
                    </div>
                )}
                <Box className={cx("boxMargin")}>
                    <InputTextField
                        error={formValidations.email}
                        errorText={"This field is required"}
                        name="email"
                        required={true}
                        id="email"
                        label="Email"
                        value={formValues.email}
                        onChange={inputChangeHandler}
                    />
                </Box>
                <Box className={cx("boxMargin")}>
                    <InputTextField
                        error={formValidations.password}
                        errorText={"This field is required"}
                        name="password"
                        required={true}
                        id="password"
                        label="Password"
                        type="password"
                        value={formValues.password}
                        onChange={inputChangeHandler}
                    />
                </Box>
                <Box className={cx("boxMargin")}>
                    <Button
                        className={cx("form-btn")}
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        size="large"
                        style={{ marginTop: 16 }}
                        onClick={submitForm}
                        endIcon={
                            isLoading ? (
                                <CircularProgress color={colors.darkPrimary} size="20px" />
                            ) : (
                                <div style={{ width: "20px" }} />
                            )
                        }
                    >
                        Sign in
                    </Button>
                </Box>
                <div className={cx("footerBtns")}>
                    <Link
                        className={"link"}
                        component={RouterLink}
                        to=""
                        onClick={() => handlDomainNavigation("forgot-password")}
                        variant="body2"
                    >
                        Forgot password?
                    </Link>
                    <Link
                        className={"link"}
                        component={RouterLink}
                        variant="body2"
                        to=""
                        onClick={() => handlDomainNavigation("signup")}
                    >
                        {"Don't have an account? Sign Up"}
                    </Link>
                </div>
            </Paper>
        </div>
    );
};

/* istanbul ignore next */
const mapStateToProps = (state) => {
    return {
        apiResponse: state.auth.login.apiResponse,
        isLoading: state.app.isLoading,
    };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch) => ({
    onLogin: (payload) => dispatch(login(payload)),
    getUserProfile: () => dispatch(getUserProfile()),
    updateMultiAccounts: (payload) => dispatch(updateMultiAccounts(payload)),
    resetApp: () => dispatch(resetApp()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
