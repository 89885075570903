import React, { useEffect, FC } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

type MaxWidthType = false | "xs" | "sm" | "md" | "lg" | "xl" | undefined;

interface AlertDialogProps {
    isOpen: boolean;
    onClickYes: (isClickYes: boolean) => void;
    title: string;
    description: string | React.ReactNode;
    yesTitle?: string;
    noTitle?: string;
    isNoDisplay?: boolean;
    maxWidth?: MaxWidthType;
}

const AlertDialog: FC<AlertDialogProps> = ({
    isOpen,
    onClickYes,
    title,
    description,
    yesTitle = "Yes",
    noTitle = "No",
    isNoDisplay = true,
    maxWidth,
}) => {
    const [open, setOpen] = React.useState(false);

    useEffect(() => {
        setOpen(isOpen);
    }, [isOpen]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (isClickYes: boolean) => {
        setOpen(false);
        onClickYes(isClickYes);
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth={maxWidth}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{description}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    {isNoDisplay && (
                        <Button onClick={() => handleClose(false)} color="primary">
                            {noTitle}
                        </Button>
                    )}
                    <Button onClick={() => handleClose(true)} color="primary" autoFocus>
                        {yesTitle}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default AlertDialog;
