import React, { FC, useEffect, useState } from "react";
import { Card, CardContent, Typography, ListItemText, Divider, Box } from "@material-ui/core";
import Avatar from "./../../../../components/common/avatar/Avatar";
import { toFormatDate } from "../../../../utils/app-utils";
import { API } from "./../../../sagas/feature/profile/api";
import CalendarIcon from "@material-ui/icons/CalendarToday";
import InputTextField from "./../../../../components/common/text-field/TextField";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import ProfileColorDialog from "./ProfileColorDialog";
import DoneOutlinedIcon from "@material-ui/icons/DoneOutlined";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";

import classnames from "classnames/bind";
import styles from "./Profile.scss";
const cx = classnames.bind(styles);

interface PageProps {
    profile: any;
    departments: any;
    designations: any;
    offices: any;
    config: any;
    updateBasicInfoSuccess: (payload: any) => null;
}

const ProfileInformation: FC<PageProps> = ({
    profile = {},
    departments = [],
    designations = [],
    offices = [],
    config = {},
    updateBasicInfoSuccess,
}) => {
    const [isEditName, setIsEditName] = useState(false);
    const [name, setName] = useState<string>("");

    const getEmploymentType = (id) => {
        return (
            ((config && config.employmentType && config.employmentType.find((item: any) => item.id === id)) || {})
                .label || "N/A"
        );
    };

    const getDepartmentName = (id) => {
        return ((departments && departments.find((item: any) => item.id === id)) || {}).DepartmentName || "N/A";
    };

    const getDesignationName = (id) => {
        return ((designations && designations.find((item: any) => item.id === id)) || {}).DesignationName || "N/A";
    };

    const getOfficeName = (id) => {
        return ((offices && offices.find((item: any) => item.id === id)) || {}).officeName || "N/A";
    };

    const handleEditName = () => {
        if (!name || name == profile.userFullName) {
            setIsEditName(false);
            return false;
        }

        const formValuesUpdated = {
            id: profile.id,
            userFullName: name || profile.userFullName,
            userEmail: profile.userEmail,
            mobileNumber: profile.mobileNumber,
            gender: profile.gender,
            departmentID: profile.departmentID,
            designationID: profile.designationID,
            officeID: profile.officeID,
            userJoinDate: profile.userJoinDate,
            employmentTypeId: profile.employmentTypeId,
        };

        API.updateBasicInfo(formValuesUpdated)
            .then(
                (res) => {
                    if (res.status === "ERROR") {
                        setIsEditName(true);
                    } else {
                        updateBasicInfoSuccess(res.data.basicInfo);
                        setIsEditName(false);
                    }
                },
                (res) => {
                    setIsEditName(false);
                }
            )
            .finally(() => {
                //
            });
    };

    const inputChangeHandler = (evt: any) => {
        setName(evt.target.value);
    };

    useEffect(() => {
        if (profile?.userFullName) setName(profile.userFullName);
    }, [profile?.userFullName]);

    return (
        <div style={{ marginBottom: 24 }} className={cx("profilePage")}>
            <div className={cx("title")}>
                <Typography color="textSecondary" gutterBottom>
                    Overview
                </Typography>
            </div>
            <Card variant="outlined">
                {/* <ProfileColorDialog /> */}
                <CardContent style={{ padding: 24 }}>
                    <div className={cx("rowItem")}>
                        <div className={cx("colItem")} style={{ display: "flex", flexDirection: "row" }}>
                            <Avatar name={profile.userFullName} bgColor={profile.userProfileColor} />
                            <div style={{ marginLeft: 16 }}>
                                {isEditName ? (
                                    <div className={cx("editProfileName")}>
                                        <InputTextField
                                            name="userFullName"
                                            required={false}
                                            id="userFullName"
                                            label=""
                                            hover={true}
                                            value={name}
                                            onChange={inputChangeHandler}
                                        />
                                        <DoneOutlinedIcon fontSize={"large"} onClick={() => handleEditName()} />
                                        <CloseOutlinedIcon fontSize={"large"} onClick={() => setIsEditName(false)} />
                                    </div>
                                ) : (
                                    <div className={cx("profileName")}>
                                        <Typography>{profile.userFullName}</Typography>
                                        <EditOutlinedIcon onClick={() => setIsEditName(true)} />
                                    </div>
                                )}
                                <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
                                    <i className="fas fa-envelope" style={{ marginRight: 8 }}></i>
                                    <Typography>{profile.userEmail}</Typography>
                                </Box>
                                {profile.mobileNumber && (
                                    <Typography>
                                        <i className="fas fa-phone" style={{ marginRight: 8 }}></i>
                                        {profile.mobileNumber}
                                    </Typography>
                                )}
                            </div>
                        </div>
                    </div>
                    <Divider />
                    <div className={cx("rowItem")}>
                        <div className={cx("colItem")}>
                            <ListItemText primary="Department" secondary={getDepartmentName(profile.departmentID)} />
                        </div>
                        <div className={cx("colItem")}>
                            <ListItemText primary="Designation" secondary={getDesignationName(profile.designationID)} />
                        </div>
                        <div className={cx("colItem")}>
                            <ListItemText primary="Office" secondary={getOfficeName(profile.officeID)} />
                        </div>
                    </div>

                    <div className={cx("rowItem")}>
                        <div className={cx("colItem")}>
                            <ListItemText
                                primary="Join Date"
                                secondary={profile.userJoinDate ? toFormatDate(profile.userJoinDate, "dd-MM-yyyy") : ""}
                            />
                        </div>
                        <div className={cx("colItem")}>
                            <ListItemText
                                primary="Employment Type"
                                secondary={getEmploymentType(profile.employmentTypeId)}
                            />
                        </div>
                        <div className={cx("colItem")}>
                            <ListItemText primary="Role" secondary={profile.roleName} />
                        </div>
                    </div>

                    <div className={cx("rowItem")}>
                        <div className={cx("colItem")}>
                            <ListItemText primary="Employee ID" secondary={profile.employeeId} />
                        </div>
                        <div className={cx("colItem")}>
                            <ListItemText
                                primary="Reporting Manager"
                                secondary={profile?.reportingManager?.userFullName || "N/A"}
                            />
                        </div>
                        <div className={cx("colItem")}></div>
                    </div>
                </CardContent>
            </Card>
        </div>
    );
};

export default ProfileInformation;
