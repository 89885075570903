import React, { FC, useEffect, useState } from "react";
import { Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";

import { WorkspaceResponse, WorkspaceSettings } from "./../../../../../utils/projectInterfaces";
import { MemberRoles } from "../../../../../utils/projectInterfaces";
import SelectField from "./../../../../../components/common/select-field/SelectField";
import AlertDialog from "./../../../../../components/common/alert-dialog/AlertDialog";
import { API } from "./../../../../../sagas/feature/workspaces/api";

import withProjectSocket from "./../../../../hoc/ProjectsSocket";

import classnames from "classnames/bind";
import styles from "./Workspaces.scss";
import { getWorkspaces } from "actions/feature/workspaces/workspacesActions";
const cx = classnames.bind(styles);

const MemberActions = ({ settingsId, role, members, user, workspaceId, getWorkspace, type }) => {
    const [selectedMemberRole, setSelectedMemberRole] = useState("");
    const [isAlertDialogRoleOpen, setIsAlertDialogRoleOpen] = useState(false);

    const [formValues, setFormValues] = useState<any>({
        initialMemberRole: "",
    });

    const inputChangeHandler = (evt: any) => {
        if (evt.target.value) {
            setSelectedMemberRole(evt.target.value);
            setIsAlertDialogRoleOpen(true);
        }
    };

    useEffect(() => {
        setSelectedMemberRole(role);
        setFormValues({ ...formValues, initialMemberRole: role });
    }, [role]);

    const isAdmin = () => {
        const member = members?.find((item) => item?.user?.id == user?.id);
        return member?.role == "ADMIN";
    };

    const handleChangeSettingsRole = (isYes: boolean) => {
        if (isYes) {
            setIsAlertDialogRoleOpen(false);
            const obj = {
                settingsId,
                workspaceId,
                type,
                role: selectedMemberRole,
            };
            API.updateWorkSpaceSettings(obj)
                .then(() => {
                    console.log("success");
                    getWorkspace(workspaceId);
                })
                .finally(() => {
                    //
                });
        } else {
            console.log("formValues", formValues);
            setSelectedMemberRole(formValues?.initialMemberRole);
            setIsAlertDialogRoleOpen(false);
        }
    };

    return (
        <div className={cx("actions")}>
            <AlertDialog
                isOpen={isAlertDialogRoleOpen}
                maxWidth={"md"}
                onClickYes={handleChangeSettingsRole}
                title={"Change Role?"}
                description={"Are you sure you want to change the role?"}
            />

            <SelectField
                name={"initialMemberRole"}
                label={"Role"}
                required={true}
                disabled={isAdmin() ? false : true}
                options={MemberRoles?.map((item) => ({
                    value: item,
                    label: item,
                }))}
                value={selectedMemberRole}
                onChange={inputChangeHandler}
            />
        </div>
    );
};

interface PageProps {
    selectedWorkspace: WorkspaceResponse;
    user: any;
    getWorkspace: (id: string) => void;
    getWorkspaces: () => void;
    socketProject: WebSocket | null;
    authToken: string;
}

const WorkspaceSetting: FC<PageProps> = ({
    selectedWorkspace,
    user,
    getWorkspace,
    getWorkspaces,
    socketProject,
    authToken,
}) => {
    const history = useHistory();
    const { settings, members, id: workspaceId } = selectedWorkspace || { settings: {}, members: [] };
    const [isAlertDialogDeleteWorkspaceOpen, setIsAlertDialogDeleteWorkspaceOpen] = useState(false);

    const handleDeleteWorkspace = (isYes: boolean) => {
        if (isYes) {
            setIsAlertDialogDeleteWorkspaceOpen(false);
            deleteWorkspace();
        } else {
            setIsAlertDialogDeleteWorkspaceOpen(false);
        }
    };

    const deleteWorkspace = () => {
        // API.deleteWorkspace(workspaceId)
        //     .then(() => {
        //         getWorkspaces();
        //         setTimeout(() => {
        //             history.push("/hrm/workspaces");
        //         }, 100);
        //     })
        //     .finally(() => {
        //         //
        //     });
        if (!workspaceId) return false;

        if (socketProject) {
            socketProject?.send(
                JSON.stringify({
                    action: "deleteWorkspace",
                    body: {
                        workspaceId: workspaceId,
                    },
                    token: authToken,
                })
            );
        }
    };

    return (
        <div className={cx("memberRowContainer")}>
            <div className={cx("settingsRow")}>
                <AlertDialog
                    isOpen={isAlertDialogDeleteWorkspaceOpen}
                    maxWidth={"md"}
                    onClickYes={handleDeleteWorkspace}
                    title={"Delete Workspace?"}
                    description={"Are you sure you want to delete the workspace?"}
                />
                <div>
                    <div>Who can create projects</div>
                    <div>
                        <MemberActions
                            role={settings?.creation}
                            settingsId={settings?.id}
                            members={members}
                            user={user}
                            type={"creation"}
                            workspaceId={workspaceId}
                            getWorkspace={getWorkspace}
                        />
                    </div>
                </div>
                <div>
                    <div>Who can delete projects</div>
                    <div>
                        <MemberActions
                            role={settings?.deletion}
                            settingsId={settings?.id}
                            members={members}
                            user={user}
                            workspaceId={workspaceId}
                            getWorkspace={getWorkspace}
                            type={"deletion"}
                        />
                    </div>
                </div>
                <div>
                    <div>Who can add members to projects</div>
                    <div>
                        <MemberActions
                            role={settings?.inviteMembers}
                            settingsId={settings?.id}
                            members={members}
                            user={user}
                            workspaceId={workspaceId}
                            getWorkspace={getWorkspace}
                            type="inviteMembers"
                        />
                    </div>
                </div>
            </div>
            <div>
                <Typography className={"delete"} onClick={() => setIsAlertDialogDeleteWorkspaceOpen(true)}>
                    Delete this workspace?
                </Typography>
            </div>
        </div>
    );
};

export default withProjectSocket(WorkspaceSetting);
