import { action } from "typesafe-actions";

export const workspacesActionTypes = {
    GET_WORKSPACES: "workspaces/GET_WORKSPACES",
    GET_WORKSPACES_SUCCESS: "workspaces/GET_WORKSPACES_SUCCESS",
    GET_WORKSPACES_FAILURE: "workspaces/GET_WORKSPACES_FAILURE",

    GET_WORKSPACE: "workspaces/GET_WORKSPACE",
    GET_WORKSPACE_SUCCESS: "workspaces/GET_WORKSPACE_SUCCESS",
    GET_WORKSPACE_FAILURE: "workspaces/GET_WORKSPACE_FAILURE",

    GET_WORKSPACE_PROJECT_LISTS: "workspaces/GET_WORKSPACE_PROJECT_LISTS",
    GET_WORKSPACE_PROJECT_LISTS_SUCCESS: "workspaces/GET_WORKSPACE_PROJECT_LISTS_SUCCESS",
    GET_WORKSPACE_PROJECT_LISTS_FAILURE: "workspaces/GET_WORKSPACE_PROJECT_LISTS_FAILURE",

    UPDATE_WORKSPACE_PROJECT_SELECTED: "workspaces/UPDATE_WORKSPACE_PROJECT_SELECTED",
    UPDATE_WORKSPACE_PROJECT_TASK_SELECTED: "workspaces/UPDATE_WORKSPACE_PROJECT_TASK_SELECTED",

    GET_WORKSPACE_TASK: "workspaces/GET_WORKSPACE_TASK",
    GET_WORKSPACE_TASK_SUCCESS: "workspaces/GET_WORKSPACE_TASK_SUCCESS",
    GET_WORKSPACE_TASK_FAILURE: "workspaces/GET_WORKSPACE_TASK_FAILURE",
    RESET_WORKSPACE_TASK: "workspaces/RESET_WORKSPACE_TASK",

    DELETE_WORKSPACE_MEMBER: "workspaces/DELETE_WORKSPACE_MEMBER",
    DELETE_WORKSPACE_MEMBER_SUCCESS: "workspaces/DELETE_WORKSPACE_MEMBER_SUCCESS",
    DELETE_WORKSPACE_MEMBER_FAILURE: "workspaces/DELETE_WORKSPACE_MEMBER_FAILURE",

    UPDATE_TASK: "workspaces/UPDATE_TASK",

    UPDATE_TASK_DETAILS: "workspaces/UPDATE_TASK_DETAILS",

    UPDATE_LIST_DETAILS: "workspaces/UPDATE_LIST_DETAILS",

    UPDATE_DELETE_TASK: "workspaces/UPDATE_DELETE_TASK",

    UPDATE_DELETE_LIST: "workspaces/UPDATE_DELETE_LIST",

    UPDATE_PROJECT: "workspaces/UPDATE_PROJECT",

    ADD_COMMENT: "workspaces/ADD_COMMENT",

    UPDATE_TASKS_ORDER: "workspaces/UPDATE_TASKS_ORDER",

    UPDATE_LIST_ORDER: "workspaces/UPDATE_LIST_ORDER",

    UPDATE_TASKS_POSITION_IN_LIST: "workspaces/UPDATE_TASKS_POSITION_IN_LIST",

    RESET: "workspaces/WORKSPACES",
};

export const getWorkspaces = () => action(workspacesActionTypes.GET_WORKSPACES);
export const getWorkspacesSuccess = (payload) => action(workspacesActionTypes.GET_WORKSPACES_SUCCESS, payload);
export const getWorkspacesFailure = (payload) => action(workspacesActionTypes.GET_WORKSPACES_FAILURE, payload);

export const getWorkspace = (payload) => action(workspacesActionTypes.GET_WORKSPACE, payload);
export const getWorkspaceSuccess = (payload) => action(workspacesActionTypes.GET_WORKSPACE_SUCCESS, payload);
export const getWorkspaceFailure = (payload) => action(workspacesActionTypes.GET_WORKSPACE_FAILURE, payload);

export const getWorkspaceProjectLists = (payload) => action(workspacesActionTypes.GET_WORKSPACE_PROJECT_LISTS, payload);
export const getWorkspaceProjectListsSuccess = (payload) =>
    action(workspacesActionTypes.GET_WORKSPACE_PROJECT_LISTS_SUCCESS, payload);
export const getWorkspaceProjectListsFailure = (payload) =>
    action(workspacesActionTypes.GET_WORKSPACE_PROJECT_LISTS_FAILURE, payload);

export const updateWorkspaceProjectSelected = (payload) =>
    action(workspacesActionTypes.UPDATE_WORKSPACE_PROJECT_SELECTED, payload);

export const updateWorkspaceProjectTaskSelected = (payload) =>
    action(workspacesActionTypes.UPDATE_WORKSPACE_PROJECT_TASK_SELECTED, payload);

export const getWorkspaceTask = (payload) => action(workspacesActionTypes.GET_WORKSPACE_TASK, payload);
export const getWorkspaceTaskSuccess = (payload) => action(workspacesActionTypes.GET_WORKSPACE_TASK_SUCCESS, payload);
export const getWorkspaceTaskFailure = (payload) => action(workspacesActionTypes.GET_WORKSPACE_TASK_FAILURE, payload);
export const resetWorkspaceTask = () => action(workspacesActionTypes.RESET_WORKSPACE_TASK);

export const deleteWorkspaceMember = (payload) => action(workspacesActionTypes.DELETE_WORKSPACE_MEMBER, payload);
export const deleteWorkspaceMemberSuccess = (payload) =>
    action(workspacesActionTypes.DELETE_WORKSPACE_MEMBER_SUCCESS, payload);
export const deleteWorkspaceMemberFailure = (payload) =>
    action(workspacesActionTypes.DELETE_WORKSPACE_MEMBER_FAILURE, payload);

export const updateTask = (payload) => action(workspacesActionTypes.UPDATE_TASK, payload);

export const addComment = (payload) => action(workspacesActionTypes.ADD_COMMENT, payload);

export const updateTaskDetails = (payload) => action(workspacesActionTypes.UPDATE_TASK_DETAILS, payload);

export const updateListDetails = (payload) => action(workspacesActionTypes.UPDATE_LIST_DETAILS, payload);

export const updateDeleteTask = (payload) => action(workspacesActionTypes.UPDATE_DELETE_TASK, payload);

export const updateDeleteList = (payload) => action(workspacesActionTypes.UPDATE_DELETE_LIST, payload);

export const updateWorkspaceProject = (payload) => action(workspacesActionTypes.UPDATE_PROJECT, payload);

export const updateTaskOrder = (payload) => action(workspacesActionTypes.UPDATE_TASKS_ORDER, payload);

export const updateListOrder = (payload) => action(workspacesActionTypes.UPDATE_LIST_ORDER, payload);

export const updateTaskPositionInList = (payload) =>
    action(workspacesActionTypes.UPDATE_TASKS_POSITION_IN_LIST, payload);

export const reset = () => action(workspacesActionTypes.RESET);
