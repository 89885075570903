import { buildGET, buildDELETE, buildAuthPUT } from "./../../../utils/workspacesApi";

// APIs
const getWorkspaces = () => buildGET("workspaces");
const getWorkspace = (payload) => buildGET(`workspaces/${payload}`);
const getWorkspaceProjectLists = (payload) =>
    buildGET(`workspaces/${payload.workspaceId}/projects/${payload.projectId}/lists`);
const getTask = (payload) => buildGET(`workspaces/task/${payload}`);
const deleteMember = (payload) => buildDELETE(`workspaces/${payload.workspaceId}/members/${payload.memberId}`);

const updateMember = (payload) => {
    const { workspaceId, memberId, ...rest } = payload;
    return buildAuthPUT(`workspaces/${workspaceId}/members/${memberId}`, rest);
};

const updateWorkSpaceSettings = (payload) => {
    const { workspaceId, settingsId, ...rest } = payload;
    return buildAuthPUT(`workspaces/${workspaceId}/settings/${settingsId}`, rest);
};

const deleteWorkspace = (workspaceId) => buildDELETE(`workspaces/${workspaceId}`);

// API export
export const API = {
    getWorkspaces,
    getWorkspace,
    getWorkspaceProjectLists,
    getTask,
    deleteMember,
    updateMember,
    updateWorkSpaceSettings,
    deleteWorkspace,
};
