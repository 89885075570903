import { action } from "typesafe-actions";

export const DesignationActionTypes = {
    GET_DESIGNATIONS: "DESIGNATION/GET_DESIGNATIONS",
    GET_DESIGNATIONS_SUCCESS: "DESIGNATION/GET_DESIGNATIONS_SUCCESS",
    GET_DESIGNATIONS_FAILURE: "DESIGNATION/GET_DESIGNATIONS_FAILURE",

    GET_DESIGNATION: "DESIGNATION/GET_DESIGNATION",
    GET_DESIGNATION_SUCCESS: "DESIGNATION/GET_DESIGNATION_SUCCESS",
    GET_DESIGNATION_FAILURE: "DESIGNATION/GET_DESIGNATION_FAILURE",

    ADD_DESIGNATION: "DESIGNATION/ADD_DESIGNATION",
    ADD_DESIGNATION_SUCCESS: "DESIGNATION/ADD_DESIGNATION_SUCCESS",
    ADD_DESIGNATION_FAILURE: "DESIGNATION/ADD_DESIGNATION_FAILURE",

    UPDATE_DESIGNATION: "DESIGNATION/UPDATE_DESIGNATION",
    UPDATE_DESIGNATION_SUCCESS: "DESIGNATION/UPDATE_DESIGNATION_SUCCESS",
    UPDATE_DESIGNATION_FAILURE: "DESIGNATION/UPDATE_DESIGNATION_FAILURE",

    DELETE_DESIGNATION: "DESIGNATION/DELETE_DESIGNATION",
    DELETE_DESIGNATION_SUCCESS: "DESIGNATION/DELETE_DESIGNATION_SUCCESS",
    DELETE_DESIGNATION_FAILURE: "DESIGNATION/DELETE_DESIGNATION_FAILURE",

    CLEAR_DESIGNATION: "DESIGNATION/CLEAR_DESIGNATION",

    RESET: "RESET",
};

export const getDesignations = () => action(DesignationActionTypes.GET_DESIGNATIONS);
export const getDesignationsSuccess = (payload) => action(DesignationActionTypes.GET_DESIGNATIONS_SUCCESS, payload);
export const getDesignationsFailure = (payload) => action(DesignationActionTypes.GET_DESIGNATIONS_FAILURE, payload);

export const getDesignation = (id) => action(DesignationActionTypes.GET_DESIGNATION, id);
export const getDesignationSuccess = (payload) => action(DesignationActionTypes.GET_DESIGNATION_SUCCESS, payload);
export const getDesignationFailure = (payload) => action(DesignationActionTypes.GET_DESIGNATION_FAILURE, payload);

export const addDesignation = (payload) => action(DesignationActionTypes.ADD_DESIGNATION, payload);
export const addDesignationSuccess = (payload) => action(DesignationActionTypes.ADD_DESIGNATION_SUCCESS, payload);
export const addDesignationFailure = (payload) => action(DesignationActionTypes.ADD_DESIGNATION_FAILURE, payload);

export const updateDesignation = (payload) => action(DesignationActionTypes.UPDATE_DESIGNATION, payload);
export const updateDesignationSuccess = (payload) => action(DesignationActionTypes.UPDATE_DESIGNATION_SUCCESS, payload);
export const updateDesignationFailure = (payload) => action(DesignationActionTypes.UPDATE_DESIGNATION_FAILURE, payload);

export const deleteDesignation = (payload) => action(DesignationActionTypes.DELETE_DESIGNATION, payload);
export const deleteDesignationSuccess = (payload) => action(DesignationActionTypes.DELETE_DESIGNATION_SUCCESS, payload);
export const deleteDesignationFailure = (payload) => action(DesignationActionTypes.DELETE_DESIGNATION_FAILURE, payload);

export const clearDesignation = () => action(DesignationActionTypes.CLEAR_DESIGNATION);

export const reset = () => action(DesignationActionTypes.RESET);
