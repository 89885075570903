import React, { FC, useContext, useState, useEffect } from "react";
import { Typography, Button } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

import Avatar from "../../../../../components/common/avatar/Avatar";
import { WorkspaceResponse, WorkspaceMember } from "../../../../../utils/projectInterfaces";
import SelectField from "../../../../../components/common/select-field/SelectField";
import InputTextField from "../../../../../components/common/text-field/TextField";
import GroupAddOutlinedIcon from "@material-ui/icons/GroupAddOutlined";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import SaveIcon from "@material-ui/icons/Save";
import MembersDropdown from "../../../common/membersDropdown/MembersDropdown";
import { getAuthToken } from "../../../../../utils/app-utils";

import { UserContext } from "../../../../../components/app/userContext";

import { API } from "../../../../../sagas/feature/workspaces/api";

import { WorkspaceVisibility } from "../../../../../utils/projectInterfaces";

import classnames from "classnames/bind";
import styles from "./CardDetails.scss";
//import { isAdmin } from "utils/roles";
const cx = classnames.bind(styles);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: "11px 32px",
        },
    })
);

interface PageProps {
    setIsAssignee: (payload: boolean) => void;
    customClass?: string;
    workspaceId: string;
    taskId: string;
    users?: any;
}

const AddAssignee: FC<PageProps> = ({ setIsAssignee, customClass = "", workspaceId, taskId, users }) => {
    const contextObj: any = useContext(UserContext);
    const [existingUsers, setExistingUsers] = React.useState<any>([]);

    const [formValues, setFormValues] = useState({
        users: [],
    });
    const [formValidations, setFormValidations] = useState({
        name: false,
    });

    useEffect(() => {
        if (users?.length) {
            const usersIdsArr = users?.filter((user) => user.chatUserStatus === "Active");
            const usersIds =
                usersIdsArr &&
                usersIdsArr?.length > 0 &&
                usersIdsArr.reduce((accum: any, curr: any) => {
                    return [...accum, curr.id];
                }, []);
            setExistingUsers(usersIds || []);
        }
    }, [users]);

    const handleUpdateAssignee = () => {
        if (contextObj?.socketProjects.current?.readyState !== WebSocket.OPEN) return false;

        if (!formValues.users || !formValues.users?.length) {
            setFormValidations({ ...formValidations, name: true });
            return false;
        }

        contextObj?.socketProjects.current?.send(
            JSON.stringify({
                action: "updateTaskMembers",
                body: {
                    users: formValues.users,
                    taskId,
                },
                token: getAuthToken(),
            })
        );
        setIsAssignee(false);
    };

    const handleWorkspaceUsers = (users) => {
        const updatedUsersList =
            users?.length &&
            users.reduce((accum, curr) => {
                return [...accum, curr["userID"]];
            }, []);
        setFormValues({ ...formValues, users: updatedUsersList });
    };

    return (
        <div className={cx("createModal", "addAsigneeModal", customClass)}>
            <div className={cx("header")}>
                <Typography>Assignee</Typography>
                <div onClick={() => setIsAssignee(false)}>
                    <CloseOutlinedIcon />
                </div>
            </div>
            <div className={cx("content")}>
                <div className={cx("row")} style={{ margin: 0 }}>
                    <MembersDropdown
                        users={[]}
                        isError={false}
                        existingUsers={existingUsers}
                        setUsers={handleWorkspaceUsers}
                        toText={""}
                        workspaceId={workspaceId}
                    />
                </div>
                <div className={cx("row")} style={{ marginTop: "24px" }}>
                    <Button
                        style={{ width: "100%" }}
                        variant="outlined"
                        color={"primary"}
                        size="small"
                        onClick={handleUpdateAssignee}
                    >
                        Save
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default AddAssignee;
