import React, { FC, useState, useEffect } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { getTopics } from "./../../../actions/feature/feeds/feedsActions";
import Topics from "./Topics";
import SubHeader from "./../../common/header/subHeader";
import { useParams } from "react-router-dom";
import { Typography, Button, TextField } from "@material-ui/core";
import Avatar from "./../../common/avatar/Avatar";

import classnames from "classnames/bind";
import styles from "./Feeds.scss";
const cx = classnames.bind(styles);

interface IPageProps {
    feeds: any;
}

const Members: FC<IPageProps> = ({ feeds }) => {
    const { groupId } = useParams();
    const [feedGroup, setFeedGroup] = useState<any>({});
    const [feedGroupUsers, setFeedGroupUsers] = useState<any>([]);

    useEffect(() => {
        if (feeds && feeds.length && groupId) {
            const feed = feeds.find((feed) => feed.id == groupId);
            setFeedGroup(feed);
            setFeedGroupUsers(feed?.users);
        }
    }, [feeds, groupId]);

    return (
        <div className={cx("members")}>
            <div className={cx("membersHeader")}>
                <Typography>Members</Typography>
                <Typography color="primary" className={cx("count")}>
                    {feedGroupUsers?.length}
                </Typography>
            </div>
            <div className={cx("tiles")}>
                {feedGroupUsers &&
                    feedGroupUsers.length > 0 &&
                    feedGroupUsers.map((user, index) => (
                        <div title={user.UserFullName} key={index}>
                            <Avatar name={user.UserFullName} />
                        </div>
                    ))}
            </div>
            <div className={cx("sep")} style={{ marginTop: 24 }}></div>
            <div className={cx("info")}>
                <Typography className={cx("infoHeader")}>Info</Typography>
                {feedGroup?.Description}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        feeds: state.feeds.feedGroups,
    };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => ({
    getTopics: (groupId: any) => dispatch(getTopics(groupId)),
});

export default connect(mapStateToProps, null)(Members);
