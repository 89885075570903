import React, { FC, useState, useEffect } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { getTopics } from "./../../../actions/feature/feeds/feedsActions";
import Topics from "./Topics";
import SubHeader from "./../../common/header/subHeader";
import { Link, useHistory, useParams } from "react-router-dom";
import { Typography, Button, TextField } from "@material-ui/core";
import Discussion from "./../../../assets/images/discussion.svg";

import classnames from "classnames/bind";
import styles from "./Feeds.scss";
const cx = classnames.bind(styles);

const HeaderContent = () => {
    return (
        <div className={cx("headerSection")}>
            <div className={cx("feedHeader")}>
                <img src={Discussion} />
            </div>
        </div>
    );
};

interface IPageProps {
    user: any;
    socket: any;
    feeds: any;
    getTopics: (groupId: any) => void;
}

const Feed: FC<IPageProps> = ({ user, socket, feeds, getTopics }) => {
    const { groupId } = useParams();
    const [feed, setFeed] = useState<any>({});
    const [showDiscussion, setShowDiscussion] = useState<boolean>(false);
    const [formValues, setFormValues] = useState<any>({
        description: "",
    });

    const inputChangeHandler = (evt: any) => {
        setFormValues({ ...formValues, [evt.target.name]: evt.target.value });
    };

    useEffect(() => {
        if (groupId && feeds && feeds.length) {
            const feedData = feeds.find((feed) => feed && feed.id == groupId);
            setFeed(feedData);
        }
    }, [groupId, feeds]);

    const handleClickDiscussion = () => {
        setShowDiscussion(true);
    };

    const handleCloseDiscussion = () => {
        setShowDiscussion(false);
    };

    const handleCreateTopic = () => {
        const payload = {
            description: formValues.description,
            feedGroupId: groupId,
        };
        if (payload.feedGroupId && payload.description) {
            console.log("socket", socket);
            if (socket) {
                socket.emit("createTopic", payload);
                handleCloseDiscussion();
                setFormValues({ ...formValues, description: "" });
            }
        }
    };

    const isFeedPrivate = () => {
        return !feed || (feed?.Access === "private" && feed?.users.find((user) => user.UserID == user.id));
    };

    return (
        <div className="contentBody" style={{ height: "80vh", padding: 0, overflowY: "unset" }}>
            <div className={"headerContainer"}>
                <HeaderContent />
            </div>
            {isFeedPrivate() ? (
                <div className={cx("accessDenied")}>Access denied</div>
            ) : (
                <>
                    {!showDiscussion ? (
                        <div className={cx("shareContainer")} onClick={handleClickDiscussion}>
                            <div className={cx("innerContainer")}>
                                <Typography>Start a discussion</Typography>
                            </div>
                        </div>
                    ) : (
                        <div className={cx("feed edit")}>
                            <TextField
                                id="outlined-multiline-static"
                                label="Start a discussion"
                                multiline
                                autoFocus={true}
                                rows="4"
                                value={formValues.description}
                                className={"textarea"}
                                margin="normal"
                                variant="outlined"
                                name="description"
                                onChange={inputChangeHandler}
                            />
                            <div className={cx("btns")}>
                                <Button
                                    type="submit"
                                    style={{ margin: "12px 24px 16px 0", width: "auto" }}
                                    variant="outlined"
                                    color="primary"
                                    size="medium"
                                    onClick={handleCloseDiscussion}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    type="submit"
                                    style={{ margin: "12px 0 16px", width: "auto" }}
                                    variant="contained"
                                    color="primary"
                                    size="medium"
                                    onClick={handleCreateTopic}
                                >
                                    Post
                                </Button>
                            </div>
                        </div>
                    )}
                    {feed && (
                        <div className={cx("feed")}>
                            {/* {feed.Name} */}
                            <div className={cx("topic")}>{/* <div>{feed.Description}</div> */}</div>
                            <Topics feed={feed} socket={socket} />
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        feeds: state.feeds.feedGroups,
        user: state.user.profile.user,
    };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => ({
    getTopics: (groupId: any) => dispatch(getTopics(groupId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Feed);
