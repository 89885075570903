import React, { FC, useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { makeStyles, Theme } from "@material-ui/core/styles";

import Avatar from "./../../../../components/common/avatar/Avatar";

import classnames from "classnames/bind";
import styles from "./Chat.scss";
import { RoomInterface } from "./../../../../utils/interfaces";
import { updateMessage } from "./../../../../actions/feature/rooms/roomsActions";

import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import SaveIcon from "@material-ui/icons/Save";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import MenuOpenOutlinedIcon from "@material-ui/icons/MenuOpenOutlined";
import NoMeetingRoomOutlinedIcon from "@material-ui/icons/NoMeetingRoomOutlined";
import GroupAddOutlinedIcon from "@material-ui/icons/GroupAddOutlined";
import MembersDropdown from "./../../common/membersDropdown/MembersDropdown";
import AlertDialog from "./../../../../components/common/alert-dialog/AlertDialog";
import { isUserChatRoom } from "./Chat";
import { usePlanActiveHook } from "../../../../utils/userAccess";

import { Typography, List, ListItem, Box, Collapse, ListItemText, Divider, Button } from "@material-ui/core";

const cx = classnames.bind(styles);

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: "8px",
        padding: "8px 16px",
        "&:last-child": {
            marginRight: 0,
        },
    },
}));

interface PageProps {
    room?: RoomInterface;
    user: any;
    isCreateNewRoom?: boolean;
    removeUser: (id: string) => void;
    joinRoom: (payload: string[]) => void;
    setIsChatSideBar: (val: boolean) => void;
    isChatSideBar: boolean;
    selectedRoomUsers: any;
}

const ChatSideBar: FC<PageProps> = ({
    room,
    isCreateNewRoom = false,
    removeUser,
    joinRoom,
    setIsChatSideBar,
    isChatSideBar,
    selectedRoomUsers,
    user,
}) => {
    const isUserAccess = usePlanActiveHook();
    const classes = useStyles();
    const [openAbout, setOpenAbout] = useState(false);
    const [openFiles, setOpenFiles] = useState(false);
    const { users, chatRoomDescription, chatUserRole, chatRoomName } = room || {};
    const [newRoomUsers, setNewRoomUsers] = useState([]);
    const [isJoinRoom, setIsJoinRoom] = useState(false);
    const [joinRoomError, setJoinRoomError] = useState(false);
    const [selectedId, setSelectedId] = React.useState("");
    const [isAlertDialogOpen, setIsAlertDialogOpen] = React.useState(false);
    const [existingUsers, setExistingUsers] = React.useState<any>([]);

    const isUserChatRoomAllowed = isUserChatRoom(isCreateNewRoom, room, user, isUserAccess);

    const handleClickAbout = () => {
        setOpenAbout(!openAbout);
    };

    const handleClickFiles = () => {
        setOpenFiles(!openFiles);
    };

    useEffect(() => {
        if (newRoomUsers?.length) {
            setJoinRoomError(false);
        }
    }, [newRoomUsers]);

    useEffect(() => {
        const usersIdsArr = users?.filter((user) => user.chatUserStatus === "Active");
        const usersIds =
            usersIdsArr &&
            usersIdsArr?.length > 0 &&
            usersIdsArr.reduce((accum: any, curr: any) => {
                return [...accum, curr.id];
            }, []);
        setExistingUsers(usersIds || []);
    }, [users]);

    const addUsersToRoom = () => {
        if (!newRoomUsers?.length) {
            setJoinRoomError(true);
            return false;
        }
        const usersIds = newRoomUsers.reduce((prevVal: any, currVal: { userID: any }, idx) => {
            return idx == 0 ? [currVal.userID] : [...prevVal, currVal.userID];
        }, []);

        joinRoom(usersIds);
        setIsJoinRoom(false);
    };

    const onClickYes = (isYes: boolean) => {
        if (!isUserChatRoomAllowed) return false;
        setIsAlertDialogOpen(false);
        if (isYes) {
            removeUser(selectedId);
        } else {
            setSelectedId("");
        }
    };

    return (
        <div className={cx("chatContainer right")}>
            <AlertDialog
                isOpen={isAlertDialogOpen}
                maxWidth={"md"}
                onClickYes={onClickYes}
                title={"Delete Member?"}
                description={
                    user?.id == selectedId
                        ? "Are you sure you want to left the chat?"
                        : "Are you sure you want to remove member from the chat?"
                }
            />
            <div className={cx("chatDetails")}>
                <div className={cx("header")}>
                    <Typography variant={"h3"}>Details</Typography>
                    <CloseOutlinedIcon onClick={() => setIsChatSideBar(false)} />
                </div>
                {!isCreateNewRoom && (
                    <>
                        {chatRoomDescription && (
                            <Box style={{ padding: 16 }}>
                                <Typography variant={"body1"}>Description</Typography>
                                <Typography>{chatRoomDescription}</Typography>
                            </Box>
                        )}
                        <Box>
                            <div className={cx("tilesHeader")}>
                                <Typography variant={"body1"} style={{ fontWeight: "bold" }}>
                                    Members
                                </Typography>
                            </div>
                            <Divider className={cx("divider")} />
                            <div className={cx("tiles")}>
                                {users &&
                                    users?.length > 0 &&
                                    users
                                        .filter((user) => user.chatUserStatus === "Active")
                                        .map((user, index) => (
                                            <div className={cx("tile")} title={user.userFullName} key={index}>
                                                <div className={cx("names")}>
                                                    <Avatar name={user.userFullName} bgColor={user.userProfileColor} />
                                                    <Typography className={cx("name")}>{user.userFullName}</Typography>
                                                </div>
                                                {selectedRoomUsers?.length > 2 && isUserChatRoomAllowed && (
                                                    <div
                                                        className={cx("removeIcon")}
                                                        onClick={() => {
                                                            setSelectedId(user.id);
                                                            setIsAlertDialogOpen(true);
                                                        }}
                                                    >
                                                        <CloseOutlinedIcon />
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                            </div>

                            {selectedRoomUsers?.length > 2 && isUserChatRoomAllowed && (
                                <>
                                    <Divider />
                                    <div
                                        className={"membersRow"}
                                        onClick={() => {
                                            setIsJoinRoom(true);
                                            setJoinRoomError(false);
                                        }}
                                    >
                                        <GroupAddOutlinedIcon style={{ marginRight: 6 }} />
                                        <Typography variant={"body1"} className={cx("cursor")}>
                                            {isJoinRoom ? "Select Members" : "Add Members"}
                                        </Typography>
                                    </div>
                                </>
                            )}
                            {isJoinRoom && selectedRoomUsers?.length > 2 && (
                                <Box style={{ padding: "20px" }}>
                                    <MembersDropdown
                                        users={users}
                                        existingUsers={existingUsers}
                                        isError={joinRoomError}
                                        setUsers={setNewRoomUsers}
                                        toText={""}
                                    />
                                    <Box
                                        display={"flex"}
                                        flexDirection={"row"}
                                        justifyContent={"space-between"}
                                        style={{ marginTop: "24px" }}
                                    >
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            color="primary"
                                            // className={classes.margin}
                                            onClick={() => setIsJoinRoom(false)}
                                            startIcon={<CloseOutlinedIcon />}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            // className={classes.margin}
                                            color="primary"
                                            onClick={addUsersToRoom}
                                            startIcon={<SaveIcon />}
                                        >
                                            Add
                                        </Button>
                                    </Box>
                                </Box>
                            )}
                            {/* {!isJoinRoom && selectedRoomUsers?.length > 2 && (
                                <div className={"membersRow"}>
                                    <NoMeetingRoomOutlinedIcon
                                        style={{ marginRight: 6 }}
                                        onClick={() => setIsJoinRoom(true)}
                                    />
                                    <Typography variant={"body1"} className={cx("cursor")}>
                                        Leave Room
                                    </Typography>
                                </div>
                            )} */}
                        </Box>
                    </>
                )}

                {/* <List component="nav" aria-labelledby="nested-list-subheader">
                    <ListItem button onClick={handleClickAbout}>
                        <ListItemText primary="About" />
                        {openAbout ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={openAbout} timeout="auto" unmountOnExit>
                        <div style={{ padding: "16px" }}>
                            <Typography variant={"body2"}>Email address: test@test.com</Typography>
                        </div>
                    </Collapse>
                </List> */}
                {/* <List component="nav" aria-labelledby="nested-list-subheader">
                    <ListItem button onClick={handleClickFiles}>
                        <ListItemText primary="Files" />
                        {openFiles ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={openFiles} timeout="auto" unmountOnExit>
                        <div style={{ padding: "16px" }}>
                            <Typography variant={"body2"}>File1</Typography>
                            <Typography variant={"body2"}>File2</Typography>
                        </div>
                    </Collapse>
                </List> */}
            </div>
        </div>
    );
};

// const mapStateToProps = (state) => {
//     return {
//         rooms: state.rooms.list || [],
//         selectedRoom: state.rooms.selectedRoom || {},
//         user: state.user.profile.user,
//     };
// };

/* istanbul ignore next */
// const mapDispatchToProps = (dispatch: Dispatch) => ({
//     getRoom: (id: string) => dispatch(getRoom(id)),
//     updateMessage: (payload: any) => dispatch(updateMessage(payload)),
// });

export default ChatSideBar;
