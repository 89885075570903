import React, { useEffect, FC, useContext } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";

import {
    Table,
    Paper,
    Typography,
    Button,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { useAllowActionsHook } from "../../../../../utils/userAccess";
import NotFound from "../../notFound/NotFound";

import { UserContext } from "./../../../../../components/app/userContext";

import AddDesignation from "./AddDesignation";
import AlertDialog from "./../../../../../components/common/alert-dialog/AlertDialog";
import { getComparator, descendingComparator, stableSort } from "./../../../../../utils/app-utils";

import { getDesignations, deleteDesignation } from "../../../../actions/designationActions";
import { toggleDrawer, closeAlert } from "./../../../../../actions/feature/app/appActions";
import SettingsSubHeader from "./../SettingsSubHeader";
import BodyContent from "./../../../common/body/bodyContent";
import { usePlanActiveHook } from "../../../../../utils/userAccess";

import classnames from "classnames/bind";
import styles from "./Styles.scss";
const cx = classnames.bind(styles);

const rows = (list: any) => {
    return list.map((item: any) => {
        return {
            id: item.id,
            name: item.DesignationName,
        };
    });
};

const headCells = [
    { id: "name", numeric: false, disablePadding: true, label: "Designation name" },
    { id: "actions", numeric: true, disablePadding: false, label: "Action" },
];

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? "right" : "left"}
                        padding={headCell.disablePadding ? "none" : "default"}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={cx("visuallyHidden")}>{order === "desc" ? "" : ""}</span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const HeaderContent = ({ onClick }) => {
    const isUserAccess = usePlanActiveHook();
    const isAdminAccess = useAllowActionsHook();
    return (
        <div className={cx("headerSection")}>
            <div className={cx("top")}>
                <div>
                    <Typography variant="h4">Designation</Typography>
                </div>
                <Button
                    color="primary"
                    variant="outlined"
                    size="small"
                    onClick={onClick}
                    startIcon={<AddIcon />}
                    disabled={!isUserAccess || !isAdminAccess}
                >
                    Add Designation
                </Button>
            </div>
        </div>
    );
};

interface DesignationPage {
    designations: [];
    onDeleteDesignation: (id: string) => null;
    toggleDrawer: (isOpen: boolean) => null;
    isDrawerOpen: boolean;
    pagination: any;
    getDesignationsList: () => null;
    closeAlert: () => void;
}

const Designation: FC<DesignationPage> = ({
    designations,
    onDeleteDesignation,
    toggleDrawer,
    isDrawerOpen,
    pagination = {},
    getDesignationsList,
    closeAlert,
}) => {
    const isUserAccess = usePlanActiveHook();
    const isAdminAccess = useAllowActionsHook();
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("name");
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [isAlertDialogOpen, setIsAlertDialogOpen] = React.useState(false);
    const [selectedId, setSelectedId] = React.useState("");
    const [action, setAction] = React.useState("");

    useEffect(() => {
        if (action === "add" || (selectedId && action === "edit")) {
            toggleDrawer(true);
            setAction("");
        }
    }, [selectedId, action]);

    useEffect(() => {
        isAdminAccess && getDesignationsList();
    }, [isAdminAccess]);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        if (!isAdminAccess) return false;
        getDesignationsList();
        setPage(newPage);
    };

    const onClickYes = (isYes: boolean) => {
        if (!isAdminAccess) return false;
        setIsAlertDialogOpen(false);
        // Delete records
        if (isYes === true) {
            onDeleteDesignation(selectedId);
        } else {
            setSelectedId("");
        }
    };

    const handleAdd = (event: any) => {
        setSelectedId("");
        setAction("add");
    };

    const handleEdit = (event: any, id: string) => {
        closeAlert();
        setSelectedId(id);
        setAction("edit");
    };

    const handleDelete = (event: any, id: string) => {
        setAction("");
        setSelectedId(id);
        setIsAlertDialogOpen(true);
    };

    const user = useContext(UserContext);

    if (!isAdminAccess) {
        return <NotFound />;
    }

    return (
        <>
            <div className={cx("designationPage")}>
                <SettingsSubHeader selected={"designation"} />
                <BodyContent>
                    <AlertDialog
                        isOpen={isAlertDialogOpen}
                        onClickYes={onClickYes}
                        title={"Delete Designation?"}
                        description={"Are you sure you want to delete record?"}
                    />
                    <AddDesignation isDrawerOpen={isDrawerOpen} toggleDrawer={toggleDrawer} id={selectedId} />

                    <div className={"headerContainer"}>
                        <HeaderContent onClick={handleAdd} />
                    </div>

                    <div className="contentBody">
                        <Paper className={cx("paper")} style={{ boxShadow: "none" }}>
                            <TableContainer>
                                <Table
                                    className={cx("table")}
                                    aria-labelledby="tableTitle"
                                    size={dense ? "small" : "medium"}
                                    aria-label="enhanced table"
                                >
                                    <EnhancedTableHead
                                        numSelected={0}
                                        order={order}
                                        orderBy={orderBy}
                                        onRequestSort={handleRequestSort}
                                        rowCount={rows.length}
                                    />
                                    <TableBody>
                                        {stableSort(rows(designations), getComparator(order, orderBy)).map(
                                            (row, index) => {
                                                const labelId = `enhanced-table-checkbox-${index}`;
                                                return (
                                                    <TableRow hover={true} role="checkbox" tabIndex={-1} key={row.name}>
                                                        <TableCell
                                                            component="th"
                                                            id={labelId}
                                                            scope="row"
                                                            padding="none"
                                                        >
                                                            {row.name}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <a
                                                                onClick={(event) =>
                                                                    isUserAccess
                                                                        ? handleEdit(event, row.id)
                                                                        : () => null
                                                                }
                                                                className={"mr-16"}
                                                            >
                                                                <i className="far fa-edit fa-lg"></i>
                                                            </a>
                                                            <a
                                                                onClick={(event) =>
                                                                    isUserAccess
                                                                        ? handleDelete(event, row.id)
                                                                        : () => null
                                                                }
                                                            >
                                                                <i className="fas fa-trash-alt fa-lg"></i>
                                                            </a>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            }
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            {pagination && pagination.records > 10 && (
                                <TablePagination
                                    rowsPerPageOptions={[10]}
                                    component="div"
                                    count={parseInt(pagination.records)}
                                    rowsPerPage={parseInt(pagination.per_page_records)}
                                    page={parseInt(pagination.page)}
                                    onChangePage={handleChangePage}
                                />
                            )}
                        </Paper>
                    </div>
                </BodyContent>
            </div>
        </>
    );
};

/* istanbul ignore next */
const mapStateToProps = (state: any) => {
    return {
        isDrawerOpen: state.app.isDrawerOpen,
        designations: state.designation.designations,
        pagination: state.designation.pagination,
    };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => ({
    getDesignationsList: (payload: any) => dispatch(getDesignations()),
    onDeleteDesignation: (id: string) => dispatch(deleteDesignation(id)),
    toggleDrawer: (isOpen: boolean) => dispatch(toggleDrawer(isOpen)),
    closeAlert: () => dispatch(closeAlert()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Designation);
