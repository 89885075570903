import React, { FC, ReactNode } from "react";
import classnames from "classnames/bind";
import styles from "./subHeader.scss";
const cx = classnames.bind(styles);

interface PageProps {
    children: ReactNode;
    styles?: any;
}

const SubHeader: FC<PageProps> = ({ children, styles }) => {
    return (
        <div className={cx("subHeader")}>
            <div className={cx("content")} style={{ ...styles }}>
                {children}
                {/* <div className={cx("content")}>{children}</div> */}
            </div>
        </div>
    );
};

export default SubHeader;
