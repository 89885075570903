import React, { FC, useState, useEffect } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { toTimeZone } from "./../../../utils/utils";

import { getTopics } from "./../../../actions/feature/feeds/feedsActions";
import { toggleDrawer } from "../../../actions/feature/app/appActions";

import Avatar from "./../../common/avatar/Avatar";
import WriteComment from "./WriteComment";

import NO_DATA from "./../../../assets/images/no-data.svg";

import classnames from "classnames/bind";
import styles from "./Feeds.scss";
import { Typography } from "@material-ui/core";
const cx = classnames.bind(styles);

const Comments = ({ comments, timeZoneName, dateFormatLabel, timeFormatLabel }) => {
    return (
        <>
            {comments &&
                comments.map((comment, index) => {
                    return (
                        <div className={cx("feedComment")} key={index}>
                            <Avatar name={comment?.createdby?.userFullName?.charAt(0)} />
                            <div className={cx("comment")}>
                                <div className={cx("commentHeader")}>
                                    <div className={cx("name")}>{comment.createdby.userFullName}</div>
                                    <div className={cx("time")}>
                                        {toTimeZone(
                                            comment.CommentCreatedOn,
                                            timeZoneName,
                                            dateFormatLabel,
                                            timeFormatLabel
                                        )}
                                    </div>
                                </div>
                                <div className={cx("commentBody")}>{comment.Comment}</div>
                            </div>
                        </div>
                    );
                })}
        </>
    );
};

interface IPageProps {
    socket: any;
    topics: any;
    feed: any;
    config: any;
    user: any;
    getTopics: (groupId: any) => void;
}

const Feeds: FC<IPageProps> = ({ socket, topics, feed, config, user, getTopics }) => {
    const [formValues, setFormValues] = useState<any>({
        comment: "",
        topic: "",
    });
    const [timeZoneName, setTimeZoneName] = useState<string>("");
    const [dateFormatLabel, setDateFormatLabel] = useState<string>("");
    const [timeFormatLabel, setTimeFormatLabel] = useState<string>("");

    const inputChangeHandler = (evt: any) => {
        setFormValues({ ...formValues, [evt.target.name]: evt.target.value });
    };
    const { timeZoneId, dateFormatId, timeFormatId } = user || {};

    useEffect(() => {
        if (feed && feed.id) {
            getTopics(feed.id);
        }
    }, [feed]);

    useEffect(() => {
        const { timezone } = config;
        if (timezone?.length) {
            const tz = timezone.find((item: any) => item.id === timeZoneId);
            setTimeZoneName(tz?.TimezoneTitle || "");
        }
    }, [timeZoneId]);

    useEffect(() => {
        const { dateFormat } = config;
        if (dateFormat?.length) {
            const dtFormat = dateFormat.find((item: any) => item.id === dateFormatId);
            setDateFormatLabel(dtFormat?.DateFormatValue || "");
        }
    }, [dateFormatId]);

    useEffect(() => {
        const { timeFormat } = config;
        if (timeFormat?.length) {
            const tFormat = timeFormat.find((item: any) => item.id === timeFormatId);
            setTimeFormatLabel(tFormat?.TimeFormatValue || "");
        }
    }, [timeFormatId]);

    return (
        <div className={cx("topics")}>
            {topics && topics.length > 0 ? (
                <>
                    {topics.map((topic, index) => (
                        <div key={index}>
                            <div className={cx("topic")} key={index}>
                                <div className={cx("topicCont")}>
                                    <div className={cx("topicHeader")}>
                                        <div className={cx("left")}>
                                            <Avatar name={topic?.CreatedBy?.userFullName?.charAt(0)} />
                                            <div className={cx("profile")}>
                                                <div className={cx("name")}>{topic.CreatedBy.userFullName}</div>
                                                <div className={cx("date")}>
                                                    {toTimeZone(
                                                        topic.CreatedOn,
                                                        timeZoneName,
                                                        dateFormatLabel,
                                                        timeFormatLabel
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className={cx("right")}></div>
                                    </div>
                                    <div>{topic.Description}</div>
                                </div>
                                <div className={cx("sep")}></div>
                                <Comments
                                    comments={topic.comments}
                                    timeZoneName={timeZoneName}
                                    dateFormatLabel={dateFormatLabel}
                                    timeFormatLabel={timeFormatLabel}
                                />
                                {topic.comments && topic.comments.length > 0 && <div className={cx("sep")}></div>}
                                <WriteComment socket={socket} topicId={topic.id} />
                            </div>
                        </div>
                    ))}
                </>
            ) : (
                <div className={cx("noCommunity")}>
                    <div className={cx("img")}>
                        <img src={NO_DATA} />
                    </div>
                    <Typography>No Data</Typography>
                </div>
            )}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        topics: state.feeds.topics,
        config: state.app.config,
        user: state.user.profile.user,
    };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => ({
    getTopics: (groupId: any) => dispatch(getTopics(groupId)),
    toggleDrawer: (isOpen: boolean) => dispatch(toggleDrawer(isOpen)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Feeds);
