import React, { FC, useContext, useEffect, useState } from "react";
import { Typography, Button } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

import AlertDialog from "./../../../../../components/common/alert-dialog/AlertDialog";

import Avatar from "./../../../../../components/common/avatar/Avatar";
import { Colors } from "./../../../../../utils/projectInterfaces";
import SelectField from "./../../../../../components/common/select-field/SelectField";
import InputTextField from "./../../../../../components/common/text-field/TextField";
import GroupAddOutlinedIcon from "@material-ui/icons/GroupAddOutlined";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import DoneOutlinedIcon from "@material-ui/icons/DoneOutlined";
import SaveIcon from "@material-ui/icons/Save";
import MembersDropdown from "../../../common/membersDropdown/MembersDropdown";
import { getAuthToken } from "./../../../../../utils/app-utils";

import { UserContext } from "../../../../../components/app/userContext";

import { API } from "./../../../../../sagas/feature/workspaces/api";

import { WorkspaceVisibility } from "../../../../../utils/projectInterfaces";

import classnames from "classnames/bind";
import styles from "./Workspaces.scss";
const cx = classnames.bind(styles);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: "11px 32px",
        },
    })
);

interface PageProps {
    setIsEditProject: (payload: boolean) => void;
    setIsCreateProject: (payload: boolean) => void;
    selectedProjectId: string;
    isEditProject: boolean;
    selectedProject: any;
}

const CreateProject: FC<PageProps> = ({
    setIsEditProject,
    setIsCreateProject,
    selectedProjectId = "",
    isEditProject = false,
    selectedProject,
}) => {
    const contextObj: any = useContext(UserContext);
    const { workspaceId = "" } = useParams();

    const [formValues, setFormValues] = useState({
        name: "",
        description: "",
        selectedBackground: "",
    });
    const [formValidations, setFormValidations] = useState({
        name: false,
    });

    useEffect(() => {
        if (isEditProject) {
            setFormValues({
                name: selectedProject?.projectName,
                description: selectedProject?.projectDescription,
                selectedBackground: selectedProject?.background,
            });
        }
    }, [selectedProject, isEditProject]);

    const setBackround = (color: string) => {
        setFormValues({ ...formValues, selectedBackground: color });
    };

    const inputChangeHandler = (evt: any) => {
        setFormValues({ ...formValues, [evt.target.name]: evt.target.value });
        if (evt.target.value) setFormValidations({ ...formValidations, [evt.target.name]: false });
    };

    const handleCreateProject = () => {
        if (contextObj?.socketProjects.current?.readyState !== WebSocket.OPEN) return false;

        if (!formValues.name || !workspaceId) {
            setFormValidations({ ...formValidations, name: true });
            return false;
        }

        contextObj?.socketProjects.current?.send(
            JSON.stringify({
                action: "createProject",
                body: {
                    workspaceId,
                    name: formValues.name,
                    description: formValues.description,
                    background: formValues.selectedBackground,
                },
                token: getAuthToken(),
            })
        );
    };

    const handleUpdateProject = () => {
        if (contextObj?.socketProjects.current?.readyState !== WebSocket.OPEN) return false;

        if (!formValues.name || !workspaceId) {
            setFormValidations({ ...formValidations, name: true });
            return false;
        }

        contextObj?.socketProjects.current?.send(
            JSON.stringify({
                action: "updateProject",
                body: {
                    projectId: selectedProject?.id,
                    projectName: formValues.name,
                    projectDescription: formValues.description,
                    background: formValues.selectedBackground,
                },
                token: getAuthToken(),
            })
        );
        setIsEditProject(false);
    };

    return (
        <div className={cx("createModal", "projectModal")}>
            <div className={cx("header")}>
                <Typography>Create Project</Typography>
                <div
                    onClick={(evt) => {
                        evt.stopPropagation();
                        setIsCreateProject(false);
                    }}
                >
                    <CloseOutlinedIcon />
                </div>
            </div>
            <div className={cx("content")}>
                <Typography>Background</Typography>
                <div className={cx("row", "wrap")}>
                    {Colors.map((color) => (
                        <div
                            className={cx("color")}
                            style={{ backgroundColor: color }}
                            onClick={() => setBackround(color)}
                        >
                            {formValues.selectedBackground === color && <DoneOutlinedIcon />}
                        </div>
                    ))}
                </div>
                <div className={cx("row")}>
                    <InputTextField
                        error={formValidations.name}
                        errorText={"This field is required"}
                        name="name"
                        required={true}
                        label="Name"
                        value={formValues.name}
                        onChange={inputChangeHandler}
                    />
                </div>
                <div className={cx("row")}>
                    <InputTextField
                        name="description"
                        label="Description"
                        value={formValues.description}
                        multiline={true}
                        rows={4}
                        onChange={inputChangeHandler}
                    />
                </div>
                {/* <div className={cx("row")}>
                    <Typography className={cx("col1")}>Visibility</Typography>
                    <SelectField
                        name={"initialMemberRole"}
                        label={"Role"}
                        required={true}
                        //disabled={member?.user?.id != user?.id && isAdmin() ? false : true}
                        options={WorkspaceVisibility?.map((item) => ({
                            value: item.value,
                            label: item.type,
                            description: item.description,
                        }))}
                        value={""}
                        onChange={inputChangeHandler}
                    />
                </div> */}

                <div className={cx("row")} style={{ marginTop: "24px" }}>
                    <Button
                        variant="outlined"
                        size="small"
                        onClick={() => (isEditProject ? handleUpdateProject() : handleCreateProject())}
                    >
                        {isEditProject ? "Update" : "Create"}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default CreateProject;
