import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import { Typography, Button, Card } from "@material-ui/core";
import { connect } from "react-redux";
import AlertDialog from "./../../../../../components/common/alert-dialog/AlertDialog";

import { API } from "./../../../../sagas/feature/company/api";
import { removeCookie } from "../../../../../utils/app-utils";

import { reset as resetUsers } from "./../../../../actions/userActions";
import { reset as resetProfile } from "./../../../../actions/profileActions";
import { reset as resetCompany } from "./../../../../actions/companyActions";
import { reset as resetDepartment } from "./../../../../actions/departmentActions";
import { reset as resetDesignation } from "./../../../../actions/designationActions";
import { reset as resetOffice } from "./../../../../actions/officeActions";
import { reset as resetPlan } from "./../../../../actions/planActions";
import { reset as resetRole } from "./../../../../actions/roleActions";
import { reset as resetAuth } from "./../../../../../actions/feature/auth/authActions";
import { reset as resetFeeds } from "./../../../../../actions/feature/feeds/feedsActions";

import { useIsAdministratorHook } from "../../../../../utils/userAccess";

import classnames from "classnames/bind";
import styles from "./../Styles.scss";
import colors from "colors";
const cx = classnames.bind(styles);

interface PageProps {
    companyId: any;
    resetUsers: () => void;
    resetProfile: () => void;
    resetCompany: () => void;
    resetDepartment: () => void;
    resetDesignation: () => void;
    resetOffice: () => void;
    resetPlan: () => void;
    resetRole: () => void;
    resetAuth: () => void;
    resetFeeds: () => void;
}

const CloseAccount: FC<PageProps> = ({
    companyId,
    resetUsers,
    resetProfile,
    resetCompany,
    resetDepartment,
    resetDesignation,
    resetOffice,
    resetPlan,
    resetRole,
    resetAuth,
    resetFeeds,
}) => {
    const [isAlertDialogOpen, setIsAlertDialogOpen] = React.useState(false);
    const history = useHistory();
    const isAdministratorAccess = useIsAdministratorHook();

    const onClickYes = (isYes: boolean) => {
        setIsAlertDialogOpen(false);
        // Delete records
        if (isYes && companyId && isAdministratorAccess) {
            API.deleteCompany(companyId).then((res) => {
                if (res.status === "SUCCESS") {
                    removeCookie();
                    resetUsers();
                    resetProfile();
                    resetCompany();
                    resetDepartment();
                    resetDesignation();
                    resetOffice();
                    resetPlan();
                    resetRole();
                    resetAuth();
                    resetFeeds();
                    history.push("/login");
                }
            });
        }
    };

    const handleSave = () => {
        if (companyId) {
            setIsAlertDialogOpen(true);
        }
    };

    if (!isAdministratorAccess) {
        return <div></div>;
    }
    return (
        <div style={{ marginTop: 32 }}>
            <Typography>Danger Zone</Typography>
            <Card
                className={cx("plan")}
                variant="outlined"
                style={{ textAlign: "center", marginTop: 8, border: "1px solid #d00" }}
            >
                <AlertDialog
                    isOpen={isAlertDialogOpen}
                    onClickYes={onClickYes}
                    title={"Close Account?"}
                    description={"Are you sure you want to close your account?"}
                />
                <Button style={{ color: "#d00" }} onClick={handleSave}>
                    <Typography variant={"body2"}>Close my account</Typography>
                </Button>
            </Card>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    resetUsers: () => dispatch(resetUsers()),
    resetProfile: () => dispatch(resetProfile()),
    resetCompany: () => dispatch(resetCompany()),
    resetDepartment: () => dispatch(resetDepartment()),
    resetDesignation: () => dispatch(resetDesignation()),
    resetOffice: () => dispatch(resetOffice()),
    resetPlan: () => dispatch(resetPlan()),
    resetRole: () => dispatch(resetRole()),
    resetAuth: () => dispatch(resetAuth()),
    resetFeeds: () => dispatch(resetFeeds()),
});

export default connect(null, mapDispatchToProps)(CloseAccount);
