import React, { FC, useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { List, ListItem, ListItemText, Typography, Collapse, Box } from "@material-ui/core";
import { useHistory, Link, useParams, useLocation } from "react-router-dom";

import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import AccountTreeOutlinedIcon from "@material-ui/icons/AccountTreeOutlined";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import PeopleOutlinedIcon from "@material-ui/icons/PeopleOutlined";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";

import { UserContext } from "../../../../../components/app/userContext";
import { Workspace } from "./../../../../../utils/projectInterfaces";
import CreateWorkspace from "./createWorkspace";

import classnames from "classnames/bind";
import styles from "./Workspaces.scss";
const cx = classnames.bind(styles);

// interface ListItemComponentProps {
//     workspaceId: string;
//     item: Workspace;
//     index: number;
// }

// const ListItemComponent: FC<ListItemComponentProps> = ({ workspaceId, item, index }) => {
//     const [open, setOpen] = React.useState(false);
//     let location = useLocation();

//     useEffect(() => {
//         if (workspaceId === item.id) {
//             setOpen(true);
//         } else if (index === 0) {
//             //setOpen(true);
//         }
//     }, [index]);

//     const handleClick = () => {
//         setOpen(!open);
//     };

//     return (
//         <>
//             <ListItem button onClick={handleClick}>
//                 <ListItemText primary={item.name} />
//                 {open ? <ExpandLess /> : <ExpandMore />}
//             </ListItem>
//             <Collapse in={open} timeout="auto" unmountOnExit>
//                 <div className={"itemContainer"}>
//                     <Link to={`/hrm/workspaces/${item.id}#projects`}>
//                         <div
//                             className={cx(
//                                 "subItem",
//                                 location.hash === "#projects" && workspaceId === item.id ? "active" : ""
//                             )}
//                         >
//                             <AccountTreeOutlinedIcon fontSize="small" />
//                             <Typography variant={"body2"}>Projects</Typography>
//                         </div>
//                     </Link>
//                     <Link to={`/hrm/workspaces/${item.id}#members`}>
//                         <div
//                             className={cx(
//                                 "subItem",
//                                 location.hash === "#members" && workspaceId === item.id ? "active" : ""
//                             )}
//                         >
//                             <PeopleOutlinedIcon fontSize="small" />
//                             <Typography variant={"body2"}>Members</Typography>
//                         </div>
//                     </Link>
//                     <Link to={`/hrm/workspaces/${item.id}#settings`}>
//                         <div
//                             className={cx(
//                                 "subItem",
//                                 location.hash === "#settings" && workspaceId === item.id ? "active" : ""
//                             )}
//                         >
//                             <SettingsOutlinedIcon fontSize="small" />

//                             <Typography variant={"body2"}>Settings</Typography>
//                         </div>
//                     </Link>
//                 </div>
//             </Collapse>
//         </>
//     );
// };

interface PageProps {
    selectedWorkspace: any;
}

const WorkspacesSubHeader: FC<PageProps> = ({ selectedWorkspace }) => {
    const history = useHistory();
    const contextObj: any = useContext(UserContext);
    const { workspaceId = "", projectId = "" } = useParams();
    const { projects } = selectedWorkspace;
    const [isCreateWorkspace, setIsCreateWorkspace] = useState(false);

    React.useMemo(() => {
        setIsCreateWorkspace(false);
    }, [workspaceId]);

    return (
        <div className={cx("subHeader")}>
            <div className={cx("topNav", "borderBox")}>
                <div className={cx("avatar", "hover")} onClick={() => history.push("/hrm/workspaces")}>
                    {selectedWorkspace?.name?.charAt(0)}
                </div>
                <Typography variant="h4" style={{ paddingLeft: "0px" }}>
                    {selectedWorkspace?.name}
                </Typography>
            </div>
            <div className={cx("content", "workspaceSubHeader")}>
                <List component="nav" aria-label="main mailbox folders">
                    <ListItem button onClick={() => history.push(`/hrm/workspaces/${workspaceId}#projects`)}>
                        <ListItemText primary={"Projects"} />
                    </ListItem>
                    <ListItem button onClick={() => history.push(`/hrm/workspaces/${workspaceId}#members`)}>
                        <ListItemText primary={"Members"} />
                    </ListItem>
                    <ListItem button onClick={() => history.push(`/hrm/workspaces/${workspaceId}#settings`)}>
                        <ListItemText primary={"Settings"} />
                    </ListItem>
                </List>
            </div>
        </div>
    );
};

export default WorkspacesSubHeader;
