import React, { FC, useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Box, Divider, Typography, Button, Link } from "@material-ui/core";
import { API } from "./../../../../sagas/feature/plan/api";
import { getDomainName } from "../../../../../utils/app-utils";
import { makeStyles, Theme } from "@material-ui/core/styles";

import BaseModal from "./../../../../../components/common/base-modal/baseModal";

import colors from "colors";

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    },
    footer: {
        padding: "24px 32px",
        borderTop: `1px solid ${colors.grey}`,
        textAlign: "right",
    },
    row: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
    },
}));

interface PaymentPreviewProps {
    title: string;
    open: boolean;
    selectedPlan: any;
    durationActive: string;
    usage: any;
    handleClose: () => void;
    totalUsers: string;
}

const PaymentPreview: FC<PaymentPreviewProps> = ({
    title,
    open,
    selectedPlan,
    durationActive,
    usage,
    handleClose,
    totalUsers,
}) => {
    const classes = useStyles();
    const [planPrice, setPlanPrice] = useState<number>(0);
    const [stripePromise, setStripePromise] = useState<any>(null);
    const [formValues, setFormValues] = useState<any>({
        users: totalUsers || 1,
    });
    const [formValidations, setFormValidations] = useState<any>({
        users: false,
    });

    useEffect(() => {
        if (durationActive === "monthly") {
            setPlanPrice(parseInt(selectedPlan.planPrice));
        } else {
            const selPrice = parseInt(selectedPlan.planPrice);
            const price = Math.floor(
                selPrice - (selPrice * parseInt(process.env.REACT_APP_PLAN_YEARLY_DISCOUNT!)) / 100
            );
            setPlanPrice(price);
        }
    }, [durationActive, selectedPlan]);

    const fetchConfig = async () => {
        const stripe = await loadStripe(process.env.REACT_APP_STRIPE_KEY!);
        setStripePromise(stripe);
    };

    useEffect(() => {
        fetchConfig();
    }, []);

    useEffect(() => {
        setFormValues({ ...formValues, users: totalUsers || 1 });
    }, [totalUsers]);

    useEffect(() => {
        if (open) setFormValues({ ...formValues });
    }, [open]);

    // const fetchCheckoutSession = async ({ plan, duration, domainName }) => {
    //     return API.makePayment({ plan, duration, domainName }).then((res) => res);
    // };

    const fetchCheckoutSession = async ({ plan, duration, users, domainName }) => {
        return API.makePayment({ plan, duration, users, domainName }).then((res) => res);
    };

    const handleOnClick = async (event) => {
        try {
            const response = await fetchCheckoutSession({
                plan: selectedPlan.id,
                duration: durationActive,
                users: formValues.users,
                domainName: getDomainName(),
            });

            const jsd = response.data;
            const res = JSON.parse(jsd);
            const sessionId = res.sessionId;

            // When the customer clicks on the button, redirect them to Checkout.
            const result = await stripePromise.redirectToCheckout({
                sessionId,
            });

            //console.log("error", error);
            // If `redirectToCheckout` fails due to a browser or network
            // error, display the localized error message to your customer
            // using `error.message`.
        } catch (err) {
            //
        }
    };

    const totalAmount = () => {
        let totalPrice = 0;
        let priceStr = "";
        if (durationActive === "yearly") {
            totalPrice = planPrice * 12;
            totalPrice = formValues.users * totalPrice;
        } else {
            totalPrice = planPrice * formValues.users;
        }

        priceStr = `$${totalPrice}`;

        return priceStr;
    };

    const inputChangeHandler = (evt: any) => {
        setFormValues({ ...formValues, [evt.target.name]: evt.target.value });
    };

    const inputChangeNumberHandler = (evt: any) => {
        if (evt.target.value < 0) {
            setFormValues({ ...formValues, [evt.target.name]: 0 });
            return;
        }

        if (evt.target.value < 0 || !Number.isInteger(parseInt(evt.target.value))) {
            setFormValidations({ ...formValidations });
        } else {
            setFormValidations({ ...formValidations });
        }
        setFormValues({ ...formValues, [evt.target.name]: evt.target.value });
    };

    // const areValidUsers = () => {
    //     if (selectedPlan.usersCountType === "Variable") {
    //         let isnum = /^\d+$/.test(formValues.users);
    //         if (!formValues.users || formValues.users < 1 || formValues.users > selectedPlan.usersCount || !isnum) {
    //             return true;
    //         } else {
    //             return false;
    //         }
    //     }
    //     return false;
    // };

    return (
        <>
            <BaseModal title={title} open={open} handleClose={handleClose} maxWidth="lg" scroll="body">
                <Box width={704} minHeight={680} className={classes.container}>
                    <Box margin={4}>
                        <Typography variant={"h4"} style={{ marginBottom: 24 }}>
                            Your plan details
                        </Typography>
                        {usage.pendingDuration && (
                            <Typography variant={"body2"} style={{ marginBottom: 24 }}>
                                Note: Only difference in amount from current active plan to new plan will be charged
                            </Typography>
                        )}
                        <Box className={classes.row} style={{ marginBottom: 16 }}>
                            <Typography variant={"body2"} style={{ width: 200 }}>
                                Name
                            </Typography>
                            <Typography variant={"body2"} style={{ paddingLeft: 16 }}>
                                {selectedPlan.PlanName}{" "}
                                <span style={{ fontSize: 14 }}>({`per month / billed ${durationActive}`})</span>
                            </Typography>
                        </Box>

                        <Divider />
                        <Box className={classes.row} style={{ marginBottom: 16, marginTop: 16 }}>
                            <Typography variant={"body2"} style={{ width: 200 }}>
                                <span>Price {`per ${durationActive}`}</span>
                                <br />
                                {durationActive === "yearly" && (
                                    <span
                                        style={{ fontSize: 12 }}
                                    >{`(You saved ${process.env.REACT_APP_PLAN_YEARLY_DISCOUNT}% with the Yearly plan)`}</span>
                                )}
                            </Typography>
                            <Typography variant={"body2"} style={{ paddingLeft: 16 }}>
                                $
                                {durationActive === "yearly" ? (
                                    <>
                                        {`${planPrice} x 12 = `} ${planPrice * 12}
                                    </>
                                ) : (
                                    <>{planPrice}</>
                                )}
                            </Typography>
                        </Box>

                        <Divider />
                        <Box className={classes.row} style={{ marginTop: 16 }}>
                            <Typography variant={"h4"} style={{ width: 200 }}>
                                Total
                            </Typography>
                            <Typography variant={"h4"} style={{ paddingLeft: 16 }}>
                                {totalAmount()}
                            </Typography>
                        </Box>
                    </Box>
                    <Box className={classes.footer}>
                        <Button
                            variant={"outlined"}
                            color={"primary"}
                            onClick={handleClose}
                            style={{ marginRight: 16 }}
                        >
                            Cancel
                        </Button>
                        <Button variant={"contained"} color={"primary"} onClick={handleOnClick}>
                            Continue
                        </Button>
                    </Box>
                </Box>
            </BaseModal>
        </>
    );
};

export default PaymentPreview;
