import { PlanActionTypes } from "./../../actions/planActions";

const initialState = {
    plans: [],
    plan: {},
    userPlans: [],
};

const reducer = (state = initialState, action: any) => {
    switch (action.type) {
        //PLANS
        case PlanActionTypes.GET_PLANS_SUCCESS:
            return { ...state, plans: action.payload };
        case PlanActionTypes.GET_PLANS_FAILURE:
            return { ...state };

        //PLAN
        case PlanActionTypes.GET_PLAN_SUCCESS:
            return { ...state, plan: action.payload.data[0] };
        case PlanActionTypes.GET_PLAN_FAILURE:
            return { ...state };

        //user plans
        case PlanActionTypes.GET_COMPANY_PLANS_SUCCESS:
            return { ...state, userPlans: action.payload.data };
        case PlanActionTypes.GET_COMPANY_PLANS_FAILURE:
            return { ...state };

        // CLEAR PLAN
        case PlanActionTypes.CLEAR_PLAN:
            return {
                ...state,
                plan: {},
            };

        case PlanActionTypes.RESET:
            return { ...state, ...initialState };

        //DEFAULT
        default:
            return state;
    }
};

export { reducer as planReducer };
