import { action } from "typesafe-actions";

export const UserActionTypes = {
    GET_USERS: "users/GET_USERS",
    GET_USERS_SUCCESS: "users/GET_USERS_SUCCESS",
    GET_USERS_FAILURE: "users/GET_USERS_FAILURE",

    GET_USER: "users/GET_USER",
    GET_USER_SUCCESS: "users/GET_USER_SUCCESS",
    GET_USER_FAILURE: "users/GET_USER_FAILURE",

    ADD_USER: "users/ADD_USER",
    ADD_USER_SUCCESS: "users/ADD_USER_SUCCESS",
    ADD_USER_FAILURE: "users/ADD_USER_FAILURE",

    ADD_USER_ONBOARDING: "users/ADD_USER_ONBOARDING",
    ADD_USER_ONBOARDING_SUCCESS: "users/ADD_USER_ONBOARDING_SUCCESS",
    ADD_USER_ONBOARDING_FAILURE: "users/ADD_USER_ONBOARDING_FAILURE",

    DELETE_USER: "users/DELETE_USER",
    DELETE_USER_SUCCESS: "users/DELETE_USER_SUCCESS",
    DELETE_USER_FAILURE: "users/DELETE_USER_FAILURE",

    UPDATE_USER: "users/UPDATE_USER",
    UPDATE_USER_SUCCESS: "users/UPDATE_USER_SUCCESS",
    UPDATE_USER_FAILURE: "users/UPDATE_USER_FAILURE",

    UPDATE_USER_SETTING: "users/UPDATE_USER_SETTING",
    UPDATE_USER_SETTING_SUCCESS: "users/UPDATE_USER_SETTING_SUCCESS",
    UPDATE_USER_SETTING_FAILURE: "users/UPDATE_USER_SETTING_FAILURE",

    UPDATE_USER_CONTACTS: "users/UPDATE_USER_CONTACTS",
    UPDATE_USER_INFORMATION: "users/UPDATE_USER_INFORMATION",

    UPDATE_BASIC_INFO_SUCCCESS: "PROFILE/UPDATE_BASIC_INFO_SUCCCESS",
    UPDATE_PERSONAL_INFO_SUCCESS: "PROFILE/UPDATE_PERSONAL_INFO_SUCCESS",
    UPDATE_CONTACTS_SUCCESS: "PROFILE/UPDATE_CONTACTS_SUCCESS",

    GET_USER_PROFILE: "APP/GET_USER_PROFILE",
    GET_USER_PROFILE_SUCCESS: "APP/GET_USER_PROFILE_SUCCESS",
    GET_USER_PROFILE_FAILURE: "APP/GET_USER_PROFILE_FAILURE",

    UPDATE_MULTI_ACCOUNTS: "APP/UPDATE_MULTI_ACCOUNTS",

    RESET: "APP/RESET",
};

export const getUsers = () => action(UserActionTypes.GET_USERS);
export const getUsersSuccess = (payload) => action(UserActionTypes.GET_USERS_SUCCESS, payload);
export const getUsersFailure = (payload) => action(UserActionTypes.GET_USERS_FAILURE, payload);

export const getUser = (id) => action(UserActionTypes.GET_USER, id);
export const getUserSuccess = (payload) => action(UserActionTypes.GET_USER_SUCCESS, payload);
export const getUserFailure = (payload) => action(UserActionTypes.GET_USER_FAILURE, payload);

export const addUser = (payload) => action(UserActionTypes.ADD_USER, payload);
export const addUserSuccess = (payload) => action(UserActionTypes.ADD_USER_SUCCESS, payload);
export const addUserFailure = (payload) => action(UserActionTypes.ADD_USER_FAILURE, payload);

export const addUserOnboarding = (payload) => action(UserActionTypes.ADD_USER_ONBOARDING, payload);
export const addUserOnboardingSuccess = (payload) => action(UserActionTypes.ADD_USER_ONBOARDING_SUCCESS, payload);
export const addUserOnboardingFailure = (payload) => action(UserActionTypes.ADD_USER_ONBOARDING_FAILURE, payload);

export const deleteUser = (payload) => action(UserActionTypes.DELETE_USER, payload);
export const deleteUserSuccess = (payload) => action(UserActionTypes.DELETE_USER_SUCCESS, payload);
export const deleteUserFailure = (payload) => action(UserActionTypes.DELETE_USER_FAILURE, payload);

export const updateUser = (payload) => action(UserActionTypes.UPDATE_USER, payload);
export const updateUserSuccess = (payload) => action(UserActionTypes.UPDATE_USER_SUCCESS, payload);
export const updateUserFailure = (payload) => action(UserActionTypes.UPDATE_USER_FAILURE, payload);

export const updateUserSetting = (payload) => action(UserActionTypes.UPDATE_USER_SETTING, payload);
export const updateUserSettingSuccess = (payload) => action(UserActionTypes.UPDATE_USER_SETTING_SUCCESS, payload);
export const updateUserSettingFailure = (payload) => action(UserActionTypes.UPDATE_USER_SETTING_FAILURE, payload);

export const updateUserContacts = (payload) => action(UserActionTypes.UPDATE_USER_CONTACTS, payload);
export const updateUserInformation = (payload) => action(UserActionTypes.UPDATE_USER_INFORMATION, payload);

export const updateUserBasicInfoSuccess = (payload) => action(UserActionTypes.UPDATE_BASIC_INFO_SUCCCESS, payload);
export const updateUserPersonalInfoSuccess = (payload) => action(UserActionTypes.UPDATE_PERSONAL_INFO_SUCCESS, payload);
export const updateUserContactsSuccess = (payload) => action(UserActionTypes.UPDATE_CONTACTS_SUCCESS, payload);

export const getUserProfile = () => action(UserActionTypes.GET_USER_PROFILE);
export const getUserProfileSuccess = (payload) => action(UserActionTypes.GET_USER_PROFILE_SUCCESS, payload);
export const getUserProfileFailure = (payload) => action(UserActionTypes.GET_USER_PROFILE_FAILURE, payload);

export const updateMultiAccounts = (payload) => action(UserActionTypes.UPDATE_MULTI_ACCOUNTS, payload);

export const reset = () => action(UserActionTypes.RESET);
