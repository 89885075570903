import React, { FC } from "react";

import classnames from "classnames/bind";
import styles from "./skeleton.scss";
import classNamesBind from "classnames/bind";
const cx = classnames.bind(styles);

interface TextareaSkeletonProps {
    description: string;
    onClick: (ev: any) => void;
}

const TextareaSkeleton: FC<TextareaSkeletonProps> = ({ description = "", onClick }) => {
    return (
        <div onClick={onClick} className={cx("textareaBox")}>
            {description}
        </div>
    );
};

export default TextareaSkeleton;
