import { action } from "typesafe-actions";

export const AuthActionTypes = {
    LOGIN: "auth/LOGIN",
    LOGIN_SUCCESS: "auth/LOGIN_SUCCESS",
    LOGIN_FAILURE: "auth/LOGIN_FAILURE",

    SIGNUP: "auth/SIGNUP",
    SIGNUP_SUCCESS: "auth/SIGNUP_SUCCESS",
    SIGNUP_FAILURE: "auth/SIGNUP_FAILURE",

    FORGOT_PASSWORD: "auth/FORGOT_PASSWORD",
    FORGOT_PASSWORD_SUCCESS: "auth/FORGOT_PASSWORD_SUCCESS",
    FORGOT_PASSWORD_FAILURE: "auth/FORGOT_PASSWORD_FAILURE",

    RESET_PASSWORD: "auth/RESET_PASSWORD",
    RESET_PASSWORD_SUCCESS: "auth/RESET_PASSWORD_SUCCESS",
    RESET_PASSWORD_FAILURE: "auth/RESET_PASSWORD_FAILURE",

    RESET: "RESET",
};

export const login = (payload) => action(AuthActionTypes.LOGIN, payload);
export const loginSuccess = (payload) => action(AuthActionTypes.LOGIN_SUCCESS, payload);
export const loginFailure = (payload) => action(AuthActionTypes.LOGIN_FAILURE, payload);

export const signup = (payload) => action(AuthActionTypes.SIGNUP, payload);
export const signupSuccess = (payload) => action(AuthActionTypes.SIGNUP_SUCCESS, payload);
export const signupFailure = (payload) => action(AuthActionTypes.SIGNUP_FAILURE, payload);

export const forgotPassword = (payload) => action(AuthActionTypes.FORGOT_PASSWORD, payload);
export const forgotPasswordSuccess = (payload) => action(AuthActionTypes.FORGOT_PASSWORD_SUCCESS, payload);
export const forgotPasswordFailure = (payload) => action(AuthActionTypes.FORGOT_PASSWORD_FAILURE, payload);

export const resetPassword = (payload) => action(AuthActionTypes.RESET_PASSWORD, payload);
export const resetPasswordSuccess = (payload) => action(AuthActionTypes.RESET_PASSWORD_SUCCESS, payload);
export const resetPasswordFailure = (payload) => action(AuthActionTypes.RESET_PASSWORD_FAILURE, payload);

export const reset = () => action(AuthActionTypes.RESET);
