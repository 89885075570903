import React, { FC, useContext } from "react";
import { connect } from "react-redux";
import { List, ListItem, ListItemText, Typography } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import { API } from "./../../../sagas/feature/profile/api";
import { removeCookie } from "../../../../utils/app-utils";

import { reset as resetUsers } from "./../../../actions/userActions";
import { reset as resetProfile } from "./../../../actions/profileActions";
import { reset as resetCompany } from "./../../../actions/companyActions";
import { reset as resetDepartment } from "./../../../actions/departmentActions";
import { reset as resetDesignation } from "./../../../actions/designationActions";
import { reset as resetOffice } from "./../../../actions/officeActions";
import { reset as resetPlan } from "./../../../actions/planActions";
import { reset as resetRole } from "./../../../actions/roleActions";
import { reset as resetAuth } from "./../../../../actions/feature/auth/authActions";
import { reset as resetFeeds } from "./../../../../actions/feature/feeds/feedsActions";
import { UserContext } from "./../../../../components/app/userContext";

import classnames from "classnames/bind";
import styles from "./../../../components/common/header/subHeader.scss";
const cx = classnames.bind(styles);

interface PageProps {
    selected: string;
    resetUsers: () => void;
    resetProfile: () => void;
    resetCompany: () => void;
    resetDepartment: () => void;
    resetDesignation: () => void;
    resetOffice: () => void;
    resetPlan: () => void;
    resetRole: () => void;
    resetAuth: () => void;
    resetFeeds: () => void;
}

const ProfileSubHeader: FC<PageProps> = ({
    selected,
    resetUsers,
    resetProfile,
    resetCompany,
    resetDepartment,
    resetDesignation,
    resetOffice,
    resetPlan,
    resetRole,
    resetAuth,
    resetFeeds,
}) => {
    const history = useHistory();
    const contextObj: any = useContext(UserContext);

    const signOut = () => {
        API.logout()
            .then(
                () => {},
                () => {}
            )
            .finally(() => {
                removeCookie();
                resetUsers();
                resetProfile();
                resetCompany();
                resetDepartment();
                resetDesignation();
                resetOffice();
                resetPlan();
                resetRole();
                resetAuth();
                resetFeeds();
                contextObj.socket.current?.close();
                history.push("/login");
            });
    };
    return (
        <div className={cx("subHeader")}>
            <div
                className={cx("topNav")}
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <Typography variant="h4" style={{ paddingLeft: "16px" }}>
                    Personal Information
                </Typography>
            </div>
            <div className={cx("content")}>
                <List component="nav" aria-label="main mailbox folders">
                    <Link to="/hrm/profile">
                        <ListItem button selected={selected === "profile"}>
                            <ListItemText primary="Personal Information" />
                        </ListItem>
                    </Link>
                    <Link to="/hrm/profile/change-password">
                        <ListItem button selected={selected === "password"}>
                            <ListItemText primary="Change Password" />
                        </ListItem>
                    </Link>
                    <ListItem button onClick={() => signOut()}>
                        <ListItemText primary="Sign out" />
                    </ListItem>
                </List>
            </div>
        </div>
    );
};

//export default ProfileSubHeader;

/* istanbul ignore next */
const mapDispatchToProps = (dispatch) => ({
    resetUsers: () => dispatch(resetUsers()),
    resetProfile: () => dispatch(resetProfile()),
    resetCompany: () => dispatch(resetCompany()),
    resetDepartment: () => dispatch(resetDepartment()),
    resetDesignation: () => dispatch(resetDesignation()),
    resetOffice: () => dispatch(resetOffice()),
    resetPlan: () => dispatch(resetPlan()),
    resetRole: () => dispatch(resetRole()),
    resetAuth: () => dispatch(resetAuth()),
    resetFeeds: () => dispatch(resetFeeds()),
});

export default connect(null, mapDispatchToProps)(ProfileSubHeader);
