import { buildGET, buildAuthPOST, buildDELETE, buildAuthPUT } from "./../../../../utils/api";

// APIs
const getDesignations = () => {
    return buildGET(`designation`);
};

const getDesignation = (id) => buildGET(`designation/${id}`);

const addDesignation = (payload) => buildAuthPOST("designation", payload);

const updateDesignation = (payload) => {
    const { id, ...rest } = payload;
    return buildAuthPUT(`designation/${id}`, rest);
};

const deleteDesignation = (id) => buildDELETE(`designation/${id}`);

// API export
export const API = {
    getDesignations,
    getDesignation,
    addDesignation,
    deleteDesignation,
    updateDesignation,
};
