import { fork, put, delay, takeLatest, select, all } from "redux-saga/effects";
import { API } from "./api";
import { getIn } from "../../../../utils/app-utils";
import callApi from "../../../../utils/callApi";

import {
    getCompany,
    getCompanyFailure,
    getCompanySuccess,
    CompanyActionTypes,
    updateCompanySuccess,
    updateCompanyFailure,
} from "../../../actions/companyActions";
import { isLoading, openSnackbar, closeSnackbar, toggleDrawer } from "../../../../actions/feature/app/appActions";

function* getCompanySaga() {
    try {
        yield put(isLoading(true));
        const [error, res] = yield* callApi(API.getCompany, { disableLoading: true }, {}, true);
        if (error || res.status === "ERROR") {
            yield put(getCompanyFailure({ status: "ERROR" }));
        } else {
            yield put(getCompanySuccess(res));
        }
        yield put(isLoading(false));
    } catch (error) {
        yield put(isLoading(false));
        yield put(getCompanyFailure({ status: "ERROR" }));
    }
}

function* updateCompanySaga(reqObject) {
    const { payload } = reqObject;
    try {
        yield put(isLoading(true));
        const [error, res] = yield* callApi(API.updateCompany, { disableLoading: true }, payload, true);
        if (error || res.status === "ERROR") {
            yield put(openSnackbar("Invalid error, please try again"));
            yield put(updateCompanyFailure({ status: "ERROR" }));
        } else {
            yield put(toggleDrawer(false));
            yield put(openSnackbar("Company updated successfully"));
            yield put(updateCompanySuccess(res.data));
        }

        yield put(isLoading(false));
        yield delay(3000);
        yield put(closeSnackbar());
    } catch (error) {
        yield put(isLoading(false));
        yield put(openSnackbar("Invalid error, please try again"));
        yield put(updateCompanyFailure({ status: "ERROR" }));
        yield delay(3000);
        yield put(closeSnackbar());
    }
}

function* watchCompany() {
    yield takeLatest(CompanyActionTypes.GET_COMPANY, getCompanySaga);
    yield takeLatest(CompanyActionTypes.UPDATE_COMPANY, updateCompanySaga);
}

const companySagas = [fork(watchCompany)];

export default companySagas;
