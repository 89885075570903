import React, { FC, useEffect } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { Typography } from "@material-ui/core";

import PersonalInformation from "./PersonalInformation";
import ProfileInformation from "./ProfileInformation";
import EmergencyContact from "./EmergencyContact";
import ProfileSubHeader from "./ProfileSubHeader";
import BodyContent from "./../../common/body/bodyContent";

import { getProfile } from "./../../../actions/profileActions";
import { getDepartments } from "./../../../actions/departmentActions";
import { getDesignations } from "./../../../actions/designationActions";
import { getOffices } from "./../../../actions/officeActions";
import {
    updateBasicInfoSuccess,
    updatePersonalInfoSuccess,
    updateContactsSuccess,
} from "../../../actions/profileActions";
import {
    isLoading,
    openSnackbar,
    closeSnackbar,
    openAlert,
    closeAlert,
} from "../../../../actions/feature/app/appActions";

import classnames from "classnames/bind";
import styles from "./Profile.scss";
const cx = classnames.bind(styles);

const HeaderContent = () => {
    return (
        <div className={cx("headerSection")}>
            <div className={cx("top")}>
                <div>
                    <Typography variant="h4">Profile</Typography>
                </div>
            </div>
        </div>
    );
};

interface PageProps {
    profile: {
        basicInfo: {
            id: "";
        };
        personalInfo: {};
        contacts: [];
    };
    departments: [];
    designations: [];
    offices: [];
    config: {};
    alert: {
        open: boolean;
        message: string;
        type: string;
    };
    closeAlert: () => void;
    getProfile: () => null;
    getDepartments: () => null;
    getDesignations: () => null;
    getOffices: () => null;
    updateBasicInfoSuccess: (payload: any) => null;
    updatePersonalInfoSuccess: (payload: any) => null;
    updateContactsSuccess: (payload: any) => null;
    openSnackbar: (payload: any) => null;
    closeSnackbar: () => null;
    openAlert: (payload: any) => null;
}

const Profile: FC<PageProps> = ({
    profile,
    getProfile,
    getDepartments,
    getDesignations,
    getOffices,
    departments,
    designations,
    offices,
    config,
    alert,
    closeAlert,
    updateBasicInfoSuccess,
    updatePersonalInfoSuccess,
    updateContactsSuccess,
    openSnackbar,
    closeSnackbar,
    openAlert,
}) => {
    useEffect(() => {
        getProfile();
        getDepartments();
        getDesignations();
        getOffices();
    }, []);

    return (
        <div className={cx("profileUserPage")}>
            <ProfileSubHeader selected={"profile"} />
            <BodyContent>
                <div className={"headerContainer"}>
                    <HeaderContent />
                </div>
                <div className="contentBody" style={{ height: "88vh" }}>
                    <ProfileInformation
                        profile={profile.basicInfo}
                        departments={departments}
                        designations={designations}
                        offices={offices}
                        config={config}
                        updateBasicInfoSuccess={updateBasicInfoSuccess}
                    />
                    {/* <BasicInformation
                        profile={profile.basicInfo}
                        departments={departments}
                        designations={designations}
                        offices={offices}
                        config={config}
                        alert={alert}
                        closeAlert={closeAlert}
                        openSnackbar={openSnackbar}
                        closeSnackbar={closeSnackbar}
                        updateBasicInfoSuccess={updateBasicInfoSuccess}
                        openAlert={openAlert}
                    /> */}
                    <PersonalInformation
                        profile={profile.personalInfo}
                        config={config}
                        alert={alert}
                        closeAlert={closeAlert}
                        openSnackbar={openSnackbar}
                        closeSnackbar={closeSnackbar}
                        updatePersonalInfoSuccess={updatePersonalInfoSuccess}
                        openAlert={openAlert}
                    />

                    <EmergencyContact
                        contacts={profile.contacts}
                        id={profile.basicInfo.id}
                        config={config}
                        updateContactsSuccess={updateContactsSuccess}
                        alert={alert}
                        closeAlert={closeAlert}
                        openSnackbar={openSnackbar}
                        closeSnackbar={closeSnackbar}
                        openAlert={openAlert}
                    />
                </div>
            </BodyContent>
        </div>
    );
};

/* istanbul ignore next */
const mapStateToProps = (state) => {
    return {
        profile: state.profile.user || {},
        departments: state.department.departments || [],
        designations: state.designation.designations || [],
        offices: state.office.offices || [],
        config: state.app.config || {},
        alert: state.app.alert,
    };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => ({
    getProfile: () => dispatch(getProfile()),
    getDepartments: () => dispatch(getDepartments()),
    getDesignations: () => dispatch(getDesignations()),
    getOffices: () => dispatch(getOffices()),
    closeAlert: () => dispatch(closeAlert()),
    openSnackbar: (message: string) => dispatch(openSnackbar(message)),
    closeSnackbar: () => dispatch(closeSnackbar()),
    updateBasicInfoSuccess: (payload: any) => dispatch(updateBasicInfoSuccess(payload)),
    updatePersonalInfoSuccess: (payload: any) => dispatch(updatePersonalInfoSuccess(payload)),
    updateContactsSuccess: (payload: any) => dispatch(updateContactsSuccess(payload)),
    openAlert: (payload: any) => dispatch(openAlert(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
