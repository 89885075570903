import { race, call, put } from "redux-saga/effects";
import { timeout } from "./utils";
// import {
//   addLoading,
//   removeLoading
// } from 'global/components/LoadingOverlay/ducks';
//import { clearData } from 'global/components/Login/ducks';
// import { push } from 'react-router-redux';

export default function*(api, options = { disableLoading: false, loadingText: false }, ...args) {
    if (!options.disableLoading) {
        // yield put(
        //   addLoading({ type: 'loading', loadingText: options.loadingText })
        // );
    }

    try {
        const [res, timed] = yield race([call(api, ...args), call(timeout, 3 * 60 * 1000)]);
        if (timed) {
            return [true, "Timeout"];
        }
        return [false, res];
    } catch (e) {
        // if (
        //   (e.response.status === 401 &&
        //     e.response.data.status === 'Unauthorized') ||
        //   (e.response.status === 403 && e.response.data.status === 'Forbidden')
        // ) {
        //   //yield put(clearData());
        // }
        //console.log("call api error", e);
        return [true, e];
    } finally {
        //if (!options.disableLoading) {
        //   yield put(removeLoading());
        //}
    }
}
