import moment from "moment";
import Cookies from "js-cookie";
import { format, setDate, format as formatDate, differenceInDays } from "date-fns";

const IS_LOCAL_ENV = process.env.REACT_APP_IS_LOCAL_ENV === "true" || false;

export const getDifferenceInDays = (date1: Date, date2: Date) => {
    return differenceInDays(new Date(date1), new Date(date2));
};

const mergeProperties = (source: {}, destination: {}) => {
    Object.keys(source).forEach((key: string) => {
        destination[key] = source[key];
    });
};

const stripSpaces = (str: string) => {
    if (!str) {
        return "";
    }
    return str.replace(/\s/g, "-").toLowerCase();
};

export const getTestId = (id?: string) => ({ id, "aria-label": id });

export const setCookie = (cname, cvalue, exdays) => {
    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
};

export const removeCookieValue = (cname) => {
    const cvalue = getCookie(cname);
    const expires = "expires=Thu, 01 Jan 1970 00:00:01 GMT";
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
};

// export const getCookie = (cname) => {
//     var name = cname + "=";
//     var decodedCookie = decodeURIComponent(document.cookie);
//     var ca = decodedCookie.split(";");
//     for (var i = 0; i < ca.length; i++) {
//         var c = ca[i];
//         while (c.charAt(0) == " ") {
//             c = c.substring(1);
//         }
//         if (c.indexOf(name) == 0) {
//             return c.substring(name.length, c.length);
//         }
//     }
//     return "";
// };

export const get = (k) => (m) => (m ? m[k] : undefined);

export const getIn = (...keys) => (m) => keys.reduce((m, k) => get(k)(m), m);

export const getComparator = (order, orderBy) => {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
};

export const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
};

export const stableSort = (array, comparator) => {
    if (!array?.length) return [];
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
};

export const getDepartmentOptions = (departments: []) => {
    if (departments && departments.length) {
        return departments.map((department: any) => {
            return {
                label: department.DepartmentName,
                value: department.id,
            };
        });
    } else {
        return [
            {
                label: "",
                value: "",
            },
        ];
    }
};

export const getDesignationOptions = (designations: []) => {
    if (designations && designations.length) {
        return designations.map((designation: any) => {
            return {
                label: designation.DesignationName,
                value: designation.id,
            };
        });
    } else {
        return [
            {
                label: "",
                value: "",
            },
        ];
    }
};

export const getOfficeOptions = (offices: []) => {
    if (offices && offices.length) {
        return offices.map((office: any) => {
            return {
                label: office.officeName,
                value: office.id,
            };
        });
    } else {
        return [
            {
                label: "",
                value: "",
            },
        ];
    }
};

export const getCountryName = (id: number, countries: any) => {
    if (id && countries) {
        const country = countries.find((c) => c.id === id);
        return country && country.id ? country.CountryName : "";
    }
    return "";
};

export const getCurrencyName = (id: number, currencies: any) => {
    if (id && currencies) {
        const currency = currencies.find((c) => c.id === id);
        return currency && currency.id ? currency.CurrencyCode : "";
    }
    return "";
};

export const getDateFormat = (id: number, dateformat: any) => {
    if (id && dateformat) {
        const obj = dateformat.find((item) => item.id === id);
        return obj && obj.id ? obj.DateFormatTitle : "";
    }
    return "";
};

export const getTimeFormat = (id: number, timeformat: any) => {
    if (id && timeformat) {
        const obj = timeformat.find((item) => item.id === id);
        return obj && obj.id ? obj.TimeFormatTitle : "";
    }
    return "";
};

export const getTimeZone = (id: number, timeZones: any) => {
    if (id && timeZones) {
        const obj = timeZones.find((item) => item.id === id);
        return obj && obj.id ? obj.TimezoneTitle : "";
    }
    return "";
};

export const toFormatDate = (date: string | null, format: string = "dd-MM-yyyy") => {
    try {
        if (!date) return "";
        return formatDate(new Date(date.split(" ")[0]), format);
    } catch (err) {
        return date;
    }
};

export const getCurrencySymbol = (currency: any) => {
    const objCurrency = {
        USD: "$",
    };
    return objCurrency[currency];
};

export const formatAmount = (amt: any) => {
    if (amt) {
        return Number.parseFloat(amt).toFixed(2);
    }
    return 0.0;
};

export const capsFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
};

export const nearestMinutes = (interval, someMoment) => {
    const roundedMinutes = Math.round(someMoment.clone().minute() / interval) * interval;
    return someMoment
        .clone()
        .minute(roundedMinutes)
        .second(0);
};

export const nearest15min = () => nearestMinutes(15, moment.utc());

export const removeCookie = () => {
    if (process.env.REACT_APP_ENV === "production") {
        Cookies.remove("ck_collabey", { domain: ".collabey.com" });
    } else {
        Cookies.remove("ck_collabey");
    }
};

export const setAuthToken = (value: string) => {
    if (process.env.REACT_APP_ENV === "production") {
        Cookies.set("ck_collabey", value, { secure: false, expires: 7, domain: ".collabey.com" });
    } else {
        Cookies.set("ck_collabey", value, { secure: false, expires: 7 });
    }
};

export const getAuthToken = () => {
    return Cookies.get("ck_collabey");
};

export const getCookie = (name: string) => {
    return Cookies.get(name);
};

export const getTimezoneOptions = (config = { timezone: [] }) => {
    const { timezone } = config;
    if (timezone && timezone.length) {
        return timezone.map((item: any) => {
            return {
                label: item.TimezoneTitle,
                value: item.id,
            };
        });
    }
    return [
        {
            value: "",
            label: "Select",
        },
    ];
};

export const getDomainName = () => {
    return window.location?.host?.split(".")[1] ? window.location.host.split(".")[0] : "";
};

export default {
    mergeProperties,
    stripSpaces,
    //setCookie,
    //getCookie,
    getIn,
    getComparator,
    descendingComparator,
    stableSort,
    getDepartmentOptions,
    getDesignationOptions,
    getOfficeOptions,
    getCountryName,
    getCurrencyName,
    toFormatDate,
    getDifferenceInDays,
    getCurrencySymbol,
    formatAmount,
    capsFirstLetter,
    nearestMinutes,
    nearest15min,
    setAuthToken,
    getAuthToken,
    getTimezoneOptions,
    getCookie,
    getDomainName,
};
