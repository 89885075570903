import React, { FC, useEffect, useState } from "react";
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { Typography, Tabs, Tab, Box, Button } from "@material-ui/core";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import Avatar from "./../../../../../components/common/avatar/Avatar";

import KanbanImage from "./../../../../../assets/images/kanban.svg";
import BodyContent from "./../../../common/body/bodyContent";

import {
    getWorkspaces,
    getWorkspace,
    deleteWorkspaceMember,
} from "./../../../../../actions/feature/workspaces/workspacesActions";
import CreateWorkspace from "./createWorkspace";

import {
    Workspace,
    WorkspaceResponse,
    WorkspaceMember,
    WorkspaceProject,
} from "./../../../../../utils/projectInterfaces";
import WorkspaceSettings from "./settings";
import WorkspaceMembers from "./members";
import WorkspaceProjects from "./projects";
import Project from "./../project/Project";

// import { getProfile } from "./../../../../actions/profileActions";
// import { getDepartments } from "./../../../actions/departmentActions";
// import { getDesignations } from "./../../../actions/designationActions";
// import { getOffices } from "./../../../actions/officeActions";

import WorkspacesSubHeader from "./WorkspacesSubHeader";
// import {
//     updateBasicInfoSuccess,
//     updatePersonalInfoSuccess,
//     updateContactsSuccess,
// } from "../../../actions/profileActions";
import {
    isLoading,
    openSnackbar,
    closeSnackbar,
    openAlert,
    closeAlert,
} from "../../../../../actions/feature/app/appActions";

// import AddBoard from "./AddBoard";

import classnames from "classnames/bind";
import styles from "./Workspaces.scss";
const cx = classnames.bind(styles);

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box className={cx("tabContent")}>{children}</Box>}
        </div>
    );
}
interface HeaderContentProps {
    value: any;
    setValue: (val: any) => void;
    selectedProject: any;
    selectedWorkspace: any;
}

const HeaderContent: FC<HeaderContentProps> = ({ value, setValue, selectedProject, selectedWorkspace }) => {
    const history = useHistory();
    const { workspaceId = "", projectId = "" } = useParams();

    const handleChange = (event, newValue) => {
        setValue(newValue);
        if (newValue === 0) {
            history.push(`/hrm/workspaces/${workspaceId}#projects`);
        } else if (newValue === 1) {
            history.push(`/hrm/workspaces/${workspaceId}#members`);
        } else if (newValue === 2) {
            history.push(`/hrm/workspaces/${workspaceId}#settings`);
        }
    };
    return (
        <div className={cx("headerSection")}>
            <div className={cx("top")}>
                <div className={cx("headerTitle")}>
                    {/* {selectedProject?.projectName ? (
                            <>{selectedProject?.projectName}</>
                        ) : (
                            <>{selectedWorkspace?.name ? <>{selectedWorkspace?.name}</> : <>Workpaces</>}</>
                        )} */}

                    <div className={cx("avatar")}>{selectedWorkspace?.name?.charAt(0)}</div>
                    <Typography variant="h4">{selectedWorkspace?.name}</Typography>
                </div>
            </div>
            {!projectId && workspaceId && (
                <Tabs value={value} onChange={handleChange} aria-label="workspaces">
                    <Tab label="Projects" />
                    <Tab label="Members" />
                    <Tab label="Settings" />
                </Tabs>
            )}
        </div>
    );
};

interface PageProps {
    user: any;
    workspaces: Workspace[];
    getWorkspaces: () => void;
    getWorkspace: (id: string) => void;
    selectedWorkspace: WorkspaceResponse;
    closeAlert: () => void;
    openSnackbar: (payload: any) => null;
    deleteWorkspaceMember: (payload: any) => void;
    closeSnackbar: () => null;
    openAlert: (payload: any) => null;
    alert: {
        open: boolean;
        message: string;
        type: string;
    };
    companyTotalUsers: number;
}

const Workspaces: FC<PageProps> = ({
    user,
    workspaces,
    getWorkspaces,
    getWorkspace,
    selectedWorkspace,
    alert,
    closeAlert,
    openSnackbar,
    deleteWorkspaceMember,
    closeSnackbar,
    openAlert,
    companyTotalUsers,
}) => {
    const history = useHistory();
    let location = useLocation();
    const { workspaceId = "", projectId = "" } = useParams();
    const [value, setValue] = useState(0);
    const [isCreateWorkspace, setIsCreateWorkspace] = useState(false);
    const [selectedProject, setSelectedProject] = useState<WorkspaceProject | undefined>();
    const [projectBackground, setProjectBackground] = useState({ background: "" });

    React.useEffect(() => {
        const project: WorkspaceProject | undefined = selectedWorkspace?.projects?.find(
            (project) => project.id == projectId
        );
        if (project) setSelectedProject(project);
        else setSelectedProject(undefined);
    }, [selectedWorkspace, projectId]);

    useEffect(() => {
        if (location.hash === "#projects") {
            setValue(0);
        } else if (location.hash === "#members") {
            setValue(1);
        } else if (location.hash === "#settings") {
            setValue(2);
        }
    }, [location.hash]);

    useEffect(() => {
        getWorkspaces();
    }, []);

    // useEffect(() => {
    //     if (!selectedWorkspace?.id && workspaces?.length > 0) {
    //         getWorkspace(workspaces[0].id);
    //     }
    // }, [workspaces, selectedWorkspace]);

    // useEffect(() => {
    //     if (!workspaceId && selectedWorkspace?.id) {
    //         history.push(`/hrm/workspaces/${selectedWorkspace.id}#projects`);
    //     }
    // }, [workspaces, selectedWorkspace]);

    // const handleClickWorkspace = (id: string) => {
    //     history.push(`/hrm/workspaces/${id}#projects`);
    // };

    useEffect(() => {
        if (workspaceId) {
            getWorkspace(workspaceId);
        }
    }, [workspaceId]);

    useEffect(() => {
        if (selectedProject?.background && workspaceId && projectId) {
            setProjectBackground({ background: selectedProject?.background });
        } else {
            setProjectBackground({ background: "" });
        }
    }, [selectedProject, workspaceId, projectId]);

    return (
        <div className={cx("workspacesPage")}>
            <div className={cx("emptySubHeader")}></div>
            <BodyContent>
                {workspaceId && (
                    <div className={"headerContainer"}>
                        <HeaderContent
                            value={value}
                            setValue={setValue}
                            selectedWorkspace={selectedWorkspace}
                            selectedProject={selectedProject}
                        />
                    </div>
                )}

                <div className="contentBody" style={{ height: "94vh", ...projectBackground }}>
                    {/* {projectId && workspaces?.length && (
                        <div className="projectContainer">
                            <Project />
                        </div>
                    )} */}
                    {!projectId && workspaceId && (
                        <div className="projectContainer">
                            <TabPanel value={value} index={0} className={cx("tabPanelContainer")}>
                                <WorkspaceProjects selectedWorkspace={selectedWorkspace} />
                            </TabPanel>
                            <TabPanel value={value} index={1} className={cx("tabPanelContainer")}>
                                <WorkspaceMembers
                                    getWorkspace={getWorkspace}
                                    deleteWorkspaceMember={deleteWorkspaceMember}
                                    user={user}
                                    selectedWorkspace={selectedWorkspace}
                                    companyTotalUsers={companyTotalUsers}
                                />
                            </TabPanel>
                            <TabPanel value={value} index={2} className={cx("tabPanelContainer")}>
                                <WorkspaceSettings
                                    selectedWorkspace={selectedWorkspace}
                                    user={user}
                                    getWorkspace={getWorkspace}
                                    getWorkspaces={getWorkspaces}
                                />
                            </TabPanel>
                        </div>
                    )}
                </div>
            </BodyContent>
        </div>
    );
};

/* istanbul ignore next */
const mapStateToProps = (state) => {
    return {
        user: state.user.profile.user,
        companyTotalUsers: state.user.profile?.company?.TotalUsers || 0,
        workspaces: state.workspaces.workspaces || [],
        selectedWorkspace: state.workspaces.selectedWorkspace || [],
        profile: state.profile.user || {},
        config: state.app.config || {},
        alert: state.app.alert,
    };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => ({
    getWorkspaces: () => dispatch(getWorkspaces()),
    getWorkspace: (id: string) => dispatch(getWorkspace(id)),
    closeAlert: () => dispatch(closeAlert()),
    openSnackbar: (message: string) => dispatch(openSnackbar(message)),
    closeSnackbar: () => dispatch(closeSnackbar()),
    openAlert: (payload: any) => dispatch(openAlert(payload)),
    deleteWorkspaceMember: (payload: any) => dispatch(deleteWorkspaceMember(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Workspaces);
