import React, { FC, useState } from "react";
import { useHistory } from "react-router-dom";

import { IconButton, Menu } from "@material-ui/core";
import MoreHorizOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";

import withProjectSocket from "./../../../../hoc/ProjectsSocket";
import { WorkspaceResponse, WorkspaceProject } from "./../../../../../utils/projectInterfaces";
import CreateProject from "./createProject";

import classnames from "classnames/bind";
import styles from "./Workspaces.scss";
const cx = classnames.bind(styles);

interface PageProps {
    selectedWorkspace: WorkspaceResponse;
    socketProject: WebSocket | null;
    authToken: string;
}

const WorkspaceProjects: FC<PageProps> = ({ selectedWorkspace, socketProject, authToken }) => {
    const history = useHistory();
    const { projects } = selectedWorkspace || { projects: [] };
    const [isCreateProject, setIsCreateProject] = useState(false);
    const [isEditProject, setIsEditProject] = useState(false);
    const [selectedProjectId, setSelectedProjectId] = useState("");
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedProject, setSelectedProject] = useState({});

    const open = Boolean(anchorEl);

    React.useMemo(() => {
        setIsCreateProject(false);
    }, [projects]);

    React.useMemo(() => {
        const project = projects?.find((item) => item.id == selectedProjectId);
        if (project) setSelectedProject(project);
    }, [projects, selectedProjectId]);

    const redirectToProjectDetails = (url: string) => {
        history.push(url);
    };

    const handleClick = (event, projectId) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setSelectedProjectId(projectId);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setSelectedProjectId("");
    };

    const handleDeleteProject = () => {
        if (socketProject) {
            socketProject?.send(
                JSON.stringify({
                    action: "deleteProject",
                    body: {
                        projectId: selectedProjectId,
                    },
                    token: authToken,
                })
            );
            handleClose();
        }
    };

    const handleViewDetails = () => {
        redirectToProjectDetails(`/hrm/workspaces/${selectedWorkspace?.id}/${selectedProjectId}`);
    };

    const handleEdit = () => {
        setIsEditProject(true);
        handleClose();
    };

    return (
        <>
            <div className={cx("tileSection", "newItem")} onClick={() => setIsCreateProject(true)}>
                <div className={cx("name")}>Create new project</div>
                {(isCreateProject || isEditProject) && (
                    <CreateProject
                        setIsEditProject={setIsEditProject}
                        isEditProject={isEditProject}
                        selectedProjectId={selectedProjectId}
                        selectedProject={selectedProject}
                        setIsCreateProject={setIsCreateProject}
                    />
                )}
            </div>
            {projects?.length > 0 &&
                projects.map((project: WorkspaceProject, index: number) => (
                    <div
                        key={index}
                        className={cx("tileSection")}
                        style={{ background: project.background }}
                        onClick={() =>
                            //redirectToProjectDetails(selectedWorkspace?.id, project?.id);
                            redirectToProjectDetails(`/hrm/workspaces/${selectedWorkspace?.id}/${project.id}`)
                        }
                    >
                        <div className={cx("name")}>{project.projectName}</div>
                        <IconButton
                            aria-label="more"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            onClick={(e) => handleClick(e, project.id)}
                        >
                            <MoreHorizOutlinedIcon />
                        </IconButton>
                    </div>
                ))}
            <Menu id="long-menu" anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
                <ul>
                    <li onClick={handleViewDetails}>View Project Details</li>
                    <li onClick={handleEdit}>Edit Project</li>
                    <li onClick={handleDeleteProject}>Close Project</li>
                </ul>
            </Menu>
            {/* <AddBoard history={history} /> */}
        </>
    );
};

export default withProjectSocket(WorkspaceProjects);
