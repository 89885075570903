import React from 'react';

import classnames from "classnames/bind";
import styles from "./layout.scss";
const cx = classnames.bind(styles);

const Layout = (props) => {
    return (
        <div className={cx("layout")}>
            {props.children}
        </div>
    );
}

export default Layout;
