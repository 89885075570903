import React, { FC, useState, useEffect } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { Button, Link, Typography, CircularProgress, Box, Paper } from "@material-ui/core";
import queryString from "query-string";
import { Link as RouterLink } from "react-router-dom";
import Alert from "./../../common/alert/Alert";
import { resetPassword } from "../../../actions/feature/auth/authActions";
import InputTextField from "../../common/text-field/TextField";
import Logo from "./../../../assets/images/logo2.png";

import classnames from "classnames/bind";
import styles from "./ForgotPassword.scss";
import colors from "colors";
const cx = classnames.bind(styles);

const useStyles = makeStyles((theme: Theme) => ({
    title: {
        textAlign: "center",
        marginBottom: "40px",
    },
}));

interface PageProps {
    apiResponse: any;
    isLoading: boolean;
    onResetPasswrod: (payload: any) => void;
}

const ActivateAccount: FC<PageProps> = ({ apiResponse, isLoading, onResetPasswrod }) => {
    const classes = useStyles();

    const [token, setToken] = useState<string>("");
    const [passwordSameError, setPasswordSameError] = useState<boolean>(false);
    const [formValues, setFormValues] = useState<any>({
        password: "",
        confirmPassword: "",
    });
    const [formValidations, setFormValidations] = useState<any>({
        password: false,
        confirmPassword: false,
    });

    const inputChangeHandler = (evt: any) => {
        setFormValues({ ...formValues, [evt.target.name]: evt.target.value });
    };

    const validateFormValues = () => {
        let isValid = true;
        let tempFormValidations = {};
        for (const [key, value] of Object.entries(formValues)) {
            if (!value) {
                isValid = false;
                tempFormValidations[`${key}`] = true;
            } else {
                tempFormValidations[`${key}`] = false;
            }
        }
        setFormValidations({ ...formValidations, ...tempFormValidations });
        return isValid;
    };

    const submitForm = () => {
        if (isLoading) {
            return false;
        }
        if (validateFormValues()) {
            if (formValues.password !== formValues.confirmPassword) {
                setPasswordSameError(true);
                return false;
            } else {
                // ""
                setPasswordSameError(false);
            }

            setTimeout(() => {
                onResetPasswrod({ password: formValues.password, confirmPassword: formValues.confirmPassword, token });
            }, 100);
        }
    };

    useEffect(() => {
        let params = queryString.parse(window.location.search);
        setToken(params.token);
    }, []);

    return (
        <div className={cx("passwordWrapper")}>
            <img src={Logo} className={cx("logopage")} />
            <Paper className={cx("formWrapper")} elevation={1}>
                <Typography variant="h1" className={classes.title}>
                    Activate your account
                </Typography>
                {passwordSameError && (
                    <div className={cx("error")}>
                        <Alert
                            onClose={() => {}}
                            message={`New password and confirm password should be same`}
                            type={"error"}
                        ></Alert>
                    </div>
                )}
                {apiResponse && apiResponse.status === "SUCCESS" && (
                    <div className={cx("success")}>
                        <Alert
                            onClose={() => {}}
                            message={`Account has been activated successfully`}
                            type={"success"}
                        ></Alert>
                    </div>
                )}
                {apiResponse && apiResponse.status === "ERROR" && apiResponse.description === "INVALID_TOKEN" && (
                    <div className={cx("error")}>
                        <Alert onClose={() => {}} message={`Invalid token, please try again`} type={"error"}></Alert>
                    </div>
                )}
                {apiResponse && apiResponse.status === "ERROR" && apiResponse.description !== "INVALID_TOKEN" && (
                    <div className={cx("error")}>
                        <Alert
                            onClose={() => {}}
                            message={`Unable to change password, please try again`}
                            type={"error"}
                        ></Alert>
                    </div>
                )}
                <div>
                    {apiResponse && apiResponse.status !== "SUCCESS" && (
                        <>
                            <Box className={cx("boxMargin")}>
                                <InputTextField
                                    error={formValidations.password}
                                    errorText={"This field is required"}
                                    name="password"
                                    required={true}
                                    id="password"
                                    type={"password"}
                                    label="New password"
                                    value={formValues.password}
                                    onChange={inputChangeHandler}
                                />
                            </Box>

                            <Box className={cx("boxMargin")}>
                                <InputTextField
                                    error={formValidations.confirmPassword}
                                    errorText={"This field is required"}
                                    name="confirmPassword"
                                    required={true}
                                    id="confirmPassword"
                                    type={"password"}
                                    label="Confirm password"
                                    value={formValues.confirmPassword}
                                    onChange={inputChangeHandler}
                                />
                            </Box>

                            <Box>
                                <Button
                                    type="submit"
                                    onClick={submitForm}
                                    style={{ margin: "16px 0 8px" }}
                                    endIcon={
                                        isLoading ? (
                                            <CircularProgress color={colors.darkPrimary} size="20px" />
                                        ) : (
                                            <div style={{ width: "20px" }} />
                                        )
                                    }
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                >
                                    Set your password
                                </Button>
                            </Box>
                        </>
                    )}
                    <Box className={cx("link")}>
                        <Link component={RouterLink} to="/login" variant="body2">
                            {"Back to login"}
                        </Link>
                    </Box>
                </div>
            </Paper>
        </div>
    );
};

/* istanbul ignore next */
const mapStateToProps = (state: any) => {
    return {
        apiResponse: state.auth.resetPassword.apiResponse,
        isLoading: state.app.isLoading,
    };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: any) => ({
    onResetPasswrod: (payload: any) => dispatch(resetPassword(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ActivateAccount);
