import React, { FC, useState, useEffect } from "react";
import { Card, Box, Typography, Button } from "@material-ui/core";
import Alert from "./../../../../../components/common/alert/Alert";
import CloseAccount from "./CloseAccount";
import { useIsAdministratorHook } from "../../../../../utils/userAccess";
import { API } from "../../../../sagas/feature/company/api";
import { formatBytes } from "./../../../../../utils/utils";

import classnames from "classnames/bind";
import styles from "./../Styles.scss";
import colors from "colors";
const cx = classnames.bind(styles);

interface CurrentPlanFeaturesProps {
    selectedPlan: any;
    company: any;
    fileStorage: any;
}

const CurrentPlanFeatures: FC<CurrentPlanFeaturesProps> = ({ selectedPlan, company, fileStorage }) => {
    const [planStorage, setPlanStorage] = useState("0 Bytes");

    const getFileStorage = () => {
        const { fileStorageUsed } = fileStorage || {};
        return formatBytes(fileStorageUsed) || 0;
    };
    return (
        <Box style={{ marginTop: 32 }}>
            {/* <Box style={{ marginBottom: 24 }}>
                <Typography color="textSecondary" gutterBottom>
                    Current plan Users
                </Typography>
                <Card className={cx("planContainer")} variant="outlined">
                    <div className={cx("planTable")}>
                        <div className={cx("featureBody")}>
                            <ul>
                                <li>
                                    <span>
                                        <Typography variant={"body2"}>Plan Users</Typography>
                                    </span>{" "}
                                    <span>{company?.TotalPlanUsers}</span>
                                </li>
                                <li style={{ marginTop: 8 }}>
                                    <span>
                                        <Typography variant={"body2"}>Additional Users</Typography>
                                    </span>{" "}
                                    
                                </li>
                            </ul>
                        </div>
                    </div>
                </Card>
            </Box> */}

            <Typography color="textSecondary" gutterBottom>
                Current plan features
            </Typography>
            <div className={cx("fileStorageLimit")}>
                <span>File storage:</span>
                <span className={cx("limitValue")}>
                    <span className={cx("bold")}>{getFileStorage()}</span> / {"1 GB"}
                </span>
            </div>
            <Card className={cx("planContainer")} variant="outlined">
                {selectedPlan && selectedPlan.category?.length && (
                    <>
                        {selectedPlan.category.map((item, index) => (
                            <div
                                key={item.id}
                                className={cx("planTable", {
                                    noBottomBorder: index === 0,
                                })}
                            >
                                <div className={cx("featureHead")}>
                                    <Typography variant={"body2"}>{item.categoryName}</Typography>
                                </div>
                                <div className={cx("featureBody")}>
                                    {item.features.map((feature) => (
                                        <ul className={cx("feature")} key={feature.id}>
                                            <li>
                                                <span>
                                                    <Typography variant={"body2"}>{feature.planFeatureName}</Typography>
                                                </span>{" "}
                                                <span>
                                                    {feature.planFeatureRecordValue === "Yes" ? (
                                                        <i className="fas fa-check-circle icon primary"></i>
                                                    ) : (
                                                        <>
                                                            {feature.planFeatureRecordValue === "No" ? (
                                                                <i className="fas fa-times-circle icon"></i>
                                                            ) : (
                                                                <>
                                                                    {feature.planFeatureRecordValue}
                                                                    {feature.unit}
                                                                </>
                                                            )}
                                                        </>
                                                    )}
                                                </span>
                                            </li>
                                        </ul>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </>
                )}
            </Card>
        </Box>
    );
};

interface PageProps {
    plans: [];
    company: any;
    companyId: number | string;
    userPlans: any;
    alert: {
        open: boolean;
        message: string;
        type: string;
    };
    setValue: (value: number) => void;
    openAlert: (payload: any) => null;
    closeAlert: () => null;
}

const PlanDetails: FC<PageProps> = ({
    plans,
    company,
    companyId,
    userPlans,
    alert,
    setValue,
    openAlert,
    closeAlert,
}) => {
    const isAdministratorAccess = useIsAdministratorHook();
    const [activeUserPlan, setActiveUserPlan] = useState<any>({});
    const [selectedPlan, setSelectedPlan] = useState<any>({});
    const [fileStorage, setFileStorage] = useState<any>({});

    useEffect(() => {
        const plan = userPlans.find(
            (item) => item.UserCompanyPlanStatus === "active" || item.UserCompanyPlanStatus === "expired"
        );
        if (plan && plan.id) {
            setActiveUserPlan(plan);
            const selPlan = plans.find((item: any) => item.id === plan.fkPlanID);
            setSelectedPlan(selPlan);
        }
    }, [userPlans]);

    // get file storage
    useEffect(() => {
        API.getCompanyFileStorage().then(
            (res) => {
                const { status, data } = res;
                if (status === "SUCCESS") {
                    console.log("res", res);
                    setFileStorage(data);
                }
            },
            (res) => {
                //
            }
        );
    }, []);

    if (!isAdministratorAccess) {
        return <div></div>;
    }
    return (
        <div className={cx("pageOuter")}>
            {alert.open && alert.message && (
                <Alert onClose={() => {}} message={alert.message} type={alert.type}></Alert>
            )}
            <Box display={"flex"} flexDirection={"column"} width={"100%"}>
                {/* <Typography color="textSecondary" gutterBottom>
                    {userPlans?.length === 1 && userPlans[0]?.UserCompanyPlanStatus === "expired" ? (
                        <>Current Plan (Expired)</>
                    ) : (
                        <>Current Plan</>
                    )}
                </Typography> */}
                {userPlans?.length > 0 && (!activeUserPlan || !activeUserPlan?.id) && (
                    <Card className={cx("plan")} variant="outlined">
                        <Box display={"flex"} flexDirection={"row"} justifyContent={"space-between"}>
                            {userPlans?.length > 0 && (!activeUserPlan || !activeUserPlan?.id) ? (
                                <div>
                                    <Typography color="textSecondary" variant="h4">
                                        Your trial with collabey.com has ended!
                                    </Typography>
                                    <Typography color="textSecondary" variant="body2">
                                        Now it's the time to choose what you want to do next:
                                    </Typography>
                                    <Button
                                        style={{ marginTop: 24 }}
                                        color={"primary"}
                                        variant={"contained"}
                                        onClick={() => setValue(1)}
                                    >
                                        Upgrade
                                    </Button>
                                </div>
                            ) : (
                                <div>
                                    {/* <Typography color="textSecondary" variant="h3">
                                    {activeUserPlan?.UserCompanyPlanDescription}
                                </Typography>
                                <Button
                                    style={{ marginTop: 24 }}
                                    color={"primary"}
                                    variant={"contained"}
                                    onClick={() => setValue(1)}
                                >
                                    Renew
                                </Button> */}
                                </div>
                            )}

                            {activeUserPlan?.IsTrial === "Yes" && (
                                <Box borderLeft={`1px solid grey`} paddingLeft={"16px"}>
                                    {activeUserPlan?.triaDaysLeft > 0 ? (
                                        <Typography color="textSecondary" variant="h4">
                                            Trial days left
                                        </Typography>
                                    ) : (
                                        <Typography color="textSecondary" variant="h4">
                                            Trial ends today
                                        </Typography>
                                    )}
                                    {activeUserPlan?.triaDaysLeft > 0 && (
                                        <Typography color="textSecondary" variant="h3">
                                            {activeUserPlan?.triaDaysLeft}
                                        </Typography>
                                    )}
                                </Box>
                            )}
                        </Box>
                    </Card>
                )}
                {selectedPlan && selectedPlan.category?.length && (
                    <CurrentPlanFeatures fileStorage={fileStorage} selectedPlan={selectedPlan} company={company} />
                )}
                {/* <Typography color="textSecondary" variant="h3">
                        Contact support
                    </Typography>
                    <Typography color="textSecondary" variant="body2">
                        Our customer success team will get back to you within record time
                    </Typography>
                    <div>
                        <Button style={{ marginTop: 24 }} color={"primary"} variant={"contained"}>
                            Contact Support
                        </Button>
                    </div> */}
                {isAdministratorAccess && <CloseAccount companyId={companyId} />}
            </Box>
        </div>
    );
};

export default PlanDetails;
