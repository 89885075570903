import React, { ReactNode } from "react";
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";
import WarningOutlinedIcon from "@material-ui/icons/WarningOutlined";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import classnames from "classnames/bind";
import styles from "./alert.scss";
import colors from "colors";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";

const cx = classnames.bind(styles);

const getType = (type: any) => {
    switch (type) {
        case "error":
            return <ErrorOutlineOutlinedIcon color="error" />;
        case "success":
            return <CheckCircleOutlineOutlinedIcon />;
        case "expire":
            return <WarningOutlinedIcon />;
        case "info":
            return <InfoOutlinedIcon />;
        default:
            return <WarningOutlinedIcon />;
    }
};

interface AlertProps {
    onClose: () => void;
    message: string | ReactNode;
    type: any;
    isCloseVisible?: boolean;
}

function Alert(props: AlertProps) {
    const { onClose, message, type, isCloseVisible = false } = props;
    return (
        <div className={cx("alert", type)}>
            <div className={cx("icon")}>{getType(type)}</div>
            <div className={cx("message")}>
                <div>{message}</div>
                {isCloseVisible && (
                    <div className={cx("close")} onClick={onClose}>
                        <CloseOutlinedIcon fontSize="large" />
                    </div>
                )}
            </div>
        </div>
    );
}

export default Alert;
