import axios from "axios";
import qs from "qs";
import { getAuthToken } from "./../utils/app-utils";
import { store } from "./../reducers/store";
import { updateApiResponse } from "./../actions/feature/app/appActions";

// Constants
//const AUTH_URL = process.env.AUTH_URL;

// declare a response interceptor
axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error?.response?.status === 401) {
            store.dispatch(updateApiResponse(401));
        }
        // handle the response error
        return Promise.reject(error);
    }
);

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:7001/api/v1";
// window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1"
//     ? "http://localhost:7001/api/v1"
//     : "https://api.collabey.com/v1";

// Helper
export const buildPOST = async (api, body = {}) => {
    const url = `${API_BASE_URL}/${api}`;
    const data = await axios.post(url, qs.stringify(body));
    return data.data;
};

export const buildAuthPOST = async (api, body = {}) => {
    const token = getAuthToken();
    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            //"X-CSRF-TOKEN": getCookie("collabeyapi_session"),
        },
        //withCredentials: process.env.REACT_APP_ENV === "production" ? true : false,
    };
    //data: { clientUUID: getClientUUID(body), ...body },
    const data = await axios({
        url: `${API_BASE_URL}/${api}`,
        method: "post",
        data: { ...body },
        ...config,
    });
    return data.data;
};

export const buildAuthPUT = async (api, body = {}) => {
    const token = getAuthToken();
    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            //"X-CSRF-TOKEN": getCookie("collabeyapi_session"),
        },
        //withCredentials: true,
    };
    const data = await axios({
        url: `${API_BASE_URL}/${api}`,
        method: "put",
        data: { ...body },
        ...config,
    });
    return data.data;
};

export const buildGET = async (api) => {
    const token = getAuthToken();
    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            //"X-CSRF-TOKEN": getCookie("collabeyapi_session"),
        },
        //withCredentials: process.env.REACT_APP_ENV === "production" ? true : false,
    };
    const data = await axios({
        url: `${API_BASE_URL}/${api}`,
        ...config,
        method: "get",
    });
    return data.data;
};

export const buildDELETE = async (api) => {
    const token = getAuthToken();
    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    };

    const data = await axios({
        url: `${API_BASE_URL}/${api}`,
        ...config,
        method: "delete",
    });
    return data.data;
};
