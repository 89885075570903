import { all } from "redux-saga/effects";

import authSaga from "./feature/auth/authSaga";
import appSagas from "./feature/app/appSaga";
import feedsSaga from "./feature/feeds/feedsSaga";
import roomsSaga from "./feature/rooms/roomsSaga";
import workspacesSaga from "./feature/workspaces/workspacesSaga";
import filesSagas from "./feature/files/filesSaga";

export default function* rootSaga() {
    yield all([...authSaga, ...appSagas, ...feedsSaga, ...roomsSaga, ...workspacesSaga, ...filesSagas]);
}
