const Config = {
    country: [
        {
            id: "1",
            CountryName: "Afghanistan",
            CountryCode: "AFG",
        },
        {
            id: "2",
            CountryName: "Netherlands",
            CountryCode: "NLD",
        },
        {
            id: "3",
            CountryName: "Netherlands Antilles",
            CountryCode: "ANT",
        },
        {
            id: "4",
            CountryName: "Albania",
            CountryCode: "ALB",
        },
        {
            id: "5",
            CountryName: "Algeria",
            CountryCode: "DZA",
        },
        {
            id: "6",
            CountryName: "American Samoa",
            CountryCode: "ASM",
        },
        {
            id: "7",
            CountryName: "Andorra",
            CountryCode: "AND",
        },
        {
            id: "8",
            CountryName: "Angola",
            CountryCode: "AGO",
        },
        {
            id: "9",
            CountryName: "Anguilla",
            CountryCode: "AIA",
        },
        {
            id: "10",
            CountryName: "Antigua and Barbuda",
            CountryCode: "ATG",
        },
        {
            id: "11",
            CountryName: "United Arab Emirates",
            CountryCode: "ARE",
        },
        {
            id: "12",
            CountryName: "Argentina",
            CountryCode: "ARG",
        },
        {
            id: "13",
            CountryName: "Armenia",
            CountryCode: "ARM",
        },
        {
            id: "14",
            CountryName: "Aruba",
            CountryCode: "ABW",
        },
        {
            id: "15",
            CountryName: "Australia",
            CountryCode: "AUS",
        },
        {
            id: "16",
            CountryName: "Azerbaijan",
            CountryCode: "AZE",
        },
        {
            id: "17",
            CountryName: "Bahamas",
            CountryCode: "BHS",
        },
        {
            id: "18",
            CountryName: "Bahrain",
            CountryCode: "BHR",
        },
        {
            id: "19",
            CountryName: "Bangladesh",
            CountryCode: "BGD",
        },
        {
            id: "20",
            CountryName: "Barbados",
            CountryCode: "BRB",
        },
        {
            id: "21",
            CountryName: "Belgium",
            CountryCode: "BEL",
        },
        {
            id: "22",
            CountryName: "Belize",
            CountryCode: "BLZ",
        },
        {
            id: "23",
            CountryName: "Benin",
            CountryCode: "BEN",
        },
        {
            id: "24",
            CountryName: "Bermuda",
            CountryCode: "BMU",
        },
        {
            id: "25",
            CountryName: "Bhutan",
            CountryCode: "BTN",
        },
        {
            id: "26",
            CountryName: "Bolivia",
            CountryCode: "BOL",
        },
        {
            id: "27",
            CountryName: "Bosnia and Herzegovina",
            CountryCode: "BIH",
        },
        {
            id: "28",
            CountryName: "Botswana",
            CountryCode: "BWA",
        },
        {
            id: "29",
            CountryName: "Brazil",
            CountryCode: "BRA",
        },
        {
            id: "30",
            CountryName: "United Kingdom",
            CountryCode: "GBR",
        },
        {
            id: "31",
            CountryName: "Virgin Islands, British",
            CountryCode: "VGB",
        },
        {
            id: "32",
            CountryName: "Brunei",
            CountryCode: "BRN",
        },
        {
            id: "33",
            CountryName: "Bulgaria",
            CountryCode: "BGR",
        },
        {
            id: "34",
            CountryName: "Burkina Faso",
            CountryCode: "BFA",
        },
        {
            id: "35",
            CountryName: "Burundi",
            CountryCode: "BDI",
        },
        {
            id: "36",
            CountryName: "Cayman Islands",
            CountryCode: "CYM",
        },
        {
            id: "37",
            CountryName: "Chile",
            CountryCode: "CHL",
        },
        {
            id: "38",
            CountryName: "Cook Islands",
            CountryCode: "COK",
        },
        {
            id: "39",
            CountryName: "Costa Rica",
            CountryCode: "CRI",
        },
        {
            id: "40",
            CountryName: "Djibouti",
            CountryCode: "DJI",
        },
        {
            id: "41",
            CountryName: "Dominica",
            CountryCode: "DMA",
        },
        {
            id: "42",
            CountryName: "Dominican Republic",
            CountryCode: "DOM",
        },
        {
            id: "43",
            CountryName: "Ecuador",
            CountryCode: "ECU",
        },
        {
            id: "44",
            CountryName: "Egypt",
            CountryCode: "EGY",
        },
        {
            id: "45",
            CountryName: "El Salvador",
            CountryCode: "SLV",
        },
        {
            id: "46",
            CountryName: "Eritrea",
            CountryCode: "ERI",
        },
        {
            id: "47",
            CountryName: "Spain",
            CountryCode: "ESP",
        },
        {
            id: "48",
            CountryName: "South Africa",
            CountryCode: "ZAF",
        },
        {
            id: "49",
            CountryName: "Ethiopia",
            CountryCode: "ETH",
        },
        {
            id: "50",
            CountryName: "Falkland Islands",
            CountryCode: "FLK",
        },
        {
            id: "51",
            CountryName: "Fiji Islands",
            CountryCode: "FJI",
        },
        {
            id: "52",
            CountryName: "Philippines",
            CountryCode: "PHL",
        },
        {
            id: "53",
            CountryName: "Faroe Islands",
            CountryCode: "FRO",
        },
        {
            id: "54",
            CountryName: "Gabon",
            CountryCode: "GAB",
        },
        {
            id: "55",
            CountryName: "Gambia",
            CountryCode: "GMB",
        },
        {
            id: "56",
            CountryName: "Georgia",
            CountryCode: "GEO",
        },
        {
            id: "57",
            CountryName: "Ghana",
            CountryCode: "GHA",
        },
        {
            id: "58",
            CountryName: "Gibraltar",
            CountryCode: "GIB",
        },
        {
            id: "59",
            CountryName: "Grenada",
            CountryCode: "GRD",
        },
        {
            id: "60",
            CountryName: "Greenland",
            CountryCode: "GRL",
        },
        {
            id: "61",
            CountryName: "Guadeloupe",
            CountryCode: "GLP",
        },
        {
            id: "62",
            CountryName: "Guam",
            CountryCode: "GUM",
        },
        {
            id: "63",
            CountryName: "Guatemala",
            CountryCode: "GTM",
        },
        {
            id: "64",
            CountryName: "Guinea",
            CountryCode: "GIN",
        },
        {
            id: "65",
            CountryName: "Guinea-Bissau",
            CountryCode: "GNB",
        },
        {
            id: "66",
            CountryName: "Guyana",
            CountryCode: "GUY",
        },
        {
            id: "67",
            CountryName: "Haiti",
            CountryCode: "HTI",
        },
        {
            id: "68",
            CountryName: "Honduras",
            CountryCode: "HND",
        },
        {
            id: "69",
            CountryName: "Hong Kong",
            CountryCode: "HKG",
        },
        {
            id: "70",
            CountryName: "Svalbard and Jan Mayen",
            CountryCode: "SJM",
        },
        {
            id: "71",
            CountryName: "Indonesia",
            CountryCode: "IDN",
        },
        {
            id: "72",
            CountryName: "India",
            CountryCode: "IND",
        },
        {
            id: "73",
            CountryName: "Iraq",
            CountryCode: "IRQ",
        },
        {
            id: "74",
            CountryName: "Ireland",
            CountryCode: "IRL",
        },
        {
            id: "75",
            CountryName: "Iceland",
            CountryCode: "ISL",
        },
        {
            id: "76",
            CountryName: "Israel",
            CountryCode: "ISR",
        },
        {
            id: "77",
            CountryName: "Italy",
            CountryCode: "ITA",
        },
        {
            id: "78",
            CountryName: "East Timor",
            CountryCode: "TMP",
        },
        {
            id: "79",
            CountryName: "Austria",
            CountryCode: "AUT",
        },
        {
            id: "80",
            CountryName: "Jamaica",
            CountryCode: "JAM",
        },
        {
            id: "81",
            CountryName: "Japan",
            CountryCode: "JPN",
        },
        {
            id: "82",
            CountryName: "Yemen",
            CountryCode: "YEM",
        },
        {
            id: "83",
            CountryName: "Jordan",
            CountryCode: "JOR",
        },
        {
            id: "84",
            CountryName: "Christmas Island",
            CountryCode: "CXR",
        },
        {
            id: "85",
            CountryName: "Yugoslavia (Former)",
            CountryCode: "YUG",
        },
        {
            id: "86",
            CountryName: "Cambodia",
            CountryCode: "KHM",
        },
        {
            id: "87",
            CountryName: "Cameroon",
            CountryCode: "CMR",
        },
        {
            id: "88",
            CountryName: "Canada",
            CountryCode: "CAN",
        },
        {
            id: "89",
            CountryName: "Cape Verde",
            CountryCode: "CPV",
        },
        {
            id: "90",
            CountryName: "Kazakstan",
            CountryCode: "KAZ",
        },
        {
            id: "91",
            CountryName: "Kenya",
            CountryCode: "KEN",
        },
        {
            id: "92",
            CountryName: "Central African Republic",
            CountryCode: "CAF",
        },
        {
            id: "93",
            CountryName: "China",
            CountryCode: "CHN",
        },
        {
            id: "94",
            CountryName: "Kyrgyzstan",
            CountryCode: "KGZ",
        },
        {
            id: "95",
            CountryName: "Kiribati",
            CountryCode: "KIR",
        },
        {
            id: "96",
            CountryName: "Colombia",
            CountryCode: "COL",
        },
        {
            id: "97",
            CountryName: "Comoros",
            CountryCode: "COM",
        },
        {
            id: "98",
            CountryName: "Congo",
            CountryCode: "COG",
        },
        {
            id: "99",
            CountryName: "Congo, The Democratic Republic of the",
            CountryCode: "COD",
        },
        {
            id: "100",
            CountryName: "Cocos (Keeling) Islands",
            CountryCode: "CCK",
        },
        {
            id: "101",
            CountryName: "South Korea",
            CountryCode: "KOR",
        },
        {
            id: "102",
            CountryName: "Greece",
            CountryCode: "GRC",
        },
        {
            id: "103",
            CountryName: "Croatia",
            CountryCode: "HRV",
        },
        {
            id: "104",
            CountryName: "Cuba",
            CountryCode: "CUB",
        },
        {
            id: "105",
            CountryName: "Kuwait",
            CountryCode: "KWT",
        },
        {
            id: "106",
            CountryName: "Cyprus",
            CountryCode: "CYP",
        },
        {
            id: "107",
            CountryName: "Laos",
            CountryCode: "LAO",
        },
        {
            id: "108",
            CountryName: "Latvia",
            CountryCode: "LVA",
        },
        {
            id: "109",
            CountryName: "Lesotho",
            CountryCode: "LSO",
        },
        {
            id: "110",
            CountryName: "Lebanon",
            CountryCode: "LBN",
        },
        {
            id: "111",
            CountryName: "Liberia",
            CountryCode: "LBR",
        },
        {
            id: "112",
            CountryName: "Libyan Arab Jamahiriya",
            CountryCode: "LBY",
        },
        {
            id: "113",
            CountryName: "Liechtenstein",
            CountryCode: "LIE",
        },
        {
            id: "114",
            CountryName: "Lithuania",
            CountryCode: "LTU",
        },
        {
            id: "115",
            CountryName: "Luxembourg",
            CountryCode: "LUX",
        },
        {
            id: "116",
            CountryName: "Western Sahara",
            CountryCode: "ESH",
        },
        {
            id: "117",
            CountryName: "Macao",
            CountryCode: "MAC",
        },
        {
            id: "118",
            CountryName: "Madagascar",
            CountryCode: "MDG",
        },
        {
            id: "119",
            CountryName: "Macedonia",
            CountryCode: "MKD",
        },
        {
            id: "120",
            CountryName: "Malawi",
            CountryCode: "MWI",
        },
        {
            id: "121",
            CountryName: "Maldives",
            CountryCode: "MDV",
        },
        {
            id: "122",
            CountryName: "Malaysia",
            CountryCode: "MYS",
        },
        {
            id: "123",
            CountryName: "Mali",
            CountryCode: "MLI",
        },
        {
            id: "124",
            CountryName: "Malta",
            CountryCode: "MLT",
        },
        {
            id: "125",
            CountryName: "Morocco",
            CountryCode: "MAR",
        },
        {
            id: "126",
            CountryName: "Marshall Islands",
            CountryCode: "MHL",
        },
        {
            id: "127",
            CountryName: "Martinique",
            CountryCode: "MTQ",
        },
        {
            id: "128",
            CountryName: "Mauritania",
            CountryCode: "MRT",
        },
        {
            id: "129",
            CountryName: "Mauritius",
            CountryCode: "MUS",
        },
        {
            id: "130",
            CountryName: "Mayotte",
            CountryCode: "MYT",
        },
        {
            id: "131",
            CountryName: "Mexico",
            CountryCode: "MEX",
        },
        {
            id: "132",
            CountryName: "Micronesia, Federated States of",
            CountryCode: "FSM",
        },
        {
            id: "133",
            CountryName: "Moldova",
            CountryCode: "MDA",
        },
        {
            id: "134",
            CountryName: "Monaco",
            CountryCode: "MCO",
        },
        {
            id: "135",
            CountryName: "Mongolia",
            CountryCode: "MNG",
        },
        {
            id: "136",
            CountryName: "Montserrat",
            CountryCode: "MSR",
        },
        {
            id: "137",
            CountryName: "Mozambique",
            CountryCode: "MOZ",
        },
        {
            id: "138",
            CountryName: "Myanmar",
            CountryCode: "MMR",
        },
        {
            id: "139",
            CountryName: "Namibia",
            CountryCode: "NAM",
        },
        {
            id: "140",
            CountryName: "Nauru",
            CountryCode: "NRU",
        },
        {
            id: "141",
            CountryName: "Nepal",
            CountryCode: "NPL",
        },
        {
            id: "142",
            CountryName: "Nicaragua",
            CountryCode: "NIC",
        },
        {
            id: "143",
            CountryName: "Niger",
            CountryCode: "NER",
        },
        {
            id: "144",
            CountryName: "Nigeria",
            CountryCode: "NGA",
        },
        {
            id: "145",
            CountryName: "Niue",
            CountryCode: "NIU",
        },
        {
            id: "146",
            CountryName: "Norfolk Island",
            CountryCode: "NFK",
        },
        {
            id: "147",
            CountryName: "Norway",
            CountryCode: "NOR",
        },
        {
            id: "148",
            CountryName: "Cote d Ivoire",
            CountryCode: "CIV",
        },
        {
            id: "149",
            CountryName: "Oman",
            CountryCode: "OMN",
        },
        {
            id: "150",
            CountryName: "Pakistan",
            CountryCode: "PAK",
        },
        {
            id: "151",
            CountryName: "Palau",
            CountryCode: "PLW",
        },
        {
            id: "152",
            CountryName: "Panama",
            CountryCode: "PAN",
        },
        {
            id: "153",
            CountryName: "Papua New Guinea",
            CountryCode: "PNG",
        },
        {
            id: "154",
            CountryName: "Paraguay",
            CountryCode: "PRY",
        },
        {
            id: "155",
            CountryName: "Peru",
            CountryCode: "PER",
        },
        {
            id: "156",
            CountryName: "Pitcairn",
            CountryCode: "PCN",
        },
        {
            id: "157",
            CountryName: "Northern Mariana Islands",
            CountryCode: "MNP",
        },
        {
            id: "158",
            CountryName: "Portugal",
            CountryCode: "PRT",
        },
        {
            id: "159",
            CountryName: "Puerto Rico",
            CountryCode: "PRI",
        },
        {
            id: "160",
            CountryName: "Poland",
            CountryCode: "POL",
        },
        {
            id: "161",
            CountryName: "Equatorial Guinea",
            CountryCode: "GNQ",
        },
        {
            id: "162",
            CountryName: "Qatar",
            CountryCode: "QAT",
        },
        {
            id: "163",
            CountryName: "France",
            CountryCode: "FRA",
        },
        {
            id: "164",
            CountryName: "French Guiana",
            CountryCode: "GUF",
        },
        {
            id: "165",
            CountryName: "French Polynesia",
            CountryCode: "PYF",
        },
        {
            id: "166",
            CountryName: "Runion",
            CountryCode: "REU",
        },
        {
            id: "167",
            CountryName: "Romania",
            CountryCode: "ROM",
        },
        {
            id: "168",
            CountryName: "Rwanda",
            CountryCode: "RWA",
        },
        {
            id: "169",
            CountryName: "Sweden",
            CountryCode: "SWE",
        },
        {
            id: "170",
            CountryName: "Saint Helena",
            CountryCode: "SHN",
        },
        {
            id: "171",
            CountryName: "Saint Kitts and Nevis",
            CountryCode: "KNA",
        },
        {
            id: "172",
            CountryName: "Saint Lucia",
            CountryCode: "LCA",
        },
        {
            id: "173",
            CountryName: "Saint Vincent and the Grenadines",
            CountryCode: "VCT",
        },
        {
            id: "174",
            CountryName: "Saint Pierre and Miquelon",
            CountryCode: "SPM",
        },
        {
            id: "175",
            CountryName: "Germany",
            CountryCode: "DEU",
        },
        {
            id: "176",
            CountryName: "Solomon Islands",
            CountryCode: "SLB",
        },
        {
            id: "177",
            CountryName: "Zambia",
            CountryCode: "ZMB",
        },
        {
            id: "178",
            CountryName: "Samoa",
            CountryCode: "WSM",
        },
        {
            id: "179",
            CountryName: "San Marino",
            CountryCode: "SMR",
        },
        {
            id: "180",
            CountryName: "Sao Tome and Principe",
            CountryCode: "STP",
        },
        {
            id: "181",
            CountryName: "Saudi Arabia",
            CountryCode: "SAU",
        },
        {
            id: "182",
            CountryName: "Senegal",
            CountryCode: "SEN",
        },
        {
            id: "183",
            CountryName: "Seychelles",
            CountryCode: "SYC",
        },
        {
            id: "184",
            CountryName: "Sierra Leone",
            CountryCode: "SLE",
        },
        {
            id: "185",
            CountryName: "Singapore",
            CountryCode: "SGP",
        },
        {
            id: "186",
            CountryName: "Slovakia",
            CountryCode: "SVK",
        },
        {
            id: "187",
            CountryName: "Slovenia",
            CountryCode: "SVN",
        },
        {
            id: "188",
            CountryName: "Somalia",
            CountryCode: "SOM",
        },
        {
            id: "189",
            CountryName: "Sri Lanka",
            CountryCode: "LKA",
        },
        {
            id: "190",
            CountryName: "Finland",
            CountryCode: "FIN",
        },
        {
            id: "191",
            CountryName: "Suriname",
            CountryCode: "SUR",
        },
        {
            id: "192",
            CountryName: "Swaziland",
            CountryCode: "SWZ",
        },
        {
            id: "193",
            CountryName: "Switzerland",
            CountryCode: "CHE",
        },
        {
            id: "194",
            CountryName: "Tajikistan",
            CountryCode: "TJK",
        },
        {
            id: "195",
            CountryName: "Taiwan",
            CountryCode: "TWN",
        },
        {
            id: "196",
            CountryName: "Tanzania",
            CountryCode: "TZA",
        },
        {
            id: "197",
            CountryName: "Denmark",
            CountryCode: "DNK",
        },
        {
            id: "198",
            CountryName: "Thailand",
            CountryCode: "THA",
        },
        {
            id: "199",
            CountryName: "Togo",
            CountryCode: "TGO",
        },
        {
            id: "200",
            CountryName: "Tokelau",
            CountryCode: "TKL",
        },
        {
            id: "201",
            CountryName: "Tonga",
            CountryCode: "TON",
        },
        {
            id: "202",
            CountryName: "Trinidad and Tobago",
            CountryCode: "TTO",
        },
        {
            id: "203",
            CountryName: "Chad",
            CountryCode: "TCD",
        },
        {
            id: "204",
            CountryName: "Czech Republic",
            CountryCode: "CZE",
        },
        {
            id: "205",
            CountryName: "Tunisia",
            CountryCode: "TUN",
        },
        {
            id: "206",
            CountryName: "Turkey",
            CountryCode: "TUR",
        },
        {
            id: "207",
            CountryName: "Turkmenistan",
            CountryCode: "TKM",
        },
        {
            id: "208",
            CountryName: "Turks and Caicos Islands",
            CountryCode: "TCA",
        },
        {
            id: "209",
            CountryName: "Tuvalu",
            CountryCode: "TUV",
        },
        {
            id: "210",
            CountryName: "Uganda",
            CountryCode: "UGA",
        },
        {
            id: "211",
            CountryName: "Ukraine",
            CountryCode: "UKR",
        },
        {
            id: "212",
            CountryName: "Hungary",
            CountryCode: "HUN",
        },
        {
            id: "213",
            CountryName: "Uruguay",
            CountryCode: "URY",
        },
        {
            id: "214",
            CountryName: "New Caledonia",
            CountryCode: "NCL",
        },
        {
            id: "215",
            CountryName: "New Zealand",
            CountryCode: "NZL",
        },
        {
            id: "216",
            CountryName: "Uzbekistan",
            CountryCode: "UZB",
        },
        {
            id: "217",
            CountryName: "Belarus",
            CountryCode: "BLR",
        },
        {
            id: "218",
            CountryName: "Wallis and Futuna",
            CountryCode: "WLF",
        },
        {
            id: "219",
            CountryName: "Vanuatu",
            CountryCode: "VUT",
        },
        {
            id: "220",
            CountryName: "Holy See (Vatican City State)",
            CountryCode: "VAT",
        },
        {
            id: "221",
            CountryName: "Venezuela",
            CountryCode: "VEN",
        },
        {
            id: "222",
            CountryName: "Russian Federation",
            CountryCode: "RUS",
        },
        {
            id: "223",
            CountryName: "Vietnam",
            CountryCode: "VNM",
        },
        {
            id: "224",
            CountryName: "Estonia",
            CountryCode: "EST",
        },
        {
            id: "225",
            CountryName: "United States",
            CountryCode: "USA",
        },
        {
            id: "226",
            CountryName: "Virgin Islands, U.S.",
            CountryCode: "VIR",
        },
        {
            id: "227",
            CountryName: "Zimbabwe",
            CountryCode: "ZWE",
        },
        {
            id: "228",
            CountryName: "Palestine",
            CountryCode: "PSE",
        },
        {
            id: "229",
            CountryName: "Antarctica",
            CountryCode: "ATA",
        },
        {
            id: "231",
            CountryName: "British Indian Ocean Territory",
            CountryCode: "IOT",
        },
        {
            id: "232",
            CountryName: "South Georgia and the South Sandwich Islands",
            CountryCode: "SGS",
        },
        {
            id: "235",
            CountryName: "United States Minor Outlying Islands",
            CountryCode: "UMI",
        },
    ],
    currency: [
        {
            id: "1",
            CurrencyTitle: "India",
            CurrencyCode: "INR",
        },
        {
            id: "2",
            CurrencyTitle: "United States",
            CurrencyCode: "USD",
        },
        {
            id: "3",
            CurrencyTitle: "Australia",
            CurrencyCode: "AUD",
        },
        {
            id: "4",
            CurrencyTitle: "Canada",
            CurrencyCode: "CAD",
        },
        {
            id: "5",
            CurrencyTitle: "Switzerland",
            CurrencyCode: "CHF",
        },
        {
            id: "6",
            CurrencyTitle: "Euroland",
            CurrencyCode: "EUR",
        },
        {
            id: "7",
            CurrencyTitle: "Japan",
            CurrencyCode: "JPY",
        },
        {
            id: "8",
            CurrencyTitle: "Great-Britain",
            CurrencyCode: "GBP",
        },
        {
            id: "9",
            CurrencyTitle: "Mexico",
            CurrencyCode: "MXN",
        },
        {
            id: "10",
            CurrencyTitle: "New-Zealand",
            CurrencyCode: "NZD",
        },
        {
            id: "11",
            CurrencyTitle: "Sweden",
            CurrencyCode: "SEK",
        },
        {
            id: "12",
            CurrencyTitle: "South Africa",
            CurrencyCode: "ZAR",
        },
        {
            id: "13",
            CurrencyTitle: "Hong Kong Dollar",
            CurrencyCode: "HKD",
        },
        {
            id: "14",
            CurrencyTitle: "Singapore Dollar",
            CurrencyCode: "SGD",
        },
        {
            id: "15",
            CurrencyTitle: "Danish Krone ",
            CurrencyCode: "DKK",
        },
        {
            id: "16",
            CurrencyTitle: "Polish Zloty",
            CurrencyCode: "PLN",
        },
        {
            id: "17",
            CurrencyTitle: "Norwegian Krone",
            CurrencyCode: "NOK",
        },
        {
            id: "18",
            CurrencyTitle: "Hungarian Forint",
            CurrencyCode: "HUF",
        },
        {
            id: "19",
            CurrencyTitle: "Czech Koruna",
            CurrencyCode: "CZK",
        },
        {
            id: "20",
            CurrencyTitle: "New Israeli Shekel",
            CurrencyCode: "ILS",
        },
        {
            id: "21",
            CurrencyTitle: "Malaysian Ringgit",
            CurrencyCode: "MYR",
        },
        {
            id: "22",
            CurrencyTitle: "Romanian Lei",
            CurrencyCode: "RON",
        },
        {
            id: "23",
            CurrencyTitle: "Philippine peso",
            CurrencyCode: "PHP",
        },
        {
            id: "24",
            CurrencyTitle: "Yuan Renminbi",
            CurrencyCode: "CNY",
        },
        {
            id: "26",
            CurrencyTitle: "UAE Dirham",
            CurrencyCode: "AED",
        },
        {
            id: "30",
            CurrencyTitle: "Netherlands Antillian Guilder",
            CurrencyCode: "ANG",
        },
        {
            id: "32",
            CurrencyTitle: "Argentine Peso",
            CurrencyCode: "ARS",
        },
        {
            id: "35",
            CurrencyTitle: "Convertible Marks",
            CurrencyCode: "BAM",
        },
        {
            id: "36",
            CurrencyTitle: "Barbados Dollar",
            CurrencyCode: "BBD",
        },
        {
            id: "37",
            CurrencyTitle: "Taka",
            CurrencyCode: "BDT",
        },
        {
            id: "38",
            CurrencyTitle: "Bulgarian Lev",
            CurrencyCode: "BGN",
        },
        {
            id: "39",
            CurrencyTitle: "Bahraini Dinar",
            CurrencyCode: "BHD",
        },
        {
            id: "43",
            CurrencyTitle: "Boliviano",
            CurrencyCode: "BOB",
        },
        {
            id: "45",
            CurrencyTitle: "Brazilian Real",
            CurrencyCode: "BRL",
        },
        {
            id: "46",
            CurrencyTitle: "Bahamian Dollar",
            CurrencyCode: "BSD",
        },
        {
            id: "48",
            CurrencyTitle: "Pula",
            CurrencyCode: "BWP",
        },
        {
            id: "49",
            CurrencyTitle: "Belarussian Ruble",
            CurrencyCode: "BYR",
        },
        {
            id: "50",
            CurrencyTitle: "Belize Dollar",
            CurrencyCode: "BZD",
        },
        {
            id: "55",
            CurrencyTitle: "Chilean Peso",
            CurrencyCode: "CLP",
        },
        {
            id: "56",
            CurrencyTitle: "Colombian Peso",
            CurrencyCode: "COP",
        },
        {
            id: "63",
            CurrencyTitle: "Dominican Peso",
            CurrencyCode: "DOP",
        },
        {
            id: "64",
            CurrencyTitle: "Algerian Dinar",
            CurrencyCode: "DZD",
        },
        {
            id: "65",
            CurrencyTitle: "Kroon",
            CurrencyCode: "EEK",
        },
        {
            id: "66",
            CurrencyTitle: "Egyptian Pound",
            CurrencyCode: "EGP",
        },
        {
            id: "69",
            CurrencyTitle: "Fiji Dollar",
            CurrencyCode: "FJD",
        },
        {
            id: "75",
            CurrencyTitle: "Guinea Franc",
            CurrencyCode: "GNF",
        },
        {
            id: "76",
            CurrencyTitle: "Quetzal",
            CurrencyCode: "GTQ",
        },
        {
            id: "80",
            CurrencyTitle: "Croatian Kuna",
            CurrencyCode: "HRK",
        },
        {
            id: "82",
            CurrencyTitle: "Rupiah",
            CurrencyCode: "IDR",
        },
        {
            id: "83",
            CurrencyTitle: "Iraqi Dinar",
            CurrencyCode: "IQD",
        },
        {
            id: "84",
            CurrencyTitle: "Iranian Rial",
            CurrencyCode: "IRR",
        },
        {
            id: "85",
            CurrencyTitle: "Iceland Krona",
            CurrencyCode: "ISK",
        },
        {
            id: "86",
            CurrencyTitle: "Jamaican Dollar",
            CurrencyCode: "JMD",
        },
        {
            id: "87",
            CurrencyTitle: "Jordanian Dinar",
            CurrencyCode: "JOD",
        },
        {
            id: "88",
            CurrencyTitle: "Kenyan Shilling",
            CurrencyCode: "KES",
        },
        {
            id: "91",
            CurrencyTitle: "Comoro Franc",
            CurrencyCode: "KMF",
        },
        {
            id: "93",
            CurrencyTitle: "Won",
            CurrencyCode: "KRW",
        },
        {
            id: "94",
            CurrencyTitle: "Kuwaiti Dinar",
            CurrencyCode: "KWD",
        },
        {
            id: "96",
            CurrencyTitle: "Tenge",
            CurrencyCode: "KZT",
        },
        {
            id: "98",
            CurrencyTitle: "Lebanese Pound",
            CurrencyCode: "LBP",
        },
        {
            id: "99",
            CurrencyTitle: "Sri Lanka Rupee",
            CurrencyCode: "LKR",
        },
        {
            id: "101",
            CurrencyTitle: "Loti",
            CurrencyCode: "LSL",
        },
        {
            id: "102",
            CurrencyTitle: "Lithuanian Litas",
            CurrencyCode: "LTL",
        },
        {
            id: "103",
            CurrencyTitle: "Latvian Lats",
            CurrencyCode: "LVL",
        },
        {
            id: "105",
            CurrencyTitle: "Moroccan Dirham",
            CurrencyCode: "MAD",
        },
        {
            id: "106",
            CurrencyTitle: "Moldovan Leu",
            CurrencyCode: "MDL",
        },
        {
            id: "107",
            CurrencyTitle: "Malagascy Ariary",
            CurrencyCode: "MGA",
        },
        {
            id: "108",
            CurrencyTitle: "Denar",
            CurrencyCode: "MKD",
        },
        {
            id: "110",
            CurrencyTitle: "Tugrik",
            CurrencyCode: "MNT",
        },
        {
            id: "112",
            CurrencyTitle: "Ouguiya",
            CurrencyCode: "MRO",
        },
        {
            id: "114",
            CurrencyTitle: "Mauritius Rupee",
            CurrencyCode: "MUR",
        },
        {
            id: "115",
            CurrencyTitle: "Rufiyaa",
            CurrencyCode: "MVR",
        },
        {
            id: "116",
            CurrencyTitle: "Kwacha",
            CurrencyCode: "MWK",
        },
        {
            id: "119",
            CurrencyTitle: "Namibian Dollar",
            CurrencyCode: "NAD",
        },
        {
            id: "120",
            CurrencyTitle: "Naira",
            CurrencyCode: "NGN",
        },
        {
            id: "122",
            CurrencyTitle: "Nepalese Rupee",
            CurrencyCode: "NPR",
        },
        {
            id: "123",
            CurrencyTitle: "Rial Omani",
            CurrencyCode: "OMR",
        },
        {
            id: "125",
            CurrencyTitle: "Nuevo Sol",
            CurrencyCode: "PEN",
        },
        {
            id: "126",
            CurrencyTitle: "Kina",
            CurrencyCode: "PGK",
        },
        {
            id: "127",
            CurrencyTitle: "Pakistan Rupee",
            CurrencyCode: "PKR",
        },
        {
            id: "128",
            CurrencyTitle: "Guarani",
            CurrencyCode: "PYG",
        },
        {
            id: "129",
            CurrencyTitle: "Qatari Rial",
            CurrencyCode: "QAR",
        },
        {
            id: "132",
            CurrencyTitle: "Russian Ruble",
            CurrencyCode: "RUB",
        },
        {
            id: "133",
            CurrencyTitle: "Rwanda Franc",
            CurrencyCode: "RWF",
        },
        {
            id: "134",
            CurrencyTitle: "Saudi Riyal",
            CurrencyCode: "SAR",
        },
        {
            id: "135",
            CurrencyTitle: "Solomon Islands Dollar",
            CurrencyCode: "SBD",
        },
        {
            id: "136",
            CurrencyTitle: "Seychelles Rupee",
            CurrencyCode: "SCR",
        },
        {
            id: "140",
            CurrencyTitle: "Slovak Koruna",
            CurrencyCode: "SKK",
        },
        {
            id: "141",
            CurrencyTitle: "Leone",
            CurrencyCode: "SLL",
        },
        {
            id: "144",
            CurrencyTitle: "Dobra",
            CurrencyCode: "STD",
        },
        {
            id: "145",
            CurrencyTitle: "El Salvador Colon",
            CurrencyCode: "SVC",
        },
        {
            id: "147",
            CurrencyTitle: "Lilangeni",
            CurrencyCode: "SZL",
        },
        {
            id: "148",
            CurrencyTitle: "Baht",
            CurrencyCode: "THB",
        },
        {
            id: "151",
            CurrencyTitle: "Tunisian Dinar",
            CurrencyCode: "TND",
        },
        {
            id: "152",
            CurrencyTitle: "Paanga",
            CurrencyCode: "TOP",
        },
        {
            id: "153",
            CurrencyTitle: "New Turkish Lira",
            CurrencyCode: "TRY",
        },
        {
            id: "154",
            CurrencyTitle: "Trinidad and Tobago Dollar",
            CurrencyCode: "TTD",
        },
        {
            id: "155",
            CurrencyTitle: "New Taiwan Dollar",
            CurrencyCode: "TWD",
        },
        {
            id: "156",
            CurrencyTitle: "Tanzanian Shilling",
            CurrencyCode: "TZS",
        },
        {
            id: "157",
            CurrencyTitle: "Hryvnia",
            CurrencyCode: "UAH",
        },
        {
            id: "158",
            CurrencyTitle: "Uganda Shilling",
            CurrencyCode: "UGX",
        },
        {
            id: "160",
            CurrencyTitle: "Peso Uruguayo",
            CurrencyCode: "UYU",
        },
        {
            id: "163",
            CurrencyTitle: "Dong",
            CurrencyCode: "VND",
        },
        {
            id: "164",
            CurrencyTitle: "Vatu",
            CurrencyCode: "VUV",
        },
        {
            id: "165",
            CurrencyTitle: "Tala",
            CurrencyCode: "WST",
        },
        {
            id: "167",
            CurrencyTitle: "East Caribbean Dollar",
            CurrencyCode: "XCD",
        },
        {
            id: "169",
            CurrencyTitle: "CFA Franc BCEAO",
            CurrencyCode: "XOF",
        },
        {
            id: "170",
            CurrencyTitle: "CFP Franc",
            CurrencyCode: "XPF",
        },
        {
            id: "171",
            CurrencyTitle: "Yemeni Rial",
            CurrencyCode: "YER",
        },
        {
            id: "172",
            CurrencyTitle: "Kwacha",
            CurrencyCode: "ZMK",
        },
        {
            id: "173",
            CurrencyTitle: "Zimbabwe Dollar",
            CurrencyCode: "ZWD",
        },
        {
            id: "174",
            CurrencyTitle: "Costa Rican Colon",
            CurrencyCode: "CRC",
        },
        {
            id: "175",
            CurrencyTitle: "Honduras Lempira",
            CurrencyCode: "HNL",
        },
    ],
    dateFormat: [
        // {
        //     id: "1",
        //     DateFormatTitle: "mm-dd-yyyy",
        //     DateFormatValue: "mm-dd-yyyy",
        // },
        // {
        //     id: "2",
        //     DateFormatTitle: "mm-dd-yy",
        //     DateFormatValue: "mm-dd-yy",
        // },
        // {
        //     id: "3",
        //     DateFormatTitle: "yyyy-mm-dd",
        //     DateFormatValue: "yyyy-mm-dd",
        // },
        // {
        //     id: "5",
        //     DateFormatTitle: "mm/dd/yyyy",
        //     DateFormatValue: "mm/dd/yyyy",
        // },
        // {
        //     id: "9",
        //     DateFormatTitle: "dd-mm-yyyy",
        //     DateFormatValue: "dd-mm-yyyy",
        // },
        {
            id: "12",
            DateFormatTitle: "MM-dd-yyyy",
            DateFormatValue: "MM-dd-yyyy",
        },
        {
            id: "18",
            DateFormatTitle: "yyyy/MM/dd",
            DateFormatValue: "yyyy/MM/dd",
        },
        {
            id: "20",
            DateFormatTitle: "dd-MM-yyyy",
            DateFormatValue: "dd-MM-yyyy",
        },
    ],
    timeFormat: [
        {
            id: "1",
            TimeFormatTitle: "12 Hours",
            TimeFormatValue: "12",
        },
        {
            id: "2",
            TimeFormatTitle: "24 Hours",
            TimeFormatValue: "24",
        },
    ],
    timezone: [
        {
            id: "1",
            fkCountryCode: "AFG",
            TimezoneTitle: "Asia/Kabul",
        },
        {
            id: "2",
            fkCountryCode: "NLD",
            TimezoneTitle: "Europe/Amsterdam",
        },
        {
            id: "3",
            fkCountryCode: "ANT",
            TimezoneTitle: "America/Curacao",
        },
        {
            id: "4",
            fkCountryCode: "ALB",
            TimezoneTitle: "Europe/Tirane",
        },
        {
            id: "5",
            fkCountryCode: "DZA",
            TimezoneTitle: "Africa/Algiers",
        },
        {
            id: "6",
            fkCountryCode: "ASM",
            TimezoneTitle: "Pacific/Pago_Pago",
        },
        {
            id: "7",
            fkCountryCode: "AND",
            TimezoneTitle: "Europe/Andorra",
        },
        {
            id: "8",
            fkCountryCode: "AGO",
            TimezoneTitle: "Africa/Luanda",
        },
        {
            id: "9",
            fkCountryCode: "AIA",
            TimezoneTitle: "America/Anguilla",
        },
        {
            id: "10",
            fkCountryCode: "ATG",
            TimezoneTitle: "America/Curacao",
        },
        {
            id: "11",
            fkCountryCode: "ARE",
            TimezoneTitle: "Asia/Dubai",
        },
        {
            id: "12",
            fkCountryCode: "ARG",
            TimezoneTitle: "America/Argentina/Buenos_Aires",
        },
        {
            id: "13",
            fkCountryCode: "ARG",
            TimezoneTitle: "America/Argentina/Cordoba",
        },
        {
            id: "14",
            fkCountryCode: "ARG",
            TimezoneTitle: "America/Argentina/Jujuy",
        },
        {
            id: "15",
            fkCountryCode: "ARG",
            TimezoneTitle: "America/Argentina/Tucuman",
        },
        {
            id: "16",
            fkCountryCode: "ARG",
            TimezoneTitle: "America/Argentina/Catamarca",
        },
        {
            id: "17",
            fkCountryCode: "ARG",
            TimezoneTitle: "America/Argentina/La_Rioja",
        },
        {
            id: "18",
            fkCountryCode: "ARG",
            TimezoneTitle: "America/Argentina/San_Juan",
        },
        {
            id: "19",
            fkCountryCode: "ARG",
            TimezoneTitle: "America/Argentina/Mendoza",
        },
        {
            id: "20",
            fkCountryCode: "ARG",
            TimezoneTitle: "America/Argentina/Rio_Gallegos",
        },
        {
            id: "21",
            fkCountryCode: "ARG",
            TimezoneTitle: "America/Argentina/Ushuaia",
        },
        {
            id: "22",
            fkCountryCode: "ARM",
            TimezoneTitle: "Asia/Yerevan",
        },
        {
            id: "23",
            fkCountryCode: "ABW",
            TimezoneTitle: "America/Aruba",
        },
        {
            id: "24",
            fkCountryCode: "AUS",
            TimezoneTitle: "Australia/Lord_Howe",
        },
        {
            id: "25",
            fkCountryCode: "AUS",
            TimezoneTitle: "Australia/Hobart",
        },
        {
            id: "26",
            fkCountryCode: "AUS",
            TimezoneTitle: "Australia/Currie",
        },
        {
            id: "27",
            fkCountryCode: "AUS",
            TimezoneTitle: "Australia/Melbourne",
        },
        {
            id: "28",
            fkCountryCode: "AUS",
            TimezoneTitle: "Australia/Lord_Howe",
        },
        {
            id: "29",
            fkCountryCode: "AUS",
            TimezoneTitle: "Australia/Broken_Hill",
        },
        {
            id: "30",
            fkCountryCode: "AUS",
            TimezoneTitle: "Australia/Brisbane",
        },
        {
            id: "31",
            fkCountryCode: "AUS",
            TimezoneTitle: "Australia/Lindeman",
        },
        {
            id: "32",
            fkCountryCode: "AUS",
            TimezoneTitle: "Australia/Adelaide",
        },
        {
            id: "33",
            fkCountryCode: "AUS",
            TimezoneTitle: "Australia/Darwin",
        },
        {
            id: "34",
            fkCountryCode: "AUS",
            TimezoneTitle: "Australia/Perth",
        },
        {
            id: "35",
            fkCountryCode: "AUS",
            TimezoneTitle: "Australia/Eucla",
        },
        {
            id: "36",
            fkCountryCode: "AZE",
            TimezoneTitle: "Asia/Baku",
        },
        {
            id: "37",
            fkCountryCode: "BHS",
            TimezoneTitle: "America/Nassau",
        },
        {
            id: "38",
            fkCountryCode: "BHR",
            TimezoneTitle: "Asia/Bahrain",
        },
        {
            id: "39",
            fkCountryCode: "BGD",
            TimezoneTitle: "Asia/Dhaka",
        },
        {
            id: "40",
            fkCountryCode: "BRB",
            TimezoneTitle: "America/Barbados",
        },
        {
            id: "41",
            fkCountryCode: "BEL",
            TimezoneTitle: "Europe/Brussels",
        },
        {
            id: "42",
            fkCountryCode: "BLZ",
            TimezoneTitle: "America/Belize",
        },
        {
            id: "43",
            fkCountryCode: "BEN",
            TimezoneTitle: "Africa/Porto-Novo",
        },
        {
            id: "44",
            fkCountryCode: "BMU",
            TimezoneTitle: "Atlantic/Bermuda",
        },
        {
            id: "45",
            fkCountryCode: "BTN",
            TimezoneTitle: "Asia/Thimphu",
        },
        {
            id: "46",
            fkCountryCode: "BOL",
            TimezoneTitle: "America/La_Paz",
        },
        {
            id: "47",
            fkCountryCode: "BIH",
            TimezoneTitle: "Europe/Sarajevo",
        },
        {
            id: "48",
            fkCountryCode: "BWA",
            TimezoneTitle: "Africa/Gaborone",
        },
        {
            id: "49",
            fkCountryCode: "BRA",
            TimezoneTitle: "America/Noronha",
        },
        {
            id: "50",
            fkCountryCode: "BRA",
            TimezoneTitle: "America/Belem",
        },
        {
            id: "51",
            fkCountryCode: "BRA",
            TimezoneTitle: "America/Fortaleza",
        },
        {
            id: "52",
            fkCountryCode: "BRA",
            TimezoneTitle: "America/Recife",
        },
        {
            id: "53",
            fkCountryCode: "BRA",
            TimezoneTitle: "America/Araguaina",
        },
        {
            id: "54",
            fkCountryCode: "BRA",
            TimezoneTitle: "America/Maceio",
        },
        {
            id: "55",
            fkCountryCode: "BRA",
            TimezoneTitle: "America/Bahia",
        },
        {
            id: "56",
            fkCountryCode: "BRA",
            TimezoneTitle: "America/Sao_Paulo",
        },
        {
            id: "57",
            fkCountryCode: "BRA",
            TimezoneTitle: "America/Santarem",
        },
        {
            id: "58",
            fkCountryCode: "BRA",
            TimezoneTitle: "America/Campo_Grande",
        },
        {
            id: "59",
            fkCountryCode: "BRA",
            TimezoneTitle: "America/Cuiaba",
        },
        {
            id: "60",
            fkCountryCode: "BRA",
            TimezoneTitle: "America/Porto_Velho",
        },
        {
            id: "61",
            fkCountryCode: "BRA",
            TimezoneTitle: "America/Boa_Vista",
        },
        {
            id: "62",
            fkCountryCode: "BRA",
            TimezoneTitle: "America/Manaus",
        },
        {
            id: "63",
            fkCountryCode: "BRA",
            TimezoneTitle: "America/Eirunepe",
        },
        {
            id: "64",
            fkCountryCode: "BRA",
            TimezoneTitle: "America/Rio_Branco",
        },
        {
            id: "65",
            fkCountryCode: "GBR",
            TimezoneTitle: "Europe/London",
        },
        {
            id: "66",
            fkCountryCode: "VGB",
            TimezoneTitle: "America/Tortola",
        },
        {
            id: "67",
            fkCountryCode: "BRN",
            TimezoneTitle: "Asia/Brunei",
        },
        {
            id: "68",
            fkCountryCode: "BGR",
            TimezoneTitle: "Europe/Sofia",
        },
        {
            id: "69",
            fkCountryCode: "BFA",
            TimezoneTitle: "Africa/Ouagadougou",
        },
        {
            id: "70",
            fkCountryCode: "BDI",
            TimezoneTitle: "Africa/Bujumbura",
        },
        {
            id: "71",
            fkCountryCode: "CYM",
            TimezoneTitle: "America/Cayman",
        },
        {
            id: "72",
            fkCountryCode: "CHL",
            TimezoneTitle: "America/Santiago",
        },
        {
            id: "73",
            fkCountryCode: "CHL",
            TimezoneTitle: "Pacific/Easter",
        },
        {
            id: "74",
            fkCountryCode: "COK",
            TimezoneTitle: "Pacific/Rarotonga",
        },
        {
            id: "75",
            fkCountryCode: "CRI",
            TimezoneTitle: "America/Costa_Rica",
        },
        {
            id: "76",
            fkCountryCode: "DJI",
            TimezoneTitle: "Africa/Djibouti",
        },
        {
            id: "77",
            fkCountryCode: "DMA",
            TimezoneTitle: "America/Dominica",
        },
        {
            id: "78",
            fkCountryCode: "DOM",
            TimezoneTitle: "America/Santo_Domingo",
        },
        {
            id: "79",
            fkCountryCode: "ECU",
            TimezoneTitle: "America/Guayaquil",
        },
        {
            id: "80",
            fkCountryCode: "ECU",
            TimezoneTitle: "Pacific/Galapagos",
        },
        {
            id: "81",
            fkCountryCode: "EGY",
            TimezoneTitle: "Africa/Cairo",
        },
        {
            id: "82",
            fkCountryCode: "SLV",
            TimezoneTitle: "America/El_Salvador",
        },
        {
            id: "83",
            fkCountryCode: "ERI",
            TimezoneTitle: "Africa/Asmara",
        },
        {
            id: "84",
            fkCountryCode: "ESP",
            TimezoneTitle: "Europe/Madrid",
        },
        {
            id: "85",
            fkCountryCode: "ESP",
            TimezoneTitle: "Africa/Ceuta",
        },
        {
            id: "86",
            fkCountryCode: "ESP",
            TimezoneTitle: "Atlantic/Canary",
        },
        {
            id: "87",
            fkCountryCode: "ZAF",
            TimezoneTitle: "Africa/Johannesburg",
        },
        {
            id: "88",
            fkCountryCode: "ETH",
            TimezoneTitle: "Africa/Addis_Ababa",
        },
        {
            id: "89",
            fkCountryCode: "FLK",
            TimezoneTitle: "Atlantic/Stanley",
        },
        {
            id: "90",
            fkCountryCode: "FJI",
            TimezoneTitle: "Pacific/Fiji",
        },
        {
            id: "91",
            fkCountryCode: "PHL",
            TimezoneTitle: "Asia/Manila",
        },
        {
            id: "92",
            fkCountryCode: "FRO",
            TimezoneTitle: "Atlantic/Faroe",
        },
        {
            id: "93",
            fkCountryCode: "GAB",
            TimezoneTitle: "Africa/Libreville",
        },
        {
            id: "94",
            fkCountryCode: "GMB",
            TimezoneTitle: "Africa/Banjul",
        },
        {
            id: "95",
            fkCountryCode: "GEO",
            TimezoneTitle: "Asia/Tbilisi",
        },
        {
            id: "96",
            fkCountryCode: "GHA",
            TimezoneTitle: "Africa/Accra",
        },
        {
            id: "97",
            fkCountryCode: "GIB",
            TimezoneTitle: "Europe/Gibraltar",
        },
        {
            id: "98",
            fkCountryCode: "GRD",
            TimezoneTitle: "America/Grenada",
        },
        {
            id: "99",
            fkCountryCode: "GRL",
            TimezoneTitle: "America/Godthab",
        },
        {
            id: "100",
            fkCountryCode: "GRL",
            TimezoneTitle: "America/Danmarkshavn",
        },
        {
            id: "101",
            fkCountryCode: "GRL",
            TimezoneTitle: "America/Scoresbysund",
        },
        {
            id: "102",
            fkCountryCode: "GRL",
            TimezoneTitle: "America/Thule",
        },
        {
            id: "103",
            fkCountryCode: "GLP",
            TimezoneTitle: "America/Guadeloupe",
        },
        {
            id: "104",
            fkCountryCode: "GUM",
            TimezoneTitle: "Pacific/Guam",
        },
        {
            id: "105",
            fkCountryCode: "GTM",
            TimezoneTitle: "America/Guatemala",
        },
        {
            id: "106",
            fkCountryCode: "GIN",
            TimezoneTitle: "Africa/Conakry",
        },
        {
            id: "107",
            fkCountryCode: "GNB",
            TimezoneTitle: "Africa/Bissau",
        },
        {
            id: "108",
            fkCountryCode: "GUY",
            TimezoneTitle: "America/Guyana",
        },
        {
            id: "109",
            fkCountryCode: "HTI",
            TimezoneTitle: "America/Port-au-Prince",
        },
        {
            id: "110",
            fkCountryCode: "HND",
            TimezoneTitle: "America/Tegucigalpa",
        },
        {
            id: "111",
            fkCountryCode: "HKG",
            TimezoneTitle: "Asia/Hong_Kong",
        },
        {
            id: "112",
            fkCountryCode: "SJM",
            TimezoneTitle: "Atlantic/Jan_Mayen",
        },
        {
            id: "113",
            fkCountryCode: "IDN",
            TimezoneTitle: "Asia/Jakarta",
        },
        {
            id: "114",
            fkCountryCode: "IDN",
            TimezoneTitle: "Asia/Pontianak",
        },
        {
            id: "115",
            fkCountryCode: "IDN",
            TimezoneTitle: "Asia/Makassar",
        },
        {
            id: "116",
            fkCountryCode: "IDN",
            TimezoneTitle: "Asia/Jayapura",
        },
        {
            id: "117",
            fkCountryCode: "IND",
            TimezoneTitle: "Asia/Calcutta",
        },
        {
            id: "118",
            fkCountryCode: "IRQ",
            TimezoneTitle: "Asia/Baghdad",
        },
        {
            id: "119",
            fkCountryCode: "IRL",
            TimezoneTitle: "Europe/Dublin",
        },
        {
            id: "120",
            fkCountryCode: "ISL",
            TimezoneTitle: "Atlantic/Reykjavik",
        },
        {
            id: "121",
            fkCountryCode: "ISR",
            TimezoneTitle: "Asia/Jerusalem",
        },
        {
            id: "122",
            fkCountryCode: "ITA",
            TimezoneTitle: "Europe/Rome",
        },
        {
            id: "123",
            fkCountryCode: "TMP",
            TimezoneTitle: "Asia/Dili",
        },
        {
            id: "124",
            fkCountryCode: "AUT",
            TimezoneTitle: "Europe/Vienna",
        },
        {
            id: "125",
            fkCountryCode: "JAM",
            TimezoneTitle: "America/Jamaica",
        },
        {
            id: "126",
            fkCountryCode: "JPN",
            TimezoneTitle: "Asia/Tokyo",
        },
        {
            id: "127",
            fkCountryCode: "YEM",
            TimezoneTitle: "Asia/Aden",
        },
        {
            id: "128",
            fkCountryCode: "JOR",
            TimezoneTitle: "Asia/Amman",
        },
        {
            id: "129",
            fkCountryCode: "CXR",
            TimezoneTitle: "Indian/Christmas",
        },
        {
            id: "130",
            fkCountryCode: "YUG",
            TimezoneTitle: "Europe/Zagreb",
        },
        {
            id: "131",
            fkCountryCode: "KHM",
            TimezoneTitle: "Asia/Phnom_Penh",
        },
        {
            id: "132",
            fkCountryCode: "CMR",
            TimezoneTitle: "Africa/Douala",
        },
        {
            id: "133",
            fkCountryCode: "CAN",
            TimezoneTitle: "America/St_Johns",
        },
        {
            id: "134",
            fkCountryCode: "CAN",
            TimezoneTitle: "America/Halifax",
        },
        {
            id: "135",
            fkCountryCode: "CAN",
            TimezoneTitle: "America/Glace_Bay",
        },
        {
            id: "136",
            fkCountryCode: "CAN",
            TimezoneTitle: "America/Moncton",
        },
        {
            id: "137",
            fkCountryCode: "CAN",
            TimezoneTitle: "America/Goose_Bay",
        },
        {
            id: "138",
            fkCountryCode: "CAN",
            TimezoneTitle: "America/Blanc-Sablon",
        },
        {
            id: "139",
            fkCountryCode: "CAN",
            TimezoneTitle: "America/Montreal",
        },
        {
            id: "140",
            fkCountryCode: "CAN",
            TimezoneTitle: "America/Toronto",
        },
        {
            id: "141",
            fkCountryCode: "CAN",
            TimezoneTitle: "America/Nipigon",
        },
        {
            id: "142",
            fkCountryCode: "CAN",
            TimezoneTitle: "America/Thunder_Bay",
        },
        {
            id: "143",
            fkCountryCode: "CAN",
            TimezoneTitle: "America/Iqaluit",
        },
        {
            id: "144",
            fkCountryCode: "CAN",
            TimezoneTitle: "America/Pangnirtung",
        },
        {
            id: "145",
            fkCountryCode: "CAN",
            TimezoneTitle: "America/Resolute",
        },
        {
            id: "146",
            fkCountryCode: "CAN",
            TimezoneTitle: "America/Atikokan",
        },
        {
            id: "147",
            fkCountryCode: "CAN",
            TimezoneTitle: "America/Rankin_Inlet",
        },
        {
            id: "148",
            fkCountryCode: "CAN",
            TimezoneTitle: "America/Winnipeg",
        },
        {
            id: "149",
            fkCountryCode: "CAN",
            TimezoneTitle: "America/Rainy_River",
        },
        {
            id: "150",
            fkCountryCode: "CAN",
            TimezoneTitle: "America/Regina",
        },
        {
            id: "151",
            fkCountryCode: "CAN",
            TimezoneTitle: "America/Swift_Current",
        },
        {
            id: "152",
            fkCountryCode: "CAN",
            TimezoneTitle: "America/Edmonton",
        },
        {
            id: "153",
            fkCountryCode: "CAN",
            TimezoneTitle: "America/Cambridge_Bay",
        },
        {
            id: "154",
            fkCountryCode: "CAN",
            TimezoneTitle: "America/Yellowknife",
        },
        {
            id: "155",
            fkCountryCode: "CAN",
            TimezoneTitle: "America/Inuvik",
        },
        {
            id: "156",
            fkCountryCode: "CAN",
            TimezoneTitle: "America/Dawson_Creek",
        },
        {
            id: "157",
            fkCountryCode: "CAN",
            TimezoneTitle: "America/Vancouver",
        },
        {
            id: "158",
            fkCountryCode: "CAN",
            TimezoneTitle: "America/Whitehorse",
        },
        {
            id: "159",
            fkCountryCode: "CAN",
            TimezoneTitle: "America/Dawson",
        },
        {
            id: "160",
            fkCountryCode: "CPV",
            TimezoneTitle: "Atlantic/Cape_Verde",
        },
        {
            id: "161",
            fkCountryCode: "KAZ",
            TimezoneTitle: "Asia/Almaty",
        },
        {
            id: "162",
            fkCountryCode: "KAZ",
            TimezoneTitle: "Asia/Qyzylorda",
        },
        {
            id: "163",
            fkCountryCode: "KAZ",
            TimezoneTitle: "Asia/Aqtobe",
        },
        {
            id: "164",
            fkCountryCode: "KAZ",
            TimezoneTitle: "Asia/Aqtau",
        },
        {
            id: "165",
            fkCountryCode: "KAZ",
            TimezoneTitle: "Asia/Oral",
        },
        {
            id: "166",
            fkCountryCode: "KEN",
            TimezoneTitle: "Africa/Nairobi",
        },
        {
            id: "167",
            fkCountryCode: "CAF",
            TimezoneTitle: "Africa/Bangui",
        },
        {
            id: "168",
            fkCountryCode: "CHN",
            TimezoneTitle: "Asia/Shanghai",
        },
        {
            id: "169",
            fkCountryCode: "CHN",
            TimezoneTitle: "Asia/Harbin",
        },
        {
            id: "170",
            fkCountryCode: "CHN",
            TimezoneTitle: "Asia/Chongqing",
        },
        {
            id: "171",
            fkCountryCode: "CHN",
            TimezoneTitle: "Asia/Urumqi",
        },
        {
            id: "172",
            fkCountryCode: "CHN",
            TimezoneTitle: "Asia/Kashgar",
        },
        {
            id: "173",
            fkCountryCode: "KGZ",
            TimezoneTitle: "Asia/Bishkek",
        },
        {
            id: "174",
            fkCountryCode: "KIR",
            TimezoneTitle: "Pacific/Tarawa",
        },
        {
            id: "175",
            fkCountryCode: "KIR",
            TimezoneTitle: "Pacific/Enderbury",
        },
        {
            id: "176",
            fkCountryCode: "KIR",
            TimezoneTitle: "Pacific/Kiritimati",
        },
        {
            id: "177",
            fkCountryCode: "COL",
            TimezoneTitle: "America/Bogota",
        },
        {
            id: "178",
            fkCountryCode: "COM",
            TimezoneTitle: "Indian/Comoro",
        },
        {
            id: "179",
            fkCountryCode: "COG",
            TimezoneTitle: "Africa/Brazilazzaville",
        },
        {
            id: "180",
            fkCountryCode: "COD",
            TimezoneTitle: "Africa/Kinshasa",
        },
        {
            id: "181",
            fkCountryCode: "COD",
            TimezoneTitle: "Africa/Lubumbashi",
        },
        {
            id: "182",
            fkCountryCode: "CCK",
            TimezoneTitle: "Indian/Cocos",
        },
        {
            id: "183",
            fkCountryCode: "KOR",
            TimezoneTitle: "Asia/Seoul",
        },
        {
            id: "184",
            fkCountryCode: "GRC",
            TimezoneTitle: "Europe/Athens",
        },
        {
            id: "185",
            fkCountryCode: "HRV",
            TimezoneTitle: "Europe/Zagreb",
        },
        {
            id: "186",
            fkCountryCode: "CUB",
            TimezoneTitle: "America/Havana",
        },
        {
            id: "187",
            fkCountryCode: "KWT",
            TimezoneTitle: "Asia/Kuwait",
        },
        {
            id: "188",
            fkCountryCode: "CYP",
            TimezoneTitle: "Asia/Nicosia",
        },
        {
            id: "189",
            fkCountryCode: "LAO",
            TimezoneTitle: "Asia/Vientiane",
        },
        {
            id: "190",
            fkCountryCode: "LVA",
            TimezoneTitle: "Europe/Riga",
        },
        {
            id: "191",
            fkCountryCode: "LSO",
            TimezoneTitle: "Africa/Maseru",
        },
        {
            id: "192",
            fkCountryCode: "LBN",
            TimezoneTitle: "Asia/Beirut",
        },
        {
            id: "193",
            fkCountryCode: "LBR",
            TimezoneTitle: "Africa/Monrovia",
        },
        {
            id: "194",
            fkCountryCode: "LBY",
            TimezoneTitle: "Africa/Tripoli",
        },
        {
            id: "195",
            fkCountryCode: "LIE",
            TimezoneTitle: "Europe/Vaduz",
        },
        {
            id: "196",
            fkCountryCode: "LTU",
            TimezoneTitle: "Europe/Vilnius",
        },
        {
            id: "197",
            fkCountryCode: "LUX",
            TimezoneTitle: "Europe/Luxembourg",
        },
        {
            id: "198",
            fkCountryCode: "ESH",
            TimezoneTitle: "Africa/El_Aaiun",
        },
        {
            id: "199",
            fkCountryCode: "MAC",
            TimezoneTitle: "Asia/Macao",
        },
        {
            id: "200",
            fkCountryCode: "MDG",
            TimezoneTitle: "Indian/Antananarivo",
        },
        {
            id: "201",
            fkCountryCode: "MKD",
            TimezoneTitle: "Europe/Skopje",
        },
        {
            id: "202",
            fkCountryCode: "MWI",
            TimezoneTitle: "Africa/Blantyre",
        },
        {
            id: "203",
            fkCountryCode: "MDV",
            TimezoneTitle: "Indian/Maldives",
        },
        {
            id: "204",
            fkCountryCode: "MYS",
            TimezoneTitle: "Asia/Kuala_Lumpur",
        },
        {
            id: "205",
            fkCountryCode: "MYS",
            TimezoneTitle: "Asia/Kuching",
        },
        {
            id: "206",
            fkCountryCode: "MLI",
            TimezoneTitle: "Africa/Bamako",
        },
        {
            id: "207",
            fkCountryCode: "MLT",
            TimezoneTitle: "Europe/Malta",
        },
        {
            id: "208",
            fkCountryCode: "MAR",
            TimezoneTitle: "Africa/Casablanca",
        },
        {
            id: "209",
            fkCountryCode: "MHL",
            TimezoneTitle: "Pacific/Majuro",
        },
        {
            id: "210",
            fkCountryCode: "MHL",
            TimezoneTitle: "Pacific/Kwajalein",
        },
        {
            id: "211",
            fkCountryCode: "MTQ",
            TimezoneTitle: "America/Martinique",
        },
        {
            id: "212",
            fkCountryCode: "MRT",
            TimezoneTitle: "Africa/Nouakchott",
        },
        {
            id: "213",
            fkCountryCode: "MUS",
            TimezoneTitle: "Indian/Mauritius",
        },
        {
            id: "214",
            fkCountryCode: "MYT",
            TimezoneTitle: "Indian/Mayotte",
        },
        {
            id: "215",
            fkCountryCode: "MEX",
            TimezoneTitle: "America/Mexico_City",
        },
        {
            id: "216",
            fkCountryCode: "MEX",
            TimezoneTitle: "America/Cancun",
        },
        {
            id: "217",
            fkCountryCode: "MEX",
            TimezoneTitle: "America/Merida",
        },
        {
            id: "218",
            fkCountryCode: "MEX",
            TimezoneTitle: "America/Monterrey",
        },
        {
            id: "219",
            fkCountryCode: "MEX",
            TimezoneTitle: "America/Mazatlan",
        },
        {
            id: "220",
            fkCountryCode: "MEX",
            TimezoneTitle: "America/Chihuahua",
        },
        {
            id: "221",
            fkCountryCode: "MEX",
            TimezoneTitle: "America/Hermosillo",
        },
        {
            id: "222",
            fkCountryCode: "MEX",
            TimezoneTitle: "America/Tijuana",
        },
        {
            id: "223",
            fkCountryCode: "FSM",
            TimezoneTitle: "Pacific/Truk",
        },
        {
            id: "224",
            fkCountryCode: "FSM",
            TimezoneTitle: "Pacific/Ponape",
        },
        {
            id: "225",
            fkCountryCode: "FSM",
            TimezoneTitle: "Pacific/Kosrae",
        },
        {
            id: "226",
            fkCountryCode: "MDA",
            TimezoneTitle: "Europe/Chisinau",
        },
        {
            id: "227",
            fkCountryCode: "MCO",
            TimezoneTitle: "Europe/Monaco",
        },
        {
            id: "228",
            fkCountryCode: "MNG",
            TimezoneTitle: "Asia/Ulan_Bator",
        },
        {
            id: "229",
            fkCountryCode: "MNG",
            TimezoneTitle: "Asia/Hovd",
        },
        {
            id: "230",
            fkCountryCode: "MNG",
            TimezoneTitle: "Asia/Choibalsan",
        },
        {
            id: "231",
            fkCountryCode: "MSR",
            TimezoneTitle: "America/Montserrat",
        },
        {
            id: "232",
            fkCountryCode: "MOZ",
            TimezoneTitle: "Africa/Maputo",
        },
        {
            id: "233",
            fkCountryCode: "MMR",
            TimezoneTitle: "Asia/Qatar",
        },
        {
            id: "234",
            fkCountryCode: "NAM",
            TimezoneTitle: "Africa/Windhoek",
        },
        {
            id: "235",
            fkCountryCode: "NRU",
            TimezoneTitle: "Pacific/Nauru",
        },
        {
            id: "236",
            fkCountryCode: "NPL",
            TimezoneTitle: "Asia/Katmandu",
        },
        {
            id: "237",
            fkCountryCode: "NIC",
            TimezoneTitle: "America/Managua",
        },
        {
            id: "238",
            fkCountryCode: "NER",
            TimezoneTitle: "Africa/Niamey",
        },
        {
            id: "239",
            fkCountryCode: "NGA",
            TimezoneTitle: "Africa/Lagos",
        },
        {
            id: "240",
            fkCountryCode: "NIU",
            TimezoneTitle: "Pacific/Niue",
        },
        {
            id: "241",
            fkCountryCode: "NFK",
            TimezoneTitle: "Pacific/Norfolk",
        },
        {
            id: "242",
            fkCountryCode: "NOR",
            TimezoneTitle: "Europe/Oslo",
        },
        {
            id: "243",
            fkCountryCode: "CIV",
            TimezoneTitle: "Africa/Abidjan",
        },
        {
            id: "244",
            fkCountryCode: "OMN",
            TimezoneTitle: "Asia/Muscat",
        },
        {
            id: "245",
            fkCountryCode: "PAK",
            TimezoneTitle: "Asia/Karachi",
        },
        {
            id: "246",
            fkCountryCode: "PLW",
            TimezoneTitle: "Pacific/Palau",
        },
        {
            id: "247",
            fkCountryCode: "PAN",
            TimezoneTitle: "America/Panama",
        },
        {
            id: "248",
            fkCountryCode: "PNG",
            TimezoneTitle: "Pacific/Port_Moresby",
        },
        {
            id: "249",
            fkCountryCode: "PRY",
            TimezoneTitle: "America/Asuncion",
        },
        {
            id: "250",
            fkCountryCode: "PER",
            TimezoneTitle: "America/Lima",
        },
        {
            id: "251",
            fkCountryCode: "PCN",
            TimezoneTitle: "Pacific/Pitcairn",
        },
        {
            id: "252",
            fkCountryCode: "MNP",
            TimezoneTitle: "Pacific/Saipan",
        },
        {
            id: "253",
            fkCountryCode: "PRT",
            TimezoneTitle: "Europe/Lisbon",
        },
        {
            id: "254",
            fkCountryCode: "PRT",
            TimezoneTitle: "Atlantic/Madeira",
        },
        {
            id: "255",
            fkCountryCode: "PRT",
            TimezoneTitle: "Atlantic/Azores",
        },
        {
            id: "256",
            fkCountryCode: "PRI",
            TimezoneTitle: "America/Puerto_Rico",
        },
        {
            id: "257",
            fkCountryCode: "POL",
            TimezoneTitle: "Europe/Warsaw",
        },
        {
            id: "258",
            fkCountryCode: "GNQ",
            TimezoneTitle: "Africa/Malabo",
        },
        {
            id: "259",
            fkCountryCode: "QAT",
            TimezoneTitle: "Asia/Qatar",
        },
        {
            id: "260",
            fkCountryCode: "FRA",
            TimezoneTitle: "Europe/Paris",
        },
        {
            id: "261",
            fkCountryCode: "GUF",
            TimezoneTitle: "America/Cayenne",
        },
        {
            id: "262",
            fkCountryCode: "PYF",
            TimezoneTitle: "Pacific/Tahiti",
        },
        {
            id: "263",
            fkCountryCode: "PYF",
            TimezoneTitle: "Pacific/Marquesas",
        },
        {
            id: "264",
            fkCountryCode: "PYF",
            TimezoneTitle: "Pacific/Gambier",
        },
        {
            id: "265",
            fkCountryCode: "REU",
            TimezoneTitle: "Indian/Reunion",
        },
        {
            id: "266",
            fkCountryCode: "ROM",
            TimezoneTitle: "Europe/Bucharest",
        },
        {
            id: "267",
            fkCountryCode: "RWA",
            TimezoneTitle: "Africa/Kigali",
        },
        {
            id: "268",
            fkCountryCode: "SWE",
            TimezoneTitle: "Europe/Stockholm",
        },
        {
            id: "269",
            fkCountryCode: "SHN",
            TimezoneTitle: "Atlantic/St_Helena",
        },
        {
            id: "270",
            fkCountryCode: "KNA",
            TimezoneTitle: "America/St_Kitts",
        },
        {
            id: "271",
            fkCountryCode: "LCA",
            TimezoneTitle: "America/St_Lucia",
        },
        {
            id: "272",
            fkCountryCode: "VCT",
            TimezoneTitle: "America/St_Vincent",
        },
        {
            id: "273",
            fkCountryCode: "SPM",
            TimezoneTitle: "America/Miquelon",
        },
        {
            id: "274",
            fkCountryCode: "DEU",
            TimezoneTitle: "Europe/Berlin",
        },
        {
            id: "275",
            fkCountryCode: "SLB",
            TimezoneTitle: "Pacific/Guadalcanal",
        },
        {
            id: "276",
            fkCountryCode: "ZMB",
            TimezoneTitle: "Africa/Lusaka",
        },
        {
            id: "277",
            fkCountryCode: "WSM",
            TimezoneTitle: "Pacific/Samoa",
        },
        {
            id: "278",
            fkCountryCode: "SMR",
            TimezoneTitle: "Europe/San_Marino",
        },
        {
            id: "279",
            fkCountryCode: "STP",
            TimezoneTitle: "Africa/Sao_Tome",
        },
        {
            id: "280",
            fkCountryCode: "SAU",
            TimezoneTitle: "Asia/Riyadh",
        },
        {
            id: "281",
            fkCountryCode: "SEN",
            TimezoneTitle: "Africa/Dakar",
        },
        {
            id: "282",
            fkCountryCode: "SYC",
            TimezoneTitle: "Indian/Mahe",
        },
        {
            id: "283",
            fkCountryCode: "SLE",
            TimezoneTitle: "Africa/Freetown",
        },
        {
            id: "284",
            fkCountryCode: "SGP",
            TimezoneTitle: "Asia/Singapore",
        },
        {
            id: "285",
            fkCountryCode: "SVK",
            TimezoneTitle: "Europe/Bratislava",
        },
        {
            id: "286",
            fkCountryCode: "SVN",
            TimezoneTitle: "Europe/Ljubljana",
        },
        {
            id: "287",
            fkCountryCode: "SOM",
            TimezoneTitle: "Africa/Mogadishu",
        },
        {
            id: "288",
            fkCountryCode: "LKA",
            TimezoneTitle: "Asia/Colombo",
        },
        {
            id: "289",
            fkCountryCode: "FIN",
            TimezoneTitle: "Europe/Helsinki",
        },
        {
            id: "290",
            fkCountryCode: "SUR",
            TimezoneTitle: "America/Paramaribo",
        },
        {
            id: "291",
            fkCountryCode: "SWZ",
            TimezoneTitle: "Africa/Mbabane",
        },
        {
            id: "292",
            fkCountryCode: "CHE",
            TimezoneTitle: "Europe/Zurich",
        },
        {
            id: "293",
            fkCountryCode: "TJK",
            TimezoneTitle: "Asia/Dushanbe",
        },
        {
            id: "294",
            fkCountryCode: "TWN",
            TimezoneTitle: "Asia/Taipei",
        },
        {
            id: "295",
            fkCountryCode: "TZA",
            TimezoneTitle: "Africa/Dar_es_Salaam",
        },
        {
            id: "296",
            fkCountryCode: "DNK",
            TimezoneTitle: "Europe/Copenhagen",
        },
        {
            id: "297",
            fkCountryCode: "THA",
            TimezoneTitle: "Asia/Bangkok",
        },
        {
            id: "298",
            fkCountryCode: "TGO",
            TimezoneTitle: "Africa/Lome",
        },
        {
            id: "299",
            fkCountryCode: "TKL",
            TimezoneTitle: "Pacific/Fakaofo",
        },
        {
            id: "300",
            fkCountryCode: "TON",
            TimezoneTitle: "Pacific/Tongatapu",
        },
        {
            id: "301",
            fkCountryCode: "TTO",
            TimezoneTitle: "America/Port_of_Spain",
        },
        {
            id: "302",
            fkCountryCode: "TCD",
            TimezoneTitle: "Africa/Ndjamena",
        },
        {
            id: "303",
            fkCountryCode: "CZE",
            TimezoneTitle: "Europe/Prague",
        },
        {
            id: "304",
            fkCountryCode: "TUN",
            TimezoneTitle: "Africa/Tunis",
        },
        {
            id: "305",
            fkCountryCode: "TUR",
            TimezoneTitle: "Asia/Istanbul",
        },
        {
            id: "306",
            fkCountryCode: "TKM",
            TimezoneTitle: "Asia/Ashgabat",
        },
        {
            id: "307",
            fkCountryCode: "TCA",
            TimezoneTitle: "America/Grand_Turk",
        },
        {
            id: "308",
            fkCountryCode: "TUV",
            TimezoneTitle: "Pacific/Funafuti",
        },
        {
            id: "309",
            fkCountryCode: "UGA",
            TimezoneTitle: "Africa/Kampala",
        },
        {
            id: "310",
            fkCountryCode: "UKR",
            TimezoneTitle: "Europe/Kiev",
        },
        {
            id: "311",
            fkCountryCode: "UKR",
            TimezoneTitle: "Europe/Uzhgorod",
        },
        {
            id: "312",
            fkCountryCode: "UKR",
            TimezoneTitle: "Europe/Zaporozhye",
        },
        {
            id: "313",
            fkCountryCode: "UKR",
            TimezoneTitle: "Europe/Simferopol",
        },
        {
            id: "314",
            fkCountryCode: "HUN",
            TimezoneTitle: "Europe/Budapest",
        },
        {
            id: "315",
            fkCountryCode: "URY",
            TimezoneTitle: "America/Montevideo",
        },
        {
            id: "316",
            fkCountryCode: "NCL",
            TimezoneTitle: "Pacific/Noumea",
        },
        {
            id: "317",
            fkCountryCode: "NZL",
            TimezoneTitle: "Pacific/Auckland",
        },
        {
            id: "318",
            fkCountryCode: "NZL",
            TimezoneTitle: "Pacific/Chatham",
        },
        {
            id: "319",
            fkCountryCode: "UZB",
            TimezoneTitle: "Asia/Tashkent",
        },
        {
            id: "320",
            fkCountryCode: "BLR",
            TimezoneTitle: "Europe/Minsk",
        },
        {
            id: "321",
            fkCountryCode: "WLF",
            TimezoneTitle: "Pacific/Wallis",
        },
        {
            id: "322",
            fkCountryCode: "VUT",
            TimezoneTitle: "Pacific/Efate",
        },
        {
            id: "323",
            fkCountryCode: "VAT",
            TimezoneTitle: "Europe/Vatican",
        },
        {
            id: "324",
            fkCountryCode: "VEN",
            TimezoneTitle: "America/Caracas",
        },
        {
            id: "325",
            fkCountryCode: "RUS",
            TimezoneTitle: "Europe/Kaliningrad",
        },
        {
            id: "326",
            fkCountryCode: "RUS",
            TimezoneTitle: "Europe/Moscow",
        },
        {
            id: "327",
            fkCountryCode: "RUS",
            TimezoneTitle: "Europe/Volgograd",
        },
        {
            id: "328",
            fkCountryCode: "RUS",
            TimezoneTitle: "Europe/Samara",
        },
        {
            id: "329",
            fkCountryCode: "RUS",
            TimezoneTitle: "Asia/Yekaterinburg",
        },
        {
            id: "330",
            fkCountryCode: "RUS",
            TimezoneTitle: "Asia/Omsk",
        },
        {
            id: "331",
            fkCountryCode: "RUS",
            TimezoneTitle: "Asia/Novosibirsk",
        },
        {
            id: "332",
            fkCountryCode: "RUS",
            TimezoneTitle: "Asia/Krasnoyarsk",
        },
        {
            id: "333",
            fkCountryCode: "RUS",
            TimezoneTitle: "Asia/Irkutsk",
        },
        {
            id: "334",
            fkCountryCode: "RUS",
            TimezoneTitle: "Asia/Yakutsk",
        },
        {
            id: "335",
            fkCountryCode: "RUS",
            TimezoneTitle: "Asia/Vladivostok",
        },
        {
            id: "336",
            fkCountryCode: "RUS",
            TimezoneTitle: "Asia/Sakhalin",
        },
        {
            id: "337",
            fkCountryCode: "RUS",
            TimezoneTitle: "Asia/Magadan",
        },
        {
            id: "338",
            fkCountryCode: "RUS",
            TimezoneTitle: "Asia/Kamchatka",
        },
        {
            id: "339",
            fkCountryCode: "RUS",
            TimezoneTitle: "Asia/Anadyr",
        },
        {
            id: "340",
            fkCountryCode: "VNM",
            TimezoneTitle: "Asia/Ho_Chi_Minh",
        },
        {
            id: "341",
            fkCountryCode: "EST",
            TimezoneTitle: "Europe/Tallinn",
        },
        {
            id: "342",
            fkCountryCode: "USA",
            TimezoneTitle: "America/New_York",
        },
        {
            id: "343",
            fkCountryCode: "USA",
            TimezoneTitle: "America/Detroit",
        },
        {
            id: "344",
            fkCountryCode: "USA",
            TimezoneTitle: "America/Kentucky/Louisville",
        },
        {
            id: "345",
            fkCountryCode: "USA",
            TimezoneTitle: "America/Kentucky/Monticello",
        },
        {
            id: "346",
            fkCountryCode: "USA",
            TimezoneTitle: "America/Indiana/Indianapolis",
        },
        {
            id: "347",
            fkCountryCode: "USA",
            TimezoneTitle: "America/Indiana/Knox",
        },
        {
            id: "348",
            fkCountryCode: "USA",
            TimezoneTitle: "America/Indiana/Winamac",
        },
        {
            id: "349",
            fkCountryCode: "USA",
            TimezoneTitle: "America/Indiana/Marengo",
        },
        {
            id: "350",
            fkCountryCode: "USA",
            TimezoneTitle: "America/Indiana/Vevay",
        },
        {
            id: "351",
            fkCountryCode: "USA",
            TimezoneTitle: "America/Chicago",
        },
        {
            id: "352",
            fkCountryCode: "USA",
            TimezoneTitle: "America/Indiana/Vincennes",
        },
        {
            id: "353",
            fkCountryCode: "USA",
            TimezoneTitle: "America/Indiana/Petersburg",
        },
        {
            id: "354",
            fkCountryCode: "USA",
            TimezoneTitle: "America/Menominee",
        },
        {
            id: "355",
            fkCountryCode: "USA",
            TimezoneTitle: "America/North_Dakota/Center",
        },
        {
            id: "356",
            fkCountryCode: "USA",
            TimezoneTitle: "America/North_Dakota/New_Salem",
        },
        {
            id: "357",
            fkCountryCode: "USA",
            TimezoneTitle: "America/Denver",
        },
        {
            id: "358",
            fkCountryCode: "USA",
            TimezoneTitle: "America/Boise",
        },
        {
            id: "359",
            fkCountryCode: "USA",
            TimezoneTitle: "America/Shiprock",
        },
        {
            id: "360",
            fkCountryCode: "USA",
            TimezoneTitle: "America/Phoenix",
        },
        {
            id: "361",
            fkCountryCode: "USA",
            TimezoneTitle: "America/Los Angeles",
        },
        {
            id: "362",
            fkCountryCode: "USA",
            TimezoneTitle: "America/Anchorage",
        },
        {
            id: "363",
            fkCountryCode: "USA",
            TimezoneTitle: "America/Juneau",
        },
        {
            id: "364",
            fkCountryCode: "USA",
            TimezoneTitle: "America/Yakutat",
        },
        {
            id: "365",
            fkCountryCode: "USA",
            TimezoneTitle: "America/Nome",
        },
        {
            id: "366",
            fkCountryCode: "USA",
            TimezoneTitle: "America/Adak",
        },
        {
            id: "367",
            fkCountryCode: "USA",
            TimezoneTitle: "Pacific/Honolulu",
        },
        {
            id: "368",
            fkCountryCode: "VIR",
            TimezoneTitle: "America/Virgin",
        },
        {
            id: "369",
            fkCountryCode: "ZWE",
            TimezoneTitle: "Africa/Harare",
        },
        {
            id: "370",
            fkCountryCode: "PSE",
            TimezoneTitle: "Asia/Jerusalem",
        },
        {
            id: "371",
            fkCountryCode: "ATA",
            TimezoneTitle: "Antarctica/McMurdo",
        },
        {
            id: "372",
            fkCountryCode: "ATA",
            TimezoneTitle: "Antarctica/South_Pole",
        },
        {
            id: "373",
            fkCountryCode: "ATA",
            TimezoneTitle: "Antarctica/Rothera",
        },
        {
            id: "374",
            fkCountryCode: "ATA",
            TimezoneTitle: "Antarctica/Palmer",
        },
        {
            id: "375",
            fkCountryCode: "ATA",
            TimezoneTitle: "Antarctica/Mawson",
        },
        {
            id: "376",
            fkCountryCode: "ATA",
            TimezoneTitle: "Antarctica/Davis",
        },
        {
            id: "377",
            fkCountryCode: "ATA",
            TimezoneTitle: "Antarctica/Casey",
        },
        {
            id: "378",
            fkCountryCode: "ATA",
            TimezoneTitle: "Antarctica/Vostok",
        },
        {
            id: "379",
            fkCountryCode: "ATA",
            TimezoneTitle: "Antarctica/DumontDUrville",
        },
        {
            id: "380",
            fkCountryCode: "ATA",
            TimezoneTitle: "Antarctica/Syowa",
        },
        {
            id: "381",
            fkCountryCode: "BVT",
            TimezoneTitle: "",
        },
        {
            id: "382",
            fkCountryCode: "IOT",
            TimezoneTitle: "Indian/Chagos",
        },
        {
            id: "383",
            fkCountryCode: "SGS",
            TimezoneTitle: "Atlantic/South_Georgia",
        },
        {
            id: "384",
            fkCountryCode: "HMD",
            TimezoneTitle: "",
        },
        {
            id: "385",
            fkCountryCode: "ATF",
            TimezoneTitle: "",
        },
        {
            id: "386",
            fkCountryCode: "UMI",
            TimezoneTitle: "Pacific/Johnston",
        },
        {
            id: "387",
            fkCountryCode: "UMI",
            TimezoneTitle: "Pacific/Midway",
        },
        {
            id: "388",
            fkCountryCode: "UMI",
            TimezoneTitle: "Pacific/Wake",
        },
    ],
    industry: [
        {
            id: "1",
            IndustryName: "Agency or Sales House",
        },
        {
            id: "2",
            IndustryName: "Agriculture",
        },
        {
            id: "3",
            IndustryName: "Art and Design",
        },
        {
            id: "4",
            IndustryName: "Automotive",
        },
        {
            id: "5",
            IndustryName: "Construction",
        },
        {
            id: "6",
            IndustryName: "Consulting",
        },
        {
            id: "7",
            IndustryName: "Education",
        },
        {
            id: "8",
            IndustryName: "Engineering",
        },
    ],
    employmentType: [
        {
            label: "Full-time",
            id: "1",
        },
        {
            label: "Part-time",
            id: "2",
        },
        {
            label: "Intern",
            id: "3",
        },
        {
            label: "Contract",
            id: "4",
        },
    ],
    weekStarts: [
        {
            label: "Sunday",
            id: "1",
        },
        {
            label: "Monday",
            id: "2",
        },
        {
            label: "Tuesday",
            id: "3",
        },
        {
            label: "Wednesday",
            id: "4",
        },
        {
            label: "Thursday",
            id: "5",
        },
        {
            label: "Friday",
            id: "6",
        },
        {
            label: "Saturday",
            id: "7",
        },
    ],
    fiscalYearMonth: [
        {
            label: "January",
            id: "1",
        },
        {
            label: "February",
            id: "2",
        },
        {
            label: "March",
            id: "3",
        },
        {
            label: "April",
            id: "4",
        },
        {
            label: "May",
            id: "5",
        },
        {
            label: "June",
            id: "6",
        },
        {
            label: "July",
            id: "7",
        },
        {
            label: "August",
            id: "8",
        },
        {
            label: "September",
            id: "9",
        },
        {
            label: "October",
            id: "10",
        },
        {
            label: "November",
            id: "11",
        },
        {
            label: "December",
            id: "12",
        },
    ],
    relationship: [
        {
            label: "Spouse",
            id: "1",
        },
        {
            label: "Parent",
            id: "2",
        },
        {
            label: "Child",
            id: "3",
        },
        {
            label: "Sibling",
            id: "4",
        },
        {
            label: "Grandparent",
            id: "5",
        },
        {
            label: "Grandchild",
            id: "6",
        },
        {
            label: "Relative",
            id: "7",
        },
        {
            label: "Guardian",
            id: "8",
        },
    ],
};

export default Config;
