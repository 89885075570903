import { AuthActionTypes } from "../../../actions/feature/auth/authActions";

const initialState = {
    login: {
        apiResponse: {},
    },
    signup: {
        apiResponse: {},
    },
    forgotPassword: {
        apiResponse: {},
    },
    resetPassword: {
        apiResponse: {},
    },
    token: "",
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case AuthActionTypes.LOGIN_SUCCESS:
            return { ...state, login: { apiResponse: action.payload } };
        case AuthActionTypes.LOGIN_FAILURE:
            return { ...state, login: { apiResponse: action.payload } };
        case AuthActionTypes.SIGNUP_SUCCESS:
            return { ...state, signup: { apiResponse: action.payload } };
        case AuthActionTypes.SIGNUP_FAILURE:
            return { ...state, signup: { apiResponse: action.payload } };
        case AuthActionTypes.FORGOT_PASSWORD_SUCCESS:
            return { ...state, forgotPassword: { apiResponse: action.payload } };
        case AuthActionTypes.FORGOT_PASSWORD_FAILURE:
            return { ...state, forgotPassword: { apiResponse: action.payload } };
        case AuthActionTypes.RESET_PASSWORD_SUCCESS:
            return { ...state, resetPassword: { apiResponse: action.payload } };
        case AuthActionTypes.RESET_PASSWORD_FAILURE:
            return { ...state, resetPassword: { apiResponse: action.payload } };
        case AuthActionTypes.RESET:
            return { ...state, ...initialState };
        default:
            return state;
    }
};

export { reducer as authReducer };
