import React, { FC, useState, useEffect, MouseEvent } from "react";
import {
    Box,
    Typography,
    TableContainer,
    Table,
    TableHead,
    Paper,
    TableBody,
    TableCell,
    TableRow,
    Popper,
    Grow,
    ClickAwayListener,
    MenuList,
    MenuItem,
} from "@material-ui/core";
import MoreIcon from "@material-ui/icons/MoreHoriz";
import { useIsAdministratorHook } from "../../../../../utils/userAccess";

import { toTimeZoneDateOnly } from "./../../../../../utils/utils";
import ConfirmModal from "components/common/confirm-modal/confirmModal";
import { API } from "./../../../../sagas/feature/company/api";
import { formatAmount, toFormatDate, capsFirstLetter } from "../../../../../utils/app-utils";
import Alert from "./../../../../../components/common/alert/Alert";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import classnames from "classnames/bind";
import styles from "./../Styles.scss";
import colors from "colors";
const cx = classnames.bind(styles);

const useStyles = makeStyles(() =>
    createStyles({
        table: {
            minWidth: 650,
        },
        paper: {
            position: "absolute",
            right: "5px",
            top: "-18px",
        },
    })
);

interface PageProps {
    user: any;
    config: any;
    plans: [];
    userPlans: any;
    alert: {
        open: boolean;
        message: string;
        type: string;
    };
    openAlert: (payload: any) => null;
    closeAlert: () => null;
}

function createData(name: string, calories: number, fat: number, carbs: number, protein: number) {
    return { name, calories, fat, carbs, protein };
}

const PlanHistory: FC<PageProps> = ({ user, config, plans, userPlans, alert, openAlert, closeAlert }) => {
    const isAdministratorAccess = useIsAdministratorHook();
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [showCancelDialog, setShowCancelDialog] = useState<boolean>(false);
    const [selectedPlan, setSelectedPlan] = useState<any>({});
    const [transactions, setTransactions] = useState<any>([]);
    const [timeZoneName, setTimeZoneName] = useState<string>("");
    const [dateFormatLabel, setDateFormatLabel] = useState<string>("");

    const { timeZoneId, dateFormatId } = user || {};

    useEffect(() => {
        if (isAdministratorAccess) {
            API.getCompanyPaymentHistory().then(
                (res) => {
                    if (res.status === "SUCCESS") {
                        setTransactions(res.data);
                    }
                },
                (err) => {
                    console.log(err);
                }
            );
        }
    }, [isAdministratorAccess]);

    useEffect(() => {
        const { timezone } = config;
        if (timezone?.length) {
            const tz = timezone.find((item: any) => item.id === timeZoneId);
            setTimeZoneName(tz?.TimezoneTitle || "");
        }
    }, [timeZoneId]);

    useEffect(() => {
        const { dateFormat } = config;
        if (dateFormat?.length) {
            const dtFormat = dateFormat.find((item: any) => item.id === dateFormatId);
            setDateFormatLabel(dtFormat?.DateFormatValue || "");
        }
    }, [dateFormatId]);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick = (event: MouseEvent<HTMLAnchorElement>, id: string) => {
        setAnchorEl(event.currentTarget as any);
        const plan = userPlans.find((plan) => plan.id === id);
        setSelectedPlan(plan);
    };

    const handleClosePlan = () => {
        setShowCancelDialog(true);
    };

    // const getPlanPrice = (planPrice: any, discount: any, duration: any) => {
    //     if (duration === "yearly") {
    //         const price = Math.floor(planPrice - (planPrice * 20) / 100);
    //         return formatAmount(price);
    //     }
    //     return formatAmount(planPrice);
    // };

    const getStatus = (key: string) => {
        const objMap = {
            not_started: "Not started",
            active: "Active",
            inactive: "Inactive",
            expired: "Expired",
            deleted: "Deleted",
        };
        return objMap[key];
    };

    if (!isAdministratorAccess) {
        return <div></div>;
    }
    return (
        <div className={cx("pageOuter")}>
            {alert.open && alert.message && (
                <Alert onClose={() => {}} message={alert.message} type={alert.type}></Alert>
            )}
            <Box display={"flex"} flexDirection={"column"} width={"100%"}>
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Plan Name</TableCell>
                                {/* <TableCell align="left">Users</TableCell> */}
                                {/* <TableCell align="left">Additional Users</TableCell> */}
                                <TableCell align="left">Payment Status</TableCell>
                                <TableCell align="left">Price</TableCell>
                                <TableCell align="left">Duration</TableCell>
                                <TableCell align="left">Start Date</TableCell>
                                <TableCell align="left">End Date</TableCell>
                                <TableCell align="left">Plan Status</TableCell>
                                {/* <TableCell align="right"></TableCell> */}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {transactions.map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell component="th" scope="row">
                                        {row.UserCompanyPlanName}
                                        {/* {row.IsTrial === "Yes" ? " (Trial)" : ""} */}
                                    </TableCell>
                                    <TableCell align="left">
                                        {row.PaymentTransactionStatus !== "NoPaymentRequired"
                                            ? row.PaymentTransactionStatus
                                            : "Not required"}
                                    </TableCell>
                                    <TableCell align="left">
                                        {row.UserCompanyPlanTotalAmount ? `$${row.UserCompanyPlanTotalAmount}` : "Nil"}
                                    </TableCell>
                                    <TableCell align="left">
                                        {row.UserCompanyPlanName === "Free" ? (
                                            <>Unlimited</>
                                        ) : (
                                            <>{row.IsTrial === "Yes" ? "14 days" : capsFirstLetter(row.PlanDuration)}</>
                                        )}
                                    </TableCell>
                                    <TableCell align="left">
                                        {toTimeZoneDateOnly(row.UserCompanyPlanStartDate, "", dateFormatLabel)}{" "}
                                        <span className={cx("small")}>UTC</span>
                                    </TableCell>
                                    <TableCell align="left">
                                        {row.UserCompanyPlanName === "Free" &&
                                        row.UserCompanyPlanStatus === "active" ? (
                                            <>Not applicable</>
                                        ) : (
                                            <>
                                                {toTimeZoneDateOnly(row.UserCompanyPlanEndDate, "", dateFormatLabel)}{" "}
                                                <span className={cx("small")}>UTC</span>
                                            </>
                                        )}
                                    </TableCell>
                                    <TableCell align="left">{getStatus(row.UserCompanyPlanStatus)}</TableCell>
                                    {/* <TableCell>
                                        <Box>
                                            <Link
                                                style={{ cursor: "pointer", display: "flex", alignItems: "right" }}
                                                onClick={(e: MouseEvent<HTMLAnchorElement>) => {
                                                    e.stopPropagation();
                                                    handleClick(e, row.id);
                                                }}
                                            >
                                                <MoreIcon />
                                            </Link>
                                        </Box>
                                    </TableCell> */}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>

            <Popper
                style={{ zIndex: 999 }}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                role={undefined}
                transition
                disablePortal
                placement="bottom-end"
            >
                {({ TransitionProps, placement }) => (
                    <Grow {...TransitionProps}>
                        <Paper className={classes.paper} style={{ transformOrigin: "right bottom" }} elevation={2}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList style={{ padding: 0 }}>
                                    {selectedPlan.UserCompanyPlanStatus === "not_started" ? (
                                        <MenuItem onClick={handleClosePlan}>Cancel Plan</MenuItem>
                                    ) : (
                                        <MenuItem disabled>Cancel Plan</MenuItem>
                                    )}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>

            <ConfirmModal
                open={showCancelDialog}
                title={"Cancel plan"}
                content={"Are you sure you want to cancel the plan"}
                textNo={"Cancel"}
                textYes={"Continue"}
                onClose={() => {
                    setShowCancelDialog(false);
                }}
                confirm={() => {
                    //console.log("cancel the payment");
                }}
            />
        </div>
    );
};

export default PlanHistory;
