import React, { FC, useState, useEffect } from "react";
import {
    Card,
    CardContent,
    Typography,
    Button,
    ListItemText,
    FormControl,
    FormLabel,
    FormControlLabel,
    RadioGroup,
    Radio,
    InputAdornment,
    Box,
} from "@material-ui/core";
import { toFormatDate } from "../../../../utils/app-utils";
import { API } from "./../../../sagas/feature/profile/api";
import DateFnsUtils from "@date-io/date-fns";
import { format } from "date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import CalendarIcon from "@material-ui/icons/CalendarToday";
import SelectField from "./../../../../components/common/select-field/SelectField";
import InputTextField from "./../../../../components/common/text-field/TextField";
import Alert from "./../../../../components/common/alert/Alert";
import { usePlanActiveHook } from "../../../../utils/userAccess";

import classnames from "classnames/bind";
import styles from "./Profile.scss";
const cx = classnames.bind(styles);

interface PageProps {
    profile: any;
    departments: any;
    designations: any;
    offices: any;
    config: any;
    alert: {
        open: boolean;
        message: string;
        type: string;
    };
    closeAlert: () => void;
    updateBasicInfoSuccess: (payload: any) => null;
    openSnackbar: (payload: any) => null;
    closeSnackbar: () => null;
    openAlert: (payload: any) => null;
    roles: [];
    currentUser: any;
    users: [];
}

const BasicInformation: FC<PageProps> = ({
    profile = {},
    departments = [],
    designations = [],
    offices = [],
    config = {},
    closeAlert,
    alert,
    updateBasicInfoSuccess,
    openSnackbar,
    closeSnackbar,
    openAlert,
    roles = [],
    currentUser,
    users,
}) => {
    const [edit, setEdit] = useState<boolean>(false);
    const isPlanActiveAccess = usePlanActiveHook();
    const [formValues, setFormValues] = useState<any>({
        id: "",
        userFullName: "",
        userEmail: "",
        mobileNumber: "",
        gender: "",
        departmentID: "",
        designationID: "",
        officeID: "",
        userJoinDate: "",
        employeeId: "",
        employmentTypeId: "",
        role: "",
        reportingManagerID: "",
    });
    const [formValidations, setFormValidations] = useState<any>({
        userFullName: false,
        userEmail: false,
    });

    useEffect(() => {
        setFormValues({
            id: profile.id,
            userFullName: profile.userFullName,
            userEmail: profile.userEmail,
            mobileNumber: profile.mobileNumber,
            gender: profile.gender,
            departmentID: profile.departmentID,
            designationID: profile.designationID,
            officeID: profile.officeID,
            userJoinDate: profile.userJoinDate,
            employeeId: profile.employeeId || "",
            employmentTypeId: profile.employmentTypeId,
            role: profile.companyRoleId,
            reportingManagerID: profile.reportingManager?.id,
        });
    }, [profile]);

    useEffect(() => {
        closeAlert();
    }, []);

    const getDepartmentOptions = () => {
        if (departments && departments.length) {
            return departments.map((department: any) => {
                return {
                    label: department.DepartmentName,
                    value: department.id,
                };
            });
        }
    };

    const getDesignationOptions = () => {
        if (designations && designations.length) {
            return designations.map((designation: any) => {
                return {
                    label: designation.DesignationName,
                    value: designation.id,
                };
            });
        }
    };

    const getOfficesOptions = () => {
        if (offices && offices.length) {
            return offices.map((office: any) => {
                return {
                    label: office.officeName,
                    value: office.id,
                };
            });
        }
    };

    const getEmploymentTypeOptions = () => {
        const { employmentType } = config;
        if (employmentType) {
            return employmentType.map((item: any) => {
                return {
                    label: item.label,
                    value: item.id,
                };
            });
        }
    };

    const getRoles = () => {
        return roles?.map((role: any) => {
            return {
                label: role.roleName,
                value: role.id,
            };
        });
    };

    const inputChangeHandler = (evt: any) => {
        setFormValues({ ...formValues, [evt.target.name]: evt.target.value });
    };

    const validateFormValues = () => {
        let isValid = true;
        let tempFormValidations = {};
        for (const [key, value] of Object.entries(formValues)) {
            if (key === "userFullName" || key === "userEmail") {
                if (!value) {
                    isValid = false;
                    tempFormValidations[`${key}`] = true;
                } else {
                    tempFormValidations[`${key}`] = false;
                }
            }
        }
        setFormValidations({ ...formValidations, ...tempFormValidations });
        return isValid;
    };

    const getEmploymentType = (id) => {
        return (
            ((config && config.employmentType && config.employmentType.find((item: any) => item.id === id)) || {})
                .label || "N/A"
        );
    };

    const getDepartmentName = (id) => {
        return ((departments && departments.find((item: any) => item.id === id)) || {}).DepartmentName || "N/A";
    };

    const getDesignationName = (id) => {
        return ((designations && designations.find((item: any) => item.id === id)) || {}).DesignationName || "N/A";
    };

    const getOfficeName = (id) => {
        return ((offices && offices.find((item: any) => item.id === id)) || {}).officeName || "N/A";
    };

    const onDateChange = (val: MaterialUiPickersDate, key: string) => {
        if (val) setFormValues({ ...formValues, ["userJoinDate"]: val });
    };

    const handleSave = () => {
        const formValuesUpdated = {
            ...formValues,
            userJoinDate: formValues.userJoinDate
                ? format(new Date(formValues.userJoinDate) || null, "yyyy-MM-dd")
                : null,
        };
        if (validateFormValues()) {
            API.updateBasicInfo(formValuesUpdated)
                .then(
                    (res) => {
                        if (res.status === "ERROR") {
                            if (res.description === "RECORD_ALREADY_EXIST") {
                                openAlert({ message: "Email already exists", type: "error" });
                            } else {
                                openAlert({ message: "Invalid error, please try again", type: "error" });
                            }
                        } else {
                            openSnackbar("Details updated successfully");
                            updateBasicInfoSuccess(res.data.basicInfo);
                            setEdit(false);
                        }
                    },
                    (res) => {
                        openAlert({ message: "Invalid error, please try again", type: "error" });
                    }
                )
                .finally(() => {
                    setTimeout(() => {
                        closeSnackbar();
                    }, 3000);
                });
        }
    };

    const getUsersOptions = () => {
        return users
            ?.filter((item: any) => item?.id != profile?.id)
            .map((item: any) => {
                return {
                    label: item.UserFullName,
                    value: item.id,
                };
            });
    };

    return (
        <div style={{ marginBottom: 24 }} className={cx("profilePage")}>
            {alert.open && alert.message && edit && (
                <Alert onClose={() => {}} message={alert.message} type={alert.type}></Alert>
            )}
            <div className={cx("title")}>
                <Typography color="textSecondary" gutterBottom>
                    Basic Information
                </Typography>
                {!edit && (
                    <Button color="primary" onClick={() => setEdit(true)} disabled={!isPlanActiveAccess}>
                        Edit
                    </Button>
                )}
            </div>
            <Card variant="outlined">
                <CardContent style={{ padding: 24 }}>
                    <div className={cx("rowItem")}>
                        <div className={cx("colItem")}>
                            {!edit && <ListItemText primary="Full Name" secondary={profile.userFullName} />}
                            {edit && (
                                <InputTextField
                                    error={formValidations.userFullName}
                                    errorText={"This field is required"}
                                    name="userFullName"
                                    required={true}
                                    id="userFullName"
                                    label="Full Name"
                                    value={formValues.userFullName}
                                    onChange={inputChangeHandler}
                                />
                            )}
                        </div>
                        <div className={cx("colItem")}>
                            {!edit && <ListItemText primary="Email" secondary={profile.userEmail} />}
                            {edit && (
                                <InputTextField
                                    error={formValidations.userEmail}
                                    errorText={"Please enter a valid email address"}
                                    name="userEmail"
                                    required={true}
                                    id="userEmail"
                                    label="Email"
                                    value={formValues.userEmail}
                                    onChange={inputChangeHandler}
                                />
                            )}
                        </div>
                    </div>
                    <div className={cx("rowItem")}>
                        <div className={cx("colItem")}>
                            {!edit && <ListItemText primary="Mobile" secondary={profile.mobileNumber} />}
                            {edit && (
                                <InputTextField
                                    name="mobileNumber"
                                    required={false}
                                    id="mobileNumber"
                                    label="Mobile"
                                    value={formValues.mobileNumber}
                                    onChange={inputChangeHandler}
                                />
                            )}
                        </div>
                        <div className={cx("colItem")}>
                            {!edit && <ListItemText primary="Gender" secondary={profile.gender} />}
                            {edit && (
                                <FormControl required error={false} component="fieldset">
                                    <FormLabel component="legend">Gender</FormLabel>
                                    <RadioGroup
                                        aria-label="Gender"
                                        name="gender"
                                        value={formValues.gender}
                                        onChange={inputChangeHandler}
                                    >
                                        <FormControlLabel
                                            value="Male"
                                            checked={formValues.gender === "Male" ? true : false}
                                            control={<Radio />}
                                            label="Male"
                                        />
                                        <FormControlLabel
                                            value="Female"
                                            checked={formValues.gender === "Female" ? true : false}
                                            control={<Radio />}
                                            label="Female"
                                        />
                                        <FormControlLabel
                                            value="NoSpecify"
                                            checked={formValues.gender === "NoSpecify" ? true : false}
                                            control={<Radio />}
                                            label="No Specify"
                                        />
                                    </RadioGroup>
                                </FormControl>
                            )}
                        </div>
                    </div>
                    <div className={cx("rowItem")}>
                        <div className={cx("colItem")}>
                            {!edit && (
                                <ListItemText
                                    primary="Department"
                                    secondary={getDepartmentName(profile.departmentID)}
                                />
                            )}
                            {edit && (
                                <SelectField
                                    name={"departmentID"}
                                    required={false}
                                    label={"Select Department"}
                                    value={formValues.departmentID}
                                    onChange={inputChangeHandler}
                                    options={getDepartmentOptions()}
                                />
                            )}
                        </div>
                        <div className={cx("colItem")}>
                            {!edit && (
                                <ListItemText
                                    primary="Designation"
                                    secondary={getDesignationName(profile.designationID)}
                                />
                            )}
                            {edit && (
                                <SelectField
                                    name={"designationID"}
                                    required={false}
                                    label={"Select Designation"}
                                    value={formValues.designationID}
                                    onChange={inputChangeHandler}
                                    options={getDesignationOptions()}
                                />
                            )}
                        </div>
                    </div>
                    <div className={cx("rowItem")}>
                        <div className={cx("colItem")}>
                            {!edit && <ListItemText primary="Office" secondary={getOfficeName(profile.officeID)} />}
                            {edit && (
                                <SelectField
                                    name={"officeID"}
                                    label={"Select Office"}
                                    required={false}
                                    options={getOfficesOptions()}
                                    value={formValues.officeID}
                                    onChange={inputChangeHandler}
                                />
                            )}
                        </div>
                        <div className={cx("colItem")}>
                            {!edit && (
                                <ListItemText
                                    primary="Join Date"
                                    secondary={
                                        profile.userJoinDate ? toFormatDate(profile.userJoinDate, "dd-MM-yyyy") : ""
                                    }
                                />
                            )}
                            {edit && (
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker
                                        inputVariant="outlined"
                                        value={formValues.userJoinDate ? formValues.userJoinDate : null}
                                        format="dd-MM-yyyy"
                                        placeholder={"Join Date"}
                                        disableFuture={false}
                                        onChange={(date) => {
                                            console.log("date", date);
                                            onDateChange(date, "userJoinDate");
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <CalendarIcon color="primary" style={{ fontSize: 20 }} />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            )}
                        </div>
                    </div>
                    <div className={cx("rowItem")}>
                        <div className={cx("colItem")}>
                            {!edit && (
                                <ListItemText
                                    primary="Employment Type"
                                    secondary={getEmploymentType(profile.employmentTypeId)}
                                />
                            )}
                            {edit && (
                                <SelectField
                                    name="employmentTypeId"
                                    required={false}
                                    id={"employmentTypeId"}
                                    label={"Select Employment Type"}
                                    options={getEmploymentTypeOptions()}
                                    value={formValues.employmentTypeId}
                                    onChange={inputChangeHandler}
                                />
                            )}
                        </div>
                        <div className={cx("colItem")}>
                            {!edit ? (
                                <ListItemText primary="Role" secondary={profile.roleName || "N/A"} />
                            ) : (
                                <SelectField
                                    name="role"
                                    required={false}
                                    id={"role"}
                                    //disabled={profile.roleName === "ADMINISTRATOR" && currentUser?.id === profile.id}
                                    disabled={currentUser?.id === profile.id}
                                    label={"Select Role"}
                                    options={getRoles()}
                                    value={formValues.role}
                                    onChange={inputChangeHandler}
                                />
                            )}
                        </div>
                        {/* <div className={cx("colItem")}>
                            {!edit && (
                                <ListItemText primary="Employee ID Prefix" secondary={`${profile.employeeIDPrefix}`} />
                            )}
                            {edit && (
                                <>
                                    <InputTextField
                                        name="employeeIDPrefix"
                                        required={false}
                                        id="employeeIDPrefix"
                                        label="Employee ID Prefix"
                                        value={formValues.employeeIDPrefix}
                                        onChange={inputChangeHandler}
                                    />
                                </>
                            )}
                        </div> */}
                    </div>
                    {/* <div className={cx("rowItem")}>
                        <div className={cx("colItem")}>
                            {!edit && <ListItemText primary="Employee ID" secondary={`${profile.employeeId}`} />}
                            {edit && (
                                <>
                                    <InputTextField
                                        name="employeeId"
                                        required={false}
                                        id="employeeId"
                                        label="Employee ID"
                                        value={formValues.employeeId}
                                        onChange={inputChangeHandler}
                                    />
                                </>
                            )}
                        </div>
                    </div> */}
                    <div className={cx("rowItem")}>
                        <div className={cx("colItem")}>
                            {!edit && (
                                <ListItemText
                                    primary="Reporting Manager"
                                    secondary={profile?.reportingManager?.userFullName}
                                />
                            )}
                            {edit && (
                                <SelectField
                                    name={"reportingManagerID"}
                                    required={true}
                                    label={"Select Reporting Manager"}
                                    value={formValues.reportingManagerID}
                                    onChange={inputChangeHandler}
                                    options={getUsersOptions()}
                                />
                            )}
                        </div>
                    </div>
                    {edit && (
                        <Box className={cx("rowItem")} style={{ justifyContent: "flex-end", marginTop: 24 }}>
                            <Button
                                variant="outlined"
                                size="medium"
                                color="primary"
                                style={{ marginRight: 16 }}
                                onClick={() => {
                                    setEdit(false);
                                    closeAlert();
                                }}
                            >
                                Cancel
                            </Button>
                            <Button variant="contained" size="medium" color="primary" onClick={handleSave}>
                                Save
                            </Button>
                        </Box>
                    )}
                </CardContent>
            </Card>
        </div>
    );
};

export default BasicInformation;
