import React, { FC } from "react";
import { Typography, Link } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import PaymentSubHeader from "./PaymentSubHeader";
import BodyContent from "./../../common/body/bodyContent";
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";

import classnames from "classnames/bind";
import styles from "./payment.scss";
import colors from "colors";
const cx = classnames.bind(styles);

const HeaderContent = () => {
    return (
        <div className={cx("headerSection")}>
            <div className={cx("top")}>
                <div>
                    <Typography variant="h4">Payment</Typography>
                </div>
            </div>
        </div>
    );
};

const PaymentCancel: FC<{}> = () => {
    const history = useHistory();

    const redirectToBilling = () => {
        history.push("/hrm/billing");
    };

    return (
        <div className={cx("profileUserPage")}>
            <PaymentSubHeader selected={"billing"} />
            <BodyContent>
                <div className={"headerContainer"}>
                    <HeaderContent />
                </div>
                <div
                    className="contentBody"
                    style={{ height: "88vh", display: "flex", flexDirection: "column", alignItems: "center" }}
                >
                    <div className={cx("paymentSuccess")}>
                        <>
                            <ErrorOutlineOutlinedIcon color={"primary"} fontSize={"large"} />
                            <Typography variant={"h3"} style={{ maxWidth: 500, textAlign: "center" }}>
                                Payment has been cancelled
                            </Typography>
                            <Link onClick={redirectToBilling}>Go to Billing Page</Link>
                        </>
                    </div>
                </div>
            </BodyContent>
        </div>
    );
};

export default PaymentCancel;
