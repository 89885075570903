import React, { FC, useContext, useEffect, useState } from "react";
import { Typography, Button } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import ChevronLeftOutlinedIcon from "@material-ui/icons/ChevronLeftOutlined";

import AlertDialog from "./../../../../../components/common/alert-dialog/AlertDialog";

import Avatar from "./../../../../../components/common/avatar/Avatar";
import { Colors } from "./../../../../../utils/projectInterfaces";
import SelectField from "./../../../../../components/common/select-field/SelectField";
import InputTextField from "./../../../../../components/common/text-field/TextField";
import GroupAddOutlinedIcon from "@material-ui/icons/GroupAddOutlined";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import DoneOutlinedIcon from "@material-ui/icons/DoneOutlined";
import SaveIcon from "@material-ui/icons/Save";
import MembersDropdown from "../../../common/membersDropdown/MembersDropdown";
import { getAuthToken } from "./../../../../../utils/app-utils";

import { UserContext } from "../../../../../components/app/userContext";

import { API } from "./../../../../../sagas/feature/workspaces/api";

import { WorkspaceVisibility } from "../../../../../utils/projectInterfaces";

import classnames from "classnames/bind";
import styles from "./CardDetails.scss";
const cx = classnames.bind(styles);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: "11px 32px",
        },
    })
);

const projectLabelsBackground = [
    "#FF0000",
    "#519839",
    "#d9b51c",
    "#cd8313",
    "#89609e",
    "#0079bf",
    "#ff78cb",
    "#344563",
];

interface PageProps {
    setIsCreateLabel: (payload: boolean) => void;
    isCreateLabel: boolean;
    projectLabels: [];
    taskId: string;
    taskLabels: any;
}

const CreateLabel: FC<PageProps> = ({ setIsCreateLabel, isCreateLabel, projectLabels, taskId, taskLabels }) => {
    const [createLabel, setCreateLabel] = useState(false);
    const defaultLabel = { id: "", name: "", description: "", background: "" };
    const [labelData, setLabelData] = useState(defaultLabel);
    const [isAlertDialogOpen, setIsAlertDialogOpen] = useState(false);

    const contextObj: any = useContext(UserContext);
    const { workspaceId = "", projectId = "" } = useParams();

    const [formValues, setFormValues] = useState({
        name: "",
        selectedBackground: "",
        selectedProjectLabelId: "",
    });
    const [formValidations, setFormValidations] = useState({
        name: false,
    });
    console.log("formValues", formValues);
    useEffect(() => {
        console.log("labelData", labelData);
        if (labelData?.id) {
            setFormValues({
                name: labelData?.name,
                selectedBackground: labelData?.background,
                selectedProjectLabelId: labelData?.id,
            });
        } else {
            setFormValues({ name: "", selectedBackground: "", selectedProjectLabelId: "" });
        }
    }, [labelData]);

    const setFormData = (color: string) => {
        console.log("color", color);
        //setFormValues({ ...formValues, selectedBackground: item.background, selectedProjectLabelId: item.id });
        setFormValues({ ...formValues, selectedBackground: color });
    };

    const inputChangeHandler = (evt: any) => {
        setFormValues({ ...formValues, [evt.target.name]: evt.target.value });
        if (evt.target.value) setFormValidations({ ...formValidations, [evt.target.name]: false });
    };

    const handleUpdateLabel = () => {
        if (contextObj?.socketProjects.current?.readyState !== WebSocket.OPEN) return false;

        if (!formValues.name || !formValues.selectedProjectLabelId || !workspaceId) {
            if (!formValues.name) {
                setFormValidations({ ...formValidations, name: true });
            }
            if (!formValues.selectedProjectLabelId) {
                //setFormValidations({ ...formValidations, name: true });
            }

            return false;
        }

        contextObj?.socketProjects.current?.send(
            JSON.stringify({
                action: "updateLabel",
                body: {
                    taskId,
                    companyLabelId: formValues.selectedProjectLabelId,
                    name: formValues.name,
                    background: formValues.selectedBackground,
                },
                token: getAuthToken(),
            })
        );
        setLabelData(defaultLabel);
        setCreateLabel(false);
    };

    const handleCreateLabel = () => {
        if (contextObj?.socketProjects.current?.readyState !== WebSocket.OPEN) return false;

        if (!formValues.name || !workspaceId || !formValues.selectedBackground) {
            setFormValidations({ ...formValidations, name: true });
            return false;
        }

        contextObj?.socketProjects.current?.send(
            JSON.stringify({
                action: "createLabel",
                body: {
                    workspaceId,
                    projectId,
                    taskId,
                    name: formValues.name,
                    background: formValues.selectedBackground,
                },
                token: getAuthToken(),
            })
        );
        setLabelData(defaultLabel);
        setCreateLabel(false);
    };

    const handleLabelToggleAssign = (item: any) => {
        //delete
        if (isLabelForTask(item)) {
            const label = taskLabels && taskLabels.find((f) => f.labelId == item.id);
            contextObj?.socketProjects.current?.send(
                JSON.stringify({
                    action: "toggleLabel",
                    body: {
                        action: "delete",
                        taskId,
                        taskLabelId: label.id,
                        companyTasklabelId: item.id,
                    },
                    token: getAuthToken(),
                })
            );
        } else {
            // add
            contextObj?.socketProjects.current?.send(
                JSON.stringify({
                    action: "toggleLabel",
                    body: {
                        action: "create",
                        taskId,
                        taskLabelId: "",
                        companyTasklabelId: item.id,
                    },
                    token: getAuthToken(),
                })
            );
        }
    };

    const onClickLeave = (isYes: boolean) => {
        if (isYes) {
            setIsAlertDialogOpen(false);
            handleDeleteLabel();
        } else {
            setIsAlertDialogOpen(false);
        }
    };

    const handleDeleteLabel = () => {
        if (contextObj?.socketProjects.current?.readyState !== WebSocket.OPEN) return false;
        if (!labelData) return false;

        contextObj?.socketProjects.current?.send(
            JSON.stringify({
                action: "deleteLabel",
                body: {
                    companyLabelId: labelData.id,
                    taskId,
                },
                token: getAuthToken(),
            })
        );

        setLabelData(defaultLabel);
        setCreateLabel(false);
    };

    const isLabelForTask = (item: any) => {
        const label = taskLabels.find((f) => f.labelId == item.id);
        return label ? true : false;
    };

    return (
        <div className={cx("createModal", "projectModal")} style={{ left: 0 }}>
            <AlertDialog
                isOpen={isAlertDialogOpen}
                maxWidth={"md"}
                onClickYes={onClickLeave}
                title={`Delete Label`}
                description={`Are you sure you want to delete this label?`}
            />
            <div className={cx("header")}>
                <Typography>
                    {!createLabel ? (
                        "Labels"
                    ) : (
                        <div className={cx("rowCenter")}>
                            <ChevronLeftOutlinedIcon
                                onClick={() => {
                                    setCreateLabel(false);
                                }}
                            />
                            {labelData?.id ? "Edit label" : "Create label"}
                        </div>
                    )}
                </Typography>
                <div
                    onClick={(evt) => {
                        evt.stopPropagation();
                        setIsCreateLabel(false);
                    }}
                >
                    <CloseOutlinedIcon />
                </div>
            </div>
            <div className={cx("bodySection")}>
                {!createLabel ? (
                    <div>
                        {projectLabels.map((item: any) => (
                            <div className={cx("labelBarSection")}>
                                <div
                                    className={cx("labelBar")}
                                    style={{ background: item.background }}
                                    onClick={() => handleLabelToggleAssign(item)}
                                >
                                    <Typography>{item.name}</Typography>
                                    {isLabelForTask(item) && <DoneOutlinedIcon style={{ color: "#FFF" }} />}
                                </div>
                                <div>
                                    <EditOutlinedIcon
                                        onClick={() => {
                                            setCreateLabel(true);
                                            setLabelData(item);
                                        }}
                                    />
                                </div>
                            </div>
                        ))}
                        <div className={cx("row1")} style={{ marginTop: "24px" }}>
                            <Button
                                variant="outlined"
                                size="small"
                                color={"primary"}
                                onClick={() => {
                                    setLabelData(defaultLabel);
                                    setCreateLabel(true);
                                }}
                            >
                                {"Create new label"}
                            </Button>
                        </div>
                    </div>
                ) : (
                    <div>
                        <div className={cx("row1")}>
                            <InputTextField
                                error={formValidations.name}
                                errorText={"This field is required"}
                                name="name"
                                required={true}
                                placeholder={"Label name"}
                                label="Label name"
                                value={formValues.name}
                                onChange={inputChangeHandler}
                            />
                        </div>
                        <Typography>Label color</Typography>
                        <div className={cx("row1", "wrap")}>
                            {projectLabelsBackground.map((color: any) => (
                                <div
                                    className={cx("color")}
                                    style={{ backgroundColor: color }}
                                    onClick={() => {
                                        if (!labelData?.id) {
                                            //create
                                            setFormData(color);
                                        } else {
                                            setFormData(color);
                                            //update, color lable cannot change
                                        }
                                    }}
                                >
                                    {formValues.selectedBackground === color && <DoneOutlinedIcon />}
                                </div>
                            ))}
                            {/* {projectLabelsBackground.map((color: any) => (
                                <div
                                    className={cx("color")}
                                    style={{ backgroundColor: item.background }}
                                    onClick={() => {
                                        if (!labelData?.id) {
                                            //create
                                            setFormData(item);
                                        } else {
                                            //update, color lable cannot change
                                        }
                                    }}
                                >
                                    {formValues.selectedProjectLabelId === item.id && <DoneOutlinedIcon />}
                                </div>
                            ))} */}
                        </div>

                        <div className={cx("row1", "buttons")} style={{ marginTop: "24px" }}>
                            <Button
                                variant="outlined"
                                color={"primary"}
                                size="small"
                                onClick={() => (labelData?.id ? handleUpdateLabel() : handleCreateLabel())}
                            >
                                {"Save"}
                            </Button>
                            {labelData?.id && (
                                <Button
                                    variant="outlined"
                                    color={"inherit"}
                                    style={{ border: "1px solid #c00", background: "#c00", color: "#FFF" }}
                                    size="small"
                                    onClick={() => setIsAlertDialogOpen(true)}
                                >
                                    {"Delete"}
                                </Button>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default CreateLabel;
