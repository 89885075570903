import React, { FC, useEffect } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { Card, CardContent, Typography, Button, CardActions, Box } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import AlertDialog from "./../../../../../components/common/alert-dialog/AlertDialog";

import { getOffices, updateOffice, deleteOffice } from "./../../../../actions/officeActions";
import { toggleDrawer } from "./../../../../../actions/feature/app/appActions";
import SettingsSubHeader from "./../SettingsSubHeader";
import BodyContent from "./../../../common/body/bodyContent";
import AddOffice from "./AddOffice";
import Alert from "./../../../../../components/common/alert/Alert";
import { useAllowActionsHook } from "../../../../../utils/userAccess";
import NotFound from "../../notFound/NotFound";
import {
    getCountryName,
    getCurrencyName,
    getDateFormat,
    getTimeFormat,
    getTimeZone,
} from "../../../../../utils/app-utils";
import { usePlanActiveHook } from "../../../../../utils/userAccess";

import classnames from "classnames/bind";
import styles from "./Styles.scss";
const cx = classnames.bind(styles);

const HeaderContent = ({ onClick }) => {
    const isUserAccess = usePlanActiveHook();
    const isAdminAccess = useAllowActionsHook();
    return (
        <div className={cx("headerSection")}>
            <div className={cx("top")}>
                <div>
                    <Typography variant="h4">Office</Typography>
                </div>
                <Button
                    color="primary"
                    variant="outlined"
                    size="small"
                    onClick={onClick}
                    startIcon={<AddIcon />}
                    disabled={!isUserAccess || !isAdminAccess}
                >
                    Add Office
                </Button>
            </div>
        </div>
    );
};
interface PageProps {
    offices: any;
    config: {
        country: [];
        dateFormat: [];
        timeFormat: [];
        timezone: [];
        currency: [];
    };
    getOffices: () => null;
    updateOffice: (payload: any) => null;
    deleteOffice: (id: string) => null;
    isDrawerOpen: boolean;
    toggleDrawer: (isOpen: boolean) => null;
    alert: {
        open: boolean;
        message: string;
        type: string;
    };
}

const Office: FC<PageProps> = ({
    offices = [],
    config = {},
    getOffices,
    updateOffice,
    deleteOffice,
    isDrawerOpen,
    toggleDrawer,
    alert,
}) => {
    const isAdminAccess = useAllowActionsHook();
    const isUserAccess = usePlanActiveHook();
    const [isAlertDialogOpen, setIsAlertDialogOpen] = React.useState(false);
    const [selectedId, setSelectedId] = React.useState("");
    const [action, setAction] = React.useState("");

    useEffect(() => {
        if (action === "add" || (selectedId && action === "edit")) {
            toggleDrawer(true);
            setAction("");
        }
    }, [selectedId, action]);

    useEffect(() => {
        isAdminAccess && getOffices();
    }, [isAdminAccess]);

    const handleAdd = (event: any) => {
        setSelectedId("");
        setAction("add");
    };

    const handleEdit = (id: string) => {
        setAction("edit");
        setSelectedId(id);
    };

    const handleDelete = (id: string) => {
        setAction("");
        setSelectedId(id);
        setIsAlertDialogOpen(true);
    };

    const onClickYes = (isYes: boolean) => {
        if (!isAdminAccess) return false;
        setIsAlertDialogOpen(false);
        if (isYes === true) {
            deleteOffice(selectedId);
        } else {
            setSelectedId("");
        }
    };

    if (!isAdminAccess) {
        return <NotFound />;
    }

    return (
        <div className={cx("pageOuter")}>
            <SettingsSubHeader selected={"office"} />
            <BodyContent>
                <AlertDialog
                    isOpen={isAlertDialogOpen}
                    onClickYes={onClickYes}
                    title={"Delete Office?"}
                    description={"Are you sure you want to delete record?"}
                />
                <AddOffice isDrawerOpen={isDrawerOpen} toggleDrawer={toggleDrawer} id={selectedId} config={config} />
                <div className={"headerContainer"}>
                    <HeaderContent onClick={handleAdd} />
                </div>
                <div className="contentBody">
                    {alert.open && alert.message && (
                        <Alert onClose={() => {}} message={alert.message} type={alert.type}></Alert>
                    )}
                    <div className={cx("officeSection")}>
                        {offices && offices.length ? (
                            <>
                                {offices.map((office: any) => (
                                    <Card key={office.id}>
                                        <CardContent>
                                            <Box className={cx("cardRow")}>
                                                <Typography variant="body1" color="textSecondary">
                                                    Office Name
                                                </Typography>
                                                <Typography variant="body1" color="textSecondary">
                                                    {office.officeName}
                                                </Typography>
                                            </Box>
                                            <Box className={cx("cardRow")}>
                                                <Typography variant="body1" color="textSecondary">
                                                    Office Address
                                                </Typography>
                                                <Typography variant="body1" color="textSecondary">
                                                    {office.officeAddress}
                                                </Typography>
                                            </Box>
                                            <Box className={cx("cardRow")}>
                                                <Typography variant="body1" color="textSecondary">
                                                    Postal Code
                                                </Typography>
                                                <Typography variant="body1" color="textSecondary">
                                                    {office.officePostalCode}
                                                </Typography>
                                            </Box>
                                            <Box className={cx("cardRow")}>
                                                <Typography variant="body1" color="textSecondary">
                                                    Country
                                                </Typography>
                                                <Typography variant="body1" color="textSecondary">
                                                    {getCountryName(office.country, config.country)}
                                                </Typography>
                                            </Box>
                                            <Box className={cx("cardRow")}>
                                                <Typography variant="body1" color="textSecondary">
                                                    Date Format
                                                </Typography>
                                                <Typography variant="body1" color="textSecondary">
                                                    {getDateFormat(office.dateFormat, config.dateFormat)}
                                                </Typography>
                                            </Box>
                                            <Box className={cx("cardRow")}>
                                                <Typography variant="body1" color="textSecondary">
                                                    Time Format
                                                </Typography>
                                                <Typography variant="body1" color="textSecondary">
                                                    {getTimeFormat(office.timeFormat, config.timeFormat)}
                                                </Typography>
                                            </Box>
                                            <Box className={cx("cardRow")}>
                                                <Typography variant="body1" color="textSecondary">
                                                    Timezone
                                                </Typography>
                                                <Typography variant="body1" color="textSecondary">
                                                    {getTimeZone(office.timeZone, config.timezone)}
                                                </Typography>
                                            </Box>
                                            <Box className={cx("cardRow")}>
                                                <Typography variant="body1" color="textSecondary">
                                                    Currency
                                                </Typography>
                                                <Typography variant="body1" color="textSecondary">
                                                    {getCurrencyName(office.currency, config.currency)}
                                                </Typography>
                                            </Box>
                                            <Box className={cx("cardRow")}>
                                                <Typography variant="body1" color="textSecondary">
                                                    Employees
                                                </Typography>
                                                <Typography variant="body1" color="textSecondary">
                                                    {office.employeesCount}
                                                </Typography>
                                            </Box>
                                        </CardContent>
                                        <CardActions style={{ marginTop: 16, paddingLeft: 0 }}>
                                            <Button
                                                size="small"
                                                onClick={() => handleEdit(office.id)}
                                                color={"primary"}
                                                disabled={!isUserAccess}
                                            >
                                                Edit
                                            </Button>
                                            <Button
                                                size="small"
                                                onClick={() => handleDelete(office.id)}
                                                color={"secondary"}
                                                disabled={!isUserAccess}
                                            >
                                                Delete
                                            </Button>
                                        </CardActions>
                                    </Card>
                                ))}
                            </>
                        ) : (
                            <Card className={"noRecord"}>No records found</Card>
                        )}
                    </div>
                </div>
            </BodyContent>
        </div>
    );
};

/* istanbul ignore next */
const mapStateToProps = (state) => {
    return {
        offices: state.office.offices,
        config: state.app.config,
        isDrawerOpen: state.app.isDrawerOpen,
        alert: state.app.alert,
    };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => ({
    getOffices: () => dispatch(getOffices()),
    updateOffice: (payload: any) => dispatch(updateOffice(payload)),
    deleteOffice: (id: string) => dispatch(deleteOffice(id)),
    toggleDrawer: (isOpen: boolean) => dispatch(toggleDrawer(isOpen)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Office);
