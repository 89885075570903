import React, { FC } from "react";

import BodyContent from "./../../common/body/bodyContent";
import NotFoundImage from "./../../../../assets/images/not_found.svg";

import classnames from "classnames/bind";
import styles from "./Styles.scss";
import { Typography } from "@material-ui/core";
const cx = classnames.bind(styles);

const NotFound: FC<{}> = () => {
    return (
        <div className={cx("pageOuterNotFound")}>
            <BodyContent>
                <img src={NotFoundImage} />
                <Typography className={"text"}>Page not found</Typography>
            </BodyContent>
        </div>
    );
};

export default NotFound;
