import React, { FC } from "react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import classnames from "classnames/bind";
import styles from "./header.scss";

import ChatOutlinedIcon from "@material-ui/icons/ChatOutlined";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import AppsIcon from "@material-ui/icons/Apps";
import { Snackbar, Portal } from "@material-ui/core";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import PlaylistAddCheckOutlinedIcon from "@material-ui/icons/PlaylistAddCheckOutlined";
// import ViewKanbanOutlinedIcon from "@mui/icons-material/ViewKanbanOutlined";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import { useAllowActionsHook } from "../../../../utils/userAccess";

import { UserProfile } from "utils/types";
import { ContactSupportOutlined } from "@material-ui/icons";

const cx = classnames.bind(styles);

interface PageProps {
    snackbar: any;
    userProfile: UserProfile;
}

const Header: FC<PageProps> = ({ snackbar, userProfile }) => {
    const isAdminAccess = useAllowActionsHook();
    const history = useHistory();
    const { pathname = "" } = history.location || {};

    // if (!userProfile) {
    //     userProfile = {
    //         user: {
    //             id: "",
    //             UserEmail: "",
    //             UserFullName: "",
    //             RoleName: "",
    //         },
    //         company: {
    //             TotalUsers: "",
    //             TotalPlanUsers: "",
    //         },
    //     };
    // }

    return (
        <div className={cx("header")}>
            <Portal>
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={snackbar.open}
                    message={snackbar.message}
                    autoHideDuration={10000}
                    key={"top" + "center"}
                />
            </Portal>
            <ul>
                {/* <li>
                    <Link to="/dashboard">
                        <HomeOutlinedIcon fontSize="large" />
                    </Link>
                </li> */}
                <li
                    className={cx({
                        active: pathname.indexOf("hrm/chat") >= 0,
                    })}
                >
                    <Link to="/hrm/chat">
                        <ChatOutlinedIcon fontSize="large" />
                    </Link>
                </li>
                {isAdminAccess && (
                    <li
                        className={cx({
                            active: pathname.indexOf("hrm/users") >= 0,
                        })}
                    >
                        <Link to="/hrm/users">
                            <PeopleAltOutlinedIcon fontSize="large" />
                        </Link>
                    </li>
                )}
                <li
                    className={cx({
                        active: pathname.indexOf("hrm/workspaces") >= 0,
                    })}
                >
                    <Link to="/hrm/workspaces">
                        <PlaylistAddCheckOutlinedIcon fontSize="large" />
                    </Link>
                </li>
            </ul>
            <div className={cx("median")}></div>
            <ul>
                {isAdminAccess && (
                    <li
                        className={cx({
                            active:
                                pathname.indexOf("/hrm/company") >= 0 ||
                                pathname.indexOf("/hrm/office") >= 0 ||
                                pathname.indexOf("/hrm/department") >= 0 ||
                                pathname.indexOf("/hrm/designation") >= 0 ||
                                pathname.indexOf("/hrm/billing") >= 0,
                        })}
                    >
                        <Link to="/hrm/company">
                            <SettingsOutlinedIcon fontSize="large" />
                        </Link>
                    </li>
                )}
                <li
                    className={cx({
                        active:
                            pathname.indexOf("/hrm/profile") >= 0 ||
                            pathname.indexOf("/hrm/profile/change-password") >= 0,
                    })}
                >
                    <Link to="/hrm/profile">
                        <AccountCircleOutlinedIcon fontSize="large" />
                    </Link>
                </li>
            </ul>
        </div>
    );
};

/* istanbul ignore next */
const mapStateToProps = (state) => {
    return {
        snackbar: state.app.snackbar,
    };
};

export default connect(mapStateToProps, null)(Header);
