import React, { FC, useState, useContext } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { Typography, Button } from "@material-ui/core";
import Textarea from "react-textarea-autosize";
import AddIcon from "@material-ui/icons/Add";
import ClickOutside from "../ClickOutside/ClickOutside";
import DoneOutlinedIcon from "@material-ui/icons/DoneOutlined";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";

import { getAuthToken } from "./../../../../../utils/app-utils";
import { UserContext } from "../../../../../components/app/userContext";

import classnames from "classnames/bind";
import styles from "./CardAdder.scss";
const cx = classnames.bind(styles);

interface PageProps {
    listId: string;
    updateTask: (payload: any) => void;
}

const CardAdder: FC<PageProps> = ({ listId, updateTask }) => {
    // const { workspaceId = "", projectId = "" } = useParams();
    const contextObj: any = useContext(UserContext);
    const [taskTitle, setTaskTitle] = useState("");
    const [isOpen, setIsOpen] = useState(false);

    const toggleCardComposer = () => {
        setIsOpen(!isOpen);
    };

    const handleChange = (event) => {
        setTaskTitle(event.target.value);
    };

    const handleKeyDown = (event) => {
        if (event.keyCode === 13 && event.shiftKey === false) {
            handleSubmit(event);
        } else if (event.keyCode === 27) {
            toggleCardComposer();
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        if (contextObj?.socketProjects.current?.readyState !== WebSocket.OPEN || !taskTitle) return false;

        updateTask({ taskTitle, listId });

        contextObj?.socketProjects.current?.send(
            JSON.stringify({
                action: "createTask",
                body: {
                    parentTaskId: "",
                    taskTitle,
                    listId,
                    taskOrder: new Date().getTime(),
                },
                token: getAuthToken(),
            })
        );
        setTaskTitle("");
    };

    return isOpen ? (
        <ClickOutside handleClickOutside={toggleCardComposer}>
            <form onSubmit={handleSubmit} className="card-adder-textarea-wrapper">
                <Textarea
                    autoFocus
                    useCacheForDOMMeasurements
                    minRows={1}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    value={taskTitle}
                    className="card-adder-textarea"
                    placeholder="Enter task title..."
                    spellCheck={false}
                    // onBlur={toggleCardComposer}
                />
                <div className={cx("bottomButtons")}>
                    <CloseOutlinedIcon fontSize={"large"} onClick={toggleCardComposer} />
                    <DoneOutlinedIcon fontSize={"large"} onClick={handleSubmit} />
                </div>
            </form>
        </ClickOutside>
    ) : (
        <div className={cx("addtask")} onClick={toggleCardComposer}>
            <AddIcon />
            <Typography> Add a Task</Typography>
        </div>
    );
};

export default CardAdder;
