import React, { FC, useEffect, useState, useRef, useCallback } from "react";
import { connect } from "react-redux";
import { withRouter, useHistory, useLocation } from "react-router-dom";
import AppHrm from "./../../hrm/components/app/app";
import Header from "./../common/header/header";
import Layout from "./../common/layout/Layout";
import Routes from "./routes";
import { getUserProfile } from "./../../hrm/actions/userActions";
import { updateApiResponse } from "actions/feature/app/appActions";
import { removeCookie, getAuthToken, getDomainName } from "../../utils/app-utils";
import { UserProfile } from "utils/types";
import { getCompany } from "./../../hrm/actions/companyActions";
import { useAllowActionsHook } from "../../utils/userAccess";
import Alert from "./../../components/common/alert/Alert";
import SetupCompany from "components/common/setup-company/SetupCompany";
import UserProvider from "./userContext";
// import WithWorkspaceSocket from "./../../hoc/workspacesSocket";

import { API } from "./../../sagas/feature/rooms/api";
import {
    updateChatUsers,
    updateMessage,
    getRoom,
    getRooms,
    updateRoom,
    createRoom,
} from "./../../actions/feature/rooms/roomsActions";
import {
    getWorkspaces,
    getWorkspace,
    getWorkspaceProjectLists,
    addComment,
    updateTaskDetails,
    getWorkspaceTask,
    updateListDetails,
    updateDeleteTask,
    updateDeleteList,
    updateWorkspaceProject,
} from "./../../actions/feature/workspaces/workspacesActions";
import { MessageType } from "./../../utils/interfaces";
// import { getCurrDateTime } from "./../../utils/utils";
import { reset as resetUsers } from "./../../hrm/actions/userActions";
import { reset as resetProfile } from "./../../hrm/actions/profileActions";
import { reset as resetCompany } from "./../../hrm/actions/companyActions";
import { reset as resetDepartment } from "./../../hrm/actions/departmentActions";
import { reset as resetDesignation } from "./../../hrm/actions/designationActions";
import { reset as resetOffice } from "./../../hrm/actions/officeActions";
import { reset as resetPlan } from "./../../hrm/actions/planActions";
import { reset as resetRole } from "./../../hrm/actions/roleActions";
import { reset as resetAuth } from "./../../actions/feature/auth/authActions";
import { reset as resetFeeds } from "./../../actions/feature/feeds/feedsActions";

interface AppPage {
    getUserProfile: () => null;
    getCompany: () => null;
    company: any;
    rooms: [];
    updateApiResponse: (payload: any) => null;
    updateMessage: (payload: any) => void;
    updateChatUsers: (users: any) => void;
    getRoom: (payload: any) => void;
    getRooms: () => void;
    apiResponse: any;
    userProfile: UserProfile;
    resetUsers: () => void;
    resetProfile: () => void;
    resetCompany: () => void;
    resetDepartment: () => void;
    resetDesignation: () => void;
    resetOffice: () => void;
    resetPlan: () => void;
    resetRole: () => void;
    resetAuth: () => void;
    resetFeeds: () => void;
    updateRoom: (payload: any) => void;
    createRoom: (payload: any) => void;
    getWorkspaces: () => void;
    getWorkspace: (payload: any) => void;
    getWorkspaceProjectLists: (payload: any) => void;
    addComment: (comment: any) => void;
    updateTaskDetails: (payload: any) => void;
    getWorkspaceTask: (payload: any) => void;
    updateListDetails: (payload: any) => void;
    updateDeleteTask: (payload: any) => void;
    updateDeleteList: (payload: any) => void;
    updateWorkspaceProject: (payload: any) => void;
}

const App: FC<AppPage> = ({
    getUserProfile,
    getCompany,
    company,
    rooms,
    apiResponse,
    updateApiResponse,
    updateMessage,
    updateChatUsers,
    getRoom,
    getRooms,
    userProfile,
    resetUsers,
    resetProfile,
    resetCompany,
    resetDepartment,
    resetDesignation,
    resetOffice,
    resetPlan,
    resetRole,
    resetAuth,
    //resetFeeds,
    updateRoom,
    createRoom,
    getWorkspaces,
    getWorkspace,
    getWorkspaceProjectLists,
    addComment,
    updateTaskDetails,
    getWorkspaceTask,
    updateListDetails,
    updateDeleteTask,
    updateDeleteList,
    updateWorkspaceProject,
}) => {
    const history = useHistory();
    const isAdminAccess = useAllowActionsHook();
    const [isAlertOpen, setIsAlertOpen] = useState(false);
    const [isLogin, setIsLogin] = useState(false);
    const location = useLocation();

    const { user } = userProfile || {};

    useEffect(() => {
        if (apiResponse === 401) {
            updateApiResponse("");
            removeCookie();
            resetUsers();
            resetProfile();
            resetCompany();
            resetDepartment();
            resetDesignation();
            resetOffice();
            resetPlan();
            resetRole();
            resetAuth();
            //resetFeeds();
            socket.current = null;
            history.push("/login#accessdenied");
        }
    }, [apiResponse, user?.userCompanyPlanStatus]);

    useEffect(() => {
        if (getAuthToken()) {
            getUserProfile();
            isAdminAccess && getCompany();
        }
    }, [getAuthToken(), isAdminAccess]);

    useEffect(() => {
        if (user?.id) {
            setIsLogin(user?.id ? true : false);
        } else {
            setIsLogin(false);
        }
    }, [user]);

    useEffect(() => {
        if (user?.userCompanyPlanStatus && user?.userCompanyPlanStatus !== "active") {
            setIsAlertOpen(true);
            disConnectSocket();
        } else {
            setIsAlertOpen(false);
        }
    }, [user?.userCompanyPlanStatus, location]);

    useEffect(() => {
        const subdomainValue = getDomainName();
        const { id, CompanyDomain } = company || {};
        if (company && id && CompanyDomain && CompanyDomain !== "" && CompanyDomain !== subdomainValue) {
            // Invalid domain
            if (process.env.REACT_APP_ENV === "production") {
                window.location.href = `https://${CompanyDomain}.collabey.com/hrm/chat`;
            } else {
                //window.location.href = `http://localhost:7006/dashboard`;
            }
        }
    }, [company]);

    /* ===================================================
    =====================CHAT Sockets STARTS ================
    ====================================================== */

    let socket = useRef<WebSocket | null>(null);
    const onSocketOpen = useCallback(() => {
        //console.log("socket open", getCurrDateTime());
    }, []);
    const onSocketClose = useCallback(() => {
        //console.log("socket close");
        socket.current = null;
        connectSocket();
    }, []);

    const onSocketMessage = useCallback(
        (dataStr) => {
            const data = JSON.parse(dataStr);
            console.log("on socket chat", data);
            const { messageType, chatRoomId, messageByUser } = data;
            if (data) {
                if (messageType === MessageType.ROOM_UPDATED) {
                    updateRoom(data);
                } else if (messageType === MessageType.ROOM_CREATED) {
                    // redirect to new chatroomid page if same user login
                    createRoom(data);
                    if (messageByUser?.id == userProfile?.user?.id) {
                        history.push(`/hrm/chat/${chatRoomId}`);
                    }
                } else if (
                    messageType === MessageType.USER_REMOVED ||
                    messageType === MessageType.USER_ADDED ||
                    messageType === MessageType.MESSAGE ||
                    messageType === MessageType.MESSAGE_FILE
                ) {
                    updateMessage(data);
                    if (messageType === MessageType.USER_REMOVED || messageType === MessageType.USER_ADDED) {
                        const { room } = data;
                        updateChatUsers({ chatRoomId, users: room.chatRoomUsers });
                    }
                }
            }
        },
        [userProfile, rooms]
    );

    useEffect(() => {
        if (isLogin) {
            setInterval(() => {
                socket.current?.send(
                    JSON.stringify({
                        action: "ping",
                        token: getAuthToken(),
                    })
                );
            }, 60000 * 8); // 8 minutes //60000 * 8
        }
    }, []);

    const getRoomsData = (chatRoomId: string) => {
        API.getRooms()
            .then((chatRooms) => {
                if (chatRooms?.length) {
                    const room = chatRooms.find((chat) => chat.chatRoomId == chatRoomId);
                    if (room) {
                        const { users } = room;
                        updateChatUsers({ users, chatRoomId });
                    }
                }
            })
            .finally(() => {
                //
            });
    };

    document.addEventListener("visibilitychange", function() {
        //if (!document.hidden && isLogin && socket.current?.readyState !== WebSocket.OPEN) {
        if (!document.hidden && isLogin && socket.current?.readyState === undefined && !isAlertOpen) {
            connectSocket();
        }
    });

    const onDisconnect = useCallback((data) => {
        socket.current?.close();
    }, []);

    const disConnectSocket = () => {
        //console.log("DISCONNECT");
        socket.current?.close();
        socket.current = null;
    };

    const connectSocket = () => {
        if (isLogin) {
            try {
                socket.current = new WebSocket(`${process.env.REACT_APP_CHAT_WEB_SOCKET}?token=${getAuthToken()}`);
                socket.current.addEventListener("open", onSocketOpen);
                socket.current.addEventListener("close", onSocketClose);
                socket.current.addEventListener("message", (event) => {
                    onSocketMessage(event.data);
                });
            } catch (err) {
                //console.log("SOCKET ERROR", err);
            }
        }
    };

    useEffect(() => {
        //console.log("socket.current?.readyState", socket.current?.readyState);
        // if (isLogin && socket.current?.readyState !== WebSocket.OPEN) {
        if (isLogin && socket.current?.readyState === undefined && !isAlertOpen) {
            //console.log("socket init client");
            connectSocket();
        }
    }, [isLogin, socket.current]);

    useEffect(() => {
        return () => {
            socket.current?.close();
        };
    }, []);

    /* ===================================================
    =====================CHAT Sockets ENDS ================
    ====================================================== */

    /* ===================================================
    =====================Projects Sockets STARTS ================
    ====================================================== */

    let socketProjects = useRef<WebSocket | null>(null);
    const onSocketProjectsOpen = useCallback(() => {
        //console.log("socket open", getCurrDateTime());
    }, []);
    const onSocketProjectsClose = useCallback(() => {
        //console.log("socket close");
        socketProjects.current = null;
        connectProjectsSocket();
    }, []);

    const onSocketProjectsMessage = useCallback(
        (dataStr) => {
            const data = JSON.parse(dataStr);
            const { workspaceId, messageType, body } = data;
            if (data) {
                console.log("PROJECTS DATA", data);

                ///////////////// WORKSPCE /////////////
                if (messageType === "WORKSPACE_CREATED") {
                    getWorkspaces();
                    history.push(`/hrm/workspaces/${workspaceId}#projects`);
                } else if (messageType === "WORKSPACE_DELETED") {
                    history.push(`/hrm/workspaces`);
                } else if (messageType === "WORKSPACE_UPDATED" || messageType === "WORKSPACE_MEMBER_ADDED") {
                    getWorkspace(workspaceId);
                    ///////////////// PROJECT /////////////
                } else if (messageType === "PROJECT_CREATED") {
                    getWorkspace(workspaceId);
                    // history.push(`/hrm/workspaces/${workspaceId}/${body?.projectId}`);
                    history.push(`/hrm/workspaces/${workspaceId}#projects`);
                } else if (messageType === "PROJECT_DELETED") {
                    getWorkspace(body?.workspaceId);
                } else if (messageType === "PROJECT_UPDATED") {
                    updateWorkspaceProject(body?.project);

                    ///////////////// LIST /////////////
                } else if (messageType === "LIST_CREATED") {
                    getWorkspace(workspaceId);
                    getWorkspaceProjectLists({
                        workspaceId,
                        projectId: body?.projectId,
                    });
                } else if (messageType === "LIST_UPDATED") {
                    // updateListDetails(body?.list);
                    getWorkspaceProjectLists({
                        workspaceId,
                        projectId: body?.projectId,
                    });
                    // getWorkspaceProjectLists({ workspaceId, projectId: body?.projectId });
                } else if (messageType === "LIST_DELETED") {
                    console.log("LIST_DELETED", body);
                    updateDeleteList({ listId: body?.list?.listId });
                    // getWorkspaceProjectLists({ workspaceId, projectId: body?.projectId });

                    ///////////////// TASK /////////////
                } else if (messageType === "TASK_CREATED") {
                    console.log("task created");
                    getWorkspaceProjectLists({
                        workspaceId,
                        projectId: body?.projectId,
                    });
                } else if (messageType === "TASK_UPDATED") {
                    if (body?.taskId) {
                        getWorkspaceTask(body?.taskId);
                    }
                    updateTaskDetails(body?.task);
                    getWorkspaceProjectLists({ workspaceId, projectId: body?.projectId });
                } else if (messageType === "TASK_DELETED") {
                    console.log("TASK_DELETED", body);
                    updateDeleteTask({ taskId: body?.task?.taskId, listId: body?.listId });
                    // getWorkspaceProjectLists({ workspaceId, projectId: body?.projectId });

                    ///////////////// COMMENT /////////////
                } else if (messageType === "COMMENT_CREATED") {
                    addComment({ taskId: body.taskId, comment: body.comment });
                    getWorkspaceProjectLists({ workspaceId, projectId: body?.projectId });
                } else if (
                    messageType === "LABEL_CREATED" ||
                    messageType === "LABEL_DELETED" ||
                    messageType === "LABEL_UPDATED"
                ) {
                    if (body?.taskId) {
                        getWorkspaceTask(body?.taskId);
                    }
                    getWorkspaceProjectLists({ workspaceId, projectId: body?.projectId });
                } else if (messageType === "TASK_FILE_CREATED" || messageType === "TASK_COMMENT_FILE_CREATED") {
                    if (body?.taskId && body?.file.id) {
                        getWorkspaceTask(body?.taskId);
                    }
                }
            }
        },
        [userProfile, rooms]
    );

    useEffect(() => {
        if (isLogin) {
            setInterval(() => {
                socketProjects.current?.send(
                    JSON.stringify({
                        action: "ping",
                        token: getAuthToken(),
                    })
                );
            }, 60000 * 8); // 8 minutes //60000 * 8
        }
    }, []);

    document.addEventListener("visibilitychange", function() {
        //if (!document.hidden && isLogin && socket.current?.readyState !== WebSocket.OPEN) {
        if (!document.hidden && isLogin && socketProjects.current?.readyState === undefined && !isAlertOpen) {
            connectProjectsSocket();
        }
    });

    const onDisconnectProjects = useCallback((data) => {
        socketProjects.current?.close();
    }, []);

    const disConnectSocketProjects = () => {
        //console.log("DISCONNECT");
        socketProjects.current?.close();
        socketProjects.current = null;
    };

    const connectProjectsSocket = () => {
        if (isLogin) {
            try {
                socketProjects.current = new WebSocket(
                    `${process.env.REACT_APP_PROJECTS_WEB_SOCKET}?token=${getAuthToken()}`
                );
                socketProjects.current.addEventListener("open", onSocketProjectsOpen);
                socketProjects.current.addEventListener("close", onSocketProjectsClose);
                socketProjects.current.addEventListener("message", (event) => {
                    onSocketProjectsMessage(event.data);
                });
            } catch (err) {
                //console.log("SOCKET ERROR", err);
            }
        }
    };

    useEffect(() => {
        //console.log("socket.current?.readyState", socket.current?.readyState);
        // if (isLogin && socket.current?.readyState !== WebSocket.OPEN) {
        if (isLogin && socketProjects.current?.readyState === undefined && !isAlertOpen) {
            //console.log("socket init client");
            connectProjectsSocket();
        }
    }, [isLogin, socketProjects.current]);

    useEffect(() => {
        return () => {
            socketProjects.current?.close();
        };
    }, []);

    /* ===================================================
    =====================Projects Sockets ENDS ================
    ====================================================== */

    const redirectToBilling = () => {
        history.push("/hrm/billing#upgrade");
    };

    const Message = () => {
        return (
            <div>
                Your plan has expired, please upgrade your plan{" "}
                <a className={"hyperlink"} onClick={redirectToBilling}>
                    here
                </a>
                {/* {location.pathname.indexOf("hrm/billing") < 0 && (
                    <a className={"hyperlink"} onClick={redirectToBilling}>
                        here
                    </a>
                )} */}
            </div>
        );
    };

    if (location.pathname.indexOf("hrm") > 0) {
        return (
            <UserProvider socket={socket} socketProjects={socketProjects}>
                {isAlertOpen && (
                    <Alert
                        isCloseVisible={true}
                        onClose={() => setIsAlertOpen(false)}
                        message={<Message />}
                        type={"expire"}
                    ></Alert>
                )}
                <SetupCompany />
                <AppHrm />
            </UserProvider>
        );
    } else {
        return (
            <UserProvider socket={socket} socketProjects={socketProjects}>
                {isAlertOpen && (
                    <Alert onClose={() => setIsAlertOpen(false)} message={<Message />} type={"expire"}></Alert>
                )}
                {location &&
                (location.pathname === "/" ||
                    location.pathname.search("login") >= 0 ||
                    location.pathname.search("signup") >= 0 ||
                    location.pathname.search("reset-password") >= 0 ||
                    location.pathname.search("activate-account") >= 0 ||
                    location.pathname.search("multi-accounts") >= 0 ||
                    location.pathname.search("forgot-password") >= 0) ? (
                    <Routes location={location} />
                ) : (
                    <Layout>
                        {/* <SetupCompany /> */}
                        <Header userProfile={userProfile} />
                        <Routes location={location} />
                    </Layout>
                )}
            </UserProvider>
        );
    }
};

/* istanbul ignore next */
const mapStateToProps = (state) => {
    return {
        apiResponse: state.app.apiResponse,
        userProfile: state.user.profile,
        company: state.company.company,
        rooms: state.rooms.list,
    };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch) => ({
    updateMessage: (payload: any) => dispatch(updateMessage(payload)),
    getUserProfile: () => dispatch(getUserProfile()),
    getCompany: () => dispatch(getCompany()),
    updateApiResponse: (payload: any) => updateApiResponse(payload),
    resetUsers: () => dispatch(resetUsers()),
    resetProfile: () => dispatch(resetProfile()),
    resetCompany: () => dispatch(resetCompany()),
    resetDepartment: () => dispatch(resetDepartment()),
    resetDesignation: () => dispatch(resetDesignation()),
    resetOffice: () => dispatch(resetOffice()),
    resetPlan: () => dispatch(resetPlan()),
    resetRole: () => dispatch(resetRole()),
    resetAuth: () => dispatch(resetAuth()),
    resetFeeds: () => dispatch(resetFeeds()),
    updateChatUsers: (users: any) => dispatch(updateChatUsers(users)),
    getRoom: (payload: any) => dispatch(getRoom(payload)),
    getRooms: () => dispatch(getRooms()),
    updateRoom: (payload: any) => dispatch(updateRoom(payload)),
    createRoom: (payload: any) => dispatch(createRoom(payload)),
    getWorkspaces: () => dispatch(getWorkspaces()),
    getWorkspace: (id: any) => dispatch(getWorkspace(id)),
    getWorkspaceProjectLists: (payload: any) => dispatch(getWorkspaceProjectLists(payload)),
    addComment: (comment: any) => dispatch(addComment(comment)),
    updateTaskDetails: (payload: any) => dispatch(updateTaskDetails(payload)),
    getWorkspaceTask: (payload: any) => dispatch(getWorkspaceTask(payload)),
    updateListDetails: (payload: any) => dispatch(updateListDetails(payload)),
    updateDeleteTask: (payload: any) => dispatch(updateDeleteTask(payload)),
    updateDeleteList: (payload: any) => dispatch(updateDeleteList(payload)),
    updateWorkspaceProject: (payload: any) => dispatch(updateWorkspaceProject(payload)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
