import { all, fork } from "redux-saga/effects";
import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import logger from "redux-logger";
import createSagaMiddleware from "redux-saga";
import rootReducer from "./";

import globalSagas from "../sagas";
import hrmSagas from "./../hrm/sagas";

function* rootSaga() {
    yield all([fork(hrmSagas), fork(globalSagas)]);
}

const middlewares = [];
export const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();
middlewares.push(routerMiddleware(history));
middlewares.push(sagaMiddleware);

if (process.env.NODE_ENV === `development`) {
    // middlewares.push(logger);
}

export const store = createStore(rootReducer(history), composeWithDevTools(applyMiddleware(...middlewares)));

sagaMiddleware.run(rootSaga);
