import React, { FC, ReactNode } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Avatar as Av } from "@material-ui/core";
import CameraAlt from "@material-ui/icons/CameraAlt";
import {
    deepOrange,
    deepPurple,
    indigo,
    red,
    cyan,
    purple,
    pink,
    lightBlue,
    lime,
    amber,
    lightGreen,
    teal,
} from "@material-ui/core/colors";
import classnames from "classnames/bind";
import styles from "./avatar.scss";

const cx = classnames.bind(styles);

const colors = [
    "orange",
    "purple",
    "cyan",
    "pink",
    "red",
    "indigo",
    "lightBlue",
    "lime",
    "amber",
    "lightGreen",
    "deepPurple",
    "teal",
];

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        orange: {
            color: theme.palette.getContrastText(deepOrange[500]),
            backgroundColor: deepOrange[500],
        },
        purple: {
            color: theme.palette.getContrastText(purple[500]),
            backgroundColor: purple[500],
        },
        cyan: {
            color: theme.palette.getContrastText(cyan[500]),
            backgroundColor: cyan[500],
        },
        pink: {
            color: theme.palette.getContrastText(pink[500]),
            backgroundColor: pink[500],
        },
        red: {
            color: theme.palette.getContrastText(red[500]),
            backgroundColor: red[500],
        },
        indigo: {
            color: theme.palette.getContrastText(indigo[500]),
            backgroundColor: indigo[500],
        },
        lightBlue: {
            color: theme.palette.getContrastText(lightBlue[500]),
            backgroundColor: lightBlue[500],
        },
        lime: {
            color: theme.palette.getContrastText(lime[500]),
            backgroundColor: lime[500],
        },
        amber: {
            color: theme.palette.getContrastText(amber[500]),
            backgroundColor: amber[500],
        },
        lightGreen: {
            color: theme.palette.getContrastText(lightGreen[500]),
            backgroundColor: lightGreen[500],
        },
        deepPurple: {
            color: theme.palette.getContrastText(deepPurple[500]),
            backgroundColor: deepPurple[500],
        },
        teal: {
            color: theme.palette.getContrastText(teal[500]),
            backgroundColor: teal[500],
        },
    })
);

interface AvatarProps {
    name?: string;
    bgColor?: string;
    children?: string | ReactNode;
    isEdit?: boolean;
    onEditClick?: () => void;
    backgroundColor?: string;
}

const Avatar: FC<AvatarProps> = ({ name, bgColor, children, isEdit = false, onEditClick, backgroundColor = "" }) => {
    const classes = useStyles();
    // if (!name) return <div>&nbsp;</div>;
    const nameFirstLetter = name?.charAt(0)?.toUpperCase();
    const randomNumber = Math.floor(Math.random() * 10);
    if (children) {
        //classes[colors["lightBlue"]]
        return <Av style={{ backgroundColor: bgColor }}>{children}</Av>;
    }
    return (
        <div className={cx("avatarContainer")} style={{ backgroundColor: backgroundColor }}>
            {/* classes[colors[randomNumber]] */}
            <Av style={{ backgroundColor: bgColor }}>{nameFirstLetter}</Av>
            {isEdit && (
                <div className={cx("iconContainer")} onClick={onEditClick}>
                    <CameraAlt color={"secondary"} />
                </div>
            )}
        </div>
    );
};

export default Avatar;
