import React, { FC, useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Card, Box, Typography, Link } from "@material-ui/core";
import { API } from "./../../../sagas/feature/plan/api";
import PaymentSubHeader from "./PaymentSubHeader";
import BodyContent from "./../../common/body/bodyContent";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import LoopOutlinedIcon from "@material-ui/icons/LoopOutlined";
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";

import classnames from "classnames/bind";
import styles from "./payment.scss";
import colors from "colors";
const cx = classnames.bind(styles);

const TRY_TIMES = 3;

const HeaderContent = () => {
    return (
        <div className={cx("headerSection")}>
            <div className={cx("top")}>
                <div>
                    <Typography variant="h4">Payment</Typography>
                </div>
            </div>
        </div>
    );
};

const PaymentSuccess: FC<{}> = () => {
    const [tries, setTries] = useState<number>(0);
    const [sessionId, setSessionId] = useState<string>("");
    const [paymentProcessed, setPaymentProcessed] = useState<boolean>(false);
    const [paymentSuccessMessage, setPaymentSuccessMessage] = useState<string>(
        "The payment has been done successfully"
    );
    const [paymentError, setPaymentError] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const history = useHistory();

    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
    };
    const query = useQuery();

    const getCheckoutSession = async (session_id) => {
        return API.getCheckoutSession(session_id).then((res) => res);
    };

    const getCheckoutSessionApi = async (session_id) => {
        if (session_id) {
            try {
                const { data, status, description } = await getCheckoutSession(session_id);
                if (status === "SUCCESS" && data.payment_status === "paid") {
                    setIsLoading(false);
                    setPaymentProcessed(true);
                    if (description === "ALREADY_PAID") {
                        setPaymentSuccessMessage("The payment has been already paid");
                    }
                    setTries(TRY_TIMES + 1);
                } else if (status === "ERROR") {
                    setIsLoading(false);
                    setPaymentError(true);
                } else {
                    setTries(tries + 1);
                }
            } catch (err) {
                setPaymentError(true);
                setIsLoading(false);
            }
        }
    };

    useEffect(() => {
        if (tries >= 1 && tries < TRY_TIMES && !paymentProcessed) {
            setTimeout(() => {
                if (!paymentProcessed) {
                    getCheckoutSessionApi(sessionId);
                }
            }, 500);
        }
    }, [tries]);

    useEffect(() => {
        const session_id = query.get("session_id");
        if (!session_id) {
            redirectToBilling();
        } else {
            setSessionId(session_id || "");
            getCheckoutSessionApi(session_id);
        }
    }, []);

    const redirectToBilling = () => {
        history.push("/hrm/billing#overview");
    };

    const redirectToHistory = () => {
        history.push("/hrm/billing#history");
    };

    return (
        <div className={cx("profileUserPage")}>
            <PaymentSubHeader selected={"billing"} />
            <BodyContent>
                <div className={"headerContainer"}>
                    <HeaderContent />
                </div>
                <div
                    className="contentBody"
                    style={{ height: "88vh", display: "flex", flexDirection: "column", alignItems: "center" }}
                >
                    <div className={cx("paymentSuccess")}>
                        {isLoading ? (
                            <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
                                <LoopOutlinedIcon color={"primary"} fontSize={"large"} />
                                <Typography style={{ marginTop: 0 }} variant={"h3"}>
                                    Loading...
                                </Typography>
                            </Box>
                        ) : (
                            <>
                                {paymentError ? (
                                    <>
                                        <ErrorOutlineOutlinedIcon color={"error"} fontSize={"large"} />
                                        <Typography variant={"h3"} style={{ maxWidth: 500, textAlign: "center" }}>
                                            Error in processing the payment
                                        </Typography>
                                        <Typography variant={"h4"} style={{ marginBottom: 16 }}>
                                            please contact support@collabey.com
                                        </Typography>
                                        <Link onClick={redirectToBilling}>Go to Billing Page</Link>
                                    </>
                                ) : (
                                    <>
                                        <CheckCircleOutlineOutlinedIcon color={"primary"} fontSize={"large"} />
                                        <Typography variant={"h3"}>{paymentSuccessMessage}</Typography>
                                        <Link onClick={redirectToHistory}>Go to Billing Page</Link>
                                    </>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </BodyContent>
        </div>
    );
};

export default PaymentSuccess;
