import axios from "axios";
import qs from "qs";
import { getAuthToken } from "./../utils/app-utils";
import { store } from "./../reducers/store";
import { updateApiResponse } from "./../actions/feature/app/appActions";

// declare a response interceptor
axios.interceptors.response.use(
    (response) => {
        if (response?.data?.status === 401) {
            store.dispatch(updateApiResponse(401));
        }
        return response;
    },
    (error) => {
        if (error?.response?.status === 401) {
            store.dispatch(updateApiResponse(401));
        }
        // handle the response error
        return Promise.reject(error);
    }
);

export const API_BASE_URL = process.env.REACT_APP_FILES_API_BASE_URL;

export const buildAuthPOST = async (api, body = {}) => {
    const token = getAuthToken();
    const config = {
        headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
        },
    };
    const data = await axios({
        url: `${API_BASE_URL}/${api}`,
        method: "post",
        data: { ...body },
        ...config,
    });
    return data.data;
};

export const buildAuthPOSTFile = async (api, body = {}) => {
    const token = getAuthToken();
    const config = {
        headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
        },
    };
    const data = await axios({
        url: `${API_BASE_URL}/${api}`,
        method: "post",
        data: body,
        ...config,
    });
    return data.data;
};

export const buildAuthPUT = async (api, body = {}) => {
    const token = getAuthToken();
    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    };
    const data = await axios({
        url: `${API_BASE_URL}/${api}`,
        method: "put",
        data: { ...body },
        ...config,
    });
    return data.data;
};

export const buildGET = async (api) => {
    const token = getAuthToken();
    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    };
    const data = await axios({
        url: `${API_BASE_URL}/${api}`,
        ...config,
        method: "get",
    });
    return data.data;
};

export const buildDELETE = async (api) => {
    const token = getAuthToken();
    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    };

    const data = await axios({
        url: `${API_BASE_URL}/${api}`,
        ...config,
        method: "delete",
    });
    return data.data;
};
