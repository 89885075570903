import { all } from "redux-saga/effects";
import profileSagas from "./feature/profile/profileSaga";
import userSagas from "./feature/user/userSaga";
import departmentSagas from "./feature/department/departmentSaga";
import companySagas from "./feature/company/companySaga";
import officeSagas from "./feature/office/officeSaga";
import planSagas from "./feature/plan/planSaga";
import roleSagas from "./feature/role/roleSaga";
import designationSagas from "./feature/designation/designationSaga";

export default function* rootSaga() {
    yield all([
        ...userSagas,
        ...departmentSagas,
        ...designationSagas,
        ...roleSagas,
        ...profileSagas,
        ...officeSagas,
        ...planSagas,
        ...companySagas,
    ]);
}
