import { buildGET, buildDELETE, buildAuthPUT } from "./../../../../utils/api";

// APIs

const getCompany = () => buildGET(`company`);

const updateCompany = (payload) => {
    const { id, ...rest } = payload;
    return buildAuthPUT(`company/${id}`, rest);
};

const deleteCompany = (id) => buildDELETE(`company/${id}`);

const getCompanyPaymentHistory = () => buildGET(`company/paymenthistory`);

const getCompanyUsers = () => buildGET(`company/users`);

const getCompanyWorkspaceUsers = (workspaceId) => buildGET(`company/users/workspace/${workspaceId}`);

const getCompanyFileStorage = () => buildGET(`company/filestorage`);

// API export
export const API = {
    getCompany,
    deleteCompany,
    updateCompany,
    getCompanyPaymentHistory,
    getCompanyUsers,
    getCompanyWorkspaceUsers,
    getCompanyFileStorage,
};
