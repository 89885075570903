import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { store, history } from "./reducers/store";
import { Router } from "react-router-dom";
//import { ThemeProvider } from "@material-ui/styles";
import theme from "./assets/theme";
import App from "./components/app/app";
import CssBaseline from "@material-ui/core/CssBaseline";
import { MuiThemeProvider } from "@material-ui/core/styles";

import "typeface-roboto";
import "./assets/scss/global.scss";
import "./assets/font-awesome/css/all.css";

// const themeLight = createMuiTheme({
//     palette: {
//       background: {
//         default: "#e4f0e2"
//       }
//     }
//   });

//   const themeDark = createMuiTheme({
//     palette: {
//       background: {
//         default: "#222222"
//       },
//       text: {
//         primary: "#ffffff"
//       }
//     }
//   });

ReactDOM.render(
    <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <Provider store={store}>
            <Router history={history}>
                <App />
            </Router>
        </Provider>
    </MuiThemeProvider>,
    document.getElementById("root")
);
