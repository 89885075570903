import React, { FC, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Draggable } from "react-beautiful-dnd";
import CardModal from "../CardModal/CardModal";
import AttachmentOutlinedIcon from "@material-ui/icons/AttachmentOutlined";
import ChatBubbleOutlineOutlinedIcon from "@material-ui/icons/ChatBubbleOutlineOutlined";
import Avatar from "./../../../../../components/common/avatar/Avatar";

import { toggleDrawer } from "../../../../../actions/feature/app/appActions";
import { updateWorkspaceProjectTaskSelected } from "./../../../../../actions/feature/workspaces/workspacesActions";

import classnames from "classnames/bind";
import styles from "./Card.scss";
const cx = classnames.bind(styles);

interface Assignee {
    id: string;
    assignee: {
        fullName: string;
        id: string;
        userProfileColor: string;
    };
}
interface CardFooterProps {
    comments: number;
    attachments: number;
    taskAssignee: Assignee[];
}

const CardFooter: FC<CardFooterProps> = ({ comments = 0, attachments = 0, taskAssignee = [] }) => {
    return (
        <div className={cx("cardFooter")}>
            <div className={cx("cardInfo")}>
                {attachments > 0 && (
                    <div className={cx("cardRow")}>
                        <AttachmentOutlinedIcon /> {attachments}
                    </div>
                )}
                {comments > 0 && (
                    <div className={cx("cardRow")}>
                        <ChatBubbleOutlineOutlinedIcon /> {comments}
                    </div>
                )}
            </div>
            <div className={cx("cardRow", "right")}>
                {taskAssignee?.length > 0 &&
                    taskAssignee.map((item) => (
                        <Avatar name={item?.assignee?.fullName} bgColor={item?.assignee?.userProfileColor} />
                    ))}
            </div>
        </div>
    );
};

interface CardLabelsProps {
    labels: any;
}

const CardLabels: FC<CardLabelsProps> = ({ labels = [] }) => {
    return (
        <div className={cx("cardLabels")}>
            {labels?.length > 0 && (
                <>
                    {labels.map((label) => {
                        return <div className={cx("cardLabelTile")} style={{ background: label.background }}></div>;
                    })}
                </>
            )}
        </div>
    );
};

interface PageProps {
    task: any;
    isDraggingOver: any;
    index: number;
    dispatch: any;
    isDrawerOpen: boolean;

    toggleDrawer: (isOpen: boolean) => null;
    updateWorkspaceProjectTaskSelected: (id: string) => void;
}

const Card: FC<PageProps> = ({
    task,
    isDraggingOver,
    index,
    dispatch,
    isDrawerOpen,
    toggleDrawer,
    updateWorkspaceProjectTaskSelected,
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [ref, setRef] = useState<any>("");
    const { listId } = task;

    const toggleModel = () => {
        setIsModalOpen(!isModalOpen);
    };

    const handleClick = (event, taskId) => {
        updateWorkspaceProjectTaskSelected(taskId);
        toggleDrawer(true);
    };

    useEffect(() => {
        if (!isDrawerOpen) {
            updateWorkspaceProjectTaskSelected("");
        }
    }, [isDrawerOpen]);

    const handleKeyDown = (event) => {
        // Only open card on enter since spacebar is used by react-beautiful-dnd for keyboard dragging
        if (event.keyCode === 13 && event.target.tagName.toLowerCase() !== "a") {
            event.preventDefault();
            toggleModel();
        }
    };

    //const checkboxes = findCheckboxes(task.title);
    return (
        <>
            <Draggable draggableId={"card-" + task.id} index={index}>
                {(provided: any, snapshot) => (
                    <>
                        {/* eslint-disable */}
                        <div
                            className={classnames("card-title", {
                                "card-title--drag": snapshot.isDragging,
                            })}
                            ref={(ref) => {
                                provided.innerRef(ref);
                                if (ref) setRef(ref);
                            }}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            onClick={(event) => {
                                //provided?.dragHandleProps?.onClick && provided?.dragHandleProps?.onClick(event);
                                handleClick(event, task.id);
                            }}
                            onKeyDown={(event) => {
                                //provided && provided?.dragHandleProps?.onKeyDown(event);
                                handleKeyDown(event);
                            }}
                            style={{
                                ...provided.draggableProps.style,
                                // background: card.color,
                            }}
                        >
                            <CardLabels labels={task.taskLabels || []} />
                            <div
                                className="card-title-html"
                                dangerouslySetInnerHTML={{
                                    //__html: formatMarkdown(card.text),
                                    __html: `${task.taskTitle}`,
                                }}
                            />
                            <CardFooter
                                comments={task?.commentsCount}
                                attachments={task?.attachments?.length}
                                taskAssignee={task?.taskAssignee}
                            />
                        </div>
                        {/* Remove placeholder when not dragging over to reduce snapping */}
                        {isDraggingOver && provided.placeholder}
                    </>
                )}
            </Draggable>
            {/* <CardModal
                isOpen={isModalOpen}
                cardElement={ref}
                card={{}}
                listId={listId}
                toggleCardEditor={toggleModel}
            /> */}
        </>
    );
};

const mapStateToProps = (state) => ({
    isDrawerOpen: state.app.isDrawerOpen,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    toggleDrawer: (isOpen: boolean) => dispatch(toggleDrawer(isOpen)),
    updateWorkspaceProjectTaskSelected: (id: string) => dispatch(updateWorkspaceProjectTaskSelected(id)),

    // closeAlert: () => dispatch(closeAlert()),
    // openSnackbar: (message: string) => dispatch(openSnackbar(message)),
    // closeSnackbar: () => dispatch(closeSnackbar()),
    // openAlert: (payload: any) => dispatch(openAlert(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Card);
