export interface ResponseInterface {
    status: number,
    data: string | {},
    description: string,
}

export interface Workspace {
    id: string;
    categoryId: string;
    name: string;
    description: string;
    status: Status;
    visibility: Visibility;
    createdBy: UserBy;
    createdOn: string;
    updatedOn: string;
}

export interface WorkspaceResponse {
    id: string;
    categoryId: string;
    name: string;
    description: string;
    status: Status;
    visibility: Visibility;
    createdBy: UserBy;
    createdOn: string;
    updatedOn: string;
    projects: WorkspaceProject[];
    members: WorkspaceMember[];
    settings: WorkspaceSettings;
}

export interface WorkspaceCategories {
    id: string;
    name: string;
    description: string;
    status: Status;
}

export interface WorkspaceTaskLabels {
    id: string;
    name: string;
    description: string;
    status: Status;
}

export interface WorkspaceSettings {
    id: string;
    creation: Role;
    deletion: Role;
    inviteMembers: Role;
}

export interface WorkspaceMember {
    id: string;
    user: {
        id: string;
        userFullName: string;
        userAvatarUrl?: string;
    };
    role: Role;
    status: Status;
    type: ProjectUserType;
}

export interface WorkspaceProject {
    id: string;
    projectName: string;
    projectDescription: string;
    background: string;
    completionDate: string;
    status: Status;
    projectVisibility: ProjectVisibility;
    createdOn: string;
    updatedOn: string;
    createdBy: UserBy;
}

export interface WorkspaceList {
    id: string;
    projectId: string;
    listTitle: string;
    listDescription: string;
    status: Status;
    createdOn: string;
    updatedOn: string;
    createdBy: UserBy
}

export interface WorkspaceTask {
    id: string;
    listId: string;
    parentTaskId: string;
    type: string;
    taskTitle: string;
    taskDescription: string;
    cover: string;
    coverType: CoverType;
    startDate: string
    dueDate: string;
    taskStatus: Status
    createdOn: string;
    updatedOn: string;
    createdBy: UserBy
}

export interface WorkspaceTaskMembers {
    id: string;
    taskId: string;
    comment: string;
    commentBy: string;
    commentCreatedOn: string;
    commentUpdatedOn: string;
}



export interface UserBy {
    id: string,
    fullName: string,
    userProfileColor?: string;
}

export enum Status {
    ACTIVE = "Active",
    INACTIVE = "Inactive",
    DELETED = "Delete"
}

export enum Visibility {
    PRIVATE = "PRIVATE",
    PUBLIC = "PUBLIC",
}

export enum Role {
    ADMIN = "ADMIN",
    MEMBER = "MEMBER",
}

export const MemberRoles = ["ADMIN", "MEMBER"];

export const WorkspaceVisibility = [
    { type: "Private", value: "PRIVATE", "description": "Only members of this project can edit" },
    { type: "Workpace", value: "WORKSPACE", "description": "All the members of this workspace can edit" }
];

export enum ProjectUserType {
    GUEST = "GUEST",
    WORKSPACE = "WORKSPACE",
}

export enum ProjectVisibility {
    PRIVATE = "PRIVATE",
    PUBLIC = "PUBLIC",
    WORKSPACE = "WORKSPACE"
}

export enum CoverType {
    IMAGE = "IMAGE",
    COLOR = "COLOR",
}

export enum MessageType {
    "MESSAGE" = "MESSAGE",
    "MESSAGE_DELETED" = "MESSAGE_DELETED",
    "USER_ADDED" = "USER_ADDED",
    "USER_REMOVED" = "USER_REMOVED",
    "ROOM_CREATED" = "ROOM_CREATED"
}


export enum Status {
    "ERROR" = "error",
    "SUCCESS" = "success",
    "ACCESS_DENIED" = "Access denied",
}

export const Colors = ["#42a5f5", "#ba68c8", "#ef5350", "#ff9800", "#9900ef", "#0288d1", "#4caf50", "#c5cae9"];