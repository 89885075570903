import { utcToZonedTime, format, toDate } from "date-fns-tz";

export const timeout = (ms, reject = false) =>
    new Promise((res, rej) => setTimeout(() => (reject ? rej : res)(true), ms));

export const toTimeZone = (time, timeZone, dateFormat = "dd-MM-yyyy", timeFormat = "12") => {
    try {
        var systemDate = (new Date(time).toJSON() || "").slice(0, 10).replace(/-/g, "/");

        var todayDate = new Date()
            .toJSON()
            .slice(0, 10)
            .replace(/-/g, "/");

        if (systemDate == todayDate) {
            return toTimeZoneTimeOnly(time, timeZone, timeFormat);
        }
        if (!dateFormat) dateFormat = "dd-MM-yyyy";
        if (!timeFormat) timeFormat = "12";

        const timeFormatLabel = timeFormat === "12" ? "hh" : "HH";
        const formattedDate = format(utcToZonedTime(time, timeZone), `${dateFormat} ${timeFormatLabel}:mm a`, {
            timeZone: timeZone,
        });

        return formattedDate;
    } catch (err) {
        return "";
    }
};

export const toTimeZoneDateOnly = (time, timeZone, dateFormat = "dd-MM-yyyy") => {
    if (!dateFormat) dateFormat = "dd-MM-yyyy";
    if (!timeZone) {
        return format(toDate(time), dateFormat);
    }

    const formattedDate = format(utcToZonedTime(time, timeZone), `${dateFormat}`, {
        timeZone: timeZone,
    });

    return formattedDate;
};

export const toTimeZoneTimeOnly = (time, timeZone, timeFormat = "12") => {
    if (!time) return false;

    const dateFormat = "dd-MM-yyyy";
    if (!timeFormat) timeFormat = "12";

    if (!timeZone) {
        return format(toDate(time), dateFormat);
    }

    const timeFormatLabel = timeFormat === "12" ? "hh" : "HH";
    const formattedDate = format(utcToZonedTime(time, timeZone), `${dateFormat} ${timeFormatLabel}:mm a`, {
        timeZone: timeZone,
    });
    const arrTime = formattedDate.split(" ");

    return `${arrTime[1]} ${arrTime[2]}` || "";
};

export const getCurrDateTime = () => {
    var currentdate = new Date();
    var datetime =
        currentdate.getDate() +
        "/" +
        (currentdate.getMonth() + 1) +
        "/" +
        currentdate.getFullYear() +
        " @ " +
        currentdate.getHours() +
        ":" +
        currentdate.getMinutes() +
        ":" +
        currentdate.getSeconds();
    return datetime;
};

export const getFirstTwoChars = (name) => {
    if (name) {
        const arr = name.split(" ");
        return `${arr[0].charAt(0)}${arr[1]?.charAt(0) || ""}`;
    }
    return "";
};

export const generateRandomNum = (range) => {
    return Math.floor(Math.random() * range + 1);
};

export const randomHexColors = [
    "#9ec0f2",
    "#532415",
    "#e42655",
    "#da1310",
    "#55024c",
    "#2cf4eb",
    "#951d3b",
    "#e72767",
    "#ea77d2",
    "#88155c",
    "#bd9087",
    "#3e89dd",
    "#417a8f",
    "#2c86a8",
    "#554820",
    "#60bb0d",
    "#19b076",
    "#2ac908",
    "#67b49a",
    "#412dbb",
    "#26b8dc",
    "#2932db",
    "#4989c8",
    "#37a73a",
    "#aa7db4",
    "#7e8ea1",
    "#852cf0",
    "#445bf9",
    "#90ab10",
    "#a91b14",
];

export const findCheckboxes = (text) => {
    const checkboxes = text.match(/\[(\s|x)\]/g) || [];
    const checked = checkboxes.filter((checkbox) => checkbox === "[x]").length;
    return { total: checkboxes.length, checked };
};

export const moveItem = (arr, fromIndex, toIndex) => {
    let itemRemoved = arr.splice(fromIndex, 1); // assign the removed item as an array
    arr.splice(toIndex, 0, itemRemoved[0]); // insert itemRemoved into the target index
    return arr;
};

//https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
export const formatBytes = (bytes, decimals = 2) => {
    if (!+bytes) return "0 Bytes";

    const k = 1000;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

// 1GB = 1000 MB
// 1MB = 1000 KB
// 1KB = 1000 Bytes
