import React, { FC, useState } from "react";
import { connect } from "react-redux";
import Textarea from "react-textarea-autosize";
import { Button, Wrapper, Menu, MenuItem } from "react-aria-menubutton";
import MoreHorizOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";

import withProjectSocket from "./../../../../hoc/ProjectsSocket";

//import FaTrash from "react-icons/lib/fa/trash";
import "./ListHeader.scss";
import { Typography } from "@material-ui/core";
import { AnyTxtRecord } from "dns";

interface PageProps {
    listTitle: string;
    listId: string;
    listOrder: any;
    projectId: string;
    cards: any;
    dragHandleProps: any;
    socketProject: WebSocket | null;
    authToken: string;
}

const ListHeader: FC<PageProps> = ({
    listTitle,
    listId,
    listOrder,
    projectId,
    cards,
    dragHandleProps,
    socketProject,
    authToken,
}) => {
    //console.log("dragHandleProps", dragHandleProps);
    const [newTitle, setNewTitle] = useState(listTitle);
    const [isOpen, setIsOpen] = useState(false);

    const handleChange = (event) => {
        setNewTitle(event.target.value);
    };

    const handleKeyDown = (event) => {
        if (event.keyCode === 13) {
            event.preventDefault();
            handleSubmit();
        } else if (event.keyCode === 27) {
            revertTitle();
        }
    };

    const handleSubmit = () => {
        if (newTitle === "") return;
        if (newTitle !== listTitle) {
            if (socketProject) {
                socketProject?.send(
                    JSON.stringify({
                        action: "updateList",
                        body: {
                            listTitle: newTitle,
                            listId,
                        },
                        token: authToken,
                    })
                );
            }
        }
        setIsOpen(false);
    };

    const revertTitle = () => {
        setNewTitle(listTitle);
        setIsOpen(false);
    };

    const deleteList = () => {
        if (socketProject) {
            socketProject?.send(
                JSON.stringify({
                    action: "deleteList",
                    body: {
                        listTitle: newTitle,
                        listId,
                    },
                    token: authToken,
                })
            );
        }
    };

    const openTitleEditor = () => {
        setIsOpen(true);
    };

    const handleButtonKeyDown = (event) => {
        if (event.keyCode === 13) {
            event.preventDefault();
            openTitleEditor();
        }
    };

    const handleDeleteList = () => {
        if (!listId) return false;
        if (socketProject) {
            socketProject?.send(
                JSON.stringify({
                    action: "deleteList",
                    body: {
                        listId,
                    },
                    token: authToken,
                })
            );
        }
        setIsOpen(false);
    };

    return (
        <div className="list-header">
            {isOpen ? (
                <div className="list-title-textarea-wrapper">
                    <Textarea
                        autoFocus
                        useCacheForDOMMeasurements
                        value={newTitle}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                        className="list-title-textarea"
                        onBlur={handleSubmit}
                        spellCheck={false}
                    />
                </div>
            ) : (
                <div
                    {...dragHandleProps}
                    role="button"
                    tabIndex={0}
                    onClick={openTitleEditor}
                    onKeyDown={(event) => {
                        handleButtonKeyDown(event);
                        dragHandleProps.onKeyDown(event);
                    }}
                    className="list-title-button"
                >
                    <Typography variant="body1">{listTitle}</Typography>
                </div>
            )}
            <Wrapper className="delete-list-wrapper" onSelection={deleteList}>
                <Button className="delete-list-button">
                    <MoreHorizOutlinedIcon />
                </Button>
                <Menu className="delete-list-menu">
                    <ul>
                        <li onClick={() => setIsOpen(true)}>Edit list title</li>
                        <li onClick={handleDeleteList}>Archive this list</li>
                    </ul>
                </Menu>
            </Wrapper>
        </div>
    );
};

export default withProjectSocket(ListHeader);
