import { buildGET, buildAuthPOST, buildAuthPUT } from "./../../../../utils/api";

// APIs
const getProfile = () => buildGET(`profile`);

const updateBasicInfo = (payload) => {
    const { id, ...rest } = payload;
    return buildAuthPUT(`users/${id}/basicinfo`, rest);
};

const updatePersonalInfo = (payload) => {
    const { id, ...rest } = payload;
    return buildAuthPUT(`users/${id}/personalinfo`, rest);
};

const updateContacts = (payload) => {
    const { id, ...rest } = payload;
    return buildAuthPUT(`users/${id}/contacts`, rest);
};

const changePassword = (payload) => {
    const { id, ...rest } = payload;
    return buildAuthPOST(`users/${id}/change-password`, rest);
};

const logout = () => buildAuthPOST(`logout`);

// API export
export const API = {
    getProfile,
    changePassword,
    updateBasicInfo,
    updatePersonalInfo,
    updateContacts,
    logout,
};
