import React, { FC, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { List, ListItem, ListItemText, Typography, Button } from "@material-ui/core";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import NoChatFoundImage from "./../../../../assets/images/chat.svg";
import { RoomMessagesInterface, RoomInterface } from "./../../../../utils/interfaces";
import { toTimeZone } from "./../../../../utils/utils";
import { UserContext } from "./../../../../components/app/userContext";
import { resetMessages } from "./../../../../actions/feature/rooms/roomsActions";
import { usePlanActiveHook } from "../../../../utils/userAccess";

import classnames from "classnames/bind";
import styles from "./Chat.scss";
const cx = classnames.bind(styles);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            maxWidth: 360,
            backgroundColor: theme.palette.background.paper,
        },
        nested: {
            paddingLeft: theme.spacing(4),
        },
    })
);

interface PageProps {
    rooms: any;
    currentUser?: any;
    isCreateNewRoom?: boolean;
    createRoom: () => void;
    selectedRoom: RoomMessagesInterface;
    resetMessages: () => void;
}

export const getOtherUserFullName = (room: RoomInterface, currentUser: any) => {
    const otherUser = room?.users.find((user) => user.id != currentUser.id);
    return otherUser ? otherUser?.userFullName : "";
};

export const getRoomName = (room: RoomInterface, currentUser: any) => {
    if (room?.chatRoomName) return room?.chatRoomName;
    const roomUsers = room?.users.filter((roomUser) => roomUser?.chatUserStatus === "Active");

    if (roomUsers?.length === 1) {
        return roomUsers?.[0].userFullName;
    } else if (roomUsers?.length === 2) {
        return getOtherUserFullName(room, currentUser);
    } else if (roomUsers?.length > 2) {
        return `${roomUsers?.[1].userFullName} and ${roomUsers?.length - 1} others `;
    }
    return "";
};

const ChatSubHeader: FC<PageProps> = ({
    rooms,
    currentUser,
    createRoom,
    isCreateNewRoom = false,
    selectedRoom,
    resetMessages,
}) => {
    const isUserAccess = usePlanActiveHook();
    const history = useHistory();
    const classes = useStyles();
    const { id } = useParams();

    const userContextObj: any = useContext(UserContext);

    const { dateFormatLabel, timeFormatLabel, timeZoneName } = userContextObj || {};

    const redirectToChatDetails = (id: string) => {
        resetMessages();
        if (id) history.push(`/hrm/chat/${id}`);
    };

    const toTimestamp = (strDate) => {
        const datum = Date.parse(strDate);
        return datum / 1000;
    };

    return (
        <div className={cx("subHeader chatContainer")}>
            <div
                className={cx("topNav")}
                style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}
            >
                <Typography variant="h4" style={{ paddingLeft: "16px" }}>
                    Chats
                </Typography>
                {isUserAccess && <i className="far fa-edit" onClick={createRoom} style={{ fontSize: "20px" }}></i>}
            </div>
            <div className={cx("content")}>
                <List component="div" aria-labelledby="nested-list-subheader" className={classes.root}>
                    <List component="div" disablePadding>
                        {isCreateNewRoom && (
                            <ListItem button selected={true} className={classes.nested}>
                                <ListItemText primary={"New chat"} />
                            </ListItem>
                        )}
                        {rooms?.length > 0 &&
                            rooms
                                .slice()
                                .sort((a, b) => toTimestamp(b?.message?.messageAt) - toTimestamp(a?.message?.messageAt))
                                .map((room, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className={cx("tileChatContainer", {
                                                selected: room.chatRoomId == id && !isCreateNewRoom,
                                            })}
                                            onClick={() => redirectToChatDetails(room.chatRoomId)}
                                        >
                                            <div className={cx("wrapper")}>
                                                <div className={cx("top")}>
                                                    <p className={cx("roomName")}>{getRoomName(room, currentUser)}</p>
                                                    <p className={cx("time")}>
                                                        {toTimeZone(
                                                            room?.message?.messageAt,
                                                            timeZoneName,
                                                            dateFormatLabel,
                                                            timeFormatLabel
                                                        )}
                                                    </p>
                                                </div>
                                                <p>{room?.message?.message}</p>
                                            </div>
                                        </div>
                                    );
                                })}

                        {rooms?.length <= 0 && !isCreateNewRoom && (
                            <div className={cx("noChatFound")}>
                                <img src={NoChatFoundImage} />
                                <div className={cx("text")}>No rooms found</div>
                                <Button
                                    variant="outlined"
                                    size="small"
                                    color="primary"
                                    startIcon={<AddOutlinedIcon />}
                                    onClick={createRoom}
                                    disabled={!isUserAccess}
                                >
                                    Create new room
                                </Button>
                            </div>
                        )}
                    </List>

                    {/* <ListItem button onClick={handleClickDirect}>
                        <ListItemText primary="Direct messages" />
                        {openDirect ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={openDirect} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem button className={classes.nested}>
                                <ListItemText primary="Starred" />
                            </ListItem>
                        </List>
                    </Collapse> */}
                </List>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        currentUser: state.user.profile.user || {},
    };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => ({
    resetMessages: () => dispatch(resetMessages()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChatSubHeader);
