import { OfficeActionTypes } from "./../../actions/officeActions";

const initialState = {
    offices: [],
    office: {},
    apiResponse: {
        getOffices: {},
        addOffice: {},
        deleteOffice: {},
        updateOffice: {},
    },
};

const reducer = (state = initialState, action: any) => {
    switch (action.type) {
        //OFFICES
        case OfficeActionTypes.GET_OFFICES_SUCCESS:
            // console.log("get offices success", action.payload.data);
            //return { ...state, offices: action.payload.data.data, pagination: action.payload.data.pagination };
            return { ...state, offices: action.payload.data };
        case OfficeActionTypes.GET_OFFICES_FAILURE:
            return { ...state, apiResponse: { getOffices: action.payload } };

        //OFFICE
        case OfficeActionTypes.GET_OFFICE_SUCCESS:
            return { ...state, office: action.payload.data[0] };
        case OfficeActionTypes.GET_OFFICE_FAILURE:
            return { ...state, apiResponse: { getOffice: action.payload } };

        //ADD OFFICE
        case OfficeActionTypes.ADD_OFFICE_SUCCESS:
            return {
                ...state,
                apiResponse: { addOffice: { status: "success" } },
                offices: [...state.offices, action.payload.data[0]],
            };
        case OfficeActionTypes.ADD_OFFICE_FAILURE:
            return { ...state, apiResponse: { addOffice: action.payload } };

        //UPDATE OFFICE
        case OfficeActionTypes.UPDATE_OFFICE_SUCCESS:
            return {
                ...state,
                office: action.payload[0],
                offices: state.offices.map((office: any) => {
                    if (office.id === action.payload[0].id) {
                        return action.payload[0];
                    } else {
                        return office;
                    }
                }),
            };
        case OfficeActionTypes.ADD_OFFICE_FAILURE:
            return { ...state, apiResponse: { updateOffice: action.payload } };

        //DELETE OFFICE
        case OfficeActionTypes.DELETE_OFFICE_SUCCESS:
            return {
                ...state,
                offices: state.offices.filter((office: any) => {
                    return office.id !== action.payload.id;
                }),
            };
        case OfficeActionTypes.DELETE_OFFICE_FAILURE:
            return { ...state };

        // CLEAR OFFICE
        case OfficeActionTypes.CLEAR_OFFICE:
            return {
                ...state,
                office: {},
            };

        case OfficeActionTypes.RESET:
            return { ...state, ...initialState };

        //DEFAULT
        default:
            return state;
    }
};

export { reducer as officeReducer };
