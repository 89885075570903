import React, { FC } from "react";

import { Select, FormControl, InputLabel, MenuItem, FormHelperText, Box } from "@material-ui/core";

interface IOption {
    value: string | number;
    label: string;
    description?: string;
}

interface IFieldProps {
    error?: boolean;
    required?: boolean;
    errorText?: string;
    name: string;
    label: string;
    value?: any;
    id?: string;
    onChange: (ev: any) => void;
    options: IOption[];
    disabled?: boolean;
}

const SelectField: FC<IFieldProps> = ({
    name,
    label,
    value = "",
    id,
    onChange,
    errorText = "Select value",
    error = false,
    required = false,
    options,
    disabled = false,
}) => {
    return (
        <FormControl required={required} variant="outlined">
            <InputLabel className={error ? "error" : ""} id="demo-simple-select-outlined-label">
                {label}
            </InputLabel>
            <Select
                labelId="demo-simple-select-outlined-label"
                id={id}
                name={name}
                required={required}
                disabled={disabled}
                value={value}
                error={error}
                onChange={onChange}
                label={label}
            >
                <MenuItem value="">
                    <em>Select</em>
                </MenuItem>
                {options &&
                    options.map((option: any) => {
                        return (
                            <MenuItem key={option.value} value={option.value}>
                                <div>
                                    {option.label}

                                    <FormHelperText>{option?.description && <>{option.description}</>}</FormHelperText>
                                </div>
                            </MenuItem>
                        );
                    })}
            </Select>
            {error && <FormHelperText className="error">{errorText}</FormHelperText>}
        </FormControl>
    );
};

export default SelectField;
