import { fork, put, delay, takeLatest, select, all } from "redux-saga/effects";
import { API } from "./api";
import { getIn } from "../../../../utils/app-utils";
import callApi from "../../../../utils/callApi";

import {
    getDesignation,
    getDesignationsFailure,
    getDesignationsSuccess,
    getDesignationFailure,
    getDesignationSuccess,
    DesignationActionTypes,
    addDesignationSuccess,
    addDesignationFailure,
    deleteDesignationSuccess,
    deleteDesignationFailure,
    updateDesignationSuccess,
    updateDesignationFailure,
} from "../../../actions/designationActions";
import {
    isLoading,
    openSnackbar,
    closeSnackbar,
    toggleDrawer,
    openAlert,
} from "../../../../actions/feature/app/appActions";

function* getDesignationsSaga() {
    try {
        yield put(isLoading(true));
        const [error, res] = yield* callApi(API.getDesignations, { disableLoading: true }, {}, true);
        if (error || res.status === "ERROR") {
            yield put(getDesignationsFailure({ status: "ERROR" }));
        } else {
            yield put(getDesignationsSuccess(res));
        }
        yield put(isLoading(false));
    } catch (error) {
        yield put(isLoading(false));
        yield put(getDesignationsFailure({ status: "ERROR" }));
    }
}

function* getDesignationSaga(reqObject) {
    const { payload } = reqObject;
    try {
        yield put(isLoading(true));
        const [error, res] = yield* callApi(API.getDesignation, { disableLoading: true }, payload, true);
        if (error || res.status === "ERROR") {
            yield put(getDesignationFailure({ status: "ERROR" }));
        } else {
            yield put(getDesignationSuccess(res));
        }
        yield put(isLoading(false));
    } catch (error) {
        yield put(isLoading(false));
        yield put(getDesignationFailure({ status: "ERROR" }));
    }
}

function* addDesignationSaga(reqObject) {
    const { payload } = reqObject;
    try {
        yield put(isLoading(true));
        const [error, res] = yield* callApi(API.addDesignation, { disableLoading: true }, payload, true);
        if (error || res.status === "ERROR") {
            if (res.description === "RECORD_ALREADY_EXIST") {
                yield put(openAlert({ message: "Designation already exist", type: "error" }));
            } else {
                yield put(openAlert({ message: "Invalid error, please try again", type: "error" }));
            }
            yield put(addDesignationFailure({ status: "ERROR" }));
        } else {
            yield put(toggleDrawer(false));
            yield put(openSnackbar("Designation added successfully"));
            yield put(addDesignationSuccess(res));
        }

        yield put(isLoading(false));
        yield delay(3000);
        yield put(closeSnackbar());
    } catch (error) {
        yield put(isLoading(false));
        yield put(openSnackbar("Invalid error, please try again"));
        yield put(addDesignationFailure({ status: "ERROR" }));
        yield delay(3000);
        yield put(closeSnackbar());
    }
}

function* updateDesignationSaga(reqObject) {
    const { payload } = reqObject;
    try {
        yield put(isLoading(true));
        const [error, res] = yield* callApi(API.updateDesignation, { disableLoading: true }, payload, true);
        if (error || res.status === "ERROR") {
            if (res.description === "RECORD_ALREADY_EXIST") {
                yield put(openAlert({ message: "Designation already exist", type: "error" }));
            } else {
                yield put(openAlert({ message: "Invalid error, please try again", type: "error" }));
            }
            yield put(updateDesignationFailure({ status: "ERROR" }));
        } else {
            yield put(toggleDrawer(false));
            yield put(openSnackbar("Designation updated successfully"));
            yield put(updateDesignationSuccess(res.data));
        }

        yield put(isLoading(false));
        yield delay(3000);
        yield put(closeSnackbar());
    } catch (error) {
        yield put(isLoading(false));
        yield put(openSnackbar("Invalid error, please try again"));
        yield put(updateDesignationFailure({ status: "ERROR" }));
        yield delay(3000);
        yield put(closeSnackbar());
    }
}

function* deleteDesignationSaga(reqObject) {
    const { payload } = reqObject;
    try {
        yield put(isLoading(true));
        const [error, res] = yield* callApi(API.deleteDesignation, { disableLoading: true }, payload, true);
        if (error || res.status === "ERROR") {
            yield put(openSnackbar("Invalid error, please try again"));
            yield put(deleteDesignationFailure({ status: "ERROR" }));
        } else {
            yield put(openSnackbar("Designation deleted successfully"));
            yield put(deleteDesignationSuccess({ id: payload }));
        }

        yield put(isLoading(false));
        yield delay(3000);
        yield put(closeSnackbar());
    } catch (error) {
        yield put(isLoading(false));
        yield put(openSnackbar("Invalid error, please try again"));
        yield put(deleteDesignationFailure({ status: "ERROR" }));
        yield delay(3000);
        yield put(closeSnackbar());
    }
}

function* watchDesignation() {
    yield takeLatest(DesignationActionTypes.GET_DESIGNATIONS, getDesignationsSaga);
    yield takeLatest(DesignationActionTypes.GET_DESIGNATION, getDesignationSaga);
    yield takeLatest(DesignationActionTypes.ADD_DESIGNATION, addDesignationSaga);
    yield takeLatest(DesignationActionTypes.UPDATE_DESIGNATION, updateDesignationSaga);
    yield takeLatest(DesignationActionTypes.DELETE_DESIGNATION, deleteDesignationSaga);
}

const designationSagas = [fork(watchDesignation)];

export default designationSagas;
