import React, { FC, useState, useContext } from "react";
import { TextField, Button } from "@material-ui/core";

import Avatar from "./../../../../../components/common/avatar/Avatar";

import { UserContext } from "../../../../../components/app/userContext";
import withProjectSocket from "./../../../../hoc/ProjectsSocket";
import { toTimeZone } from "./../../../../../utils/utils";
import SendOutlinedIcon from "@material-ui/icons/SendOutlined";

import FileUpload from "./../../../../../components/common/file-upload/FileUpload";
import { RenderAttachment } from "./CardDetails";

import classnames from "classnames/bind";
import styles from "./Comments.scss";

const cx = classnames.bind(styles);

interface PageProps {
    comments: any;
    taskId: string;
    setIsEditComment: (val: boolean) => void;
    isEditComment: boolean;
    socketProject: WebSocket | null;
    authToken: string;
}

const TaskComments: FC<PageProps> = ({
    comments,
    taskId,
    setIsEditComment,
    isEditComment,
    socketProject,
    authToken,
}) => {
    const contextObj: any = useContext(UserContext);
    const { currentUser } = contextObj;

    const [formValues, setFormValues] = useState<any>({
        message: "",
    });

    const resetForm = () => {
        setFormValues({ message: "", chatRoomName: "" });
    };

    const handleKeyDown = (e: any) => {
        if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            saveComment();
            resetForm();
        }
    };

    const inputChangeHandler = (evt: any) => {
        setFormValues({ ...formValues, [evt.target.name]: evt.target.value });
    };

    const openWriteComment = () => {
        setIsEditComment(true);
        setFormValues({ ...formValues, message: "" });
    };

    const saveComment = () => {
        if (socketProject && formValues.message) {
            socketProject?.send(
                JSON.stringify({
                    action: "createComment",
                    body: {
                        comment: formValues.message,
                        taskId,
                    },
                    token: authToken,
                })
            );
            setIsEditComment(false);
        }
    };

    return (
        <>
            <div className={cx("messageOuter")}>
                <Avatar name={currentUser?.fullName} backgroundColor={currentUser?.fullName} />
                {!isEditComment ? (
                    <div className={cx("writeComment")} onClick={openWriteComment}>
                        Write a comment...
                    </div>
                ) : (
                    <div className={cx("messageInputContainer")}>
                        <div className={cx("message")}>
                            <div className={cx("inputBox")}>
                                <TextField
                                    name="message"
                                    required={true}
                                    id="message"
                                    autoFocus={true}
                                    value={formValues.message}
                                    multiline
                                    placeholder={"Write a comment..."}
                                    onKeyDown={handleKeyDown}
                                    onChange={inputChangeHandler}
                                />
                            </div>
                            <div className={cx("actions")}>
                                <div style={{ display: "flex" }}>
                                    <FileUpload type={"taskComment"} id={taskId} />
                                </div>
                                <div style={{ display: "flex" }}>
                                    {/* <Button
                                        style={{ marginRight: "16px" }}
                                        size={"small"}
                                        onClick={saveComment}
                                        variant={"outlined"}
                                        color={"primary"}
                                        disabled={!formValues.message}
                                    >
                                        Save
                                    </Button> */}

                                    <SendOutlinedIcon onClick={saveComment} />
                                    {/* <Button size={"small"} onClick={() => setIsEditComment(false)} color={"primary"}>
                                        Cancel
                                    </Button> */}
                                    {/* <SendOutlinedIcon onClick={saveComment} /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div className={cx("messageContainerOuter")}>
                <MessagesList comments={comments} />
            </div>
        </>
    );
};

const MessagesList = ({ comments }: { comments: any }) => {
    const userContextObj: any = useContext(UserContext);
    const { dateFormatLabel, timeFormatLabel, timeZoneName } = userContextObj || {};

    return (
        <>
            {comments?.map((comment: any, index: number) => {
                return (
                    <div className={cx("messageContainer")} key={index}>
                        <div className={cx("avatar")}>{comment?.commentBy?.fullName?.charAt(0)}</div>
                        <div className={cx("content")}>
                            <span
                                style={{
                                    marginRight: 4,
                                    fontWeight: "bold",
                                    fontSize: 16,
                                }}
                            >
                                {comment?.commentBy?.fullName}
                            </span>{" "}
                            <span className={cx("time")}>
                                {toTimeZone(comment.commentCreatedOn, timeZoneName, dateFormatLabel, timeFormatLabel)}
                            </span>
                            <div className={cx("messageBox")}>
                                {comment.commentType === "FILE" ? (
                                    <RenderAttachment attachment={comment.file} />
                                ) : (
                                    <div className={cx("message")}>{comment.comment}</div>
                                )}
                            </div>
                        </div>
                    </div>
                );
            })}
        </>
    );
};

export default withProjectSocket(TaskComments);
