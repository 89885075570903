import React, { FC, useContext, useEffect, useState } from "react";
import { Typography, Button } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

import AlertDialog from "./../../../../../components/common/alert-dialog/AlertDialog";

import Avatar from "./../../../../../components/common/avatar/Avatar";
import { Colors } from "./../../../../../utils/projectInterfaces";
import SelectField from "./../../../../../components/common/select-field/SelectField";
import InputTextField from "./../../../../../components/common/text-field/TextField";
import GroupAddOutlinedIcon from "@material-ui/icons/GroupAddOutlined";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import DoneOutlinedIcon from "@material-ui/icons/DoneOutlined";
import SaveIcon from "@material-ui/icons/Save";
import MembersDropdown from "../../../common/membersDropdown/MembersDropdown";
import { getAuthToken } from "./../../../../../utils/app-utils";
import withProjectSocket from "./../../../../hoc/ProjectsSocket";
import { MemberRoles } from "../../../../../utils/projectInterfaces";

import { UserContext } from "../../../../../components/app/userContext";

import { API } from "./../../../../../sagas/feature/workspaces/api";

import { WorkspaceVisibility } from "../../../../../utils/projectInterfaces";

import classnames from "classnames/bind";
import styles from "./Workspaces.scss";
const cx = classnames.bind(styles);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: "11px 32px",
        },
    })
);

const MemberRole = ({ userId, newWorkspaceUsers, setNewWorkspaceUsers }) => {
    const [selectedMemberRole, setSelectedMemberRole] = useState("MEMBER");

    useEffect(() => {
        const users = newWorkspaceUsers?.map((item) => {
            return { ...item, role: selectedMemberRole };
        });
        setNewWorkspaceUsers(users);
    }, []);

    const inputChangeHandler = (evt: any) => {
        if (evt.target.value) {
            setSelectedMemberRole(evt.target.value);
            const list = newWorkspaceUsers.map((item: any) => {
                if (item.userID == userId) {
                    return {
                        ...item,
                        role: evt.target.value,
                    };
                }
                return { ...item };
            });
            setNewWorkspaceUsers([...list]);
        }
    };

    return (
        <SelectField
            name={"initialMemberRole"}
            label={"Role"}
            required={true}
            options={MemberRoles?.map((item) => ({
                value: item,
                label: item,
            }))}
            //defaultValue={selectedMemberRole}
            value={selectedMemberRole}
            onChange={inputChangeHandler}
        />
    );
};

interface PageProps {
    selectedProjectId?: string;
    setIsAddMembers: (val: boolean) => void;
    members: any;
    socketProject: WebSocket | null;
    authToken: string;
}

const AddMember: FC<PageProps> = ({ selectedProjectId = "", setIsAddMembers, members, socketProject, authToken }) => {
    const { workspaceId = "" } = useParams();
    const [existingUsers, setExistingUsers] = React.useState<any>([]);
    const [newWorkspaceUsers, setNewWorkspaceUsers] = useState([]);

    useEffect(() => {
        const usersIds =
            members &&
            members?.length > 0 &&
            members.reduce((accum: any, curr: any) => {
                return [...accum, curr.user.id];
            }, []);
        setExistingUsers(usersIds || []);
    }, [members]);

    const handleClose = (evt) => {
        evt.stopPropagation();
        setIsAddMembers(false);
    };

    const handleAddMembers = (evt) => {
        if (socketProject && newWorkspaceUsers?.length) {
            const payloadUsers = newWorkspaceUsers.reduce((accum: any, curr: any) => {
                return [...accum, { id: curr.userID, role: curr.role }];
            }, []);

            if (!payloadUsers?.length) return false;

            socketProject?.send(
                JSON.stringify({
                    action: "addWorkspaceMember",
                    body: {
                        workspaceId,
                        members: payloadUsers,
                    },
                    token: authToken,
                })
            );
            handleClose(evt);
        }
    };

    return (
        <div className={cx("createModal", "memberModal")}>
            <div className={cx("header")}>
                <Typography>Add Members</Typography>
                <div onClick={handleClose}>
                    <CloseOutlinedIcon />
                </div>
            </div>
            <div className={cx("content")}>
                <div className={"membersAddRow"}>
                    <MembersDropdown
                        existingUsers={existingUsers}
                        users={members}
                        isError={false}
                        setUsers={setNewWorkspaceUsers}
                        toText={""}
                    />
                </div>

                {newWorkspaceUsers?.length > 0 && (
                    <div>
                        {newWorkspaceUsers.map((item: any) => {
                            return (
                                <div className={cx("membersRoleRow")}>
                                    <div>{item?.userFullName}</div>
                                    <MemberRole
                                        userId={item.userID}
                                        newWorkspaceUsers={newWorkspaceUsers}
                                        setNewWorkspaceUsers={setNewWorkspaceUsers}
                                    />
                                </div>
                            );
                        })}
                    </div>
                )}

                <div className={cx("membersAddRow")} style={{ marginTop: "40px" }}>
                    <Button
                        variant="outlined"
                        size="small"
                        color="primary"
                        onClick={handleClose}
                        startIcon={<CloseOutlinedIcon />}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="outlined"
                        size="small"
                        color="primary"
                        onClick={handleAddMembers}
                        startIcon={<SaveIcon />}
                    >
                        Save
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default withProjectSocket(AddMember);
