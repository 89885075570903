import React, { useState } from "react";
import { Document, Page } from "react-pdf";

import classnames from "classnames/bind";
import styles from "./preview.scss";
import BaseModal from "../base-modal/baseModal";

const cx = classnames.bind(styles);
interface PreviewProps {
    url: string;
    type?: string;
}

function PreviewPDF(props: PreviewProps) {
    const { url } = props;
    const [open, setOpen] = useState(false);

    const handlePreviewPDF = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <div className={cx("pdfContainer")} onClick={handlePreviewPDF}>
                <span className="label"></span>
            </div>
            <BaseModal
                title={"PDF"}
                open={open}
                fullScreen={true}
                fullWidth={true}
                handleClose={handleClose}
                scroll="body"
            >
                <iframe src={url} width="100%" height="100%"></iframe>
            </BaseModal>
        </>
    );
}

export default PreviewPDF;
