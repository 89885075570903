import { action } from "typesafe-actions";

export const RoomsActionTypes = {
    GET_ROOMS: "rooms/GET_ROOMS",
    GET_ROOMS_SUCCESS: "rooms/GET_ROOMS_SUCCESS",
    GET_ROOMS_FAILURE: "rooms/GET_ROOMS_FAILURE",

    GET_ROOM: "rooms/GET_ROOM",
    GET_ROOM_SUCCESS: "rooms/GET_ROOM_SUCCESS",
    GET_ROOM_FAILURE: "rooms/GET_ROOM_FAILURE",

    UPDATE_MESSAGE: "rooms/UPDATE_MESSAGE",

    UPDATE_CHAT_USERS: "rooms/UPDATE_CHAT_USERS",

    UPDATE_ROOM: "rooms/UPDATE_ROOM",

    CREATE_ROOM: "rooms/CREATE_ROOM",

    RESET: "rooms/ROOMS",

    RESET_MESSAGES: "rooms/RESET_MESSAGES",
};

export const getRooms = () => action(RoomsActionTypes.GET_ROOMS);
export const getRoomsSuccess = (payload) => action(RoomsActionTypes.GET_ROOMS_SUCCESS, payload);
export const getRoomsFailure = (payload) => action(RoomsActionTypes.GET_ROOMS_FAILURE, payload);

export const getRoom = (payload) => action(RoomsActionTypes.GET_ROOM, payload);
export const getRoomSuccess = (payload) => action(RoomsActionTypes.GET_ROOM_SUCCESS, payload);
export const getRoomFailure = (payload) => action(RoomsActionTypes.GET_ROOM_FAILURE, payload);

export const updateMessage = (payload) => action(RoomsActionTypes.UPDATE_MESSAGE, payload);

export const updateChatUsers = (payload) => action(RoomsActionTypes.UPDATE_CHAT_USERS, payload);

export const updateRoom = (payload) => action(RoomsActionTypes.UPDATE_ROOM, payload);

export const createRoom = (payload) => action(RoomsActionTypes.CREATE_ROOM, payload);

export const resetMessages = () => action(RoomsActionTypes.RESET_MESSAGES);

export const reset = () => action(RoomsActionTypes.RESET);
