import React, { FC, useState } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";

import { Card, CardContent, Button, Typography, Paper } from "@material-ui/core";
import Alert from "./../../../../components/common/alert/Alert";
import { changePassword } from "./../../../actions/profileActions";

import InputTextField from "./../../../../components/common/text-field/TextField";
import ProfileSubHeader from "./ProfileSubHeader";
import BodyContent from "./../../common/body/bodyContent";
import { API } from "./../../../sagas/feature/profile/api";
import { UserProfile } from "./../../../../utils/types";
import { usePlanActiveHook } from "../../../../utils/userAccess";

import classnames from "classnames/bind";
import styles from "./Profile.scss";
const cx = classnames.bind(styles);

const HeaderContent = () => {
    return (
        <div className={cx("headerSection")}>
            <div className={cx("top")}>
                <div>
                    <Typography variant="h4">Change Password</Typography>
                </div>
            </div>
        </div>
    );
};

interface PageProps {
    userProfile: UserProfile;
    changePassword: (payload: any) => null;
}

const ChangePassword: FC<PageProps> = ({ changePassword, userProfile }) => {
    const isUserAccess = usePlanActiveHook();
    const [changePasswordApiResponse, setChangePasswordApiResponse] = useState<any>({
        status: "",
        description: "",
    });
    const [formValues, setFormValues] = useState<any>({
        password: "",
        confirmPassword: "",
    });

    const [formValidations, setFormValidations] = useState<any>({
        password: false,
        confirmPassword: false,
    });

    const inputChangeHandler = (evt: any) => {
        setFormValues({ ...formValues, [evt.target.name]: evt.target.value });
    };

    const validateFormValues = () => {
        let isValid = true;
        let tempFormValidations = {};
        for (const [key, value] of Object.entries(formValues)) {
            if (!value) {
                isValid = false;
                tempFormValidations[`${key}`] = true;
            } else {
                tempFormValidations[`${key}`] = false;
            }
        }
        setFormValidations({ ...formValidations, ...tempFormValidations });
        return isValid;
    };

    const handleSave = () => {
        if (!isUserAccess) return false;
        if (validateFormValues()) {
            API.changePassword({
                ...formValues,
                id: userProfile && userProfile.user ? userProfile.user.id : "",
            }).then((res) => {
                if (res.status === "ERROR") {
                    setChangePasswordApiResponse({
                        status: "ERROR",
                        description: res.description === "PASSWORD_NOT_MATCH" ? "PASSWORD_NOT_MATCH" : "",
                    });
                } else {
                    setChangePasswordApiResponse({
                        status: "SUCCESS",
                        description: "",
                    });
                    setFormValues({ password: "", confirmPassword: "" });
                }
            });
        }
    };

    return (
        <div className={cx("profileUserPage")}>
            <ProfileSubHeader selected={"password"} />
            <BodyContent>
                <div className={"headerContainer"}>
                    <HeaderContent />
                </div>
                <div className="contentBody">
                    <Paper className={cx("paper")} style={{ padding: 14, boxShadow: "none" }} elevation={2}>
                        {changePasswordApiResponse && changePasswordApiResponse.status === "SUCCESS" && (
                            <Alert
                                onClose={() => {}}
                                message={`Password changed successfully`}
                                type={"success"}
                            ></Alert>
                        )}
                        {changePasswordApiResponse &&
                            changePasswordApiResponse.status === "ERROR" &&
                            changePasswordApiResponse.description === "PASSWORD_NOT_MATCH" && (
                                <Alert
                                    onClose={() => {}}
                                    message={`Password and confirm password does not match`}
                                    type={"error"}
                                ></Alert>
                            )}
                        {changePasswordApiResponse &&
                            changePasswordApiResponse.status === "ERROR" &&
                            changePasswordApiResponse.description !== "PASSWORD_NOT_MATCH" && (
                                <Alert
                                    onClose={() => {}}
                                    message={`Server error, please try again`}
                                    type={"error"}
                                ></Alert>
                            )}
                        <Card variant="outlined" style={{ border: "none" }}>
                            <CardContent style={{ padding: 0 }}>
                                <div className={cx("rowItem")}>
                                    <div className={cx("colItem")}>
                                        <InputTextField
                                            error={formValidations.password}
                                            errorText={"This field is required"}
                                            name="password"
                                            required={true}
                                            id="password"
                                            label="Password"
                                            type="password"
                                            value={formValues.password}
                                            onChange={inputChangeHandler}
                                        />
                                    </div>
                                </div>
                                <div className={cx("rowItem")}>
                                    <div className={cx("colItem")}>
                                        <InputTextField
                                            error={formValidations.confirmPassword}
                                            errorText={"This field is required"}
                                            name="confirmPassword"
                                            required={true}
                                            type="password"
                                            id="confirmPassword"
                                            label="Confirm Password"
                                            value={formValues.confirmPassword}
                                            onChange={inputChangeHandler}
                                        />
                                    </div>
                                </div>
                                <div className={cx("rowItem")}>
                                    <div className={cx("colItem")} style={{ width: "auto" }}>
                                        <Button
                                            variant="contained"
                                            size="medium"
                                            color="primary"
                                            onClick={handleSave}
                                            disabled={!isUserAccess}
                                        >
                                            Change Password
                                        </Button>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    </Paper>
                </div>
            </BodyContent>
        </div>
    );
};

/* istanbul ignore next */
const mapStateToProps = (state) => {
    return {
        userProfile: state.user.profile || {},
    };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => ({
    changePassword: (payload: any) => dispatch(changePassword(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
