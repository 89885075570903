import { DeleteTwoTone } from "@material-ui/icons";
import { workspacesActionTypes } from "../../../actions/feature/workspaces/workspacesActions";

const initialState = {
    workspaces: [],
    selectedWorkspace: {},
    selectedWorkspaceProject: {},
    selectedWorkspaceProjectTaskId: "",
    selectedWorkspaceProjectTask: "",
    lists: [],
    projectLabels: [],
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        // GET Workspaces
        case workspacesActionTypes.GET_WORKSPACES_SUCCESS:
            return { ...state, workspaces: action.payload };

        case workspacesActionTypes.GET_WORKSPACES_FAILURE:
            return { ...state };

        case workspacesActionTypes.GET_WORKSPACE_SUCCESS:
            return {
                ...state,
                selectedWorkspace: action.payload,
            };
        case workspacesActionTypes.GET_WORKSPACE_FAILURE:
            return { ...state };

        case workspacesActionTypes.GET_WORKSPACE_PROJECT_LISTS_SUCCESS:
            return { ...state, lists: action.payload.lists, projectLabels: action.payload.projectLabels };

        case workspacesActionTypes.GET_WORKSPACE_PROJECT_LISTS_FAILURE:
            return { ...state };

        case workspacesActionTypes.GET_WORKSPACE_TASK_SUCCESS:
            return { ...state, selectedWorkspaceProjectTask: action.payload };

        case workspacesActionTypes.GET_WORKSPACE_TASK_FAILURE:
            return { ...state };

        case workspacesActionTypes.resetWorkspaceTask:
            return { ...state, selectedWorkspaceProjectTask: {} };

        case workspacesActionTypes.UPDATE_WORKSPACE_PROJECT_SELECTED:
            return { ...state, selectedWorkspaceProject: action.payload };

        case workspacesActionTypes.UPDATE_WORKSPACE_PROJECT_TASK_SELECTED:
            return { ...state, selectedWorkspaceProjectTaskId: action.payload };

        case workspacesActionTypes.RESET:
            return { ...state, ...initialState };

        case workspacesActionTypes.ADD_COMMENT:
            const { taskId } = action.payload;
            const { commentId, comment, commentBy, commentCreatedOn, commentUpdatedOn } = action.payload.comment || {};
            const newComment = {
                id: commentId,
                comment,
                commentBy,
                commentCreatedOn,
                commentUpdatedOn,
            };

            return {
                ...state,
                selectedWorkspaceProjectTask: {
                    ...state?.selectedWorkspaceProjectTask,
                    comments:
                        state?.selectedWorkspaceProjectTask?.id == taskId
                            ? [newComment, ...state?.selectedWorkspaceProjectTask?.comments]
                            : state?.selectedWorkspaceProjectTask?.comments?.length
                            ? [...state?.selectedWorkspaceProjectTask?.comments]
                            : [],
                },
            };

        case workspacesActionTypes.UPDATE_TASK:
            const { listId, ...rest } = action.payload;
            return {
                ...state,
                lists: [
                    ...state.lists.map((list) => {
                        if (list?.id == listId) {
                            return {
                                ...list,
                                tasks: [
                                    ...list.tasks,
                                    {
                                        id: "",
                                        listId: listId,
                                        parentTaskId: "",
                                        type: "TASK",
                                        taskTitle: rest.taskTitle,
                                        taskDescription: rest.taskDescription,
                                        // cover,
                                        // coverType,
                                        // startDate,
                                        // dueDate,
                                        taskStatus: "Active",
                                    },
                                ],
                            };
                        }
                        return { ...list };
                    }),
                ],
            };

        case workspacesActionTypes.UPDATE_TASK_DETAILS:
            const { taskId: updateTaskId, ...restTask } = action.payload;
            return {
                ...state,
                selectedWorkspaceProjectTask: {
                    ...state.selectedWorkspaceProjectTask,
                    ...(state.selectedWorkspaceProjectTask.id === updateTaskId ? { ...restTask } : {}),
                },
            };

        case workspacesActionTypes.UPDATE_DELETE_TASK:
            const { taskId: deleteTaskId, listId: deleteListId } = action.payload;
            return {
                ...state,
                lists: [
                    ...state.lists
                        .filter((list) => list?.id == deleteListId)
                        .map((list) => {
                            return { ...list, tasks: [...list.tasks.filter((task) => task.id != deleteTaskId)] };
                        }),
                ],
            };

        case workspacesActionTypes.UPDATE_LIST_DETAILS:
            const { listId: listIdEnc, ...restList } = action.payload;
            return {
                ...state,
                lists: [
                    ...state.lists.map((list) => {
                        if (list?.id == listIdEnc) {
                            return {
                                ...list,
                                ...restList,
                            };
                        }
                        return { ...list };
                    }),
                ],
            };

        case workspacesActionTypes.UPDATE_PROJECT:
            const { projectId: projectIdEnc, ...restProjectObj } = action.payload;
            return {
                ...state,
                selectedWorkspace: {
                    ...state.selectedWorkspace,
                    projects: [
                        ...state.selectedWorkspace.projects.map((item) => {
                            if (item) {
                                return {
                                    ...item,
                                    ...restProjectObj,
                                };
                            }
                            return { ...item };
                        }),
                    ],
                },
            };

        case workspacesActionTypes.UPDATE_DELETE_LIST:
            const { listId: listIdDelete } = action.payload;
            return {
                ...state,
                lists: [...state.lists.filter((list) => list?.id != listIdDelete)],
            };

        case workspacesActionTypes.UPDATE_LIST_ORDER:
            const { listId: listId3, listOrder } = action.payload;
            return {
                ...state,
                lists: [
                    ...state.lists.map((list) => {
                        if (list.id == listId3) {
                            return {
                                ...list,
                                listOrder: listOrder,
                            };
                        }
                        return { ...list };
                    }),
                ],
            };

        case workspacesActionTypes.UPDATE_TASKS_ORDER:
            const { listId: listId2, taskOrder, taskId: taskId2 } = action.payload;
            return {
                ...state,
                lists: [
                    ...state.lists.map((list) => {
                        if (list.id == listId2) {
                            return {
                                ...list,
                                tasks: [
                                    ...list.tasks.map((item) => {
                                        if (item.id == taskId2) {
                                            return {
                                                ...item,
                                                taskOrder: taskOrder,
                                            };
                                        }
                                        return { ...item };
                                    }),
                                ],
                            };
                        }
                        return { ...list };
                    }),
                ],
            };

        case workspacesActionTypes.UPDATE_TASKS_POSITION_IN_LIST:
            const { taskId: editTaskId, removedList, addedList, taskOrder: taskOrderEnc } = action.payload;

            let task = {};
            let removeIndex = "";
            state.lists.find((list) => {
                if (list.id == removedList) {
                    const t = list.tasks.find((task, index) => {
                        if (task.id == editTaskId) {
                            removeIndex = index;
                            return true;
                        }
                    });
                    if (t) {
                        task = t;
                    }
                }
            });

            return {
                ...state,
                lists: [
                    ...state.lists.map((list) => {
                        if (list.id == removedList) {
                            return {
                                ...list,
                                tasks: [...list.tasks.filter((task) => task.id != editTaskId)],
                            };
                        } else if (list.id == addedList) {
                            return { ...list, tasks: [...list.tasks, { ...task, taskOrder: taskOrderEnc }] };
                        } else {
                            return { ...list };
                        }
                    }),
                ],
            };

        default:
            return state;
    }
};

export { reducer as workspacesReducer };
