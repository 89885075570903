import { fork, put, delay, takeLatest } from "redux-saga/effects";
import { API } from "./api";
import callApi from "../../../../utils/callApi";

import {
    getProfileSuccess,
    getProfileFailure,
    ProfileActionTypes,
    changePasswordSuccess,
    changePasswordFailure,
} from "../../../actions/profileActions";
import { isLoading, openSnackbar, closeSnackbar, openAlert } from "../../../../actions/feature/app/appActions";

function* getProfileSaga() {
    try {
        yield put(isLoading(true));
        const [error, res] = yield* callApi(API.getProfile, { disableLoading: true }, {}, true);
        if (error || res.status === "ERROR") {
            yield put(getProfileFailure({ status: "ERROR" }));
        } else {
            yield put(getProfileSuccess(res));
        }
        yield put(isLoading(false));
    } catch (error) {
        yield put(isLoading(false));
        yield put(getProfileFailure({ status: "ERROR" }));
    }
}

function* changePasswordSaga(reqPayload) {
    const { payload } = reqPayload;;

    try {
        yield put(isLoading(true));
        const [error, res] = yield* callApi(API.changePassword, { disableLoading: true }, payload, true);
        if (error || res.status === "ERROR") {
            yield put(changePasswordFailure({ status: "ERROR", description: res.description }));
        } else {
            yield put(openSnackbar("Password changed successfully"));
            yield put(changePasswordSuccess(res.data));
        }

        yield put(isLoading(false));
        yield delay(3000);
        yield put(closeSnackbar());
    } catch (error) {
        yield put(isLoading(false));
        yield put(openSnackbar("Invalid error, please try again"));
        yield put(changePasswordFailure({ status: "ERROR" }));
        yield delay(3000);
        yield put(closeSnackbar());
    }
}

function* watchDepartment() {
    yield takeLatest(ProfileActionTypes.GET_PROFILE, getProfileSaga);
    yield takeLatest(ProfileActionTypes.CHANGE_PASSWORD, changePasswordSaga);
    
}

const departmentSagas = [fork(watchDepartment)];

export default departmentSagas;
