import React, { FC, useState, useEffect } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Typography, Paper } from "@material-ui/core";

import Logo from "./../../../assets/images/logo2.png";
import { setAuthToken } from "./../../../utils/app-utils";

import classnames from "classnames/bind";
import styles from "./ForgotPassword.scss";
import colors from "colors";
const cx = classnames.bind(styles);

const useStyles = makeStyles((theme: Theme) => ({
    title: {
        textAlign: "center",
        marginBottom: "40px",
    },
}));

interface PageProps {
    multiAccounts: any;
}

const MultiAccounts: FC<PageProps> = ({ multiAccounts }) => {
    const classes = useStyles();
    const history = useHistory();

    const handleClick = (item: any) => {
        setAuthToken(item.jwt);
        history.push("hrm/chat");
    };

    return (
        <div className={cx("passwordWrapper")}>
            <img src={Logo} className={cx("logopage")} />
            <Paper className={cx("formWrapper")} elevation={1}>
                {multiAccounts && multiAccounts.length > 0 ? (
                    <Typography className={classes.title}>
                        <div>We detected multiple accounts</div>
                        <div>Please choose account below and proceed</div>
                    </Typography>
                ) : (
                    <Typography className={classes.title}>
                        <div>Something went wrong, please try again</div>
                    </Typography>
                )}
                <div className={cx("accounts")}>
                    {multiAccounts &&
                        multiAccounts.map((item: any) => (
                            <div onClick={() => handleClick(item)}>
                                <Typography>{item.companyName || "Unassigned name"}</Typography>
                            </div>
                        ))}
                </div>
            </Paper>
        </div>
    );
};

/* istanbul ignore next */
const mapStateToProps = (state: any) => {
    return {
        multiAccounts: state.user.multiAccounts,
    };
};

export default connect(mapStateToProps, null)(MultiAccounts);
