import { action } from "typesafe-actions";

export const FeedActionTypes = {
    GET_FEEDS: "feeds/GET_FEEDS",
    GET_FEEDS_SUCCESS: "feeds/GET_FEEDS_SUCCESS",
    GET_FEEDS_FAILURE: "feeds/GET_FEEDS_FAILURE",

    GET_TOPICS: "feeds/GET_TOPICS",
    GET_TOPICS_SUCCESS: "feeds/GET_TOPICS_SUCCESS",
    GET_TOPICS_FAILURE: "feeds/GET_TOPICS_FAILURE",

    UPDATE_TOPICS_SUCCESS: "feeds/UPDATE_TOPICS_SUCCESS",

    UPDATE_COMMENT: "feeds/UPDATE_COMMENT",

    RESET: "feeds/RESET",
};

export const getFeeds = () => action(FeedActionTypes.GET_FEEDS);
export const getFeedsSuccess = (payload) => action(FeedActionTypes.GET_FEEDS_SUCCESS, payload);
export const getFeedsFailure = (payload) => action(FeedActionTypes.GET_FEEDS_FAILURE, payload);

export const getTopics = (groupId) => action(FeedActionTypes.GET_TOPICS, groupId);
export const getTopicsSuccess = (payload) => action(FeedActionTypes.GET_TOPICS_SUCCESS, payload);
export const getTopicsFailure = (payload) => action(FeedActionTypes.GET_TOPICS_FAILURE, payload);

export const updateTopicsSuccess = (payload) => action(FeedActionTypes.UPDATE_TOPICS_SUCCESS, payload);

export const updateComment = (payload) => action(FeedActionTypes.UPDATE_COMMENT, payload);

export const reset = () => action(FeedActionTypes.RESET);
