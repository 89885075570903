import React, { createContext, useState, useEffect, ReactNode, useRef } from "react";
import { connect } from "react-redux";

export const UserContext = createContext({});

export interface UserProviderProps {
    children: ReactNode;
    userProfile: any;
    socket: WebSocket | null;
    socketProjects: WebSocket | null;
    config: any;
}

const UserProvider = ({ children, userProfile, socket, socketProjects, config }) => {
    const { user, company } = userProfile || {};
    const [userPlanStatus, setUserPlanStatus] = useState(user?.userCompanyPlanStatus);
    const [totalPlanUsers, setTotalPlanUsers] = useState(parseInt(company?.TotalPlanUsers || 0));
    const [totalUsers, setTotalUsers] = useState(parseInt(company?.TotalUsers));
    const [userRole, setUserRole] = useState(user?.RoleName);
    const [currentUser, setCurrentUser] = useState({ id: "", fullName: "", userProfileColor: "" });

    const { timeZoneId, dateFormatId, timeFormatId } = user || {};
    const [timeZoneName, setTimeZoneName] = useState<string>("");
    const [dateFormatLabel, setDateFormatLabel] = useState<string>("");
    const [timeFormatLabel, setTimeFormatLabel] = useState<string>("");

    useEffect(() => {
        setTotalPlanUsers(parseInt(company?.TotalPlanUsers || 0));
    }, [company]);

    useEffect(() => {
        setUserRole(user?.RoleName);
    }, [user?.RoleName]);

    useEffect(() => {
        setCurrentUser({
            id: user?.id,
            fullName: user?.UserFullName,
            userProfileColor: user?.UserProfileColor,
        });
    }, [user]);

    useEffect(() => {
        setTotalUsers(parseInt(company?.TotalUsers));
    }, [company?.TotalUsers]);

    useEffect(() => {
        const { timezone } = config;
        if (timezone?.length) {
            const tz = timezone.find((item: any) => item.id === timeZoneId);
            setTimeZoneName(tz?.TimezoneTitle || "");
        }
    }, [timeZoneId]);

    useEffect(() => {
        const { dateFormat } = config;
        if (dateFormat?.length) {
            const dtFormat = dateFormat.find((item: any) => item.id === dateFormatId);
            setDateFormatLabel(dtFormat?.DateFormatValue || "");
        }
    }, [dateFormatId]);

    useEffect(() => {
        const { timeFormat } = config;
        if (timeFormat?.length) {
            const tFormat = timeFormat.find((item: any) => item.id === timeFormatId);
            setTimeFormatLabel(tFormat?.TimeFormatValue || "");
        }
    }, [timeFormatId]);

    useEffect(() => {
        setUserPlanStatus(user?.userCompanyPlanStatus);
    }, [user?.userCompanyPlanStatus]);

    return (
        <UserContext.Provider
            value={{
                currentUser,
                userPlanStatus,
                userRole,
                totalPlanUsers,
                totalUsers,
                socket,
                socketProjects,
                timeZoneName,
                dateFormatLabel,
                timeFormatLabel,
            }}
        >
            {children}
        </UserContext.Provider>
    );
};

const mapStateToProps = (state) => {
    return {
        userProfile: state.user.profile,
        config: state.app.config,
    };
};

export default connect(mapStateToProps, null)(UserProvider);
