import { AppActionTypes } from "../../../actions/feature/app/appActions";
import Config from "./../../../utils/config";

const initialState = {
    isLoading: false,
    isDrawerOpen: false,
    snackbar: {
        open: false,
        message: "",
    },
    alert: {
        open: false,
        message: "",
        type: "",
    },
    userSettings: {},
    config: Config,
    apiResponse: "",
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case AppActionTypes.IS_LOADING:
            return { ...state, isLoading: action.payload };

        case AppActionTypes.OPEN_SNACKBAR:
            return { ...state, snackbar: { open: true, message: action.payload } };

        case AppActionTypes.CLOSE_SNACKBAR:
            return { ...state, snackbar: { open: false, message: "" } };

        case AppActionTypes.TOGGLE_DRAWER:
            return { ...state, isDrawerOpen: action.payload };

        case AppActionTypes.OPEN_ALERT:
            return { ...state, alert: { open: true, message: action.payload.message, type: action.payload.type } };

        case AppActionTypes.CLOSE_ALERT:
            return { ...state, alert: { open: false, message: "", type: "" } };

        case AppActionTypes.API_RESPONSE:
            return { ...state, apiResponse: action.payload };

        case AppActionTypes.RESET:
            return { ...state, ...initialState };

        default:
            return state;
    }
};

export { reducer as appReducer };
