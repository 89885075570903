import { FeedActionTypes } from "../../../actions/feature/feeds/feedsActions";

const initialState = {
    feedGroups: [],
    topics: [],
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        //FEEDS
        case FeedActionTypes.GET_FEEDS_SUCCESS:
            return { ...state, feedGroups: action.payload };
        case FeedActionTypes.GET_FEEDS_FAILURE:
            return { ...state };

        //TOPICS
        case FeedActionTypes.GET_TOPICS_SUCCESS:
            return { ...state, topics: action.payload };
        case FeedActionTypes.GET_TOPICS_FAILURE:
            return { ...state };

        // UPDATE TOPICS
        case FeedActionTypes.UPDATE_TOPICS_SUCCESS:
            const isTopicFound = state.topics.find((topic) => topic.id == action.payload.id);
            if (isTopicFound) {
                return { ...state };
            } else {
                return { ...state, topics: [action.payload, ...state.topics] };
            }

        case FeedActionTypes.UPDATE_COMMENT:
            return {
                ...state,
                topics: state.topics.map((topic) => {
                    if (topic.id === action.payload.topicId) {
                        const isCommentFound = topic.comments.find((comment) => comment.id == action.payload.id);
                        if (isCommentFound) {
                            return topic;
                        } else {
                            return { ...topic, comments: [...topic.comments, action.payload] };
                        }
                    } else {
                        return topic;
                    }
                }),
            };

        case FeedActionTypes.RESET:
            return { ...state, ...initialState };

        //DEFAULT
        default:
            return state;
    }
};

export { reducer as feedsReducer };
