import { buildGET, buildAuthPOST, buildDELETE, buildAuthPUT } from "./../../../../utils/api";

// APIs
const getUsers = () => buildGET("users");

const getUser = (id) => buildGET(`users/${id}`);

const getUserProfile = () => buildGET(`user`);

const addUser = (payload) => buildAuthPOST("users", payload);

const addUserOnboarding = (payload) => buildAuthPOST("users/onboarding", payload);

const updateUserOffboarding = (payload) => buildAuthPOST("users/offboarding", payload);

const sendInviteEmail = (payload) => buildAuthPOST("users/resendInvite", payload);

const deleteUser = (id) => buildDELETE(`users/${id}`);

const updateUser = (payload) => {
    const { id, ...rest } = payload;
    return buildAuthPUT(`users/${id}`, rest);
};

const updateUserSetting = (payload) => {
    const { id, ...rest } = payload;
    return buildAuthPUT(`users/setting/${id}`, rest);
};

// API export
export const API = {
    getUsers,
    getUser,
    addUser,
    deleteUser,
    updateUser,
    addUserOnboarding,
    updateUserSetting,
    getUserProfile,
    updateUserOffboarding,
    sendInviteEmail,
};
