import React from "react";
import { Typography } from "@material-ui/core";
import ReactDOM from "react-dom";
import { loadStripe } from "@stripe/stripe-js";

import PaymentSubHeader from "./PaymentSubHeader";
import BodyContent from "./../../common/body/bodyContent";
import classnames from "classnames/bind";
import styles from "./payment.scss";
const cx = classnames.bind(styles);

const HeaderContent = () => {
    return (
        <div className={cx("headerSection")}>
            <div className={cx("top")}>
                <div>
                    <Typography variant="h4">Payment</Typography>
                </div>
            </div>
        </div>
    );
};

//"@stripe/react-stripe-js": "^1.1.2",

const fetchCheckoutSession = async ({ quantity }) => {
    return fetch("http://localhost/collab-team-backend/api/v1.0/stripe", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            quantity,
        }),
    }).then((res) => res.json());
};

const connectToStripe = async () => {
    fetch("http://localhost/collab-team-backend/api/v1.0/stripe/connect", {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    })
        .then((response) => response.json())
        .then((data) => {
            const { url } = data.data;

            if (url) {
                window.location = url;
            } else {
                console.log("Error data", data);
            }
        });
};

export class Stripe extends React.Component {
    state = {
        stripePromise: null,
    };

    componentDidMount() {
        this.fetchConfig();
    }

    fetchConfig = async () => {
        const stripe = await loadStripe(process.env.REACT_APP_STRIPE_KEY!);
        this.setState({ stripePromise: stripe });
    };

    render() {
        const handleClick = async (event) => {
            // Call your backend to create the Checkout session.

            const response = await fetchCheckoutSession({
                quantity: 2,
            });
            const jsd = response.data;
            const res = JSON.parse(jsd);
            const sessionId = res.sessionId;

            // When the customer clicks on the button, redirect them to Checkout.
            // const { error } = await this.state.stripePromise.redirectToCheckout({
            //     sessionId,
            // });
            // If `redirectToCheckout` fails due to a browser or network
            // error, display the localized error message to your customer
            // using `error.message`.
        };

        const handleConnectClick = async (event) => {
            const connectResponse = await connectToStripe();
        };

        return (
            <>
                <div className={cx("profileUserPage")}>
                    <PaymentSubHeader selected={"billing"} />
                    <BodyContent>
                        <div className={"headerContainer"}>
                            <HeaderContent />
                        </div>
                        <div className="contentBody" style={{ height: "88vh" }}>
                            <button role="link" onClick={handleClick}>
                                Pay 10
                            </button>
                            <br />
                            <button className="connectStripe" role="link" onClick={handleConnectClick}>
                                Connect to Your Stripe Account
                            </button>
                        </div>
                    </BodyContent>
                </div>
            </>
        );
    }
}

export default Stripe;
