import React, { FC, useState, useEffect } from "react";
import { Card, CardContent, Typography, Button, Box, Divider, ListItemText } from "@material-ui/core";

import { API } from "./../../../sagas/feature/profile/api";
import SelectField from "./../../../../components/common/select-field/SelectField";
import InputTextField from "./../../../../components/common/text-field/TextField";
import Alert from "./../../../../components/common/alert/Alert";
import { usePlanActiveHook } from "../../../../utils/userAccess";

import classnames from "classnames/bind";
import styles from "./Profile.scss";
const cx = classnames.bind(styles);

interface PageProps {
    id: string;
    contacts: any[];
    style?: any;
    config: any;
    alert: {
        open: boolean;
        message: string;
        type: string;
    };
    closeAlert: () => void;
    updateContactsSuccess: (payload: any) => null;
    openSnackbar: (payload: any) => null;
    closeSnackbar: () => null;
    openAlert: (payload: any) => null;
}

const EmergencyContact: FC<PageProps> = ({
    id,
    contacts,
    style,
    config,
    closeAlert,
    alert,
    updateContactsSuccess,
    openSnackbar,
    closeSnackbar,
    openAlert,
}) => {
    const isUserAccess = usePlanActiveHook();
    const [edit, setEdit] = useState<boolean>(false);

    const [primaryFormValues, setPrimaryFormValues] = useState<any>({
        id: "",
        isContactPrimary: "Yes",
        contactName: "",
        contactRelationship: "",
        contactPhone: "",
    });
    const [secondaryFormValues, setSecondaryFormValues] = useState<any>({
        id: "",
        isContactPrimary: "No",
        contactName: "",
        contactRelationship: "",
        contactPhone: "",
    });
    const [formValidations, setFormValidations] = useState<any>({
        contactName: false,
        contactRelationship: false,
        contactPhone: false,
    });

    useEffect(() => {
        const primary =
            (contacts && contacts?.length && contacts.find((contact) => contact.isContactPrimary === "Yes")) || {};
        const secondary =
            (contacts && contacts?.length && contacts.find((contact) => contact.isContactPrimary === "No")) || {};

        setPrimaryFormValues({
            id: primary?.id || "",
            isContactPrimary: "Yes",
            contactName: primary?.contactName,
            contactRelationship: primary?.contactRelationship,
            contactPhone: primary?.contactPhone,
        });
        setSecondaryFormValues({
            id: secondary?.id || "",
            isContactPrimary: "No",
            contactName: secondary?.contactName,
            contactRelationship: secondary?.contactRelationship,
            contactPhone: secondary?.contactPhone,
        });
    }, [contacts]);

    const getRelationShipValue = (id) => {
        return (
            ((config && config.relationship && config.relationship.find((item: any) => item.id === id)) || {}).label ||
            "N/A"
        );
    };

    const getRelationShipOptions = () => {
        const { relationship } = config;
        if (relationship && relationship.length) {
            return relationship.map((item: any) => {
                return {
                    label: item.label,
                    value: item.id,
                };
            });
        }
    };

    const inputPrimaryChangeHandler = (evt: any) => {
        setPrimaryFormValues({ ...primaryFormValues, [evt.target.name]: evt.target.value });
    };

    const inputSecondaryChangeHandler = (evt: any) => {
        setSecondaryFormValues({ ...secondaryFormValues, [evt.target.name]: evt.target.value });
    };

    const validateFormValues = () => {
        let isValid = true;
        let tempFormValidations = {};
        for (const [key, value] of Object.entries(primaryFormValues)) {
            if (formValidations.hasOwnProperty(key)) {
                if (key !== "id") {
                    if (!value) {
                        isValid = false;
                        tempFormValidations[`${key}`] = true;
                    } else {
                        tempFormValidations[`${key}`] = false;
                    }
                }
            }
        }
        setFormValidations({ ...formValidations, ...tempFormValidations });
        return isValid;
    };

    const handleSave = () => {
        if (validateFormValues()) {
            const payload = {
                id: id,
                primary: primaryFormValues,
                secondary: secondaryFormValues,
            };
            API.updateContacts(payload)
                .then(
                    (res) => {
                        if (res.status === "ERROR") {
                            openAlert({ message: "Invalid error, please try again", type: "error" });
                        } else {
                            openSnackbar("Contacts updated successfully");
                            updateContactsSuccess(res.data.contacts);
                            setEdit(false);
                        }
                    },
                    (res) => {
                        openAlert({ message: "Invalid error, please try again", type: "error" });
                    }
                )
                .finally(() => {
                    setTimeout(() => {
                        closeSnackbar();
                    }, 3000);
                });
        }
    };

    return (
        <div style={{ ...style, marginBottom: 24 }} className={cx("profilePage")}>
            <div className={cx("title")}>
                <Typography color="textSecondary" gutterBottom>
                    Emergency Contact
                </Typography>
                {!edit && (
                    <Button color="primary" onClick={() => setEdit(true)} disabled={!isUserAccess}>
                        Edit
                    </Button>
                )}
            </div>
            <Card variant="outlined">
                <CardContent style={{ padding: 24 }}>
                    <div className={cx("sectionHead")}>Primary</div>
                    {alert.open && alert.message && edit && (
                        <Alert onClose={() => {}} message={alert.message} type={alert.type}></Alert>
                    )}
                    <div className={cx("rowItem")}>
                        <div className={cx("colItem")}>
                            {!edit && <ListItemText primary="Name" secondary={primaryFormValues?.contactName} />}
                            {edit && (
                                <InputTextField
                                    error={formValidations.contactName}
                                    errorText={"This field is required"}
                                    name="contactName"
                                    required={true}
                                    id="contactName"
                                    label="Name"
                                    value={primaryFormValues.contactName}
                                    onChange={inputPrimaryChangeHandler}
                                />
                            )}
                        </div>
                        <div className={cx("colItem")}>
                            {!edit && (
                                <ListItemText
                                    primary="Relationship"
                                    secondary={getRelationShipValue(primaryFormValues?.contactRelationship)}
                                />
                            )}
                            {edit && (
                                <SelectField
                                    error={formValidations.contactRelationship}
                                    errorText={"This field is required"}
                                    name="contactRelationship"
                                    required={true}
                                    id="contactRelationship"
                                    label="Relationship"
                                    value={primaryFormValues.contactRelationship}
                                    onChange={inputPrimaryChangeHandler}
                                    options={getRelationShipOptions()}
                                />
                            )}
                        </div>
                    </div>

                    <div className={cx("rowItem")}>
                        <div className={cx("colItem")}>
                            {!edit && <ListItemText primary="Phone No." secondary={primaryFormValues?.contactPhone} />}
                            {edit && (
                                <InputTextField
                                    error={formValidations.contactPhone}
                                    errorText={"This field is required"}
                                    name="contactPhone"
                                    required={true}
                                    id="contactPhone"
                                    label="Phone No."
                                    value={primaryFormValues.contactPhone}
                                    onChange={inputPrimaryChangeHandler}
                                />
                            )}
                        </div>
                    </div>
                    <Divider className={cx("line")} />
                    <div className={cx("sectionHead")}>Secondary</div>

                    <div className={cx("rowItem")}>
                        <div className={cx("colItem")}>
                            {!edit && <ListItemText primary="Name" secondary={secondaryFormValues?.contactName} />}
                            {edit && (
                                <InputTextField
                                    name="contactName"
                                    required={false}
                                    id="contactName"
                                    label="Name"
                                    value={secondaryFormValues.contactName}
                                    onChange={inputSecondaryChangeHandler}
                                />
                            )}
                        </div>
                        <div className={cx("colItem")}>
                            {!edit && (
                                <ListItemText
                                    primary="Relationship"
                                    secondary={getRelationShipValue(secondaryFormValues?.contactRelationship)}
                                />
                            )}
                            {edit && (
                                <SelectField
                                    name="contactRelationship"
                                    required={false}
                                    id="contactRelationship"
                                    label="Relationship"
                                    value={secondaryFormValues.contactRelationship}
                                    onChange={inputSecondaryChangeHandler}
                                    options={getRelationShipOptions()}
                                />
                            )}
                        </div>
                    </div>

                    <div className={cx("rowItem")}>
                        <div className={cx("colItem")}>
                            {!edit && (
                                <ListItemText primary="Phone No." secondary={secondaryFormValues?.contactPhone} />
                            )}
                            {edit && (
                                <InputTextField
                                    name="contactPhone"
                                    required={false}
                                    id="contactPhone"
                                    label="Phone No."
                                    value={secondaryFormValues.contactPhone}
                                    onChange={inputSecondaryChangeHandler}
                                />
                            )}
                        </div>
                    </div>
                    {edit && (
                        <Box className={cx("rowItem")} style={{ justifyContent: "flex-end", marginTop: 24 }}>
                            <Button
                                variant="outlined"
                                style={{ marginRight: 16 }}
                                size="medium"
                                color="primary"
                                onClick={() => setEdit(false)}
                            >
                                Cancel
                            </Button>
                            <Button variant="contained" size="medium" color="primary" onClick={handleSave}>
                                {"Save"}
                            </Button>
                        </Box>
                    )}
                </CardContent>
            </Card>
        </div>
    );
};

export default EmergencyContact;
