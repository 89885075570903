import { action } from "typesafe-actions";

export const RoleActionTypes = {
    GET_ROLES: "ROLE/GET_ROLES",
    GET_ROLES_SUCCESS: "ROLE/GET_ROLES_SUCCESS",
    GET_ROLES_FAILURE: "ROLE/GET_ROLES_FAILURE",

    GET_ROLE: "ROLE/GET_ROLE",
    GET_ROLE_SUCCESS: "ROLE/GET_ROLE_SUCCESS",
    GET_ROLE_FAILURE: "ROLE/GET_ROLE_FAILURE",

    ADD_ROLE: "ROLE/ADD_ROLE",
    ADD_ROLE_SUCCESS: "ROLE/ADD_ROLE_SUCCESS",
    ADD_ROLE_FAILURE: "ROLE/ADD_ROLE_FAILURE",

    UPDATE_ROLE: "ROLE/UPDATE_ROLE",
    UPDATE_ROLE_SUCCESS: "ROLE/UPDATE_ROLE_SUCCESS",
    UPDATE_ROLE_FAILURE: "ROLE/UPDATE_ROLE_FAILURE",

    DELETE_ROLE: "ROLE/DELETE_ROLE",
    DELETE_ROLE_SUCCESS: "ROLE/DELETE_ROLE_SUCCESS",
    DELETE_ROLE_FAILURE: "ROLE/DELETE_ROLE_FAILURE",

    GET_ROLES_CAPABILITIES: "ROLE/GET_ROLES_CAPABILITIES",
    GET_ROLES_CAPABILITIES_SUCCESS: "ROLE/GET_ROLES_CAPABILITIES_SUCCESS",
    GET_ROLES_CAPABILITIES_FAILURE: "ROLE/GET_ROLES_CAPABILITIES_FAILURE",

    UPDATE_CAPABILITY: "ROLE/UPDATE_CAPABILITY",

    UPDATE_ROLE_CAPABILITY: "ROLE/UPDATE_ROLE_CAPABILITY",
    UPDATE_ROLE_CAPABILITY_SUCCESS: "ROLE/UPDATE_ROLE_CAPABILITY_SUCCESS",
    UPDATE_ROLE_CAPABILITY_FAILURE: "ROLE/UPDATE_ROLE_CAPABILITY_FAILURE",

    CLEAR_ROLE: "ROLE/CLEAR_ROLE",

    RESET: "RESET",
};

export const getRoles = () => action(RoleActionTypes.GET_ROLES);
export const getRolesSuccess = (payload) => action(RoleActionTypes.GET_ROLES_SUCCESS, payload);
export const getRolesFailure = (payload) => action(RoleActionTypes.GET_ROLES_FAILURE, payload);

export const getRole = (id) => action(RoleActionTypes.GET_ROLE, id);
export const getRoleSuccess = (payload) => action(RoleActionTypes.GET_ROLE_SUCCESS, payload);
export const getRoleFailure = (payload) => action(RoleActionTypes.GET_ROLE_FAILURE, payload);

export const addRole = (payload) => action(RoleActionTypes.ADD_ROLE, payload);
export const addRoleSuccess = (payload) => action(RoleActionTypes.ADD_ROLE_SUCCESS, payload);
export const addRoleFailure = (payload) => action(RoleActionTypes.ADD_ROLE_FAILURE, payload);

export const updateRole = (payload) => action(RoleActionTypes.UPDATE_ROLE, payload);
export const updateRoleSuccess = (payload) => action(RoleActionTypes.UPDATE_ROLE_SUCCESS, payload);
export const updateRoleFailure = (payload) => action(RoleActionTypes.UPDATE_ROLE_FAILURE, payload);

export const deleteRole = (payload) => action(RoleActionTypes.DELETE_ROLE, payload);
export const deleteRoleSuccess = (payload) => action(RoleActionTypes.DELETE_ROLE_SUCCESS, payload);
export const deleteRoleFailure = (payload) => action(RoleActionTypes.DELETE_ROLE_FAILURE, payload);

export const getRoleCapabilities = (id) => action(RoleActionTypes.GET_ROLES_CAPABILITIES, id);
export const getRoleCapabilitiesSuccess = (payload) => action(RoleActionTypes.GET_ROLES_CAPABILITIES_SUCCESS, payload);
export const getRoleCapabilitiesFailure = (payload) => action(RoleActionTypes.GET_ROLES_CAPABILITIES_FAILURE, payload);

export const updateCapability = (payload) => action(RoleActionTypes.UPDATE_CAPABILITY, payload);

export const updateRoleCapability = (payload) => action(RoleActionTypes.UPDATE_ROLE_CAPABILITY, payload);
export const updateRoleCapabilitySuccess = (payload) => action(RoleActionTypes.UPDATE_ROLE_CAPABILITY_SUCCESS, payload);
export const updateRoleCapabilityFailure = (payload) => action(RoleActionTypes.UPDATE_ROLE_CAPABILITY_FAILURE, payload);

export const clearRole = () => action(RoleActionTypes.CLEAR_ROLE);

export const reset = () => action(RoleActionTypes.RESET);
