import { RoleActionTypes } from "./../../actions/roleActions";

const initialState = {
    roles: [],
    role: {
        id: "",
        roleName: "",
        roleType: "",
        capability: [],
    },
    pagination: {
        records: 0,
        per_page_records: 0,
        page: 0,
        pages: 0,
    },
    capabilities: [],
    apiResponse: {
        getRoles: {},
        getRole: {},
        addRole: {},
        deleteRole: {},
        updateRole: {},
    },
};

const reducer = (state = initialState, action: any) => {
    switch (action.type) {
        //ROLES
        case RoleActionTypes.GET_ROLES_SUCCESS:
            return {
                ...state,
                roles: action.payload.data,
            };
        case RoleActionTypes.GET_ROLES_FAILURE:
            return { ...state, apiResponse: { ...state.apiResponse, getRoles: action.payload } };

        //ROLE
        case RoleActionTypes.GET_ROLE_SUCCESS:
            return { ...state, role: action.payload };
        case RoleActionTypes.GET_ROLE_FAILURE:
            return { ...state, apiResponse: { ...state.apiResponse, getRole: action.payload } };

        //ADD ROLE
        case RoleActionTypes.ADD_ROLE_SUCCESS:
            return {
                ...state,
                apiResponse: { ...state.apiResponse, addRole: { status: "success" } },
                roles: [...state.roles, action.payload],
            };
        case RoleActionTypes.ADD_ROLE_FAILURE:
            return { ...state, apiResponse: { ...state.apiResponse, addRole: action.payload } };

        //UPDATE ROLE
        case RoleActionTypes.UPDATE_ROLE_SUCCESS:
            return {
                ...state,
                roles: state.roles.map((role: any) => {
                    if (role.id === action.payload.id) {
                        return action.payload;
                    } else {
                        return role;
                    }
                }),
            };
        case RoleActionTypes.ADD_ROLE_FAILURE:
            return { ...state, apiResponse: { ...state.apiResponse, updateRole: action.payload } };

        //DELETE ROLE
        case RoleActionTypes.DELETE_ROLE_SUCCESS:
            return {
                ...state,
                roles: state.roles.filter((role: any) => {
                    return role.id !== action.payload.id;
                }),
            };
        case RoleActionTypes.DELETE_ROLE_FAILURE:
            return { ...state, apiResponse: { ...state.apiResponse, deleteRole: action.payload } };

        //ROLE CAPABILITIES
        case RoleActionTypes.GET_ROLES_CAPABILITIES_SUCCESS:
            return { ...state, capabilities: action.payload.data };
        case RoleActionTypes.GET_ROLES_CAPABILITIES_FAILURE:
            return { ...state, apiResponse: { ...state.apiResponse, capabilities: action.payload } };

        //UPDATE CAPABILITY UI
        case RoleActionTypes.UPDATE_CAPABILITY:
            return {
                ...state,
                role: {
                    ...state.role,
                    capability: state.role.capability.map((item: any) => {
                        if (item.id === action.payload.capabilityId) {
                            const subCapabilityArr = item.subCapability.map((subItem: any) => {
                                if (subItem.id === action.payload.subCapabilityId) {
                                    return {
                                        ...subItem,
                                        isGrant:
                                            action.payload.type === "grant"
                                                ? action.payload.isChecked
                                                : !action.payload.isChecked,
                                    };
                                } else {
                                    return subItem;
                                }
                            });
                            return { ...item, subCapability: subCapabilityArr };
                        } else {
                            return item;
                        }
                    }),
                },
            };

        // CLEAR ROLE
        case RoleActionTypes.CLEAR_ROLE:
            return {
                ...state,
                role: {},
            };

        case RoleActionTypes.RESET:
            return { ...state, ...initialState };

        //DEFAULT
        default:
            return state;
    }
};

export { reducer as roleReducer };
