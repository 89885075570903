import { buildGET, buildAuthPOST, buildDELETE, buildAuthPUT } from "./../../../utils/chatApi";

// APIs
const getRooms = () => buildGET("rooms");
const getRoom = (payload) => buildGET(`rooms?id=${payload.id}&page=${payload.page}`);

// API export
export const API = {
    getRooms,
    getRoom,
};
