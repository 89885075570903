import { DepartmentActionTypes } from "./../../actions/departmentActions";

const initialState = {
    departments: [],
    department: {},
    pagination: {
        records: 0,
        per_page_records: 0,
        page: 0,
        pages: 0,
    },
    apiResponse: {
        getDepartments: {},
        getDepartment: {},
        addDepartment: {},
        deleteDepartment: {},
        updateDepartment: {},
    },
};

const reducer = (state = initialState, action: any) => {
    switch (action.type) {
        //DEPARTMENTS
        case DepartmentActionTypes.GET_DEPARTMENTS_SUCCESS:
            // console.log("get departments success", action.payload.data);
            return { ...state, departments: action.payload.data.data, pagination: action.payload.data.pagination };
        // return { ...state, departments: action.payload.data };
        case DepartmentActionTypes.GET_DEPARTMENTS_FAILURE:
            return { ...state, apiResponse: { getDepartments: action.payload } };

        //DEPARTMENT
        case DepartmentActionTypes.GET_DEPARTMENT_SUCCESS:
            return { ...state, department: action.payload.data[0] };
        case DepartmentActionTypes.GET_DEPARTMENT_FAILURE:
            return { ...state, apiResponse: { getDepartment: action.payload } };

        //ADD DEPARTMENT
        case DepartmentActionTypes.ADD_DEPARTMENT_SUCCESS:
            return {
                ...state,
                apiResponse: { addDepartment: { status: "success" } },
                departments: [...state.departments, action.payload.data[0]],
            };
        case DepartmentActionTypes.ADD_DEPARTMENT_FAILURE:
            return { ...state, apiResponse: { addDepartment: action.payload } };

        //UPDATE DEPARTMENT
        case DepartmentActionTypes.UPDATE_DEPARTMENT_SUCCESS:
            return {
                ...state,
                departments: state.departments.map((department: any) => {
                    if (department.id === action.payload[0].id) {
                        return action.payload[0];
                    } else {
                        return department;
                    }
                }),
            };
        case DepartmentActionTypes.ADD_DEPARTMENT_FAILURE:
            return { ...state };

        //DELETE DEPARTMENT
        case DepartmentActionTypes.DELETE_DEPARTMENT_SUCCESS:
            return {
                ...state,
                departments: state.departments.filter((department: any) => {
                    return department.id !== action.payload.id;
                }),
            };
        case DepartmentActionTypes.DELETE_DEPARTMENT_FAILURE:
            return { ...state, apiResponse: { deleteDepartment: action.payload } };

        // CLEAR DEPARTMENT
        case DepartmentActionTypes.CLEAR_DEPARTMENT:
            return {
                ...state,
                department: {},
            };

        case DepartmentActionTypes.RESET:
            return { ...state, ...initialState };

        //DEFAULT
        default:
            return state;
    }
};

export { reducer as departmentReducer };
