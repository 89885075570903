import React, { FC, ReactNode } from "react";

import classnames from "classnames/bind";
import styles from "./bodyContent.scss";
const cx = classnames.bind(styles);

interface PageProps {
    children: ReactNode;
}

const BodyContent: FC<PageProps> = ({ children }) => {
    return <div className={cx("bodyContent")}>{children}</div>;
};

export default BodyContent;
