import React, { FC, useState, useEffect } from "react";
import { Dispatch } from "redux";
import { useLocation, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Box, Typography } from "@material-ui/core";
import BaseModal from "./../../../components/common/base-modal/baseModal";
import InputTextField from "./../../../components/common/text-field/TextField";
import { API } from "./../../../hrm/sagas/feature/company/api";
import { openSnackbar, closeSnackbar } from "../../../actions/feature/app/appActions";
import { getCompany } from "./../../../hrm/actions/companyActions";
import { getAuthToken } from "../../../utils/app-utils";

import classnames from "classnames/bind";
import styles from "./SetupCompany.scss";
const cx = classnames.bind(styles);

interface IPageProps {
    user: any;
    company: any;
    openSnackbar: (payload: any) => null;
    closeSnackbar: () => void;
    getCompany: () => void;
}

const SetupCompany: FC<IPageProps> = ({ user, company, openSnackbar, closeSnackbar, getCompany }) => {
    const [open, setOpen] = useState(false);
    const [domainExist, setDomainExist] = useState<boolean>(false);
    const location = useLocation();
    const history = useHistory;

    const [formValues, setFormValues] = useState({
        companyName: "",
        companyDomain: "",
        //timeZone: "",
    });
    const [formValidations, setFormValidations] = useState({
        companyName: false,
        companyDomain: false,
        //timeZone: false,
    });

    React.useEffect(() => {
        getCompany();
        closeSnackbar();
    }, []);

    React.useEffect(() => {
        if (open) {
            setFormValues({ ...formValues, companyName: "", companyDomain: "" });
            setFormValidations({ ...formValidations, companyName: false, companyDomain: false });
        }
    }, [open]);

    React.useEffect(() => {
        if (open) {
            setFormValues({
                ...formValues,
                companyName: company?.CompanyName,
                companyDomain: company?.CompanyDomain,
                //timeZone: company?.companyTimeZoneId,
            });
        }
    }, [open, user, company]);

    // useEffect(() => {
    //     if (formValues.timeZone) {
    //         const { timezone } = config;
    //         const timeZoneObj: any = timezone?.find((item: any) => item.id == formValues.timeZone);

    //         if (timeZoneObj) {
    //             setSelectedTimeZone({
    //                 label: timeZoneObj.TimezoneTitle,
    //                 value: timeZoneObj.id,
    //             });
    //         }
    //     }
    // }, [formValues.timeZone]);

    const inputChangeHandler = (evt: any) => {
        setFormValues({ ...formValues, [evt.target.name]: evt.target.value });
    };

    const companyNameChangeHandler = (evt: any) => {
        setFormValues({ ...formValues, [evt.target.name]: evt.target.value });
    };

    const domainNameChangeHandler = (evt: any) => {
        const regexp = new RegExp("^[A-Za-z0-9-]+$");
        if (!regexp.test(evt.target.value) && evt.target.value !== "") return false;
        setFormValues({ ...formValues, [evt.target.name]: evt.target.value });
    };

    const validateFormValues = () => {
        let isValid = true;
        let tempFormValidations = {};
        for (const [key, value] of Object.entries(formValues)) {
            if (!value) {
                isValid = false;
                tempFormValidations[`${key}`] = true;
            } else {
                tempFormValidations[`${key}`] = false;
            }
        }
        setFormValidations({ ...formValidations, ...tempFormValidations });
        return isValid;
    };

    const handleOnSubmit = () => {
        if (validateFormValues()) {
            const payload = {
                CompanyName: formValues.companyName,
                CompanyDomain: formValues.companyDomain,
                //TimeZoneId: parseInt(formValues.timeZone),
                id: company?.id,
            };
            API.updateCompany(payload)
                .then(
                    (res) => {
                        const { status, data, description } = res;
                        if (status === "SUCCESS") {
                            setDomainExist(false);
                            setOpen(false);
                            getCompany();
                            //setSubdomain(formValues.companyDomain);
                            openSnackbar("Company details updated successfully");

                            setTimeout(() => {
                                closeSnackbar();
                                if (process.env.REACT_APP_ENV === "production") {
                                    if (data[0]?.CompanyDomain) {
                                        location.href = `https://${data[0]?.CompanyDomain}.collabey.com/hrm/chat`;
                                    } else {
                                        location.href = `https://app.collabey.com/hrm/chat`;
                                    }
                                } else {
                                    location.href = `http://localhost:7006/hrm/chat`;
                                }
                            }, 1000);
                        } else if (status === "ERROR" && description === "DOMAIN_ALREADY_EXIST") {
                            setDomainExist(true);
                            setOpen(true);
                        } else {
                            openSnackbar("Invalid error, please try again");
                            setDomainExist(false);
                            setOpen(true);
                        }
                    },
                    (res) => {
                        setOpen(true);
                        openSnackbar("Invalid error, please try again");
                    }
                )
                .finally(() => {
                    setTimeout(() => {
                        closeSnackbar();
                    }, 3000);
                });
        }
    };

    React.useEffect(() => {
        if (
            !open &&
            user?.RoleName === "ADMINISTRATOR" &&
            company?.id &&
            (company?.CompanyName === "" || company?.CompanyDomain === "")
        ) {
            setOpen(true);
        }
    }, [company, user]);

    return (
        <BaseModal
            showClose={false}
            title={"Setup Company"}
            open={open}
            handleClose={() => setOpen(false)}
            maxWidth="lg"
            scroll="body"
        >
            <Box width={704} minHeight={480} height="auto">
                <div className={cx("createCommunity")}>
                    <div className={cx("row")}>
                        <InputTextField
                            error={formValidations.companyName}
                            errorText={"This field is required"}
                            name="companyName"
                            required={true}
                            label="Company Name"
                            value={formValues.companyName}
                            onChange={companyNameChangeHandler}
                        />
                    </div>
                    <div className={cx("row", "half")}>
                        {/* <Typography>https://</Typography> */}
                        <InputTextField
                            error={formValidations.companyDomain || domainExist}
                            errorText={domainExist ? "Domain already exists" : "This field is required"}
                            name="companyDomain"
                            required={true}
                            label="Company Domain Name"
                            value={formValues.companyDomain}
                            helperText="Only characters, numbers and hyphen allowed"
                            onChange={domainNameChangeHandler}
                        />
                        <Typography>.collabey.com</Typography>
                    </div>
                    {/* <div className={cx("row")}>
                        <Autocomplete
                            freeSolo
                            options={getTimezoneOptions(config)}
                            getOptionLabel={(option: any) => option?.label}
                            value={selectedTimeZone}
                            renderOption={(option) => <React.Fragment>{option.label}</React.Fragment>}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    name={formValues.timeZone}
                                    label={"TimeZone"}
                                    error={formValidations.timeZone}
                                    helperText={formValidations.timeZone ? "This field is required" : ""}
                                    required
                                    variant="outlined"
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: "new-password", // disable autocomplete and autofill
                                    }}
                                />
                            )}
                            onChange={(event, newValue: any) => {
                                setFormValues({ ...formValues, timeZone: newValue?.value });
                            }}
                        />
                    </div> */}
                    <Box className={cx("bottomBar")}>
                        <Button variant={"contained"} color={"primary"} onClick={handleOnSubmit}>
                            Submit
                        </Button>
                    </Box>
                </div>
            </Box>
        </BaseModal>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.user.profile.user,
        company: state.company.company,
        //config: state.app.config,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    openSnackbar: (payload: any) => dispatch(openSnackbar(payload)),
    closeSnackbar: () => dispatch(closeSnackbar()),
    getCompany: () => dispatch(getCompany()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SetupCompany);
