import * as React from "react";
import { Route, Switch } from "react-router-dom";

import Dashboard from "../feature/dashboard/Dashboard";
import Users from "../feature/users/users/Users";

import Workspaces from "../feature/kanban/workspaces";
import Workspace from "../feature/kanban/workspaces/workspace";
import Project from "../feature/kanban/project/Project";

//import Roles from "../feature/users/roles/Roles";
//import Permissions from "../feature/users/permissions/Permissions";
import Company from "../feature/settings/company/Company";
import Office from "../feature/settings/company/Office";
import Department from "../feature/settings/organization/Department";
import Designations from "../feature/settings/organization/Designations";
import Billing from "../feature/settings/billing/Billing";
import Profile from "../feature/profile/Profile";
import ChangePassword from "../feature/profile/ChangePassword";
// import AccountSettings from "../feature/profile/accountSettings";
// import Preferences from "../feature/profile/Preferences";

import Payment from "../feature/payment/Payment";
// import StripeConnect from "../feature/payment/stripe/StripeConnect";
// import StripeSuccess from "../feature/payment/stripe/StripeSuccess";
import PaymentSuccess from "../feature/payment/PaymentSuccess";
import PaymentFailure from "../feature/payment/PaymentFailure";
import PaymentCancel from "../feature/payment/PaymentCancel";
import NotFound from "../feature/notFound/NotFound";

import Chat from "../feature/chat/Chat";

const Routes = ({ location }) => {
    return (
        <Switch location={location}>
            <Route path="/" exact={true} component={Dashboard} />
            {/* <Route path="/hrm/users/roles" component={Roles} />
            <Route path="/hrm/users/permissions" component={Permissions} /> */}
            <Route path="/hrm/users" component={Users} />
            {/* <Route path="/hrm/roles" component={Roles} />
            <Route path="/hrm/permissions" component={Permissions} /> */}
            <Route path="/hrm/department" component={Department} />
            <Route path="/hrm/designation" component={Designations} />
            <Route path="/hrm/company" component={Company} />
            <Route path="/hrm/office" component={Office} />

            {/* <Route exact={true} path="/hrm/chat/:id" component={Chat} />
            
             */}

            <Route exact={true} path="/hrm/chat/:id" component={Chat} />
            <Route path="/hrm/chat" component={Chat} />

            {/* <Route path="/hrm/settings/user-access-control" component={UserAccessControl} /> */}
            {/* <Route path="/hrm/settings" component={Settings} /> */}
            <Route path="/hrm/profile/change-password" component={ChangePassword} />
            {/* <Route path="/hrm/profile/account-settings" component={AccountSettings} /> */}

            {/* <Route path="/hrm/profile/preferences" component={Preferences} /> */}
            <Route path="/hrm/profile" component={Profile} />
            {/* <Route path="/hrm/dashboard" component={Dashboard} /> */}

            <Route path="/hrm/payment" exact={true} component={Payment} />
            {/* <Route path="/hrm/billing/stripe-connect" exact={true} component={StripeConnect} /> */}
            {/* <Route path="/hrm/billing/stripe-success" exact={true} component={StripeSuccess} /> */}

            <Route path="/hrm/billing/payment-success" exact={true} component={PaymentSuccess} />
            <Route path="/hrm/billing/payment-failure" exact={true} component={PaymentFailure} />
            <Route path="/hrm/billing/payment-cancel" exact={true} component={PaymentCancel} />
            <Route path="/hrm/billing" component={Billing} />

            {/* <Route path="/hrm/workspaces/:workspaceId/:projectId/:taskId" component={Project} exact /> */}
            <Route path="/hrm/workspaces/:workspaceId/:projectId" component={Project} exact />
            <Route path="/hrm/workspaces/:workspaceId" component={Workspace} exact />
            <Route path="/hrm/workspaces" component={Workspaces} exact />

            <Route path="*" component={NotFound} />
        </Switch>
    );
};

export default Routes;
