import { CompanyActionTypes } from "./../../actions/companyActions";

const initialState = {
    company: {},
    apiResponse: {
        getCompany: {},
        updateCompany: {},
        closeCompany: {},
    },
};

const reducer = (state = initialState, action: any) => {
    switch (action.type) {
        //COMPANY
        case CompanyActionTypes.GET_COMPANY_SUCCESS:
            return { ...state, company: action.payload.data[0] };
        case CompanyActionTypes.GET_COMPANY_FAILURE:
            return { ...state, apiResponse: { ...state.apiResponse, getCompany: action.payload } };

        //UPDATE COMPANY
        case CompanyActionTypes.UPDATE_COMPANY_SUCCESS:
            return { ...state, company: action.payload[0] };
        case CompanyActionTypes.UPDATE_COMPANY_FAILURE:
            return { ...state, apiResponse: { updateCompany: action.payload } };

        //CLOSE COMPANY
        case CompanyActionTypes.CLOSE_COMPANY_SUCCESS:
            return { ...state };
        case CompanyActionTypes.CLOSE_COMPANY_FAILURE:
            return { ...state, apiResponse: { closeCompany: action.payload } };

        // CLEAR COMPANY
        case CompanyActionTypes.CLEAR_COMPANY:
            return {
                ...state,
                company: {},
            };

        case CompanyActionTypes.RESET:
            return { ...state, ...initialState };

        //DEFAULT
        default:
            return state;
    }
};

export { reducer as companyReducer };
