// import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";
// import { blue } from "@material-ui/core/colors";

// const baseTheme = createMuiTheme({
//     props: {
//         MuiPaper: {
//             elevation: 0,
//         },
//         MuiAppBar: {
//             elevation: 1,
//         },
//         MuiButton: {
//             elevation: 1,
//         },
//         MuiMenu: {
//             elevation: 1,
//         },
//         MuiCard: {
//             elevation: 0,
//         },
//     },
//     overrides: {
//         MuiButton: {
//             root: {
//                 minWidth: 1,
//                 padding: "4px",
//             },
//             contained: {
//                 boxShadow: "none",
//                 "&:active": {
//                     boxShadow: "none",
//                 },
//                 "&:focus": {
//                     boxShadow: "none",
//                 },
//             },
//             containedSecondary: {
//                 color: "#fff",
//                 "&:hover": {
//                     backgroundColor: blue[500],
//                 },
//             },
//         },
//         MuiButtonGroup: {
//             root: {
//                 boxShadow: "none",
//             },
//             contained: {
//                 boxShadow: "none",
//                 "&:active": {
//                     boxShadow: "none",
//                 },
//                 "&:focus": {
//                     boxShadow: "none",
//                 },
//             },
//         },
//         MuiListItemIcon: {
//             root: {
//                 minWidth: 40,
//             },
//         },
//         MuiCardContent: {
//             root: {
//                 "&:last-child": {
//                     paddingBottom: 16,
//                 },
//             },
//         },
//         MuiLinearProgress: {
//             root: {
//                 background: "#f3f3f3 !important",
//             },
//         },
//     },
//     palette: {
//         divider: "rgba(30, 30, 30, 0.06)",
//         secondary: {
//             main: blue[100], //'#619f30',
//         },
//         primary: {
//             //main: "#00B6EE", //'#619f30', 009be5
//             // main: '#9027d1', //'#619f30',
//             main: blue[500], //'#619f30',
//         },
//         text: {
//             secondary: "rgba(102, 102, 102, 0.83)",
//             positive: "#8cd136",
//             negative: "#e35959",
//         },
//     },
//     typography: {
//         fontSize: 16,
//     },
// });

// const adminTheme = {
//     header: {
//         background: "#FFF",
//     },
//     sidebar: {
//         width: 255,
//         widthCollapsed: baseTheme.spacing(7),
//         background: "#4a4d5a;",
//         color: "#fff",
//     },
// };

// const theme = {
//     ...responsiveFontSizes(baseTheme),
//     ...adminTheme,
// };

// export default theme;

import { createMuiTheme } from "@material-ui/core/styles";
import { ImportantDevices } from "@material-ui/icons";
import { transcode } from "buffer";
import colors from "./colors";

declare module "@material-ui/core/styles/overrides" {
    export interface ComponentNameToClassKey {
        MuiAutocomplete: {
            inputRoot: {
                height: number;
            };
        };
    }
}

const theme = createMuiTheme({
    typography: {
        fontFamily: ["Roboto", "Arial", "sans-serif"].join(","),
    },
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: colors.primary,
            // dark: will be calculated from palette.primary.main,
            contrastText: colors.white,
        },
        secondary: {
            main: colors.secondary,
            // dark: will be calculated from palette.secondary.main,
            contrastText: colors.white,
        },
        // Used by `getContrastText()` to maximize the contrast between
        // the background and the text.
        contrastThreshold: 3,
        // Used by the functions below to shift a color's luminance by approximately
        // two indexes within its tonal palette.
        // E.g., shift from Red 500 to Red 300 or Red 700.
        tonalOffset: 0.2,
        error: {
            main: colors.red,
        },
        text: {
            primary: colors.dark,
        },
    },
    props: {
        MuiButtonBase: {
            disableRipple: true,
        },
    },
    overrides: {
        MuiCheckbox: {
            root: {
                padding: "0",
                color: colors.primary,
                "&:hover": {
                    backgroundColor: "transparent",
                },
            },
            colorPrimary: {
                color: colors.primary,
                "&$checked": {
                    color: colors.primary,
                    "&:hover": {
                        backgroundColor: "transparent",
                    },
                },
            },
        },
        MuiButton: {
            root: {
                fontSize: 16,
                lineHeight: "24px",
                color: colors.darkGrey,
                textTransform: "none",
                "&:hover": { backgroundColor: "transparent" },
            },
            contained: {
                boxShadow: "none",
            },
            containedSizeLarge: {
                padding: "16px 32px",
                fontSize: 16,
            },
            label: {
                padding: 0,
            },
            textSizeSmall: {
                padding: 0,
                fontSize: 14,
            },
            text: {
                padding: 0,
                minWidth: "initial",
                color: colors.darkGrey,
                "&:hover": {
                    color: colors.lightGrey,
                    backgroundColor: "transparent",
                },
            },
            textPrimary: {
                "&:hover": {
                    color: colors.darkPrimary,
                    backgroundColor: "transparent",
                },
            },
            outlinedSizeSmall: {
                padding: "6px 16px",
                fontSize: 14,
                lineHeight: "18px",
            },
            // outlinedSizeMedium: {
            //     padding: "12px 24px",
            //     fontSize: 14,
            //     lineHeight: "18px",
            // },
            // outlinedSizeMedium: {
            //     padding: "12px 24px",
            //     fontSize: 14,
            //     lineHeight: "18px",
            // },
            outlinedPrimary: {
                height: "auto",
                padding: "16px 32px",
                "&:hover": { backgroundColor: colors.lightestPrimary },
            },
            outlinedSecondary: {
                backgroundColor: colors.lightGrey,
                color: colors.dark,
                border: `1px solid ${colors.darkerGrey}`,
                "&:hover": {
                    backgroundColor: colors.lighterGrey,
                    border: `1px solid ${colors.darkerGrey}`,
                },
            },
            containedSizeSmall: {
                padding: "12px 24px",
            },
            containedPrimary: {
                height: "auto",
                padding: "16px 32px",
                "&:hover": { backgroundColor: colors.darkPrimary, boxShadow: "none" },
                "&$disabled": {
                    backgroundColor: colors.darkPrimary,
                    color: colors.white,
                },
            },
        },
        MuiMenu: {
            paper: {
                background: colors.light,
                color: colors.dark,
                border: `1px solid ${colors.lightGrey}`
            },
        },
        MuiPaper: {
            elevation1: {
                boxShadow: "2px 2px 8px #E6EAF2",
            },
            elevation8: {
                boxShadow: "2px 2px 8px #E6EAF2",
            },
        },
        MuiExpansionPanel: {
            rounded: {
                borderRadius: 4,
            },
            root: {
                background: `${colors.white} !important`,
            },
        },
        MuiDrawer: {
            paper: {
                width: "50%",
            },
        },
        MuiTableCell: {
            head: {
                // paddingTop: 0,
                color: colors.darkGrey,
            },
            paddingNone: {
                padding: "16px",
            },
        },
        MuiInputBase: {
            // input: {
            //     border: "1px solid"
            // },
            root: {
                height: 48,
            },
            multiline: {
                height: "auto!important",
                minHeight: "48px"
            }
        },
        MuiMenuItem: {
            root: {
                width: "100%",
            }
        },
        MuiFormHelperText: {
            root: {
                marginLeft: 0,
                marginRight: 0,
                marginTop: 4,
                fontSize: 12,
                lineHeight: "18px",
            },
            contained: {
                marginLeft: 0,
                marginRight: 0,
                marginTop: 4,
                fontSize: 12,
                lineHeight: "18px",
            },
        },
        MuiAutocomplete: {
            inputRoot: {
                height: "auto",
                minHeight: 56,
            },
        },
        MuiCardContent: {
            root: {
                padding: 0,
            },
        },
        MuiTypography: {
            h1: {
                fontSize: 38,
                lineHeight: "36px",
                fontWeight: 500,
            },
            h2: {
                fontSize: 36,
                lineHeight: "36px",
                fontWeight: 500,
            },
            h3: {
                fontSize: 24,
                lineHeight: "36px",
                fontWeight: 500,
            },
            h4: {
                fontSize: 18,
                lineHeight: "28px",
                fontWeight: 500,
            },
            body1: {
                fontSize: 16,
                lineHeight: "24px",
            },
            body2: {
                fontSize: 14,
                lineHeight: "20px",
            },
        },
        MuiListItemText: {
            root: {
                color: colors.bodyDark,
            },
        },
        MuiOutlinedInput: {
            root: {
                boxSizing: "border-box",
            },
            input: {
                fontSize: 14,
                "&::placeholder": {
                    fontSize: 16,
                    color: "#000",
                },
            },
        },
        // PrivateNotchedOutline: {
        //     root: {
        //         boxSizing: "border-box",
        //     },
        // },
        // MuiBox: {
        //     root: {
        //         marginRight: "8px",
        //     },
        // },
        MuiFormControl: {
            root: {
                width: "100%",
            },
        },
        MuiSvgIcon: {
            root: {
                color: colors.primary,
                "&:hover": {
                    cursor: "pointer",
                    // background: "#e6f8ff",
                    // borderRadius: "4px"
                }
            },
        },
        MuiInputLabel: {
            outlined: {
                transform: "translate(14px, 15px) scale(1)",
            },
            root: {
                fontSize: 14,
                lineHeight: "21px",
                color: colors.darkGrey,
            },
        },
        MuiDialogActions: {
            spacing: {
                padding: "20px",
            },
        },
        MuiDialog: {
            paperWidthMd: {
                width: "40%",
            },
        },
        MuiGrid: {
            root: {
                padding: "8px 12px!important",
            },
        },
        MuiSnackbar: {
            root: {
                zIndex: 2000,
            },
        },
    },
});

export default theme;
