import { buildGET, buildAuthPOST, buildDELETE, buildAuthPUT } from "./../../../../utils/api";

// APIs
const getOffices = () => {
    return buildGET(`office`);
};

const getOffice = (id) => buildGET(`office/${id}`);

const addOffice = (payload) => buildAuthPOST("office", payload);

const updateOffice = (payload) => {
    const { id, ...rest } = payload;
    return buildAuthPUT(`office/${id}`, rest);
};

const deleteOffice = (id) => buildDELETE(`office/${id}`);

// API export
export const API = {
    getOffices,
    getOffice,
    addOffice,
    deleteOffice,
    updateOffice,
};
