import React, { useState } from "react";
import classnames from "classnames/bind";
import styles from "./renderImage.scss";

const cx = classnames.bind(styles);

interface Props {
    src: string;
}

function RenderImage(props: Props) {
    const { src } = props;
    const [isVisibleOriginal, setIsVisibleOriginal] = useState(false);
    const handleToggleOriginal = (state) => {
        setIsVisibleOriginal(state);
    };
    return (
        <div className={"imageOverlayContainer"}>
            <img style={{ width: "300px", cursor: "zoom-in" }} onClick={() => handleToggleOriginal(true)} src={src} />
            <div
                id="modal01"
                className="w3-modal"
                style={{ display: isVisibleOriginal ? "block" : "none" }}
                onClick={() => handleToggleOriginal(false)}
            >
                <span className="w3-button w3-hover-red w3-xlarge w3-display-topright">&times;</span>
                <div className="w3-modal-content w3-animate-zoom">
                    <img className="w3-modal-content" src={src} style={{ width: "100%" }} />
                </div>
            </div>
        </div>
    );
}

export default RenderImage;
