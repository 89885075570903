import React, { FC, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Typography, TextField, Checkbox, CircularProgress, Chip } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { API } from "../../../sagas/feature/company/api";

import classnames from "classnames/bind";
import styles from "./MembersDropdown.scss";
const cx = classnames.bind(styles);

interface PageProps {
    users?: any;
    user: any;
    id?: any;
    toText?: string;
    isError?: boolean;
    existingUsers: any;
    workspaceId?: string;
    setUsers: (usersList: any) => void;
    entryPoint?: boolean;
}

const AddNewMember: FC<PageProps> = ({
    users,
    user,
    id,
    toText,
    isError = false,
    existingUsers,
    setUsers,
    workspaceId,
    entryPoint = "chat", //chat or workspace
}) => {
    const [open, setOpen] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [options, setOptions] = useState<any>([]);
    const loading = open && options.length === 0;
    const [formValues, setFormValues] = useState<any>({
        users: "",
    });
    const [isLoading, setIsLoading] = useState(true);
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;

    React.useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    React.useEffect(() => {
        setUsers && setUsers(formValues?.users);
    }, [formValues?.users]);

    React.useEffect(() => {
        let active = true;

        (async () => {
            if (workspaceId) {
                API.getCompanyWorkspaceUsers(workspaceId).then(
                    (res) => {
                        const { status, data } = res;
                        if (status === "SUCCESS") {
                            if (true) {
                                const users = data
                                    .filter((dataItem) => {
                                        // OWN id doesn't included
                                        //return dataItem.id != user?.id && dataItem?.UserStatus === "Active";
                                        return dataItem?.UserStatus === "Active";
                                    })
                                    .map((item: any) => ({
                                        userID: item.id,
                                        userFullName: item.UserFullName,
                                    }));
                                setOptions(users);
                            }
                        } else {
                            setOpen(false);
                        }
                        setIsLoading(false);
                    },
                    (res) => {
                        setIsLoading(false);
                        setOpen(false);
                    }
                );
            } else {
                API.getCompanyUsers().then(
                    (res) => {
                        const { status, data } = res;
                        if (status === "SUCCESS") {
                            if (true) {
                                const users = data
                                    .filter((dataItem) => {
                                        // OWN id doesn't included
                                        return dataItem.id != user?.id && dataItem?.UserStatus === "Active";
                                    })
                                    .map((item: any) => ({
                                        userID: item.id,
                                        userFullName: item.UserFullName,
                                    }));
                                setOptions(users);
                            }
                        } else {
                            setOpen(false);
                        }
                        setIsLoading(false);
                    },
                    (res) => {
                        setIsLoading(false);
                        setOpen(false);
                    }
                );
            }
        })();

        return () => {
            active = false;
        };
    }, []);

    React.useEffect(() => {
        if (isVisible) {
            const users =
                options?.length &&
                options
                    .filter((dataItem) => dataItem.userID != user?.id)
                    .map((item: any) => ({
                        userID: item.userID,
                        userFullName: item.userFullName,
                    }));

            setOptions(users);
        }
    }, [isVisible, user]);

    const userAlreadyAdded = (id: any) => {
        const userFound = existingUsers.indexOf(parseInt(id)) >= 0;
        return userFound ? true : false;
    };

    //!options || options?.length <= 0 ||
    if (isLoading) {
        return <Typography>Loading users...</Typography>;
    } else if (options?.length <= 0) {
        if (entryPoint === "chat") {
            return (
                <div>
                    No user found, Click <Link to="/hrm/users">here</Link> to create new user
                </div>
            );
        } else {
            return <span></span>;
        }
    }
    return (
        <div className={cx("membersContainer")}>
            {options?.length <= 0 && !isLoading ? (
                <div>{/* <Typography>Loading...</Typography> */}</div>
            ) : (
                <>
                    <div className={cx("addMembersContainer")}>
                        {toText && <Typography>{toText}</Typography>}
                        <div className={cx("userAutoComplete")}>
                            <Autocomplete
                                multiple
                                limitTags={2}
                                freeSolo
                                disableCloseOnSelect
                                id="multiple-limit-tags"
                                options={options}
                                getOptionLabel={(option: any) => option?.userFullName}
                                getOptionDisabled={(option) => {
                                    if (workspaceId) {
                                        return userAlreadyAdded(option.userID);
                                    } else {
                                        return option.userID == user?.id || userAlreadyAdded(option.userID);
                                    }
                                }}
                                renderTags={(tagValue, getTagProps) =>
                                    tagValue.map((option, index) => (
                                        <Chip
                                            label={option.userFullName}
                                            {...getTagProps({ index })}
                                            disabled={option.userID == user?.id}
                                        />
                                    ))
                                }
                                renderOption={(option: any, { selected }) => {
                                    return (
                                        <React.Fragment>
                                            <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                            />
                                            {option.userFullName}
                                        </React.Fragment>
                                    );
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Select Members"
                                        variant="outlined"
                                        placeholder="Members"
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <React.Fragment>
                                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                    {params.InputProps.endAdornment}
                                                </React.Fragment>
                                            ),
                                        }}
                                    />
                                )}
                                onChange={(event, newValue, reason, detail) => {
                                    setFormValues({ ...formValues, users: newValue });
                                }}
                            />
                            {isError && <div className={cx("error")}>Select users</div>}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.user.profile.user,
    };
};

export default connect(mapStateToProps, null)(AddNewMember);
