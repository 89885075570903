import { fork, put, delay, takeLatest, select, all } from "redux-saga/effects";
import { API } from "./api";
import { getIn } from "../../../../utils/app-utils";
import callApi from "../../../../utils/callApi";

import {
    getDepartment,
    getDepartmentsFailure,
    getDepartmentsSuccess,
    getDepartmentFailure,
    getDepartmentSuccess,
    DepartmentActionTypes,
    addDepartmentSuccess,
    addDepartmentFailure,
    deleteDepartmentSuccess,
    deleteDepartmentFailure,
    updateDepartmentSuccess,
    updateDepartmentFailure,
} from "../../../actions/departmentActions";
import {
    isLoading,
    openSnackbar,
    closeSnackbar,
    toggleDrawer,
    openAlert,
} from "../../../../actions/feature/app/appActions";

function* getDepartmentsSaga() {
    try {
        yield put(isLoading(true));
        const [error, res] = yield* callApi(API.getDepartments, { disableLoading: true }, {}, true);
        if (error || res.status === "ERROR") {
            yield put(getDepartmentsFailure({ status: "ERROR" }));
        } else {
            yield put(getDepartmentsSuccess(res));
        }
        yield put(isLoading(false));
    } catch (error) {
        yield put(isLoading(false));
        yield put(getDepartmentsFailure({ status: "ERROR" }));
    }
}

function* getDepartmentSaga(reqObject) {
    const { payload } = reqObject;
    try {
        yield put(isLoading(true));
        const [error, res] = yield* callApi(API.getDepartment, { disableLoading: true }, payload, true);
        if (error || res.status === "ERROR") {
            yield put(getDepartmentFailure({ status: "ERROR" }));
        } else {
            yield put(getDepartmentSuccess(res));
        }
        yield put(isLoading(false));
    } catch (error) {
        yield put(isLoading(false));
        yield put(getDepartmentFailure({ status: "ERROR" }));
    }
}

function* addDepartmentSaga(reqObject) {
    const { payload } = reqObject;
    try {
        yield put(isLoading(true));
        const [error, res] = yield* callApi(API.addDepartment, { disableLoading: true }, payload, true);
        if (error || res.status === "ERROR") {
            if (res.description === "RECORD_ALREADY_EXIST") {
                yield put(openAlert({ message: "Department name already exist", type: "error" }));
            } else {
                yield put(openAlert({ message: "Invalid error, please try again", type: "error" }));
            }
            yield put(addDepartmentFailure({ status: "ERROR" }));
        } else {
            yield put(toggleDrawer(false));
            yield put(openSnackbar("Department added successfully"));
            yield put(addDepartmentSuccess(res));
        }

        yield put(isLoading(false));
        yield delay(3000);
        yield put(closeSnackbar());
    } catch (error) {
        yield put(isLoading(false));
        yield put(openSnackbar("Invalid error, please try again"));
        yield put(addDepartmentFailure({ status: "ERROR" }));
        yield delay(3000);
        yield put(closeSnackbar());
    }
}

function* updateDepartmentSaga(reqObject) {
    const { payload } = reqObject;
    try {
        yield put(isLoading(true));
        const [error, res] = yield* callApi(API.updateDepartment, { disableLoading: true }, payload, true);
        if (error || res.status === "ERROR") {
            if (res.description === "RECORD_ALREADY_EXIST") {
                yield put(openAlert({ message: "Department name already exist", type: "error" }));
            } else {
                yield put(openAlert({ message: "Invalid error, please try again", type: "error" }));
            }
            yield put(updateDepartmentFailure({ status: "ERROR" }));
        } else {
            yield put(toggleDrawer(false));
            yield put(openSnackbar("Department updated successfully"));
            yield put(updateDepartmentSuccess(res.data));
        }

        yield put(isLoading(false));
        yield delay(3000);
        yield put(closeSnackbar());
    } catch (error) {
        yield put(isLoading(false));
        yield put(openSnackbar("Invalid error, please try again kl"));
        yield put(updateDepartmentFailure({ status: "ERROR" }));
        yield delay(3000);
        yield put(closeSnackbar());
    }
}

function* deleteDepartmentSaga(reqObject) {
    const { payload } = reqObject;
    try {
        yield put(isLoading(true));
        const [error, res] = yield* callApi(API.deleteDepartment, { disableLoading: true }, payload, true);
        if (error || res.status === "ERROR") {
            yield put(openSnackbar("Invalid error, please try again"));
            yield put(deleteDepartmentFailure({ status: "ERROR" }));
        } else {
            yield put(openSnackbar("Department deleted successfully"));
            yield put(deleteDepartmentSuccess({ id: payload }));
        }

        yield put(isLoading(false));
        yield delay(3000);
        yield put(closeSnackbar());
    } catch (error) {
        yield put(isLoading(false));
        yield put(openSnackbar("Invalid error, please try again"));
        yield put(deleteDepartmentFailure({ status: "ERROR" }));
        yield delay(3000);
        yield put(closeSnackbar());
    }
}

function* watchDepartment() {
    yield takeLatest(DepartmentActionTypes.GET_DEPARTMENTS, getDepartmentsSaga);
    yield takeLatest(DepartmentActionTypes.GET_DEPARTMENT, getDepartmentSaga);
    yield takeLatest(DepartmentActionTypes.ADD_DEPARTMENT, addDepartmentSaga);
    yield takeLatest(DepartmentActionTypes.UPDATE_DEPARTMENT, updateDepartmentSaga);
    yield takeLatest(DepartmentActionTypes.DELETE_DEPARTMENT, deleteDepartmentSaga);
}

const departmentSagas = [fork(watchDepartment)];

export default departmentSagas;
