import React, { FC, useState, useEffect } from "react";
import { connect } from "react-redux";

import { Drawer } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import { getUser, updateUserContacts, updateUserInformation } from "./../../../../actions/userActions";
import { getDepartments } from "./../../../../actions/departmentActions";
import { getDesignations } from "./../../../../actions/designationActions";
import { getOffices } from "./../../../../actions/officeActions";
import { addUser } from "./../../../../actions/userActions";
import { getRoles } from "./../../../../actions/roleActions";
import PersonalInformation from "./../../profile/PersonalInformation";
import BasicInformation from "./../../profile/BasicInformation";
import EmergencyContact from "./../../profile/EmergencyContact";
import Alert from "./../../../../../components/common/alert/Alert";
import {
    updateUserBasicInfoSuccess,
    updateUserPersonalInfoSuccess,
    updateUserContactsSuccess,
} from "../../../../actions/userActions";
import {
    isLoading,
    openSnackbar,
    closeSnackbar,
    openAlert,
    closeAlert,
} from "../../../../../actions/feature/app/appActions";

import classnames from "classnames/bind";
import styles from "./../Styles.scss";
const cx = classnames.bind(styles);

interface IPageState {
    fullName: string;
    fullNameError: boolean;
    fullNameErrorText: string;

    email: string;
    emailError: boolean;
    emailErrorText: string;
}

interface IPageProps {
    id: string;
    isLoading: boolean;
    isDrawerOpen: boolean;
    user: {
        basicInfo: any;
        personalInfo: any;
        contacts: any;
    };
    departments: [];
    designations: [];
    offices: [];
    config: {};
    alert: {
        open: boolean;
        message: string;
        type: string;
    };
    getUser: (id: string) => null;
    getDepartments: () => null;
    getDesignations: () => null;
    getOffices: () => null;
    updateBasicInfoSuccess: (payload: any) => null;
    updatePersonalInfoSuccess: (payload: any) => null;
    updateContactsSuccess: (payload: any) => null;
    toggleDrawer: (isOpen: boolean) => null;
    closeAlert: () => void;
    openSnackbar: (payload: any) => null;
    closeSnackbar: () => null;
    openAlert: (payload: any) => null;
    getRoles: () => null;
    roles: [];
    currentUser: {};
    users: [];
}

const EditUser: FC<IPageProps> = ({
    id,
    isLoading,
    isDrawerOpen,
    user,
    departments,
    designations,
    offices,
    config,
    alert,
    getUser,
    getDepartments,
    getDesignations,
    updateBasicInfoSuccess,
    updatePersonalInfoSuccess,
    updateContactsSuccess,
    toggleDrawer,
    closeAlert,
    openSnackbar,
    closeSnackbar,
    openAlert,
    getRoles,
    getOffices,
    roles,
    currentUser,
    users,
}) => {
    useEffect(() => {
        if (id) {
            getUser(id);
        }
        getDepartments();
        getDesignations();
        getOffices();
        getRoles();
    }, []);

    useEffect(() => {
        closeAlert();
    }, []);

    const closeDrawer = () => {
        toggleDrawer(false);
    };

    return (
        <Drawer anchor={"right"} open={isDrawerOpen} onClose={closeDrawer}>
            {alert.open && alert.message && (
                <Alert onClose={() => {}} message={alert.message} type={alert.type}></Alert>
            )}
            <div role="presentation" className={cx("drawerContainer")}>
                <div className={cx("drawerTitle")}>
                    <h3>{id ? "User Details" : "Add User"}</h3>
                    <div className={cx("icon")}>
                        <CloseIcon color="primary" onClick={closeDrawer} onKeyDown={closeDrawer} />
                    </div>
                </div>
                <div className={cx("drawerSection")}>
                    <BasicInformation
                        profile={user.basicInfo}
                        users={users}
                        departments={departments}
                        designations={designations}
                        offices={offices}
                        config={config}
                        alert={alert}
                        closeAlert={closeAlert}
                        openSnackbar={openSnackbar}
                        closeSnackbar={closeSnackbar}
                        updateBasicInfoSuccess={updateBasicInfoSuccess}
                        openAlert={openAlert}
                        roles={roles}
                        currentUser={currentUser}
                    />
                    <PersonalInformation
                        profile={user.personalInfo}
                        config={config}
                        alert={alert}
                        closeAlert={closeAlert}
                        openSnackbar={openSnackbar}
                        closeSnackbar={closeSnackbar}
                        updatePersonalInfoSuccess={updatePersonalInfoSuccess}
                        openAlert={openAlert}
                    />

                    <EmergencyContact
                        contacts={user.contacts}
                        id={user.basicInfo.id}
                        config={config}
                        updateContactsSuccess={updateContactsSuccess}
                        alert={alert}
                        closeAlert={closeAlert}
                        openSnackbar={openSnackbar}
                        closeSnackbar={closeSnackbar}
                        openAlert={openAlert}
                    />
                </div>
            </div>
        </Drawer>
    );
};

/* istanbul ignore next */
const mapStateToProps = (state: any) => {
    return {
        isLoading: state.app.isLoading,
        alert: state.app.alert,
        user: state.user.user || {},
        currentUser: state.user.profile.user || {},
        departments: state.department.departments || [],
        designations: state.designation.designations || [],
        offices: state.office.offices || [],
        config: state.app.config || {},
        roles: state.role.roles || [],
        users: state.user.users || [],
    };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: any) => ({
    getUser: (id: string) => dispatch(getUser(id)),
    getRoles: () => dispatch(getRoles()),
    getDepartments: () => dispatch(getDepartments()),
    getDesignations: () => dispatch(getDesignations()),
    getOffices: () => dispatch(getOffices()),
    updateBasicInfoSuccess: (payload: any) => dispatch(updateUserBasicInfoSuccess(payload)),
    updatePersonalInfoSuccess: (payload: any) => dispatch(updateUserPersonalInfoSuccess(payload)),
    updateContactsSuccess: (payload: any) => dispatch(updateUserContactsSuccess(payload)),
    closeAlert: () => dispatch(closeAlert()),
    openSnackbar: (payload: any) => dispatch(openSnackbar(payload)),
    closeSnackbar: () => dispatch(closeSnackbar()),
    openAlert: (payload: any) => dispatch(openAlert(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditUser);
